import React from 'react';
import EmailTemplate from '../../Interview/Create-Interview/EmailTemplate/EmailTemplate';

//  const value = renderEmail(<EmailTemplate />);
//     console.log(value);
const Template = () => {

    
    return (

       <></> 
    );
};

export default Template;