export const environment = {
  client_id: process.env.REACT_APP_client_id,
  aws_cognito_identity_pool_id: process.env.REACT_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: process.env.REACT_APP_aws_cognito_region,
  aws_user_pools_id: process.env.REACT_APP_aws_user_pools_id,
  domain: process.env.REACT_APP_domain,
  redirect_uri: process.env.REACT_APP_redirect_uri,
  socialRedirect_URI: process.env.REACT_APP_socialRedirect_URI,
  response_type: process.env.REACT_APP_response_type,
  refreshtokenKey: process.env.REACT_APP_refreshtokenKey,
  tokenEndPoint: process.env.REACT_APP_AWS_tokenEndPoint,
  videoInterviewUrl: process.env.REACT_APP_videoInterviewUrl,
  refresh_token_key: process.env.REACT_APP_refresh_token_key
};