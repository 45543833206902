import { AxiosInstanceCds } from "./http.handler";
import AppConstants from "../Constants";
export async function GetUserDetails(accessToken, username) {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    return await AxiosInstanceCds.get(`${AppConstants.serverConstants.CDS.GET_USER_DETAILS}?source=test&perpage=1&next=1&username=${username}`, {
        headers: headers
    })
        .then((response) => response
        )
        .catch((error) => error);
};

export const getUserRole = async (accessToken, userName) => {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };
    const data = JSON.stringify({ "username": userName, "category": "VideoInterview" });
    return await AxiosInstanceCds.post(`${AppConstants.serverConstants.CDS.GET_USER_ROLE}?source=test`, data, { headers: headers }
    )
        .then((response) => response
        )
}
