import React from 'react';
import InterviewList from './InterviewList';

const OneWayInterviewList = () => {
    return (
        <div>
        <InterviewList/>        
        </div>
    );
};

export default OneWayInterviewList;