import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Sidebar from './Sidebar/Sidebar';
import Dashboard from '../Dashboard/Dashboard';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Createinterview from '../Interview/Create-Interview/Createinterview';
import OneWayInterviewList from '../Interview/InterviewList/OneWayInterviewList';
import CreateJob from '../Job/Create-Job/CreateJob';
import InterviewList from '../Interview/InterviewList/InterviewList'
import Template from '../Template/email-template/Template';
import LiveInterviewList from '../Interview/InterviewList/LiveInterviewList';
import ViewJob from '../Job/ViewJob';
import InterviewVideoPreview from '../Dashboard/Video/InterviewVideoPreview';
import logo from '../../Assets/icons/logo.png';
import '../Navigation/Header/Header.css';
import Error_Page from '../Error/Error_Page';
import ArchiveInterviewList from '../Interview/InterviewList/ArchiveInterviewList';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AppDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [crossPageOpen, setCrossPageOpen] = React.useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Header handleDrawerOpen={handleDrawerOpen} open={open} />
        </AppBar>
        <Drawer variant="permanent" open={open} id='navigation-drawer'>
          <DrawerHeader>
            {
              open && <Box className='hitech-logo' sx={{ marginRight: '18px' }}><img src={logo} /></Box>
            }
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Sidebar menuOpen={open} crossPageOpen={crossPageOpen} setCrossPageOpen={setCrossPageOpen} />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <Routes>
            <Route path="*" element={<Error_Page errorType='404' />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route path='/dashboard' element={<Dashboard />} />
            <Route exact path='/dashboard/create-interview' element={<Createinterview open={open} />} />
            <Route path='/Job/create-job' element={<CreateJob setCrossPageOpen={setCrossPageOpen} />} />
            <Route path='/template/email-template' element={<Template />} />
            <Route path='/interview/interview-list' element={<InterviewList />} />
            <Route exact path='/interview/one-way-interviewlist' element={<OneWayInterviewList />} />
            <Route exact path='/interview/archive-interview' element={<ArchiveInterviewList />} />
            <Route exact path='/dashboard/review-interview' element={<InterviewVideoPreview />} />
            {/* <Route path='/settings' element={</>} /> */}
            <Route exact path='/job/view-job' element={<ViewJob _open={open} />} />
          </Routes>
          <Footer />
        </Box>
      </Box>
    </BrowserRouter>
  );
}
