
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import './Dashboard.css';
import { Button, Divider, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { GetVideo } from '../../handlers/interviewVideo';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import usePagination from '../Job/Pagination';
import Pagination from '@mui/material/Pagination';
import Loader from '../Preloaders/Loader';
import { handleJobs, ViewJobList } from '../../handlers/job_handlers';
import { filterDataList } from '../../utils/Helper/filterDataList';
import ClearIcon from '@mui/icons-material/Clear';
import InterviewVideoPreview from './Video/InterviewVideoPreview';
import { useNavigate, useLocation } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import logo from '../../Assets/icons/globalhunt-india-image.png';
import logo2 from '../../Assets/icons/Hitech-logo.png'
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NativeSelect from '@mui/material/NativeSelect';
import InputAdornment from "@material-ui/core/InputAdornment";
import filter from "../../Assets/icons/filter.png";
import { useUserAuthContext } from '../../utils/Contexts/Auth/UserAuthProvider';
import InterviewList from '../Interview/InterviewList/InterviewList';
import { useState } from 'react';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const paperStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', boxShadow: 'none', padding: '0 5px' };


const mapDateDataGridConstraintsForServerSideFiltering =
{
    endswith: 'endwith',
    startswith: 'startwith',
    notcontains: 'notcontains',
    contains: 'contains',
    '=': 'daterange',
    '<>': 'notequaldate',
    '<': 'dateless',
    '>': 'dategreater',
    '>=': 'dategreaterequal',
    '<=': 'datelessequal',
    'between': 'daterange',
}




const Dashboard = () => {
    const [value, setvalue] = React.useState([]);
    const [filteredValues, setFilteredValues] = React.useState([]);
    const [open, setOpen] = React.useState({ isOpen: false, actionType: '' });
    const [videoDialogOpen, setVideoDialogOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageCount, setPageCount] = React.useState(9);
    const [dataLoader, setDataLoader] = React.useState('loading');
    const [_dataLoader, _setDataLoader] = React.useState('');
    const [_dataLoaderFilterPanel, _setDataLoaderFilterPanel] = React.useState('');
    const initialDataLoad = React.useRef('initial');
    const [jobsList, setJobsList] = React.useState([]);
    const [jobFilterConstraints, setJobFilterConstraints] = React.useState({ jobName: '', status: '', startDate: '', endDate: '' });
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const jobStages = [{ keyname: 'Completed', status: 'completed' },
        //  { keyname: 'Pending', status: 'pending' }
    ];
    const tempValueArray = React.useRef([]);
    const deobunceIntervalId = React.useRef('');
    const [selectedVideo, setSelectedVideo] = React.useState(false);
    const [selectedVideoData, setSelectedVideoData] = React.useState('');
    const [apiError, setApiError] = useState(false);
    const navigate = useNavigate();
    const currentVideoIndexRef = React.useRef('');
    const [videoApiDataCount, setVideoApiDataCount] = useState({ status: 'idle', count: 0 });
    const [searchCriteria, setSearchCriteria] = useState('candidateName');
    const searchTextValue = React.useRef('');
    const [searchButtonClicked, setSearchButtonClicked] = useState('idle');
    const [error, setError] = useState(false);
    const PER_PAGE = pageCount;
    const [_count, _setCount] = useState(videoApiDataCount.count);
    const [clr, setClr] = useState(false);

    const count = Math.ceil(_count / PER_PAGE);
    const _DATA = usePagination(filteredValues, PER_PAGE);

    const location = useLocation();



    const copyUserInfo = {
        email: '',
        ...userInfo
    }

    const handlePageChange = (e = '', p) => {

        // console.log('paginationvalue', p, count);

        // if(p === count){
        //     console.log('paginationvalue FETCH NEXT DATA');f
        // }

        setOpen(prevState => ({ ...prevState, actionType: 'pagination' }));
        setPage(p);
        _DATA.jump(p);
    };

    function checkVideoPlaying(videoContainer, videoIndex) {
        //  if(mode === 'play'){
        if (currentVideoIndexRef.current !== '') {
            if (currentVideoIndexRef.current === videoIndex) {
                currentVideoIndexRef.current = '';
            }
            videoContainer.target.pause();
        }
        else {
            currentVideoIndexRef.current = videoIndex;
            videoContainer.target.play();
        }
        //  }
        // if (currentVideoIndexRef.current == '') {
        //     currentVideoIndexRef.current = videoIndex;
        // }
        // else {
        //    // currentVideoIndexRef.current = videoIndex;
        //     if (currentVideoIndexRef.current === videoIndex) {
        //         currentVideoIndexRef.current = '';
        //     }
        //     videoContainer.target.pause();
        // }
    }

    const handleChange = (name, value) => {
        // console.log({ jobFilterConstraints, [e.target.name]: e.target.value });
        if (name === 'page_count') {
            setPageCount(value)
        }
        else {
            setJobFilterConstraints(state => ({ ...state, [name]: value }))
        }
    };

    const handleCloseOpen = () => {
        if (filteredValues.length < 1) {
            setJobFilterConstraints({ jobName: '', status: '', startDate: '', endDate: '' });
            setFilteredValues(value);
        }
        setOpen({ isOpen: false, actionType: 'cut' });
        setError(false);
    };

    React.useEffect(() => {
        if (location.state != null) {
            if (location.state.page) {
                setPage(location.state.page)
            }
        }
    }, []);

    React.useEffect(() => {
        if (accessToken && copyUserInfo.email && userRole) {
            ViewJobList(accessToken, copyUserInfo, userRole)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data != null) {
                                setJobsList(res.data.data.response)
                            }
                        }
                    }
                    //setTableLoaderErrInfo('')
                })
                .catch(err => {
                    //setTableLoaderErrInfo('Contact to Administrator')
                })
        }
    }, [accessToken, copyUserInfo.email, userRole]);

    React.useEffect(() => {
        if (accessToken && userRole && copyUserInfo.email && open.isOpen === false && (open.actionType === 'cancel' || open.actionType === '' || open.actionType === 'pagination')) {
            // GetVideo(accessToken, 'Global Administrat or', userInfo.email)
            if (initialDataLoad.current !== 'initial') {
                _setDataLoader('loading');
            }
            // function formatJobDate(job) {
            //     const formattedJob = { ...job }; // create a copy of the input object
            //     // format the startDate property
            //     if (job.startDate !== null && job.endDate) {
            //         formattedJob.startDate = `${job.startDate}TO${job.endDate}`;
            //     }
            //     else if (job.endDate) {
            //         formattedJob.endDate = '';
            //     }
            //     return formattedJob;
            // };
            // const formattedJob = formatJobDate(jobFilterConstraints);
            // const jobAfterFilter = { jobName: formattedJob.jobName, status: formattedJob.status, startDate: formattedJob.startDate };
            GetVideo(accessToken, userRole, copyUserInfo.email, pageCount, page, searchCriteria, searchTextValue.current, jobFilterConstraints)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                setDataLoader('no_data_found');
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('no_data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                            else if (res.data.data) {
                                //  console.log(res.data);
                                _setCount(res.data.data.count)  // have to work on this state
                                setvalue(res.data.data.response);
                                setFilteredValues(res.data.data.response);
                                setDataLoader('data_found');
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                        }
                    }
                })
                .catch(err => {
                    if (initialDataLoad.current !== 'initial') {
                        _setDataLoader('api_error');
                    }
                    setDataLoader('api_error');
                    setApiError(true)
                })
        }
    }, [accessToken, userRole, copyUserInfo.email, pageCount, page, open, jobFilterConstraints]);

    React.useEffect(() => {
        if (accessToken && userRole && copyUserInfo.email && videoApiDataCount.status === 'idle') {
            // GetVideo(accessToken, 'Global Administrator', userInfo.email)
            GetVideo(accessToken, userRole, copyUserInfo.email, 1, 1)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                // setDataLoader('no_data_found');
                            }
                            else if (res.data.data) {
                                //  console.log(res.data);
                                setVideoApiDataCount({ status: 'loaded', count: res.data.data.count });
                            }
                        }
                    }
                })
                .catch(err => {
                    // setDataLoader('api_error');
                    // setApiError(true)
                })
        }
    }, [accessToken, userRole, copyUserInfo.email, videoApiDataCount.status]);




    const filterInterviews = (searchboxText = '') => {
        // console.log(searchboxText);
        tempValueArray.current = [...value];
        if (!searchboxText) {
            for (let key in jobFilterConstraints) {
                if (jobFilterConstraints[key] !== '') {
                    tempValueArray.current = filterDataList(key, tempValueArray.current, jobFilterConstraints[key]);
                }
            }
        }
        else {
            // console.log(tempValueArray.current);
            tempValueArray.current = filterDataList('filter_through_search', tempValueArray.current, searchboxText);
            handlePageChange('', 1)
        }
        setFilteredValues(tempValueArray.current)
        // filterDataList('', value, jobFilterConstraints)
    }

    const filterInterviewsAPILoad = () => {
        // console.log(searchboxText);
        // jobFilterConstraints.jobName
        if (jobFilterConstraints.startDate == '' || jobFilterConstraints.endDate == "") {
            setError(true)
        }
        if (accessToken && userRole && copyUserInfo.email && jobFilterConstraints.startDate !== '' && jobFilterConstraints.endDate !== "") {
            // GetVideo(accessToken, 'Global Administrator', userInfo.email)
            if (initialDataLoad.current !== 'initial') {
                _setDataLoader('loading');
            }
          
            // function formatJobDate(job) {
            //     const formattedJob = { ...job }; // create a copy of the input object
            //     // format the startDate property
            //     console.log(formattedJob, 'djgh');
            //     if (job.startDate !== null && job.endDate) {
            //         formattedJob.startDate = `${job.startDate}TO${job.endDate}`;
            //     }
            //     else if (job.endDate) {
            //         formattedJob.endDate = '';
            //     }
            //     return formattedJob;
            // };
            // const formattedJob = formatJobDate(jobFilterConstraints);
            // const jobAfterFilter = { jobName: formattedJob.jobName, status: formattedJob.status, startDate: formattedJob.startDate };
            GetVideo(accessToken, userRole, copyUserInfo.email, pageCount, page, '', '', jobFilterConstraints)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                _setDataLoaderFilterPanel('no_data_found');
                                setFilteredValues([]);
                                // _setCount(res.data.data.count);
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('no_data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                            else if (res.data.data) {
                                //  console.log(res.data);
                                setvalue(res.data.data.response);
                                setFilteredValues(res.data.data.response);
                                // setDataLoader('data_found');
                                _setCount(res.data.data.count)
                                _setDataLoaderFilterPanel('data_found');
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('data_found');
                                    _setDataLoaderFilterPanel('data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                                setOpen({ isOpen: false, actionType: 'cancel' });
                            }
                        }
                    }
                })
                .catch(err => {
                    if (initialDataLoad.current !== 'initial') {
                        _setDataLoader('api_error');
                    }
                    setDataLoader('api_error');
                    setApiError(true)
                })
        }
        else {
            // alert('All field are mandatory')
        }
    }

    function delayExpensiveTask(value, delay) {
        // console.log('value, delay', value, delay);
        clearTimeout(deobunceIntervalId.current);
        deobunceIntervalId.current = setTimeout(() => {
            filterInterviews(value)
        }, delay);
    }

    const handleClearConstraint = (constraint) => {
        setJobFilterConstraints(state => ({ ...state, [constraint]: '' }))
        // console.log(constraint);
    }

    const handleFilterSearch = (e) => {
        // delayExpensiveTask(e.target.value, 500)
        searchTextValue.current = e.target.value;
    }

    function generateVideos() {
        return _DATA.currentData().map((values, index) => {
            // console.log(values);
            let videoUrl = '';
            let dateString = '';
            let timeString = '';
            let dateDisplayStr = '';
            let endDate = '';
            let startDateDisplayStr = '';
            let endDateDisplayStr = '';
            let firstLattter = '';
            const nameArray = values.candidateName.split(' ');
            nameArray.forEach(element => {
                firstLattter += element.slice(0, 1)
            });
            if (values.chunkStartTime) {
                dateString = values.chunkStartTime['$date'];
                timeString = new Date(dateString).toTimeString();
                dateString = new Date(dateString).toDateString();
                dateString = dateString.split(' ');
                timeString = timeString.split(' ');
                dateDisplayStr = `Interview start date and time: ${dateString[2]} ${dateString[1]} ${dateString[3]} ${timeString[0]}`;
            }
            if (values.updatedAt) {
                dateString = values.updatedAt;
                timeString = new Date(dateString).toTimeString();
                dateString = new Date(dateString).toDateString();
                dateString = dateString.split(' ');
                timeString = timeString.split(' ')
                endDate = `Interview end date and time: ${dateString[2]} ${dateString[1]} ${dateString[3]} ${timeString[0]}`;
            }
            if (values.startDate) {
                dateString = values.startDate.$date;
                timeString = new Date(dateString).toTimeString();
                dateString = new Date(dateString).toDateString();
                dateString = dateString.split(' ');
                timeString = timeString.split(' ')
                startDateDisplayStr = `Start date: ${dateString[2]} ${dateString[1]} ${dateString[3]} ${timeString[0]}`;
            }
            if (values.endDate) {
                dateString = values.endDate.$date;
                timeString = new Date(dateString).toTimeString();
                dateString = new Date(dateString).toDateString();
                dateString = dateString.split(' ');
                timeString = timeString.split(' ')
                endDateDisplayStr = `End date: ${dateString[2]} ${dateString[1]} ${dateString[3]} ${timeString[0]}`;
            }
            if (values.video) {
                videoUrl = values.video.videoInterviewUrl;
            }

            return <Grid item xs={12} md={4} key={index + values.id} onClick={() => videoSelection(values)} data-cy-test={'dashboard-video-click-' + index}>
                {/* <Link to="/dashboard/review-interview" style={{textDecoration: 'none'}} state={{selectedVideoData: selectedVideoData}}> */}
                <Item className='main_dash zoom' sx={{
                    width: '100%',
                    boxShadow: 4,
                    marginBottom: '20px',
                    //  padding: '20px 20px 0 20px' 
                }}>{values.name}
                    <Box>
                        <Box sx={{ height: '90%' }}>
                            {
                                videoUrl ?
                                    <Box sx={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', color: 'white', borderRadius: '7px' }}>
                                        <Box sx={{ width: '100%', height: '100%' }}>
                                            <video className='interview-video-player'
                                                controls
                                                controlsList="nodownload nofullscreen"
                                                onPlay={(e) => checkVideoPlaying(e, index)} onPause={(e) => checkVideoPlaying(e, index)}
                                                poster={logo}
                                            ><source src={videoUrl} type="video/mp4" /></video>
                                        </Box>
                                    </Box>
                                    :
                                    <Box sx={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', color: 'white', borderRadius: '7px' }}>
                                        <Box>
                                            <h2>Sorry</h2>
                                            <h2>This Video Does Not Exist</h2>
                                        </Box>
                                    </Box>
                            }

                        </Box>

                        {/*  */}

                        <Box className='dashboard-ui-detail-card'>
                            <Box sx={{ display: 'flex', height: '100%', paddingTop: '15px' }} className='d-ui-card-outer'>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }} className='d-ui-card-inner-1'>
                                    <div style={{ border: videoUrl ? '3px solid #008000' : '3px solid  #ff0000' }} className='jd-video-card'>
                                        {firstLattter.toUpperCase()}
                                    </div>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }} className='d-ui-card-inner-2'>
                                    <List
                                        sx={{
                                            width: '100%',
                                            // maxWidth: 360,
                                            bgcolor: 'background.paper',
                                            paddingTop: '0'
                                        }}>
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={values.candidateName} sx={{ margin: 0, color: 'black' }} />
                                        </ListItem>
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={values.candidateEmail} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem>
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={values.jobName} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem>
                                        {/* <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={`${dateDisplayStr ? dateDisplayStr : 'Created Date Not Found'}`} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem>
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={`${endDate ? endDate : 'Date Not Found'}`} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem> */}
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={`${startDateDisplayStr ? startDateDisplayStr : 'Date Not Found'}`} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem>
                                        <ListItem sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                            <ListItemText primary={`${endDateDisplayStr ? endDateDisplayStr : 'Date Not Found'}`} sx={{ margin: 0, color: '#7f7f7f' }} />
                                        </ListItem>
                                    </List>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }} className='d-ui-card-inner-3'>
                                    <CheckBoxOutlinedIcon fontSize='large' sx={{ color: videoUrl ? '#008000' : '#ff0000' }} />
                                </Box>
                            </Box>
                        </Box>
                        {/*  */}
                    </Box>
                </Item>
                {/* </Link> */}
            </Grid>
        })
    }


    /*
    React.useEffect(() => {
            let videos = document.getElementsByClassName('interview-video-player');
    
            // video.forEach((item, i) => {
            //     console.log('video-player', item, item.duration);
            // })
            // video = Array.from(video)
            console.log('video-player1', videos.length);
            for (let i = 0; i < videos.length; i++) {
                videos[i].onloadedmetadata = function () {
                    videos[i].currentTime = 10
                    console.log('video-player', videos[i], videos[i].duration, videos[i].currentTime);
                };
            }
    
        }, [generateVideos()]);
    */


    function videoSelection(values) {
        if (values) {
            setVideoDialogOpen(true);
            setSelectedVideoData(values);
            navigate("/dashboard/review-interview", { state: { ...values, page } })
        }
    };


    function handleSearchCriteriaChange(event) {

        setSearchCriteria(event.target.value)
    }


    function handleVieoSearchThroughFilter() {

        if (accessToken && userRole && copyUserInfo.email) {
            // GetVideo(accessToken, 'Global Administrator', userInfo.email)
            if (initialDataLoad.current !== 'initial') {
                _setDataLoader('loading');
            }
            GetVideo(accessToken, userRole, copyUserInfo.email, pageCount, page, searchCriteria, searchTextValue.current.trim(), jobFilterConstraints)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                // setDataLoader('no_data_found');
                                _setCount(0);
                                setFilteredValues([]);
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('no_data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }

                            }
                            else if (res.data.data) {
                                //  console.log(res.data);
                                setvalue(res.data.data.response);
                                setFilteredValues(res.data.data.response);
                                _setCount(res.data.data.count)
                                // setDataLoader('data_found');

                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                        }
                    }
                })
                .catch(err => {
                    if (initialDataLoad.current !== 'initial') {
                        _setDataLoader('api_error');
                    }
                    setDataLoader('api_error');
                    setApiError(true)
                })
        }
    }
  

    function clearFilter() {
        if (accessToken && userRole && copyUserInfo.email) {
            // GetVideo(accessToken, 'Global Administrator', userInfo.email)
            setJobFilterConstraints({ jobName: '', status: '', startDate: '', endDate: '' });
            setOpen({ isOpen: false, actionType: '' });
            setClr(true);
            searchTextValue.current = "";
            if (initialDataLoad.current !== 'initial') {
                _setDataLoader('loading');
            }
            GetVideo(accessToken, userRole, copyUserInfo.email, pageCount, page, "", "", "")
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                // setDataLoader('no_data_found');
                                _setCount(0);
                                setFilteredValues([]);
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('no_data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                            else if (res.data.data) {
                                //  console.log(res.data);
                                setvalue(res.data.data.response);
                                setFilteredValues(res.data.data.response);
                                _setCount(res.data.data.count)
                                setDataLoader('data_found');
                                // _setDataLoader('data_found');
                                if (initialDataLoad.current !== 'initial') {
                                    _setDataLoader('data_found');
                                }
                                else {
                                    initialDataLoad.current = 'notinitial';
                                }
                            }
                        }
                    }
                })
                .catch(err => {
                    if (initialDataLoad.current !== 'initial') {
                        _setDataLoader('api_error');
                    }
                    setDataLoader('api_error');
                    setApiError(true)
                })
        }
    }

    return (
        <>
            {/* <Box sx={{ position: 'absolute', zIndex: '9999', display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Loader className='preloader-icon-dashboard' />
            </Box> */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={_dataLoader === 'loading'}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', position: 'sticky', top: '-12px', background: 'white', zIndex: '9 !important', height: '70px' }}>
                <Tooltip title='Clear all filter' placement='top'>
                    <IconButton style={{ marginRight: '30px', backgroundColor: '#74c2ff', color: 'white', border: '1px solid #008bf7' }} aria-label="search" onClick={clearFilter} >
                        <div style={{ margin: '0 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '14px' }}>
                            <FilterAltOffIcon fontSize='medium' />
                        </div>
                    </IconButton>
                </Tooltip>
                <button onClick={() => { setOpen(prevState => ({ ...prevState, isOpen: !prevState.isOpen })) }} className='center filter-show-btn'>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                        <p>Filter Details</p>
                    </Box>
                </button>
                <Box
                    component="form"
                    sx={{
                        margin: '10px 18px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10%', height: 30, borderRadius: '30px', position: 'sticky',
                        //boxShadow: '0px 3px 4px -20px rgb(0 0 0 / 20%), 2px 7px 14px -8px rgb(0 0 0 / 14%), 0px 1px 14px -1px rgb(0 0 0 / 12%)', 
                        // border: '1px solid #d3d3d3eb'
                    }}
                // className='d-board-search-box'
                >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        {/* <InputLabel id="demo-simple-select-label" sx={{ fontSize: '10px' }}>Search Criteria</InputLabel> */}
                        <InputLabel id="demo-select-small">Search Criteria</InputLabel>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={searchCriteria}
                            label="Search Criteria"
                            onChange={handleSearchCriteriaChange}
                        >
                            {/* <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem> */}
                            <MenuItem value='candidateName'>Candidate Name</MenuItem>
                            <MenuItem value='jobName'>Job Name</MenuItem>
                            <MenuItem value='candidateEmail'>Candidate Email</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {/* <Box
                    component="form"
                    sx={{
                         margin: '10px 18px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20%', height: 41, borderRadius: '30px', position: 'sticky',
                        //boxShadow: '0px 3px 4px -20px rgb(0 0 0 / 20%), 2px 7px 14px -8px rgb(0 0 0 / 14%), 0px 1px 14px -1px rgb(0 0 0 / 12%)', 
                        border: '1px solid #d3d3d3eb'
                    }}
                    className='d-board-search-box'
                >

                    <InputBase
                        sx={{ ml: 1, flex: 1, width: '100px' }}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleFilterSearch}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                        className='dashboard-filter-box'
                    />

                    <Divider sx={{ height: '100%', borderColor: '#dfdfdf;' }} orientation="vertical" />
                    <IconButton sx={{ p: '10px' }} aria-label="search" className='dashboard-filter-box-btn' onClick={handleVieoSearchThroughFilter}>
                        <SearchIcon sx={{ color: '#0aa699' }} />
                    </IconButton>
                </Box> */}
                <VideoInterviewSearch handleVieoSearchThroughFilter={handleVieoSearchThroughFilter} searchTextValue={searchTextValue} setClr={setClr} clr={clr} />
            </Grid>
            {!apiError ?
                <>
                    {
                        dataLoader === 'data_found' ? <>
                            <Grid container spacing={2} >
                                <Grid sx={{ flexGrow: 1, height: '80vh', overflowY: 'scroll', overflowX: 'hidden', padding: '10px' }} >
                                    {!selectedVideo ?
                                        <>
                                            <Grid container spacing={2} sx={{ marginTop: '20px' }} className='multiple-videos-section' id=''>
                                                {generateVideos()}
                                            </Grid>
                                            {filteredValues.length > 0 && <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', width: '100%', padding: '0px 17px' }}>
                                                <Box>
                                                    <Box sx={{ minWidth: 100, height: '10px' }} className='page_count'>
                                                        <FormControl sx={{ width: '90px' }}>
                                                            <InputLabel id="demo-simple-select-label">Page</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={pageCount}
                                                                label="pageCount"
                                                                name='page_count'
                                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                                style={{ height: '41px' }}
                                                            >
                                                                <MenuItem value={9}>9</MenuItem>
                                                                <MenuItem value={18}>18</MenuItem>
                                                                <MenuItem value={30}>30</MenuItem>
                                                                <MenuItem value={90}>90</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Stack spacing={2} >
                                                        <Pagination shape="rounded" count={count} size="large" page={page} variant="outlined" onChange={handlePageChange} />
                                                    </Stack>
                                                </Box>
                                            </Box>
                                            }
                                            {selectedVideoData && <InterviewVideoPreview selectedVideoData={selectedVideoData} videoDialogOpen={videoDialogOpen} setVideoDialogOpen={setVideoDialogOpen} />}
                                        </>
                                        :
                                        <></>

                                    }
                                </Grid>
                            </Grid>
                            {
                                open.isOpen &&
                                <Box
                                    className='filter-container-area'
                                    sx={{ minHeight: '470px', width: '350px', padding: '5px 15px', boxShadow: '0px 3px 0px -14px rgb(0 0 0 / 20%), 0px 5px 8px -6px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)', zIndex: 9, position: 'absolute', right: '66px', top: '150px', background: 'white', opacity: '1' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <FilterAltOutlinedIcon sx={{ color: 'black' }} />
                                        <Box>
                                            <h3 className='dashboard-filter-head'>Filter</h3>
                                        </Box>
                                        <Tooltip title='Hide Filter' placement="top">
                                            <IconButton onClick={handleCloseOpen}>
                                                <HighlightOffIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Divider sx={{ height: 50, mt: '-50px' }} orientation="horizontal" className='filter-divider' />
                                    <p style={{ color: 'black' }}>Job</p>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            {/* <InputLabel id="demo-simple-select-label">Select Job</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={jobFilterConstraints.jobName}
                                        name='jobName'
                                        label="Select Job"
                                        onChange={handleChange}
                                        endAdornment={<IconButton sx={{ visibility: jobFilterConstraints.jobName ? "visible" : "hidden" }} onClick={() => handleClearConstraint('jobName')}><ClearIcon /></IconButton>}
                                    >
                                        {
                                            jobsList.map((item, index) => {
                                                return <MenuItem value={item.jobName} key={index}>{item.jobName}</MenuItem>
                                            })
                                        }
                                    </Select> */}
                                            {
                                                <Autocomplete
                                                    // value={1.jobName}
                                                    // freeSolo
                                                    name='jobName'
                                                    label="Select Job"
                                                    onChange={(e, newValue) => {
                                                        //console.log('my name is khan1', newValue);
                                                        handleChange('jobName', newValue.jobName)
                                                    }}
                                                    // onInputChange={(e) => {
                                                    //     console.log('my name is khan2',newValue);
                                                    // }}
                                                    loading={!jobsList.length ? true : false}
                                                    loadingText="Loading... "
                                                    clearIcon={false}
                                                    inputValue={jobFilterConstraints.jobName}
                                                    renderInput={(params) => <TextField {...params} label="Please Select Job" />}
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    // getOptionLabel={(option) => option.name}
                                                    getOptionLabel={(option) => (option.jobName ? option.jobName : '')}
                                                    options={jobsList}
                                                    sx={{ width: 320 }}
                                                    renderOption={(props, option) => (
                                                        <Box component="li" {...props} key={option.jobId != undefined ? option.jobId + props['data-option-index'] : props['data-option-index']}>
                                                            {option.jobName}
                                                        </Box>
                                                    )}
                                                    className='filter-autocomplete-box'
                                                />
                                            }
                                        </FormControl>
                                    </Box>
                                    <p style={{ color: 'black' }}>Status</p>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">All Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={jobFilterConstraints.status}
                                                label="All status"
                                                name='status'
                                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                endAdornment={<IconButton sx={{ display: jobFilterConstraints.status ? "block" : "none" }} onClick={() => handleClearConstraint('status')}><ClearIcon /></IconButton>}
                                            >
                                                {
                                                    jobStages.map((item, index) => {
                                                        return <MenuItem value={item.status} key={index}>{item.keyname}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Grid container spacing={.5} sx={{ margin: '35px 0 0 0 ' }}>
                                        <Grid item xs={6} md={6}>
                                            <Paper sx={paperStyles}>
                                                <TextField
                                                    id="date"
                                                    required
                                                    label="From Date"
                                                    type="date"
                                                    name="startDate"
                                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                    value={jobFilterConstraints.startDate}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='filter-input-box'
                                                    error={error && jobFilterConstraints.startDate === ''}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Paper sx={paperStyles}>
                                                <TextField
                                                    id="date"
                                                    label="To Date"
                                                    required
                                                    type="date"
                                                    name="endDate"
                                                    InputProps={{ inputProps: { min: jobFilterConstraints.startDate } }}
                                                    value={jobFilterConstraints.endDate}
                                                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className='filter-input-box'
                                                    error={error && jobFilterConstraints.endDate === ''}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid sx={{ position: 'absolute', right: '60px', bottom: '20px' }}>
                                            <Button variant="contained" sx={{ background: '#1890ff', margin: '10px' }} onClick={() => {
                                                // setJobFilterConstraints({ jobName: '', status: '', startDate: '', endDate: '' });
                                                // setFilteredValues(value);
                                                setOpen({ isOpen: false, actionType: 'cancel' });
                                                _setDataLoaderFilterPanel('');
                                                setJobFilterConstraints({ jobName: '', status: '', startDate: '', endDate: '' });
                                                setError(false);
                                                // setFilterPanelStatus(prevState => {
                                                //     if (prevState === 'idle') {
                                                //         return 'active';
                                                //     }
                                                //     else {
                                                //         return 'idle';
                                                //     }
                                                // });
                                            }}>Cancel</Button>
                                            <Button sx={{ background: '#1890ff', margin: '10px' }} variant="contained" onClick={() => {
                                                // filterInterviews('')
                                                filterInterviewsAPILoad();
                                            }}>Search</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                            {
                                filteredValues.length < 1 &&
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <span className='preloader-icon-dashboard NoData'>No data found</span>
                                </Box>
                            }

                        </> :
                            <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                {!selectedVideo && (dataLoader === ('no_data_found') ? <span className='preloader-icon-dashboard NoData'>No data found</span> : <Loader className='preloader-icon-dashboard' />)}
                            </Box>
                    }
                </>
                :
                <>{
                    dataLoader === "api_error" &&
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span className='preloader-icon-dashboard NoData'>Some error occurred, please contact to administrator</span>
                    </Box>
                }
                </>

            }
        </>
    );
};

export default Dashboard;


function VideoInterviewSearch({ handleVieoSearchThroughFilter, searchTextValue, clr, setClr }) {
    const [searchTextValueState, setSearchTextValueState] = useState({ text: '', status: 'idle' });
    const searchButtonClickStatus = React.useRef('idle');

    const handleFilterSearch = (e) => {
        // delayExpensiveTask(e.target.value, 500)
        //searchTextValue.current = e.target.value;
        searchTextValue.current = e.target.value;
        setSearchTextValueState({ text: e.target.value, status: 'textfilled' });
    }
    
    const clearSearchBoxText = () => {

        searchTextValue.current = '';
        setSearchTextValueState({ text: '', status: 'textcleared' });
    }

    React.useEffect(() => {

        if (clr) {
            searchTextValue.current = '';
            setSearchTextValueState({ text: '', status: 'textcleared' });
            setClr(false);
        }
    }, [clr])

    React.useEffect(() => {
        if (searchTextValueState.status === 'textcleared' || (searchTextValueState.status === 'textfilled' && searchTextValueState.text == '')) {
            if (searchButtonClickStatus.current !== 'idle') {
                handleVieoSearchThroughFilter();
            }
            setSearchTextValueState({ text: '', status: '' });
        }
    }, [searchTextValueState]);

    function searchButtonIntermediate() {
        if (searchTextValueState.text.trim() != '') {
            searchButtonClickStatus.current = 'clicked'
            handleVieoSearchThroughFilter();
        }
    }

    return (<Box
        component="form"
        sx={{
            margin: '10px 18px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20%', height: 41, borderRadius: '30px', position: 'sticky',
            //boxShadow: '0px 3px 4px -20px rgb(0 0 0 / 20%), 2px 7px 14px -8px rgb(0 0 0 / 14%), 0px 1px 14px -1px rgb(0 0 0 / 12%)', 
            border: '1px solid #95c2e6'
        }}
        className='d-board-search-box'
    >

        <InputBase
            sx={{ ml: 1, flex: 1, width: '100px' }}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleFilterSearch}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            className='dashboard-filter-box'
            value={searchTextValueState.text}
            endAdornment={
                <InputAdornment position="end">
                    <IconButton sx={{ visibility: searchTextValueState.text.length > 0 ? "visible" : "hidden" }} onClick={clearSearchBoxText}>
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            }
        />

        <Divider sx={{ height: '100%', borderColor: '#dfdfdf;' }} orientation="vertical" />
        <IconButton sx={{ p: '10px' }} aria-label="search" className='dashboard-filter-box-btn' onClick={searchButtonIntermediate}>
            <SearchIcon sx={{ color: '#0aa699' }} />
        </IconButton>
    </Box>

    );
}