import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import './index.css';
import { useEffect, useRef } from 'react';


export const SuccessAlert = (props) => {

    const { alertMessage, setAlertMessage } = props;

    function handleClose() {
        setAlertMessage({ display: false, text: '' })
    }

    const ref = useRef();
    useOnClickOutside(ref, () => setAlertMessage({ display: false, text: '' }));
    
    return (

        <>
            {alertMessage.display && <div ref={ref} className='disp-error-alert success-alert' >
                <Stack sx={{ width: '60%' }} spacing={2}>
                    <div className='disp-error-alert-inner'>
                        <Alert severity="success">{alertMessage.text}</Alert>
                        <div className='close-icon-err-alert'>
                            <CloseIcon onClick={handleClose} sx={{ color: '#939393' }} />
                        </div>
                    </div>
                </Stack>
            </div>}
        </>

    )
}


function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}