import CircularProgress from '@mui/material/CircularProgress';
import './index.css';

export default function Loader({ className }) {

    return (
        <>
            <div className={className}>
                <CircularProgress />
            </div>
        </>
    )
}