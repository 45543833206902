import * as React from 'react';
import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import { ArchiveList } from '../../../handlers/job_handlers';
import styled from '@emotion/styled';
import { filterDataList } from '../../../utils/Helper/filterDataList';
import { useState, useCallback } from "react";
import 'devextreme/dist/css/dx.light.css';
import { LoadPanel } from 'devextreme-react/load-panel';
import './Archive.css';
import DateBox from 'devextreme-react/date-box';
import { GridCustomization, GridCustomizeGet, } from "../../../handlers/customize";
import clean from "../../../Assets/icons/clean.png";
import { useRef } from "react";
import ClearFiltericon from "../../../Assets/icons/ClearFiltericon.png";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const allowedPageSizes = [20, 50, 100];
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import DataGrid, {
    Column,
    FilterRow,
    Paging,
    // Scrolling,
    Pager,
    ColumnChooser,
    Sorting,
    Lookup,
    Item,
    Toolbar,
    Export,
    ColumnFixing,
    Editing,
    Scrolling,
    StateStoring,
    Selection
} from "devextreme-react/data-grid";
const initialColumnStructure = {
    searchText: "",
    allowedPageSizes: [20, 50, 100],
    filterPanel: { filterEnabled: true },
    pageIndex: 0,
    columns: [
        { visible: true, visibleIndex: 0, name: "buttons" },
        {
            dataField: "id",
            visible: true,
            visibleIndex: 1,
            dataType: "number",
            name: "id",
            width: "50px",
            allowSorting: false

        },
        {
            dataField: "CandidateName",
            visible: true,
            visibleIndex: 2,
            dataType: "string",
            name: "CandidateName",
            width: "200px"

        },
        {
            dataField: "Email",
            visible: true,
            visibleIndex: 3,
            dataType: "string",
            name: "Email",
            width: "250px"

        },
        {
            dataField: "Contact No",
            visible: true,
            visibleIndex: 4,
            dataType: "string",
            name: "Contact No",
            width: "150px"

        },
        {
            dataField: "JobName",
            visible: true,
            visibleIndex: 5,
            dataType: "string",
            name: "JobName",
            width: "250px"

        },
        {
            dataField: "Status",
            visible: true,
            visibleIndex: 6,
            dataType: "string",
            name: "Status",
            width: "150px"

        },
        // {
        //     dataField: "createdAt",
        //     visible: false,
        //     // visibleIndex: 8,
        //     dataType: "string",
        //     dataType: "date",
        //     name: "Created At",
        //     width: "210px"

        // },
        {
            dataField: "Interview Date",
            visible: true,
            visibleIndex: 7,
            dataType: "date",
            name: "Interview Date",
            width: "260px"
        },
        {
            dataField: "Submitted Date",
            visible: true,
            visibleIndex: 8,
            dataType: "date",
            name: "Submitted Date",
            width: "260px"

        },
    ],
    pageSize: 20,
};
import CustomStore from "devextreme/data/custom_store";

// const mapDataGridFeildsForServerSideSorting = {
//     "id": 'id',
//     "CandidateName": 'candidateName',
//     "Contact No": 'candidateNumber',
//     "JobName": 'jobName',
//     "Email": 'candidateEmailId',
//     "Status": 'status',
//     "Interview Date": 'startDate',
//     "Submitted Date": 'endDate'
// };

const mapDataGridFeildsForServerSideSorting = {
    "id": 'id',
    "CandidateName": 'candidateName',
    "Email": 'candidateEmail',
    "Contact No": 'candidateNumber',
    "JobName": 'jobName',
    // "Email": 'recruiterEmailId',
    "Status": 'status',
    "Interview Date": 'startDate',
    "Submitted Date": 'endDate',
    "updatedAt": 'updatedAt'
};

const mapDataGridConstraintsForServerSideFiltering =
{
    endswith: 'endwith',
    startswith: 'startwith',
    notcontains: 'notcontains',
    contains: 'contains',
    '=': 'equal',
    '<>': 'notequal',
    '<': 'dateless',
    '>': 'dategreater',
    '>=': 'dategreaterequal',
    '<=': 'datelessequal'
}

const mapDateDataGridConstraintsForServerSideFiltering =
{
    endswith: 'endwith',
    startswith: 'startwith',
    notcontains: 'notcontains',
    contains: 'contains',
    '=': 'daterange',
    '<>': 'notequaldate',
    '<': 'dateless',
    '>': 'dategreater',
    '>=': 'dategreaterequal',
    '<=': 'datelessequal',
    'between': 'daterange',
}




const gridStatusValue = {
    "Interview InProcess": "interviewInProcess",
    "Interview completed": "interviewcompleted",
    "Interview Pending": "interviewPending",
    "Interview Disqualified": "interviewDisqualified",

    "Interview Link Expired": "interviewLinkExpired",
    "Interview LinkExpired": "interviewLinkExpired",
    "interview linkexpired": "interviewLinkExpired",
    "interview link expired": "interviewLinkExpired",
    "InterviewLinkExpired": "interviewLinkExpired",
    "interviewLinkExpired": "interviewLinkExpired",
    "interviewlinkexpired": "interviewLinkExpired",
}
import Button from "devextreme-react/button";
// import { Scrolling } from 'devextreme-react/scheduler';
const serverError = [500, 502, 503, 504, 400, 404];

export default function ArchiveInterviewList() {
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const [listData, setListData] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [loaderCircular, setLoaderCircular] = useState(true);
    const [customizationData, setCustomizationData] = React.useState(null);
    const reset_custom = useRef(initialColumnStructure);
    const DateFilterExpressionRef = React.useRef('');
    const [resetCall, setResetCall] = React.useState("idle");
    const DateFilterStatusRef = React.useRef({ startDate: 'idle', endDate: 'idle' });
    const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState("Loading");
    const [clearCustomizationPopUp, setClearCustomizationPopUp] = React.useState(false)
    const dataGridRef = useRef(null);

    // var selectedMembers = [];

    const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
    // React.useEffect(() => {
    //     if (accessToken && userInfo && userRole) {
    //         setTableLoaderErrInfo("loading");
    //         ArchiveList(accessToken, userInfo, userRole)
    //             .then(res => {

    //                 if (res.status) {
    //                     if (res.data.data === null) {
    //                         setTableLoaderErrInfo("No record found");
    //                     }
    //                     else {
    //                         setListData(res.data.data.response);
    //                         setTableLoaderErrInfo("");
    //                     }
    //                 }
    //                 else {
    //                     throw { code: res.code, message: res.message };
    //                 }

    //             })
    //             .catch(err => {
    //                 if (serverError.includes(err.code)) {
    //                     setTableLoaderErrInfo(err.message);
    //                     setCommonAlert({ apiErrorStatus: true, message: err.message });
    //                 } else {
    //                     setTableLoaderErrInfo("Contact to Administrator")
    //                 }
    //             })
    //     }
    // }, [accessToken, userRole]);


    React.useEffect(() => {
        if (accessToken && userInfo.userName) {
            const ArchiveList = "archive-interview";
            GridCustomizeGet(accessToken, userInfo.userName, ArchiveList).then(
                (res) => {
                    // console.log(res.status, "res", res);
                    if (res.status) {
                        // console.log(res);
                        setCustomizationData(res.data.data);
                    }
                }
            );
        }
    }, [accessToken, userInfo.userName]);



    function handle() {
        // console.log("hello")
    }

    function searchHandler(e) {
        setFilteredData(filterDataList('candidateName', listData, e.target.value));
    };

    React.useEffect(() => {
        if (listData.length) {
            setFilteredData(listData)
            setLoaderCircular(false)
        }
    }, [listData]);

    // React.useEffect(() => {
    //     if (!filteredData.length) {
    //         setTableLoaderErrInfo('No record found');
    //     }
    // }, [filteredData]);





    function viewArchiveList() {
        return [...filteredData].map((item, index) => {
            // console.log(item);
            let startTime = '';
            if (item.startDate) {
                const startDate = new Date(item.startDate * 1000).toDateString();
                const myArray = startDate.split(" ");
                const localTimeString = new Date(item.startDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
                const localTime = localTimeString.slice(0, 8)
                const sDate = (myArray[2] + '-' + myArray[1] + '-' + myArray[3]);
                // console.log(sDate);
                startTime = sDate + ' ' + localTime;
                // console.log(startTime);
            }
            let endTime = '';
            if (item.endDate) {
                const endDate = new Date(item.endDate * 1000).toDateString();
                const myArray = endDate.split(" ");
                const localTimeString = new Date(item.endDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
                const localTime = localTimeString.slice(0, 8)
                const lDate = (myArray[2] + '-' + myArray[1] + '-' + myArray[3]);
                endTime = lDate + ' ' + localTime;
            }
            let candidateName = '';
            const nameArray = item.candidateName.split(' ');
            nameArray.forEach(element => {
                candidateName += element.slice(0, 1)
            });

            return {
                id: index + 1, CandidateName: candidateName, Email: item.candidateEmail, 'Contact No': item.candidateNumber, JobName: item.jobName, 'Status': item.status,
                'Interview Date': startTime, 'Submitted Date': endTime, mid: item.id

            }

        })
    };

    const loadState = useCallback(() => {
        let data = {};
        if (resetCall === "active") {
            data = reset_custom.current;
            setResetCall("inactive");
            setClearCustomizationPopUp(false)

        }
        else if (resetCall === "idle") {
            data = { ...customizationData }

        }
        return data;
    }, [customizationData, resetCall]);



    const saveState = useCallback(
        (state) => {
            // console.log(state, "state");
            const interviewList = "archive-interview";

            //setLoaderCircular(true); commented due to loading issue arised


            // let tableData = "";
            // if (resetCall === "inactive" && !checkstate) {
            //   tableData = state;
            // } else {
            //   tableData = reset_custom.current;
            // }

            if (userInfo.userName && DateFilterStatusRef.current.startDate != undefined && DateFilterStatusRef.current.endDate != undefined) {
                GridCustomization(
                    accessToken,
                    userInfo.userName,
                    state,
                    interviewList
                ).then((res) => {
                    if (res.status === false) {
                        throw { code: res.code, message: res.message };
                    }
                    // setLoaderCircular(false); commented due to loading issue arised
                })
                    .catch(err => {
                        if (serverError.includes(err.code)) {
                            // setCommonAlert({ apiErrorStatus: true, message: err.message });
                        }
                    });

            }
        },
        [userInfo, accessToken]
    );
    const refresh = () => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.refresh();
        }
    }

    //     const process = () => {
    //         selectedMembers.forEach((m) => console.log(m));
    //       };
    //     const onSelectionChanged = e => {
    //     console.log("Processing", e.selectedRowKeys);
    //     setSelectedMembers(e.selectedRowKeys);
    //   };


    function getStatusColor(data) {
        let color = '';
        if (data.Status === 'Interview completed') {
            return color = 'green'
        }
        else if (data.Status === 'Interview InProcess') {
            return color = 'blue'
        }
        else if (data.Status === 'Interview Disqualified') {
            return color = 'red'
        }
        else if (data.Status.trim() === 'Unsent') {
            return color = '#aa6d6b'
        }
        else if (data.Status === 'Interview Link Expired') {
            return color = 'rgb(255 46 46 / 56%)'
        }
        else {
            color = '#ffa100'
        }
        return color
    };

    function setStatus(e) {
        return <div style={{ color: getStatusColor(e.data), fontWeight: '500' }}>{e.value}</div>
    };

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== '';
    }

    const store = new CustomStore({
        key: "id",
        async load(loadOptions) {
            // console.log(loadOptions);
            if (userInfo.email == undefined) {
                return {
                    data: [],
                    totalCount: 0
                }
            }

            const searchfilters = loadOptions.filter;

            if (loadOptions.filter) {
                if (loadOptions.filter.includes('and')) {
                    searchfilters.map((filter, index) => {
                        let array = filter;
                        if (filter instanceof Array) {
                            array[2] = encodeURIComponent(array[2]);
                        }
                        return array;
                    });

                }
                else {
                    searchfilters[2] = encodeURIComponent(searchfilters[2])
                }
            }
            loadOptions.filter = searchfilters;


            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "requireGroupCount",
                "sort",
                "filter",
                "totalSummary",
                "group",
                "groupSummary"
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            const paramss = new URLSearchParams(params);
            const skip = paramss.get('skip');
            const take = paramss.get('take');
            const filter = paramss.get('filter');
            // console.log(filter, 'ribhb');
            let sortArray = {};

            const _filterData = [];

            // console.log(JSON.parse(paramss.get('filter')), 'ribhb')

            if (paramss.get('filter') != null) {
                const filterValue = JSON.parse(paramss.get('filter'));
                // console.log(filterValue, 'fgjhfgjh');
                filterValue.forEach((item, index) => {
                    if (item instanceof Array) {

                        if (item[0] == "Status" && item[1] == 'contains') {
                            let filterSearchArray = item[2].split(" ");

                            let filterSearch = '';

                            for (let filterSearchArrayItem of filterSearchArray) {
                                filterSearch += filterSearchArrayItem;
                            }

                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: filterSearch
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "=") {
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "startswith") {
                            let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }

                        else if (item[0] == "Status" && item[1] == "endswith") {
                            let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "notcontains") {
                            let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue.replace(/\s{1,}/g, '')
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "<>") {
                            let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == 'datefilter') {
                            _filterData.push({
                                key: item[2],
                                [mapDataGridFeildsForServerSideSorting[item[1]]]: item[3]
                            });
                        }
                        else {
                            _filterData.push(
                                {
                                    key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                    [mapDataGridFeildsForServerSideSorting[item[0]]]: item[2]
                                });
                        }
                    }
                });
                if (_filterData.length === 0) {
                    // console.log(filterValue, 'jd');
                    if (filterValue[0] == "Status" && filterValue[1] == 'contains') {
                        let filterSearchArray = filterValue[2].split(" ");
                        let filterSearch = '';

                        for (let filterSearchArrayItem of filterSearchArray) {
                            filterSearch += filterSearchArrayItem;
                        }

                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterSearch
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "=") {
                        let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                        });
                    }
                    // else if (filterValue[0] == "CandidateName" && filterValue[1] == ("=" || "<>" || "endswith")) {
                    //     _filterData.push({
                    //         key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                    //         [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterValue[2] + ' '
                    //     });
                    // }
                    else if (filterValue[0] == "Status" && filterValue[1] == "notcontains") {
                        debugger
                        let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue.replace(/\s{1,}/g, '')
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "startswith") {

                        let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "endswith") {

                        let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "<>") {
                        let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                        });
                    }
                    else if (filterValue[0] === 'datefilter') {
                        _filterData.push({
                            key: filterValue[2],
                            [mapDataGridFeildsForServerSideSorting[filterValue[1]]]: filterValue[3]
                        });
                    }
                    else {
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterValue[2]
                        });
                    }
                }
            }
            _filterData.forEach(item => {
                if (item.key === 'daterange') {
                    if (item.startDate != undefined) {
                        if (item.startDate.toLowerCase().includes('undefined') || item.startDate.toLowerCase().includes('Date-undefined')) {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, startDate: undefined }
                        }
                        else {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, startDate: 'idle' }
                        }
                    }
                    if (item.endDate != undefined) {
                        if (item.endDate.toLowerCase().includes('undefined') || item.endDate.toLowerCase().includes('Date-undefined')) {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, endDate: undefined }
                        }
                        else {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, endDate: 'idle' }
                        }
                    }
                }
            });
            const selectorObj = { selector: 'updatedAt', desc: true };
            if (paramss.get('sort') != null) {
                sortArray = JSON.parse(paramss.get('sort'))
                // console.log(sortArray, 'sortArray');
                selectorObj.selector = sortArray[0].selector;
                selectorObj.desc = sortArray[0].desc;
            }
            params = params.slice(0, -1);
            try {
                let totalCount = 0;
                let array = [];
                // ArchiveList(accessToken, userInfo, userRole)
                // .then(res => {

                //     if (res.status) {
                //         if (res.data.data === null) {
                //             setTableLoaderErrInfo("No record found");
                //         }
                //         else {
                //             setListData(res.data.data.response);
                //             setTableLoaderErrInfo("");
                //         }
                //     }
                //     else {
                //         throw { code: res.code, message: res.message };
                //     }

                // })
                // .catch(err => {
                //     if (serverError.includes(err.code)) {
                //         setTableLoaderErrInfo(err.message);
                //         setCommonAlert({ apiErrorStatus: true, message: err.message });
                //     } else {
                //         setTableLoaderErrInfo("Contact to Administrator")
                //     }
                // })
                const res = await ArchiveList(accessToken, userInfo, userRole, (skip / take) + 1, take, mapDataGridFeildsForServerSideSorting[selectorObj.selector], selectorObj.desc, _filterData)
                // console.log(res, 'jajajaa');
                if (res.status) {
                    if (res.data.status === 'succeed') {
                        if (res.data.data !== null) {
                            array = [...res.data.data.response].map((item, index) => {

                                let startTime = "";
                                // console.log(item);
                                if (item.startDate) {
                                    const startDate = new Date(item.startDate * 1000).toDateString();
                                    const _startTime = new Date(item.startDate * 1000);
                                    const myArray = startDate.split(" ");
                                    //const localTimeString = new Date(item.startDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
                                    // const localTime = localTimeString.slice(0, 11);
                                    //const localTime = localTimeString.slice(0, 8);
                                    const localTime = formatDateTo24Hour(_startTime);
                                    // const timeFormat = localTimeString.slice(9, 12);
                                    // console.log('local', localTime, timeFormat);
                                    const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
                                    startTime = sDate + " " + localTime;
                                }
                                let endTime = "";
                                if (item.endDate) {

                                    const endDate = new Date(item.endDate * 1000).toDateString();
                                    const _endTime = new Date(item.endDate * 1000);
                                    const myArray_1 = endDate.split(" ");
                                    // const localTimeString_1 = new Date(item.endDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
                                    // const localTime_1 = localTimeString_1.slice(0, 8);
                                    const localTime_1 = formatDateTo24Hour(_endTime);
                                    // const timeFormat = localTimeString.slice(9, 12);
                                    // console.log('local', localTime, timeFormat);
                                    const sDate_1 = myArray_1[2] + "-" + myArray_1[1] + "-" + myArray_1[3];
                                    endTime = sDate_1 + " " + localTime_1;
                                };

                                let interviewStatus = '';
                                if (item.status.includes('interviewLinkExpired')) {
                                    interviewStatus = item.status.slice(0, 9).charAt(0).toUpperCase() + item.status.slice(1, 9) + ' ' + item.status.slice(9, 13) + ' ' + item.status.slice(13, 20);
                                }
                                else {
                                    interviewStatus = item.status.slice(0, 9).charAt(0).toUpperCase() + '' + item.status.slice(0, 9).slice(1) + ' ' + item.status.slice(9);
                                }
                                // console.log(interviewStatus);
                                let candidateName = item.candidateName;
                                candidateName = candidateName.replace(/\b\w/g, function (l) { return l.toUpperCase() });
                                return {
                                    id: index + 1,
                                    CandidateName: candidateName,
                                    Email: item.candidateEmail,
                                    "Contact No": item.candidateNumber,
                                    JobName: item.jobName,
                                    Status: interviewStatus,
                                    'Interview Date': startTime,
                                    'Submitted Date': endTime,
                                    mid: item.id,
                                    questionnairesId: item.questionnairesId,
                                    item,
                                };
                            });
                            totalCount = res.data.data.count;
                        }
                        else {
                            setTableLoaderErrInfo("Record Not Found")
                        }
                    }
                }


                return {
                    data: array,
                    totalCount: totalCount
                };
            } catch {
                setTableLoaderErrInfo("Please Contact to Administrator")
                throw new Error("Data Loading Error");
            }
        }
    });
    const customChanges = {
        icon: 'undo',
        text: 'Clear',
        type: 'default',
        onClick: () => setResetCall('active')
    }

    const cancelCustomchanges = {
        text: 'Close',
        icon: 'remove',
        type: 'danger',
        onClick: () => { setClearCustomizationPopUp(false) },
    }
    const _InterviewListData = viewArchiveList();
    // console.log(_InterviewListData, tableLoaderErrInfo, '_InterviewListData')

    function calculateFilterExpression(value, selectedFilterOperations, target) {
        DateFilterExpressionRef.current = selectedFilterOperations;
        const column = this;
        if (target === 'headerFilter' && value === 'weekends') {
            return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
        }
        if (selectedFilterOperations === "between") {
            const filterExpression = [this.dataField, 'darterange', value];

            // return filterExpression;
        }

        if ((this.dataField === 'Interview Date') || (this.dataField === 'Submitted Date')) {
            let dateText = '';
            let commonDateValue = value;
            if (selectedFilterOperations === "between") {


                /* code commented on 24-04-2023
                let dateFrom = new Date(value[0]).toLocaleDateString().split('/');
                let dateTo = new Date(value[1]).toLocaleDateString().split('/');

                let zeroIndex = dateFrom[0];
                let firstIndex = dateFrom[1];
                let datetozeroIndex = dateTo[0];
                let datetofirstIndex = dateTo[1];

                if (dateFrom[0] < 10) {
                    zeroIndex = '0' + dateFrom[0];
                }
                if (dateFrom[1] < 10) {
                    firstIndex = '0' + dateFrom[1];
                }
                if (dateTo[0] < 10) {
                    datetozeroIndex = '0' + dateTo[0];
                }
                if (dateTo[1] < 10) {
                    datetofirstIndex = '0' + dateTo[1];
                }


                dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;
                dateTo = dateTo[2] + '-' + datetozeroIndex + '-' + datetofirstIndex;
                */
                let dateFrom = generateDates(value[0]);
                let dateTo = generateDates(value[1]);
                commonDateValue = dateFrom + 'TO' + dateTo;

            }
            else {
                /* code commented on 24-04-2023
                let dateFrom = new Date(value).toLocaleDateString().split('/');

                let zeroIndex = dateFrom[0];
                let firstIndex = dateFrom[1];

                if (dateFrom[0] < 10) {
                    zeroIndex = '0' + dateFrom[0];
                }
                if (dateFrom[1] < 10) {
                    firstIndex = '0' + dateFrom[1];
                }

                dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;
                */
                let dateFrom = generateDates(value);
                if (selectedFilterOperations === '=') {
                    commonDateValue = dateFrom + 'TO' + dateFrom;
                }
                else {
                    commonDateValue = dateFrom;
                }


            }
            const filterExpression = [
                'datefilter',
                this.dataField,
                // selectedFilterOperations,
                mapDateDataGridConstraintsForServerSideFiltering[selectedFilterOperations],
                commonDateValue
            ];

            return filterExpression



        }

        return column.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
    }


    function generateDates(value) {

        if (value) {
            const today = new Date(value);
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm
            }

            return `${yyyy}-${mm}-${dd}`;

        }
        else {
            return undefined;
        }

        //console.log(value, 'sdgyvdsygvdy', `${yyyy}-${mm}-${dd}`)


    }
    const clearFilters = (e) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.clearFilter();
        }
    };


    const formatDateTo24Hour = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    // console.log(tableLoaderErrInfo, 'gagaga');
    return (
        <>
            {/* <div className='search_bar'>    
            </div> */}
            {/* Add Media query here */}
            {/* <div className='dev-extreme-datagrid-list' style={{ height: "90vh" }}> */}
            {/* <button onClick={clearFilters}>Clear Filters</button> */}
            <Popup
                visible={clearCustomizationPopUp}
                // onHiding={hideInfo}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                container="body"
                width={500}
                height={200}
                title="Alert"
            >

                <Position
                    my="center"
                    of={'body'}
                    collision="fit"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={customChanges}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={cancelCustomchanges}
                />
                <p>Your custom changes made by you on grid will be removed..</p>
            </Popup>

            {/* <LoadPanel visible={(tableLoaderErrInfo === 'loading') ? true : false} position={{ of: '.dev-extreme-datagrid-list' }} /> */}

            {
                <div style={{ height: '100vh', width: '100%' }}>
                    <div className="pull-right">
                        <div className="pull-right-child">
                            <span className="legendLable">
                                <span className="redspan1"></span>
                                Interview Disqualified
                            </span>
                            <span className="legendLable">
                                <span className="redspan2"></span>
                                Interview Completed
                            </span>
                            <span className="legendLable">
                                <span className="redspan3"></span>
                                Interview In Process
                            </span>
                            <span className="legendLable">
                                <span className="redspan4"></span>
                                Interview Pending
                            </span>
                            <span className="legendLable">
                                <span className="redspan5"></span>
                                Unsent
                            </span>
                            <span className="legendLable">
                                <span className="redspan6"></span>
                                Interview Link Expired
                            </span>
                        </div>
                    </div>
                    <hr />
                    {/* <div style={{ height: `calc(90%)`, width: `calc(99%-10px)`, marginRight: '0', minHeight: '600px' }}> */}



                    <DataGrid
                        dataSource={store}
                        remoteOperations={true}
                        keyExpr="id"
                        key="Candidate name"
                        // onSelectionChanged={onSelectionChanged}

                        // selectedRowKeys={viewArchiveList()}
                        height="80%"
                        width='100%'
                        // showBorders='2'
                        // defaultValue="#f05b41"
                        showRowLines={true}
                        showColumnLines={true}
                        noDataText={tableLoaderErrInfo}
                        ref={dataGridRef}

                        hoverStateEnabled
                        showBorders={true}
                        // showRowLines
                        rowAlternationEnabled={true}
                        allowColumnResizing={true}
                        allowColumnReordering={true}
                        loadPanel={{ enabled: true }}
                        filterSyncEnabled={true}
                        columnMinWidth={50}
                    // customizeColumns={customizeColumns}
                    // columnWidth={100}
                    // columnAutoWidth={true}
                    >
                        {/* <Selection
                            mode="multiple"
                            showCheckBoxesMode={'always'}
                        /> */}
                        <StateStoring
                            enabled={true}
                            type="custom"
                            customLoad={loadState}
                            customSave={saveState}
                        />
                        <Scrolling columnRenderingMode="virtual" />
                        <Paging defaultPageSize={20} enabled={false} />
                        <Lookup
                            dataSource={store}
                            valueExpr="ID"
                            displayExpr="Name" />
                        <Toolbar>
                            <Item>
                                <DevExtremToolbarClearfilter clearFilters={clearFilters} dataGridRef={dataGridRef} />
                            </Item>
                            <Item location="after">
                                <Button hint='Refresh'
                                    icon='refresh'
                                    onClick={() => refresh()}
                                />
                            </Item>
                            <Item
                                name="columnChooserButton"
                            />
                            <Item
                                name="exportButton"
                            />
                            <Item>
                                <img
                                    title='Clear Customization'
                                    className="clean"
                                    src={clean}
                                    onClick={() => {
                                        setClearCustomizationPopUp(true)
                                    }}
                                ></img>

                            </Item>
                        </Toolbar>
                        <ColumnChooser enabled={true} />
                        <Export enabled={true} allowExportSelectedData={false} />
                        <ColumnFixing enabled={true} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showInfo={true} visible={true} />
                        {/* <Scrolling useNative={true} /> */}
                        <Paging defaultPageSize={20} enabled={true} />
                        {/* <LoadPanel visible={loaderCircular} /> */}
                        <FilterRow visible={true} />
                        <Column allowSorting={false} width="50px" allowFiltering={false} alignment="left" caption="S.No" dataField='id' allowReordering={false} />
                        <Column width="200px" caption="Candidate Name" dataField="CandidateName" cellRender={setStatus} allowReordering={false} editorOptions={{
                            showClearButton: true
                        }} />
                        <Column width="250px" caption="Email ID" dataField="Email" allowReordering={false}
                            editorOptions={{
                                showClearButton: true
                            }} />
                        <Column width="150px" caption="Contact No" dataField="Contact No"
                            editorOptions={{
                                showClearButton: true
                            }} />
                        <Column width="250px" caption="Job Title" dataField="JobName"
                            editorOptions={{
                                showClearButton: true
                            }} />
                        <Column width="150px" dataField="Status" cellRender={setStatus}
                            editorOptions={{
                                showClearButton: true
                            }} />
                        {/* <Column width="210px" dataField="createdAt" dataType="date" /> */}
                        <Column width="260px" dataField="Interview Date" dataType="date" editorOptions={true} format={filterDateFormat} calculateFilterExpression={calculateFilterExpression} />
                        <Column width="260px" dataField="Submitted Date" dataType="date" editorOptions={true} format={filterDateFormat} calculateFilterExpression={calculateFilterExpression} />
                    </DataGrid>

                </div>

            }

            {/* </div> */}
        </>
    );
}



function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {

    const [filterAlert, setfilterAlert] = useState(false);

    return (
        <>
            <img
                title='Clear Filter'
                className="clean"
                src={ClearFiltericon}
                onClick={() => {
                    const isFilterApplied = dataGridRef.current.instance.getCombinedFilter();
                    if (isFilterApplied != undefined) {
                        clearFilters();
                        setfilterAlert(true);
                        setTimeout(() => {
                            setfilterAlert(false);
                        }, 3000);
                    }

                }}
            ></img>



            < SnackBar filterAlert={filterAlert} />
            {/* <SnackBar /> */}
        </>
    )
}

function SnackBar({ filterAlert }) {



    return (
        <>
            {
                filterAlert &&
                <Snackbar
                    open={filterAlert}
                    autoHideDuration={2000}
                    // message="Filter Removed Successfully"
                    // action={action}
                    // variant="filled"
                    variant="outlined"
                    severity="success"
                >
                    <Alert fontSize="small" variant="filled" severity="success">Filter Removed Successfully</Alert>

                </Snackbar>
            }
        </>
    )
}