import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import plus from '../../../../Assets/icons/plus.png';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import './AddQuestionnaires.css';
// import { v4 as uuidv4 } from 'uuid';
import { ErrorAlert } from '../../../Alerts/ErrorAlert';
import { SuccessAlert } from '../../../Alerts/SuccessAlert';
import { postQuestionSetName } from '../../../../handlers/question_handler';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';







/*----redux---*/

import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';
import { useEffect } from 'react';
/*----redux---*/

export default function AddQuestionnaires() {
    const { accessToken, userInfo } = useUserAuthContext();
    const [open, setOpen] = React.useState(false);
    const [showError, setShowError] = React.useState({ display: false, text: '' });
    const [alertMessage, setAlertMessage] = React.useState({ display: false, text: '' });
    const questionSetName = React.useRef('')

    //const questionSet = useSelector(state => state.handleCreateInterviewInitialState.questionSet);
    const { questionSet, selectedJob } = useSelector(state => state.handleCreateInterviewInitialState);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // function closeAlert() {
    //     setTimeout(() => {
    //         setAlertMessage({ display: false, text: '' })
    //     }, 3000);
    // };
    function closeAlert(setState) {
        setTimeout(() => {
            setState({ display: false, text: '' })
        }, 3000);
    };

    const postQuestionSet = async () => {
        postQuestionSetName(accessToken, {
            "recruiterEmailId": userInfo.email,
            "name": questionSetName.current,
            "questionnairesType": "Candidate",
            "industry": "IT",
            "jobName": selectedJob.jobName,
            "createdBy": userInfo.userName,
            "createdAt": new Date().toISOString(),
            "deleted": "false"
        })
            .then(res => {
                if (res.status) {
                    if (res.data.status === 'succeed') {
                        if (res.data.data.message === "records successfully posted") {
                            setAlertMessage({ display: true, text: 'Question set Added successfully' });
                            closeAlert(setAlertMessage);
                            dispatch(CI_InitialStateAction('question_set_name', {
                                questionsetId: res.data.data["id"][0],
                                questionsetName: questionSetName.current,
                                questions: [],
                                createdBy: userInfo.userName
                            }
                            ))
                            questionSetName.current = ''
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setShowError({ display: true, text: 'Something went wrong!' });
                closeAlert(setShowError);
            })
    }
    const handleSave = () => {
        if (questionSetName.current === '') {
            setShowError({ display: true, text: "This field can't be empty" });
            closeAlert(setShowError);
            return
        }
        if (questionSetName.current.trim() != '') {
            if (checkDataExist(questionSet, 'questionsetName', questionSetName.current)) {
                postQuestionSet();
                closeAlert(setShowError);
                handleClose();
            }
            else {
                setShowError({ display: true, text: 'Question Set already exist' });
                closeAlert(setShowError);
            }
        }
    }


    function checkDataExist(array, keyName, value) {
        let tempArray = [];
        array.forEach((item, index) => {
            tempArray.push(item[keyName])
        });
        if (!tempArray.includes(value)) {
            return true;
        }
        return false;
    }


    return (
        <>
            <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <ErrorAlert showError={showError} setShowError={setShowError} />
            <div>
                <Tooltip title='Add Question set' placement="top">
                    <IconButton aria-label="search" onClick={handleClickOpen} className='plus_img'>
                        <img src={plus} />
                    </IconButton>
                </Tooltip>
                <Dialog open={open} onClose={handleClose} sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}>
                    <DialogTitle>Add Question Set</DialogTitle>
                    <Divider />
                    <DialogContent  >
                        <TextField
                            required
                            id="outlined-required"
                            label="Question set name"
                            sx={{ width: '95%', paddingBlock: '10px' }}
                            onChange={(e) => questionSetName.current = e.target.value}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={handleSave}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
