import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import job from '../../Assets/icons/job.png';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import './ViewJob.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import usePagination from './Pagination';
import AppsIcon from '@mui/icons-material/Apps';
import TableViewIcon from '@mui/icons-material/TableView';
import InputBase from '@mui/material/InputBase';
import { Button, Dialog, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
// import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { updateJob, ViewJobList } from '../../handlers/job_handlers';
import { useUserAuthContext } from '../../utils/Contexts/Auth/UserAuthProvider';
import Loader from '../Preloaders/Loader';
import { filterDataList } from '../../utils/Helper/filterDataList';
import FadeMenu from '../../Components/Interview/InterviewList/FadeMenu'
import jobIcon from '../../Assets/icons/suitcase.png';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef, useState, useEffect, useCallback } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { SuccessAlert } from '../Alerts/SuccessAlert';
import { ErrorAlert, ErrorAlertMultiline } from '../Alerts/ErrorAlert';
import { deleteJob } from '../../handlers/job_handlers';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

// import Dialog from '@mui/material/Dialog';
// dx
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, {
    Column,
    FilterRow,
    Paging,
    Scrolling,
    Selection,
    Pager,
    ColumnChooser,
    Sorting,
    Lookup,
    Item,
    Toolbar,
    Export,
    ColumnFixing,
    Editing,
    Box as DevExpressBox,
    Button as DevExpressButton,
    StateStoring,
} from "devextreme-react/data-grid";
import { Button as Button2 } from "devextreme-react/button";
import clean from "../../Assets/icons/clean.png";
import ClearFiltericon from "../../Assets/icons/ClearFiltericon.png";


import {
    GridCustomization,
    GridCustomizeGet,
} from "../../handlers/customize";



const allowedPageSizes = [20, 50, 100];

import CustomStore from "devextreme/data/custom_store";
// import { DevExtremToolbarClearfilter } from '../Interview/InterviewList/InterviewList';

const initialColumnStructure = {
    searchText: "",
    // allowedPageSizes: [20, 50, 100],
    filterPanel: { filterEnabled: true },
    pageIndex: 0,
    "columns": [
        {
            "visibleIndex": 0,
            "dataField": "id",
            "name": "id",
            "dataType": "number",
            width: '50px',
            "visible": true,
            "fixed": true,
            allowSorting: false

        },
        {
            "visibleIndex": 1,
            "dataField": "JobTitle",
            "name": "JobTitle",
            "dataType": "string",
            width: "240px",
            "visible": true
        },
        {
            "visibleIndex": 2,
            "dataField": "JobCode",
            "name": "JobCode",
            "dataType": "string",
            width: "240px",
            "visible": true
        },
        {
            "visibleIndex": 3,
            "dataField": "Email",
            "name": "Email",
            "dataType": "string",
            width: "240px",
            "visible": true
        },
        {
            "visibleIndex": 4,
            "dataField": "Contact No",
            "name": "Contact No",
            "dataType": "string",
            width: "150px",
            "visible": true,
        },
        {
            "visibleIndex": 5,
            "dataField": "Location",
            "name": "Location",
            "dataType": "string",
            width: "250px",
            "visible": true
        },
        {
            "visibleIndex": 6,
            "dataField": "Status",
            "name": "Status",
            "dataType": "string",
            width: "150px",
            "visible": true
        },
        {
            "visibleIndex": 7,
            "dataField": "Created Date",
            "name": "Created Date",
            "dataType": "date",
            width: "200px",
            "visible": true
        },
        {
            "visibleIndex": 8,
            "dataField": "Updated date",
            "name": "Updated date",
            "dataType": "date",
            width: "200px",
            "visible": true
        }
    ],
    "allowedPageSizes": [20, 50, 100],
    pageSize: 20,
};

const mapDataGridFeildsForServerSideSorting = {
    "id": 'id',
    "Contact No": 'recruiterMobileNumber',
    "JobTitle": 'jobName',
    "Email": 'recruiterEmailId',
    "Status": 'jobStatus',
    "Location": 'location',
    "jobCode": 'jobCode',
    "Created Date": 'createdAt',
    "Updated date": 'updatedAt'
};



const mapDataGridConstraintsForServerSideFiltering = {
    endswith: 'endwith',
    startswith: 'startwith',
    notcontains: 'notcontains',
    contains: 'contains',
    '=': 'equal',
    '<>': 'notequal'
}
// dx

const mapDateDataGridConstraintsForServerSideFiltering =
{
    endswith: 'endwith',
    startswith: 'startwith',
    notcontains: 'notcontains',
    contains: 'contains',
    '=': 'daterange',
    '<>': 'notequaldate',
    '<': 'dateless',
    '>': 'dategreater',
    '>=': 'dategreaterequal',
    '<=': 'datelessequal',
    'between': 'daterange',
}

const gridStatusValue = {
    "active": "Active",
    "inactive": "Inactive",
    "close": "Close",
    "reactivated": "Reactivated",
    "Active": "Active",
    "Inactive": "Inactive",
    "Close": "Close",
    "Reactivated": "Reactivated"
}


const divstyle =
{
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}
// const handleRowClick = (e) => {
//     e.event.preventDefault();
// }


const getRowId = params => params.data.id;
const ViewJob = ({ _open }) => {
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    let [page, setPage] = React.useState(1);
    const [View, setView] = useState('grid');
    const [jobList, setJobList] = useState([]);
    const [loading, setLoading] = useState('loading');
    const [filteredData, setFilteredData] = useState([]);
    const [openEditMenu, setOpenEditMenu] = useState(false);
    const [pageCount, setPageCount] = React.useState(6);
    const PER_PAGE = 6;
    // const count = Math.ceil(filteredData.length / PER_PAGE);
    // let count = 9;
    const _DATA = usePagination(filteredData, pageCount);
    const [rowData, setRowData] = useState('');
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' });
    const [isAdmin, setIsAdmin] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeletId] = useState(false);
    const [pageSize, setPageSize] = React.useState(20);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [mailError, setMailError] = useState({
        error: false,
        message: ''
    });
    const [errorInfo, setErrorInfo] = useState({
        status: false,
        error: {}
    });
    const [showError, setShowError] = useState({ display: false, text: '' });
    const [showErrorMultiline, setShowErrorMultiline] = useState(false);
    // const [initialSearch, setInitialSearch] = useState(true);
    const [tableData, setTableData] = useState({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        skills: '',
        email: ''
    });
    // const [pageCount, setPageCount] = useState(1);
    const [perPage, setPerPage] = useState(6);
    const [count, setCount] = useState('');
    const [filterValues, setFilterValues] = useState([]);



    //--
    const [errorDataStore, setErrorDataStore] = useState({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        skills: '',
        email: ''
    })
    //--



    const errorRef = useRef({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        email: ''
    });


    const [DGPageNumber, setDGPageNumber] = useState(0);

    const DateFilterExpressionRef = React.useRef('');
    const DateFilterStatusRef = React.useRef({ createdAt: 'idle', updatedAt: 'idle' });


    // dx
    const [clearCustomizationPopUp, setClearCustomizationPopUp] = React.useState(false);
    const [customizationData, setCustomizationData] = React.useState(null);
    const [resetCall, setResetCall] = React.useState("idle");
    const [pageIndex, setPageIndex] = useState(1);
    const [loaderCircular, setLoaderCircular] = useState(true);
    const reset_custom = React.useRef(initialColumnStructure);
    const [loadingErrorState, setLoadingErrorState] = React.useState("Loading")
    const [deleteJobPopUp, setDeleteJobPopUp] = React.useState(false)
    const dataGridRef = useRef(null);
    const visibleJobDelete = useRef("");
    const timmerId = useRef("");
    const [isInterviewStatusAvailable, setIsInterviewStatusAvailable] = React.useState(false)
    // dx


    const handleClose = () => {
        setOpen(false);
    };
   
    let width = '';
    if (_open) {
        width = "84vw"
    }
    else {
        width = "93vw"
    }
    useEffect(() => {
        if (openEditMenu) {
            const _rowData = {
                recruiterMobileNumber: '', jobName: '', jobStatus: '', designation: '', location: '', experience: [{ experienceTo: '', experienceFrom: '' }], ctc: [
                    { ctcfrom: '', ctcto: '' }], skills: [], recruiterEmailId: ''
            };
            for (let key in _rowData) {
                if (rowData[key]) {
                    // if (_rowData instanceof Array) {
                    //     _rowData[key] = rowData[key];
                    // }
                    // else {
                    _rowData[key] = rowData[key];
                    // }
                }
            }
            setTableData({
                recruiterMobileNumber: _rowData.recruiterMobileNumber,
                jobName: _rowData.jobName,
                jobStatus: _rowData.jobStatus,
                designation: _rowData.designation,
                location: _rowData.location,
                experience_from: _rowData.experience[0].experienceFrom
                    ? _rowData.experience[0].experienceFrom
                    : "",
                experience_to: _rowData.experience[0].experienceTo
                    ? _rowData.experience[0].experienceTo
                    : "",
                ctc_from: _rowData.ctc[0].ctcfrom,
                ctc_to: _rowData.ctc[0].ctcto,
                skills: _rowData.skills,
                email: _rowData.recruiterEmailId,
            });
        }
    }, [openEditMenu, rowData]);
    // dx

    const columns = [
        {
            field: 'id',
            renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
            headerName: 'S.No.',
            width: 70,
            sortable: false,
        },
        {
            field: 'JobTitle',
            headerName: 'Job Title',
            width: 200,
            editable: false,
        },
        {
            field: 'JobCode',
            headerName: 'Job Code',
            width: 200,
            editable: false,
        },
        {
            field: 'Email',
            headerName: 'Email ID',
            width: 340,
            editable: false,
        },
        {
            field: 'Contact No',
            headerName: 'Contact No',
            width: 120,
            editable: false,
        },
        {
            field: 'Location',
            headerName: 'Location',
            editable: false,
            width: 380,
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 100,
            editable: false,
        },
        {
            field: 'Created Date',
            headerName: 'Created Date',
            width: 200,
            editable: false,
        },
        {
            field: 'Updated date',
            headerName: 'Updated Date',
            width: 200,
            editable: false
        },
        // {
        //     field: 'Submitted Date',
        //     headerName: 'Submitted Date',
        //     width: 140,
        //     editable: false,
        // },
        {
            field: 'Action',
            headerName: 'Action',
            editable: false,
            type: "actions",
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    // disabled={!params.row.item.isLocal}
                    icon={<EditIcon color='info' />}
                    // label={params.row.item.isLocal ? "Edit Job" : 'CRM Job'}
                    label='Edit Job'
                    onClick={() => {
                        editOpen(params.row)
                    }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon color='warning' />}
                    label="Delete"
                    onClick={() => { handleDelete(params.id) }}
                    showInMenu
                />,
            ]
        },
    ];

    function handleDelete(id) {
        setDeletId(id);
        setOpen(true);
    }

    function closeAlert() {
        setTimeout(() => {
            setShowError({ display: false, text: '' })
        }, 4000);
    };

    function closeSuccess() {
        setTimeout(() => {
            setAlertMessage({ display: false, text: '' })
        }, 4000);
    };

    // dx
    function editOpen(param, status = false) {
        setOpenEditMenu(true);
        setRowData(param.row.data.item);
        setIsInterviewStatusAvailable(status)
    }


    function generateDates(value) {
        if (value) {
            const today = new Date(value);
            const yyyy = today.getFullYear();
            let mm = today.getMonth() + 1; // Months start at 0!
            let dd = today.getDate();
            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            return `${yyyy}-${mm}-${dd}`;
        }
        else {
            return undefined;
        }
    }
    // dx


    function handlePopUpClose() {
        setOpenEditMenu(false);
        setTableData(
            {
                recruiterMobileNumber: "",
                jobName: "",
                jobStatus: "",
                designation: "",
                location: '',
                experience_from: '',
                experience_to: '',
                ctc_from: '',
                ctc_to: '',
                skills: '',
                email: ''
            }
        );
        setErrorInfo({
            status: false,
            error: {}
        });

        setMailError({
            error: false, message: ''
        });

        setErrorDataStore({
            recruiterMobileNumber: "",
            jobName: "",
            jobStatus: "",
            designation: "",
            location: '',
            experience_from: '',
            experience_to: '',
            ctc_from: '',
            ctc_to: '',
            skills: '',
            email: ''
        })
    }


    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        setCount(0);
    };
    function handleView(str) {
        setView(str);
    };

    useEffect(() => {
        if (jobList) {
            setFilteredData(jobList)
        }
    }, [jobList]);

    // useEffect(() => {
    //     if (!filteredData.length && jobList.length) {
    //         setLoading('no_record_found')
    //     }
    //     else if (filteredData.length) {
    //         setLoading('empty')
    //     }
    // }, [filteredData, jobList]);

    //dx
    function updateJobList() {
        updateJob(accessToken, tableData, userInfo, rowData.id, isAdmin).then(
            (res) => {

                if (res.data.data.message === "Job Updated Successfully!!") {
                    setOpenEditMenu(false);
                    setAlertMessage({ display: true, text: "Job edited successfully" });
                    closeSuccess();
                    getJobListBasedOnUserRole();
                } else if (
                    res.data.data.message ===
                    "Interview already Schedule on Job can't be Edit!!"
                ) {
                    setOpenEditMenu(false);
                    setShowError({
                        display: true,
                        text: "Interview on this job is already scheduled. Job cannot be edited!!",
                    });
                    closeAlert();
                }
            }
        );
    }


    function deleteJobHandler(e) {
        deleteJob(e, accessToken, userInfo)
            .then((res) => {
                if (res.data.data.message === "Interview already Schedule on Job can't be Delete!!") {
                    setShowError({
                        display: true,
                        text: "Interview on this job is already scheduled can't be Deleted",
                    });
                    // setOpen(false)
                    closeAlert();
                }
                if (res.data.data.message === "Job Deleted Successfully!!") {
                    setAlertMessage({ display: true, text: "Job Deleted Successfully" });
                    getJobListBasedOnUserRole();
                    closeSuccess();
                    // setOpen(false)
                }
            })
            .catch((err) => {
                console.log(err);
                // setShowError({
                //     display: true,
                //     text: "Something went wrong please contact to administrator ",
                // })
            }
            );
    }
    //dx
    //  ------------------------------
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'experience_from' || name === 'experience_to' || name === 'ctc_from' || name === 'ctc_to') {
            if ((/^[0-9]*(.){0,1}[0-9]*$/.test(value))) {
                if (value > -1) {
                    setTableData({
                        ...tableData,
                        [name]: value,
                    });
                }
            }
        }
        else if (name === 'recruiterMobileNumber') {
            if ((/^[0-9]{0,10}$/.test(value))) {
                setErrorDataStore(state => ({ ...state, [name]: '' }))
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                const errorLabelText = { recruiterMobileNumber: 'recruiterMobileNumber' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `Phone number can't be empty` }))
                }
                else if ((value.length < 10)) {
                    setErrorDataStore(state => ({ ...state, [name]: `Phone number should be of 10 digits` }))
                }
            }
            // else {
            //     // setTableData({
            //     //     ...tableData,
            //     //     [name]: value,
            //     // });
            //     const errorLabelText = { recruiterMobileNumber: 'recruiterMobileNumber' };
            //     if (value == '') {
            //         setErrorDataStore(state => ({ ...state, [name]: `Phone number can't be empty` }))
            //     }
            //     else {
            //         setErrorDataStore(state => ({ ...state, [name]: `Please enter valid ${errorLabelText[name]}` }))
            //     }
            // }
        }
        else if (name === 'designation' || name === 'jobName') {
            if ((/^[a-zA-Z\s]+$/.test(value))) {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                setErrorDataStore(state => ({ ...state, [name]: `` }))
            }
            else {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                const errorLabelText = { designation: 'Designation', jobName: 'Job Title' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `${errorLabelText[name]} can't be empty` }))
                }
                else {
                    setErrorDataStore(state => ({ ...state, [name]: `Please enter valid ${errorLabelText[name]}` }))
                }
            }
        }
        else if (name === 'skills' || name === 'location') {
            if ((/^[a-zA-Z0-9\s.]+$/.test(value))) {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                setErrorDataStore(state => ({ ...state, [name]: '' }))
            }
            else {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                const errorLabelText = { skills: 'Skills', location: 'Location' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `${errorLabelText[name]} can't be empty` }))
                }
                else {
                    setErrorDataStore(state => ({ ...state, [name]: `Please enter valid ${errorLabelText[name]}` }))
                }
            }
        }
        else if (name === 'email') {
            if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))) {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                setMailError({
                    error: false, message: ''
                })
            }
            else {
                setTableData({
                    ...tableData,
                    [name]: value,
                });
                if (value === '') {
                    setMailError({
                        error: true, message: "This field can't be empty"
                    })
                }
                else {
                    setMailError({
                        error: true, message: 'Please Enter valid email'
                    })
                }
            }
        }
        else {
            setTableData({
                ...tableData,
                [name]: value,
            });
        }
    };
    //  -------------------------------

    useEffect(() => {
        if (accessToken && userInfo.email && userRole) {
            clearTimeout(timmerId.current);
            timmerId.current = setTimeout(() => {
                getJobListBasedOnUserRole();
            }, 500)
        }
    }, [accessToken, userInfo, userRole, page, filterValues, pageCount]);
    //  This Api is used to get filtred data 
    function getJobListBasedOnUserRole() {
        setLoading('loading')
        let jobSearch = [];
        if (filterValues.length > 0) {
            jobSearch.push({
                "key": "contains",
                "jobName": filterValues.trim()
            })
        }
        // pageIndex, pageSize, selector = '', isDesc = '', appmodule = '', filterConstraints = []

        ViewJobList(accessToken, userInfo, userRole, page, pageCount, '', '', 'viewJobList', jobSearch)
            .then(res => {
                if (res.data.data === null) {
                    setLoading('idle')
                    setJobList([])
                    setCount(0);
                }
                else if (res.data.data.response) {
                    setLoading('idle')
                    setJobList(res.data.data.response)
                    setCount(res.data.data.count)
                }
            })
            .catch((err) => setShowError({ display: true, text: 'Something went wrong please contact to administrator ' }))

    }
    const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
    // function viewJobList() {
    //     return [...filteredData].map((item, index) => {
    //         let startTime = '';
    //         if (item.createdAt) {
    //             // const dateValue = ((new Date(item.createdAt).toISOString()))
    //             // const date = new Date(dateValue).toLocaleString('en-IN');
    //             const startDate = new Date(item.createdAt).toDateString();
    //             const myArray = startDate.split(" ");
    //             const localTimeString = new Date(item.createdAt).toTimeString();
    //             const localTime = localTimeString.slice(0, 8)
    //             const sDate = (myArray[2] + '-' + myArray[1] + '-' + myArray[3]);
    //             startTime = sDate + ' ' + localTime;
    //         };

    //         let updatedDate = '';
    //         if (item.updatedAt) {
    //             // const dateValue = ((new Date(item.createdAt).toISOString()))
    //             // const date = new Date(dateValue).toLocaleString('en-IN');
    //             const startDate = new Date(item.updatedAt).toDateString();
    //             const myArray = startDate.split(" ");
    //             const localTimeString = new Date(item.updatedAt).toTimeString();
    //             const localTime = localTimeString.slice(0, 8)
    //             const sDate = (myArray[2] + '-' + myArray[1] + '-' + myArray[3]);
    //             updatedDate = sDate + ' ' + localTime;
    //         };

    //         return {
    //             //id: index + 1, 
    //             JobTitle: item.jobName, Email: item.recruiterEmailId, 'Contact No': item.recruiterMobileNumber ? item.recruiterMobileNumber : 'N/A', Location: item.location ? item.location : 'N/A', Status: item.jobStatus, 'Created Date': startTime, 'Updated date': updatedDate ? updatedDate : 'N/A', skills: item.skills,
    //             Action: '', id: item.id, designation: item.designation, item: item
    //         }
    //     })
    // };
    //  This function is used to store filtered value 
    function searchHandler(e) {
        setFilterValues(e.target.value);
        // if (initialSearch) {            
        setPage(1);
        //  setInitialSearch(false);
        // }
    };
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: "1px solid #f0f0f0",
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#e0e0e0"
        },
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: `2px solid #f0f0f0`, borderLeft: `1px solid #f0f0f0`
        },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid #f0f0f0`, borderTop: `1px solid #f0f0f0`
        },
    }));

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const statusValue = [
        {
            value: 'Active',
            label: 'Active',
        },
        {
            value: 'Close',
            label: 'Close',
        },
    ];

    useEffect(() => {
        if (userRole === 'Global Administrator' || userRole === 'VideoInterview Administrator') {
            setIsAdmin(true)
        }
        else {
            setIsAdmin(false)
        }
    }, [userRole]);

    // function handleEmpty() {

    //     let error =
    //     {
    //         recruiterMobileNumber: "",
    //         jobName: "",
    //         jobStatus: "",
    //         designation: "",
    //         location: '',
    //         experience_from: '',
    //         experience_to: '',
    //         ctc_from: '',
    //         ctc_to: '',
    //         email: ''
    //     }

    //     for (var key in tableData) {
    //         if (tableData[key] === "") {
    //             // setShowError({ display: true, text: `${key} can't be empty` });
    //             errorRef.current[key] = `${key} can't be empty`;
    //             // setIsEmpty('empty');
    //         }
    //         else {
    //             errorRef.current[key] = '';
    //             // setIsEmpty('not empty');
    //         }
    //     }

    //     let status = 1;
    //     let errorstatus = 1;
    //     if (parseInt(tableData.experience_from) > parseInt(tableData.experience_to)) {
    //         errorRef.current.experience_from = 'Experience From should be less then experience to';
    //     }

    //     if (errorInfo.status && tableData.experience_from == '') {
    //         errorRef.current.experience_from = 'Experience From should be less then experience to';
    //     }

    //     if (errorInfo.status && tableData.experience_to == '') {
    //         errorRef.current.experience_to = "This field can't be empty";
    //     }


    //     if (parseInt(tableData.ctc_from) > parseInt(tableData.ctc_to)) {

    //         errorRef.current.ctc_from = 'Ctc To should be greater than CTC From';
    //     }

    //     if (errorInfo.status && tableData.ctc_from === '') {
    //         errorRef.current.ctc_from = "This field can't be empty";

    //     }

    //     if (errorInfo.status && tableData.ctc_to === '') {
    //         errorRef.current.ctc_to = "This field can't be empty";
    //     }

    //     for (let key in errorRef.current) {
    //         if (errorRef.current[key] !== '') {
    //             status = 0
    //             break;
    //         }
    //     }

    //     for (let key in errorDataStore) {
    //         if (errorDataStore[key] !== '') {
    //             errorstatus = 0
    //             break;
    //         }
    //     }

    //     setShowErrorMultiline(status ? false : true)
    //     setErrorInfo({ status: !status, error: errorRef.current });
    //     if (status == 1 && errorstatus == 1 && tableData.recruiterMobileNumber.length == 10 && !mailError.error) {
    //         updateJobList()
    //     }
    //     // else {
    //     //     setShowError({ display: true, text: 'Mobile number min and max length is 10' });
    //     // }
    // };





    let errorContainer = []
    for (let key in errorInfo.error) {
        if (errorInfo.error[key]) {
            errorContainer.push(errorInfo.error[key])
        }
    }


    let fieldError = {
        ctc_from: '',
        ctc_to: '',
        experience_from: '',
        experience_to: ''
    };

    if (parseInt(tableData.experience_from) > parseInt(tableData.experience_to)) {
        fieldError.experience_from = 'Experience From should be less then experience to';
    }

    if (errorInfo.status && tableData.experience_from == '') {
        fieldError.experience_from = "This field can't be empty";
    }

    if (errorInfo.status && tableData.experience_to == '') {
        fieldError.experience_to = "This field can't be empty";
    }

    if (parseInt(tableData.ctc_from) > parseInt(tableData.ctc_to)) {
        fieldError.ctc_from = ' CTC TO should be greater than CTC From';
    }

    if (errorInfo.status && tableData.ctc_from === '') {
        fieldError.ctc_from = "This field can't be empty";

    }

    if (errorInfo.status && tableData.ctc_to === '') {
        fieldError.ctc_to = "This field can't be empty";
    }

    // dx

    const refresh = () => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.refresh();
        }
    }

    const saveState = useCallback(
        (state) => {
            const interviewList = "view-job";
            // setLoaderCircular(true);
            // let tableData = "";
            // if (resetCall === "inactive" && !checkstate) {
            //     tableData = state;
            // } else {
            //     tableData = reset_custom.current;
            // }

            if (userInfo.userName && DateFilterStatusRef.current.createdAt != undefined && DateFilterStatusRef.current.updatedAt != undefined) {
                GridCustomization(
                    accessToken,
                    userInfo.userName,
                    state,
                    interviewList
                ).then((res) => {
                });
            }
        },
        [userInfo, accessToken]
    );
    const loadState = useCallback(() => {

        let data = {};
        if (resetCall === "active") {
            data = reset_custom.current;
            setResetCall("inactive");
            setClearCustomizationPopUp(false)
        } else if (resetCall === "idle") {
            data = { ...customizationData };
        }
        return data;
    }, [customizationData, resetCall]);

    React.useEffect(() => {
        if (accessToken && userInfo.userName) {
            const interviewList = "view-job";
            GridCustomizeGet(accessToken, userInfo.userName, interviewList).then(
                (res) => {
                    if (res.status) {
                        setCustomizationData(res.data.data);
                    }
                }
            );
        }
    }, [accessToken, userInfo.userName]);


    function onOptionChanged(e) {
        setPageIndex(e.component.pageIndex());
        setPageSize(e.component.pageSize());
    }


    const customChanges = {
        icon: 'undo',
        text: 'Clear',
        type: 'default',
        onClick: () => setResetCall('active')
    }

    const cancelCustomchanges = {
        text: 'Close',
        icon: 'remove',
        type: 'danger',
        onClick: () => { setClearCustomizationPopUp(false) },
    }

    function isNotEmpty(value) {
        return value !== undefined && value !== null && value !== "";
    };


    const store = new CustomStore({
        key: "id",
        async load(loadOptions) {
            if (userInfo.email == undefined) {
                return {
                    data: [],
                    totalCount: 0
                }
            }
            const searchfilters = loadOptions.filter;
            if (loadOptions.filter) {
                if (loadOptions.filter.includes('and')) {
                    searchfilters.map((filter, index) => {
                        let array = filter;
                        if (filter instanceof Array) {
                            array[2] = encodeURIComponent(array[2]);
                        }
                        return array;
                    });
                }
                else {
                    searchfilters[2] = encodeURIComponent(searchfilters[2])
                }
            }
            loadOptions.filter = searchfilters;
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "requireGroupCount",
                "sort",
                "filter",
                "totalSummary",
                "group",
                "groupSummary"
            ].forEach((i) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            const paramss = new URLSearchParams(params);
            const skip = paramss.get('skip');
            const take = paramss.get('take');
            const filter = paramss.get('filter')
            let sortArray = {};
            const selectorObj = { selector: 'JobName', desc: 'true' };
            if (paramss.get('sort') != null) {
                sortArray = JSON.parse(paramss.get('sort'))
                selectorObj.selector = sortArray[0].selector;
                selectorObj.desc = sortArray[0].desc;
            }
            // params.slice(0, -1);


            let filterData = null;
            const _filterData = [];

            if (paramss.get('filter') != null) {
                const filterValue = JSON.parse(paramss.get('filter'));
                filterValue.forEach((item, index) => {
                    if (item instanceof Array) {
                        if (item[0] == 'Status' && item[1] == 'contains') {
                            let filterSearchArray = item[2].split(" ");

                            let filterSearch = ''
                            if (filterSearchArray.length > 1) {
                                filterSearch = filterSearchArray[0] + '' + filterSearchArray[1];
                            }
                            else {
                                filterSearch = filterSearchArray[0]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: filterSearch
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "=") {
                            let boxFilteredValue = gridStatusValue[item[2]];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == "Status" && item[1] == "<>") {
                            let boxFilteredValue = gridStatusValue[item[2]];
                            if (boxFilteredValue == undefined) {
                                boxFilteredValue = item[2]
                            }
                            _filterData.push({
                                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
                            });
                        }
                        else if (item[0] == 'datefilter') {
                            _filterData.push({
                                key: item[2],
                                [mapDataGridFeildsForServerSideSorting[item[1]]]: item[3]
                            });
                        }
                        else {
                            _filterData.push(
                                {
                                    key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                                    [mapDataGridFeildsForServerSideSorting[item[0]]]: item[2]
                                });
                        }
                    }


                });


                if (_filterData.length === 0) {
                    if (filterValue[0] == 'Status' && filterValue[1] == 'contains') {
                        let filterSearchArray = filterValue[2].split(" ");
                        let filterSearch = '';
                        if (filterSearchArray.length > 1) {
                            filterSearch = filterSearchArray[0] + '' + filterSearchArray[1];
                        }
                        else {
                            filterSearch = filterSearchArray[0]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterSearch
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "=") {
                        let boxFilteredValue = gridStatusValue[filterValue[2]];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                            //[mapDataGridFeildsForServerSideSorting[filterValue[0]]]: gridStatusValue[filterValue[2]]
                        });
                    }
                    else if (filterValue[0] == "Status" && filterValue[1] == "<>") {
                        let boxFilteredValue = gridStatusValue[filterValue[2]];
                        if (boxFilteredValue == undefined) {
                            boxFilteredValue = filterValue[2]
                        }
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
                            //[mapDataGridFeildsForServerSideSorting[filterValue[0]]]: gridStatusValue[filterValue[2]]
                        });
                    }
                    else if (filterValue[0] === 'datefilter') {

                        _filterData.push({
                            key: filterValue[2],
                            [mapDataGridFeildsForServerSideSorting[filterValue[1]]]: filterValue[3]
                        });

                    }
                    else {
                        _filterData.push({
                            key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
                            [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterValue[2]
                        });
                    }
                }




            }



            _filterData.forEach(item => {
                if (item.key === 'daterange') {
                    if (item.createdAt != undefined) {
                        if (item.createdAt.toLowerCase().includes('undefined') || item.createdAt.toLowerCase().includes('Date-undefined')) {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, createdAt: undefined }
                        }
                        else {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, createdAt: 'idle' }
                        }
                    }
                    if (item.updatedAt != undefined) {
                        if (item.updatedAt.toLowerCase().includes('undefined') || item.updatedAt.toLowerCase().includes('Date-undefined')) {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, updatedAt: undefined }
                        }
                        else {
                            DateFilterStatusRef.current = { ...DateFilterStatusRef.current, updatedAt: 'idle' }
                        }
                    }
                }
            });


            return ViewJobList(accessToken, userInfo, userRole, skip / take + 1, take, mapDataGridFeildsForServerSideSorting[selectorObj.selector], selectorObj.desc, 'viewJobList', _filterData)
                .then((data) => {

                    let array = [];
                    let totalCount = 0;
                    if (data.status) {
                        if (data.data.status === "succeed") {
                            if (data.data.data !== null) {
                                array = data.data.data.response.map((item, index) => {

                                    let startTime = "";
                                    if (item.createdAt) {
                                        // const dateValue = ((new Date(item.createdAt).toISOString()))
                                        // const date = new Date(dateValue).toLocaleString('en-IN');
                                        const startDate = new Date(item.createdAt).toDateString();
                                        const myArray = startDate.split(" ");
                                        const localTimeString = new Date(item.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
                                        const localTime = localTimeString.slice(0, 8);
                                        const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
                                        startTime = sDate + " " + localTime;
                                    }
                                    let updatedDate = "";
                                    if (item.updatedAt) {
                                        // const dateValue = ((new Date(item.createdAt).toISOString()))
                                        // const date = new Date(dateValue).toLocaleString('en-IN');
                                        const startDate = new Date(item.updatedAt).toDateString();
                                        const myArray = startDate.split(" ");
                                        const localTimeString = new Date(item.updatedAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
                                        const localTime = localTimeString.slice(0, 8);
                                        const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
                                        updatedDate = sDate + " " + localTime;
                                    }

                                    return {
                                        // id: item.id
                                        JobTitle: item.jobName,
                                        Email: item.recruiterEmailId,
                                        "Contact No": item.recruiterMobileNumber
                                            ? item.recruiterMobileNumber
                                            : "N/A",
                                        Location: item.location ? item.location : " ",
                                        Status: item.jobStatus ? item.jobStatus : " ",
                                        "Created Date": startTime,
                                        "Updated date": updatedDate ? updatedDate : " ",
                                        skills: item.skills,
                                        Action: "",
                                        id: index + 1,
                                        jobCode: item.jobCode ? item.jobCode : '',
                                        designation: item.designation,
                                        item: item,
                                    }
                                    // {
                                    //   data: data.data.data.response,
                                    // totalCount: data.data.data.count,
                                    // }
                                    // return array
                                })
                                totalCount = data.data.data.count;
                            }
                            else {
                                setLoadingErrorState("No Record Found")
                            }
                        }
                    }

                    return {
                        data: array,
                        totalCount: totalCount
                    }

                })
                .catch(() => {
                    setLoadingErrorState("Something went wrong Please Contact to administrator")
                    throw new Error("Data Loading Error");
                });
        },
        async remove(loadOptions) {
            return;
        }
    });
    function getStatusColor(data) {

        let color = '';
        if (data.Status === 'Active') {
            return color = 'green'
        }
        else if (data.Status === 'Reactivated') {
            return color = 'green'
        }
        else if (data.Status === 'Close') {
            return color = 'red'
        }
        else {
            color = '#ffa100'
        }
        return color
    };

    function setStatus(e) {
        return <div style={{ color: getStatusColor(e.data), fontWeight: '500' }}>{e.value}</div>
    };

    function calculateFilterExpression(value, selectedFilterOperations, target) {
        DateFilterExpressionRef.current = selectedFilterOperations;
        const column = this;
        if (target === 'headerFilter' && value === 'weekends') {
            return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
        }
        if (selectedFilterOperations === "between") {
            const filterExpression = [this.dataField, 'darterange', value];

            // return filterExpression;
        }

        if ((this.dataField === 'Created Date') || (this.dataField === 'Updated date')) {
            let dateText = '';
            let commonDateValue = value;
            if (selectedFilterOperations === "between") {
                // let dateFrom = new Date(value[0]).toLocaleDateString().split('/');
                // let dateTo = new Date(value[1]).toLocaleDateString().split('/');

                // let zeroIndex = dateFrom[0];
                // let firstIndex = dateFrom[1];
                // let datetozeroIndex = dateTo[0];
                // let datetofirstIndex = dateTo[1];

                // if (dateFrom[0] < 10) {
                //     zeroIndex = '0' + dateFrom[0];
                // }
                // if (dateFrom[1] < 10) {
                //     firstIndex = '0' + dateFrom[1];
                // }
                // if (dateTo[0] < 10) {
                //     datetozeroIndex = '0' + dateTo[0];
                // }
                // if (dateTo[1] < 10) {
                //     datetofirstIndex = '0' + dateTo[1];
                // }


                // dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;


                // dateTo = dateTo[2] + '-' + datetozeroIndex + '-' + datetofirstIndex;

                // commonDateValue = dateFrom + 'TO' + dateTo;

                let dateFrom = generateDates(value[0]);
                let dateTo = generateDates(value[1]);
                commonDateValue = dateFrom + 'TO' + dateTo;


            }
            else {
                // let dateFrom = new Date(value).toLocaleDateString().split('/');

                // let zeroIndex = dateFrom[0];
                // let firstIndex = dateFrom[1];

                // if (dateFrom[0] < 10) {
                //     zeroIndex = '0' + dateFrom[0];
                // }
                // if (dateFrom[1] < 10) {
                //     firstIndex = '0' + dateFrom[1];
                // }

                // dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;
                // if (selectedFilterOperations === '=') {
                //     commonDateValue = dateFrom + 'TO' + dateFrom;
                // }
                // else {
                //     commonDateValue = dateFrom;
                // }
                let dateFrom = generateDates(value);
                if (selectedFilterOperations === '=') {
                    commonDateValue = dateFrom + 'TO' + dateFrom;
                }
                else {
                    commonDateValue = dateFrom;
                }

            }
            const filterExpression = [
                'datefilter',
                this.dataField,
                // selectedFilterOperations,
                mapDateDataGridConstraintsForServerSideFiltering[selectedFilterOperations],
                commonDateValue
            ];

            return filterExpression



        }

        return column.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
    }

    // dx



    function cellOpen(e) {
        // deleteCandidateIdRow.current = e.row.data.item.attachCandidate[0].candidateId;

        visibleJobDelete.current = e.data.item.id;
        setDeleteJobPopUp(true)
    }
    function closeHandler() {
        setDeleteJobPopUp(false)
    }

    function deleteCellHandler(e) {

        deleteJobHandler(visibleJobDelete.current)

        setDeleteJobPopUp(false);
    }

    const clearFilters = (e) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.clearFilter();
        }
    };
    const handleCountChange = (name, value) => {
        if (name === 'page_count') {
            setPageCount(value)
        }
    };
    return (
        <>
            <div>
                <Dialog
                    open={deleteJobPopUp}
                    // TransitionComponent={Transition}
                    keepMounted
                    onClose={closeHandler}
                    aria-describedby="alert-dialog-slide-description"
                    style={{
                        backdropFilter: 'blur(4px)', backgroundColor: 'rgba(111, 126, 140, 0.2)', transition: 'opacity 100ms ease 0s', zIndex: 1600
                    }}
                >
                    <DialogTitle style={{ background: '#3aa1f0', marginBottom: '3rem', color: "white" }}>{"Alert"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to delete this record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={deleteCellHandler} color='success' variant="contained">Yes</Button>
                        <Button color='error' variant="contained" onClick={closeHandler} >No</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <ErrorAlert showError={showError} setShowError={setShowError} />
            {/* <ErrorAlertMultiline errorContainer={errorContainer} showErrorMultiline={showErrorMultiline} setShowErrorMultiline={setShowErrorMultiline} /> */}
            {
                openEditMenu && <EditJob openEditMenu={openEditMenu} tableData={tableData} setOpenEditMenu={setOpenEditMenu} rowData={rowData} setAlertMessage={setAlertMessage} setShowError={setShowError} isInterviewStatusAvailable={isInterviewStatusAvailable} />
            }
            <>{

            }
            </>
            <Box
                sx={{
                    // width: '100%',
                    // height: '80vh',
                    // overflowY: 'scroll'
                }}
            >
                <div className='box_head'>
                    <h2 style={{ width: '100%' }} className='all-jobs-heading'>ALL JOBS</h2>
                    <div className='head_icon'>
                        <Tooltip title='Card View' placement="top"><IconButton onClick={() => { handleView('list') }}> <AppsIcon fontSize="large" sx={{ color: View == 'list' ? '#1890ff' : 'rgb(144 144 144)' }} /></IconButton></Tooltip>
                        <Tooltip title='List View' placement="top"><IconButton onClick={() => { handleView('grid') }}><TableViewIcon fontSize="large" sx={{ color: View == 'grid' ? '#1890ff' : 'rgb(144 144 144)' }} /></IconButton></Tooltip>
                        {View == 'list' && <Box
                            component="form"
                            sx={{ margin: '1px 18px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '30%', height: 50, borderRadius: 4, boxShadow: 5 }}
                            className='view-job-search-bar'
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1, width: '100px' }}
                                placeholder="Search Job Title"
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(e) => searchHandler(e)}
                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                className='view-job-search-bar-input'
                            />
                            <Divider sx={{ height: 28, marginTop: '2px' }} orientation="vertical" />
                            <IconButton sx={{ p: '10px' }} aria-label="search" className='view-job-search-bar-input-btn'>
                                <SearchIcon />
                            </IconButton>
                        </Box>}
                    </div>
                </div>
                <hr />
                {
                    View === 'list' &&
                    <>{
                        loading === 'loading' ? <Loader className={'loadercenter'} /> :
                            <div style={{ height: '100%', width: '100%' }}>
                                <Grid container spacing={2} sx={{ overflow: 'sroll' }}>
                                    {_DATA.currentData().length === 0 ? <div className='loadercenter'>No Record Found</div> : _DATA.currentData().map((value, index) => {
                                        const sDate = new Date(value.createdAt).toDateString();
                                        const myArray = sDate.split(" ");
                                        const localTimeString = new Date(value.createdAt).toTimeString();
                                        const startTime = localTimeString.slice(0, 8)
                                        const startDate = (myArray[2] + '-' + myArray[1] + '-' + myArray[3]);
                                        return <Grid item xs={12} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box className='viewJobCard' sx={{ minWidth: '100%', minHeight: '31vh', boxShadow: 5, borderRadius: 2, margin: '2px', borderColor: 'grey.500' }}>
                                                <Box>
                                                    <div style={divstyle}>
                                                        <Box sx={{
                                                            margin: '15px 10px',
                                                            border: '1px solid #2b95fd',
                                                            borderRadius: '50%',
                                                            padding: '7px',
                                                            width: '50px',
                                                            height: '50px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            background: '#e9e9e9',
                                                            marginRight: '20px'
                                                        }}>
                                                            <img src={jobIcon} style={{
                                                                width: '80%',
                                                                height: '80%',
                                                            }} />
                                                        </Box>
                                                        <div className='header' style={{ width: '100%' }}>
                                                            <h3>{value.jobName}</h3>
                                                            <h5>{value.location ? value.location : 'N/A'}</h5>
                                                        </div>
                                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
                                                            <CheckBoxOutlinedIcon fontSize='large'
                                                                sx={{
                                                                    //color: !value.Status ? '#008000' : '#ff0000'
                                                                    color: '#008000'
                                                                }} />
                                                        </Box>
                                                    </div>
                                                    <div className='icon' style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                                                        <div style={divstyle} className='header'>
                                                            <MailOutlinedIcon />
                                                            <p style={{ paddingRight: '10px' }}>{value.recruiterEmailId}</p>
                                                        </div>
                                                        <div style={divstyle} className='header'>
                                                            <PhoneOutlinedIcon />
                                                            <p>{value.recruiterMobileNumber ? value.recruiterMobileNumber : 'N/A'}</p>
                                                        </div>
                                                        <div style={divstyle} className='header'>
                                                            <WorkOutlineIcon />
                                                            <p style={(value.jobStatus === "Active" || value.jobStatus === "Reactivated") ? { color: 'green' } : { color: '#ff6666' }}>{value.jobStatus ? value.jobStatus : 'N/A'}</p>
                                                        </div>
                                                        <div style={divstyle} className='header'>
                                                            <DateRangeOutlinedIcon />
                                                            <p>{startDate}</p>
                                                        </div>
                                                        <div style={divstyle} className='header'>
                                                            <AccessTimeOutlinedIcon />
                                                            <p>{startTime}</p>
                                                        </div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    })
                                    }
                                </Grid>
                            </div>
                    }
                        {
                            filteredData.length && pageCount > 0 && loading === 'loading' ? '' :
                                <>
                                    <Box className='pagination' sx={{ margin: '20px 0', marginBottom: '40px' }} spacing={2}>
                                        <Box sx={{ height: '10px' }}>
                                            <FormControl sx={{ width: '90px', marginTop: '10px' }}>
                                                <InputLabel id="demo-simple-select-label">Page</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={pageCount}
                                                    label="pageCount"
                                                    name='page_count'
                                                    onChange={(e) => handleCountChange(e.target.name, e.target.value)}
                                                    style={{ height: '50px' }}
                                                >
                                                    <MenuItem value={6}>6</MenuItem>
                                                    <MenuItem value={12}>12</MenuItem>
                                                    <MenuItem value={18}>18</MenuItem>
                                                    <MenuItem value={30}>30</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Stack spacing={2}>
                                            <Pagination shape="rounded" count={Math.ceil(count / pageCount)} size="large" page={page} variant="outlined" onChange={handleChange} sx={{ display: 'flex', justifyContent: 'flex-end' }} />
                                        </Stack>

                                    </Box> </>

                        }
                    </>}
                {
                    loading === 'no_record_found' && <div className='loadercenter'>No record Found</div>
                }
                {
                    View == 'grid' && <Box className="grid-size" >
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Alert"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Are you sure you want to delete?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>No</Button>
                                <Button onClick={() => deleteJob(deleteId, accessToken, userInfo)
                                    .then((res) => {
                                        if (res.data.data.message === "Interview already Schedule on Job can't be Delete!!") {
                                            setShowError({ display: true, text: "Interview on this job is already scheduled can't be Deleted" })
                                            setOpen(false)
                                            closeAlert()
                                        }
                                        if (res.data.data.message === "Job Deleted Successfully!!") {
                                            setAlertMessage({ display: true, text: "Job Deleted Successfully" });
                                            getJobListBasedOnUserRole();
                                            closeSuccess()
                                            setOpen(false)
                                        }
                                    })
                                    .catch((err) => setShowError({ display: true, text: 'Something went wrong please contact to administrator ' }))
                                } >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className='dev-extreme-datagrid-list' style={{ height: "100%", width: '100%' }}>
                            <Popup
                                visible={clearCustomizationPopUp}
                                // onHiding={hideInfo}
                                dragEnabled={false}
                                hideOnOutsideClick={true}
                                showCloseButton={false}
                                showTitle={true}
                                container="body"
                                width={500}
                                height={200}
                                title="Alert"
                            >
                                <Position
                                    my="center"
                                    of={'body'}
                                    collision="fit"
                                />
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={customChanges}
                                />
                                <ToolbarItem
                                    widget="dxButton"
                                    toolbar="bottom"
                                    location="after"
                                    options={cancelCustomchanges}
                                />
                                <p>Your custom changes made by you on grid will be removed..</p>
                            </Popup>
                            {
                                <div className='dev-extreme-datagrid-list' style={{ height: "100vh", width: width }}>

                                    <>
                                        <DataGrid
                                            dataSource={store}
                                            keyExpr="id"
                                            key="Job Title"
                                            // selectedRowKeys={viewArchiveList()}
                                            height="70%"
                                            width={'100%'}
                                            // showBorders='2'
                                            // defaultValue="#f05b41"
                                            hoverStateEnabled
                                            noDataText={loadingErrorState}
                                            showBorders={true}
                                            remoteOperations={true}
                                            showRowLines
                                            // columnMinWidth={100}
                                            allowColumnResizing={true}
                                            allowColumnReordering={true}
                                            onRowRemoving={(e) => deleteJobHandler(e)}
                                            onOptionChanged={onOptionChanged}
                                            loadPanel={{ enabled: true }}
                                            // selection={false}
                                            // onRowClick={handleRowClick}
                                            AllowLoadState={false}
                                            rowAlternationEnabled={true}
                                            filterSyncEnabled={true}
                                            // columnAutoWidth={true}
                                            ref={dataGridRef}
                                            columnAutoWidth={true}
                                            columnMinWidth={50}
                                        >
                                            <StateStoring
                                                enabled={true}
                                                type="custom"
                                                customLoad={loadState}
                                                customSave={saveState}
                                            />
                                            <Scrolling mode="standard" />

                                            <Lookup
                                                dataSource={store}
                                                valueExpr="ID"
                                                displayExpr="Name"
                                            />
                                            <Toolbar>
                                                <Item>
                                                    <div className="pull-right">
                                                        <div className="pull-right-child">
                                                            <span className="legendLable">
                                                                <span className="span1"></span>
                                                                Active/Reactivated
                                                            </span>
                                                            <span className="legendLable">
                                                                <span className="span2"></span>
                                                                Closed
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <DevExtremToolbarClearfilter clearFilters={clearFilters} dataGridRef={dataGridRef} />
                                                </Item>
                                                <Item location="after">
                                                    <Button2
                                                        hint="Refresh"
                                                        icon="refresh"
                                                        onClick={() => refresh()}
                                                    />
                                                </Item>
                                                <Item name="columnChooserButton" />
                                                <Item name="exportButton" />
                                                <Item>
                                                    <img
                                                        title="Clear Customization"
                                                        className="clean"
                                                        src={clean}
                                                        onClick={() => {
                                                            setClearCustomizationPopUp(true)
                                                        }}
                                                    ></img>
                                                </Item>
                                            </Toolbar>
                                            <ColumnChooser enabled={true} />
                                            <Export enabled={true} allowExportSelectedData={false} />
                                            <ColumnFixing enabled={true} />
                                            {/* For delete Property */}
                                            <Pager
                                                showPageSizeSelector={true}
                                                allowedPageSizes={allowedPageSizes}
                                                showInfo={true}
                                                visible={true}
                                            />
                                            <Paging defaultPageSize={20} enabled={true} />
                                            {/* <LoadPanel visible={loaderCircular} /> */}
                                            {/* <Scrolling mode="infinite" showScrollbar="true" /> */}
                                            {/* <Selection mode="multiple"
                                                showCheckBoxesMode={'always'} /> */}
                                            <FilterRow visible={true} />
                                            <Editing
                                                refreshMode="reshape"
                                                useIcons={true}
                                                allowDeleting={true}
                                            />
                                            <Column allowFiltering={false}
                                                allowReordering={false}
                                                allowSorting={false}
                                                width="50px"
                                                alignment="left"
                                                caption="S.No"
                                                dataField="id"
                                                fixed={true}
                                            />
                                            <Column width="240px" caption="Job Title" dataField="JobTitle" cellRender={setStatus}
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column width="240px" caption="Job Code" dataField="jobCode" cellRender={setStatus}
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column allowReordering={false} width="240px" caption="Email ID" dataField="Email"
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column allowReordering={false} width="150px" caption='Contact Number' dataField="Contact No"
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column width="250px" caption="Location" dataField="Location"
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column width="150px" caption="Status" dataField="Status" cellRender={setStatus}
                                                editorOptions={{
                                                    showClearButton: true
                                                }} />
                                            <Column width="200px"
                                                caption='Created Date'
                                                dataField="Created Date"
                                                dataType="date"

                                                editorOptions={true}
                                                format={filterDateFormat}
                                                calculateFilterExpression={calculateFilterExpression}
                                            />
                                            <Column width="200px"
                                                caption="Updated Date"
                                                dataField="Updated date"
                                                dataType="date"

                                                editorOptions={true}
                                                format={filterDateFormat}
                                                calculateFilterExpression={calculateFilterExpression}
                                            />
                                            <Column caption="Action" type="buttons" width="100px"
                                                cellRender={(data) => {
                                                    let isInterviewStatusAvailable = false;
                                                    if (data.data.item.isInterviewScheduled != undefined || data.data.item.mailSend != undefined) {
                                                        if (data.data.item.isInterviewScheduled || data.data.item.mailSend) {
                                                            isInterviewStatusAvailable = true;
                                                        }
                                                    }

                                                    //  isInterviewStatusAvailableRef.current = isInterviewStatusAvailable;


                                                    // if (data.data.item.mailSend != undefined) {
                                                    //     if (data.data.item.mailSend) {
                                                    //         deleteBtnDisabled = true;
                                                    //     }

                                                    // }
                                                    // let tooltiptext = data.data.item .mailSend ? "Interview Already Schedule" : 'Edit Job'                       
                                                    return <>
                                                        <Tooltip title='Edit Job' >
                                                            <span>
                                                                <IconButton
                                                                    onClick={() => editOpen(data, isInterviewStatusAvailable)}
                                                                >
                                                                    <EditIcon fontSize="small"
                                                                        sx={{ color: '#337ab7' }}
                                                                        // sx={{ color: data.data.item.mailSend ? 'grey' : ' #337ab7' }}
                                                                        icon='delete' name='delete' title='delete' />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                        <Tooltip title='Delete Job' >
                                                            <span>
                                                                <IconButton disabled={isInterviewStatusAvailable} onClick={() => cellOpen(data)} > <DeleteIcon fontSize="small"
                                                                    sx={{ color: isInterviewStatusAvailable ? 'grey' : '#ed6c02' }}></DeleteIcon></IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    </>
                                                }
                                                }
                                            >
                                                <DevExpressButton name="delete" />
                                                {/* <DevExpressButton
                                                    onClick={(e) => editOpen(e)}
                                                    hint="Edit Job"
                                                    icon="edit"
                                                ></DevExpressButton> */}
                                            </Column>
                                        </DataGrid>
                                    </>
                                </div>
                            }
                        </div>
                    </Box>
                }
            </Box >
        </>
    );
};

export default ViewJob;
function EditJob({ openEditMenu, tableData, setOpenEditMenu, rowData, setAlertMessage, setShowError, isInterviewStatusAvailable }) {
    const [closeMenu, setCloseMenu] = useState(false);
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const [isAdmin, setIsAdmin] = useState(false);
    const [tableDatas, setTableData] = useState({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        skills: '',
        email: ''
    });
    const [errorDataStore, setErrorDataStore] = useState({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        skills: '',
        email: ''
    });
    const [errorInfo, setErrorInfo] = useState({
        status: false,
        error: {}
    });


    const errorRef = useRef({
        recruiterMobileNumber: "",
        jobName: "",
        jobStatus: "",
        designation: "",
        location: '',
        experience_from: '',
        experience_to: '',
        ctc_from: '',
        ctc_to: '',
        email: ''
    });


    useEffect(() => {
        setTableData({
            recruiterMobileNumber: tableData.recruiterMobileNumber,
            jobName: tableData.jobName,
            jobStatus: tableData.jobStatus,
            designation: tableData.designation,
            location: tableData.location,
            experience_from: tableData.experience_from
                ? tableData.experience_from
                : "",
            experience_to: tableData.experience_to
                ? tableData.experience_to
                : "",
            ctc_from: tableData.ctc_from,
            ctc_to: tableData.ctc_to,
            skills: tableData.skills[0],
            email: tableData.email,
        });
    }, [tableData, openEditMenu])

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    function handlePopUpClose() {
        setCloseMenu(false);
        setOpenEditMenu(false);
    };
    const [mailError, setMailError] = useState({
        error: false,
        message: ''
    });
    const statusValue = [
        {
            value: 'Active',
            label: 'Active',
        },
        {
            value: 'Close',
            label: 'Close',
        },
        {
            value: 'Reactivated',
            label: 'Reactivated',
        },
    ];
    let fieldError = {
        ctc_from: '',
        ctc_to: '',
        experience_from: '',
        experience_to: ''
    };
    if (parseInt(tableDatas.experience_from) > parseInt(tableDatas.experience_to)) {
        fieldError.experience_from = 'Experience From should be less then experience to';
    }
    if (errorInfo.status && tableDatas.experience_from == '') {
        fieldError.experience_from = "This field can't be empty";
    }
    if (errorInfo.status && tableDatas.experience_to == '') {
        fieldError.experience_to = "This field can't be empty";
    }
    if (parseInt(tableDatas.ctc_from) > parseInt(tableDatas.ctc_to)) {
        fieldError.ctc_from = 'CTC To should be greater than CTC From';
    }
    if (errorInfo.status && tableDatas.ctc_from === '') {
        fieldError.ctc_from = "This field can't be empty";
    }
    if (errorInfo.status && tableDatas.ctc_to === '') {
        fieldError.ctc_to = "This field can't be empty";
    }


    function closeAlert() {
        setTimeout(() => {
            setShowError({ display: false, text: '' })
        }, 4000);
    };
    const handleInputChange = (e) => {

        const { name, value } = e.target;
        if (name === 'experience_from' || name === 'experience_to' || name === 'ctc_from' || name === 'ctc_to') {
            if ((/^[0-9]*(.){0,1}[0-9]*$/.test(value))) {
                if (value > -1) {
                    setTableData({
                        ...tableDatas,
                        [name]: value,
                    });
                }
            }
        }
        else if (name === 'recruiterMobileNumber') {
            if ((/^[0-9]{0,10}$/.test(value))) {
                setErrorDataStore(state => ({ ...state, [name]: '' }))
                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                const errorLabelText = { recruiterMobileNumber: 'recruiterMobileNumber' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `Phone number can't be empty` }))
                }
                else if ((value.length < 10)) {
                    setErrorDataStore(state => ({ ...state, [name]: `Phone number should be of 10 digits` }))
                }
            }
        }
        else if (name === 'designation') {

            if ((/^[a-zA-Z\s]+$/.test(value))) {
                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                setErrorDataStore(state => ({ ...state, [name]: `` }))
            }
            else {
                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                const errorLabelText = { designation: 'Designation' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `${errorLabelText[name]} can't be empty` }))
                }
                else {
                    setErrorDataStore(state => ({ ...state, [name]: `Please enter valid ${errorLabelText[name]}` }))
                }
            }
        }

        else if (name === 'skills' || name === 'location' || name === 'jobName') {
            // if ((/^[a-zA-Z0-9\s.]+$/.test(value))) {^[a-zA-Z0-9,\s\W]*$
            if ((/^[a-zA-Z0-9,\s\W]+$/.test(value))) {

                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                setErrorDataStore(state => ({ ...state, [name]: `` }))
            }
            else {

                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                const errorLabelText = { skills: 'Skills', location: 'Location', jobName: 'Job Title' };
                if (value == '') {
                    setErrorDataStore(state => ({ ...state, [name]: `${errorLabelText[name]} can't be empty` }))
                }
                else {
                    setErrorDataStore(state => ({ ...state, [name]: `Please enter valid ${errorLabelText[name]}` }))
                }
            }
        }
        else if (name === 'email') {
            if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value))) {
                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                setMailError({
                    error: false, message: ''
                })
            }
            else {
                setTableData({
                    ...tableDatas,
                    [name]: value,
                });
                if (value === '') {
                    setMailError({
                        error: true, message: "This field can't be empty"
                    })
                }
                else {
                    setMailError({
                        error: true, message: 'Please Enter valid email'
                    })
                }
            }
        }
        else {
            setTableData({
                ...tableDatas,
                [name]: value,
            });
        }
    };
    useEffect(() => {
        if (userRole === 'Global Administrator' || userRole === 'VideoInterview Administrator') {
            setIsAdmin(true)
        }
        else {
            setIsAdmin(false)
        }
    }, [userRole]);

    function closeSuccess() {
        setTimeout(() => {
            setAlertMessage({ display: false, text: '' })
        }, 4000);
    };

    function updateJobList() {
        updateJob(accessToken, tableDatas, userInfo, rowData.id, isAdmin).then(
            (res) => {
                if (res.data.data.message === "Job Updated Successfully!!") {
                    setOpenEditMenu(false);
                    setAlertMessage({ display: true, text: "Job edited successfully" });
                    closeSuccess();
                    getJobListBasedOnUserRole();
                } else if (
                    res.data.data.message ===
                    "Interview already Schedule on Job can't be Edit!!"
                ) {
                    setOpenEditMenu(false);
                    setShowError({
                        display: true,
                        text: "Interview on this job is already scheduled. Job cannot be edited!!",
                    });
                    closeAlert();
                }
            }
        );
    };
    function handleEmpty() {
        let error =
        {
            recruiterMobileNumber: "",
            jobName: "",
            jobStatus: "",
            designation: "",
            location: '',
            experience_from: '',
            experience_to: '',
            ctc_from: '',
            ctc_to: '',
            email: ''
        }

        for (var key in tableDatas) {
            if (tableDatas[key] === "") {
                // setShowError({ display: true, text: `${key} can't be empty` });
                errorRef.current[key] = `${key} can't be empty`;
                // setIsEmpty('empty');
            }
            else {
                errorRef.current[key] = '';
                // setIsEmpty('not empty');
            }
        }

        let status = 1;
        let errorstatus = 1;
        if (parseInt(tableDatas.experience_from) > parseInt(tableDatas.experience_to)) {
            errorRef.current.experience_from = 'Experience From should be less then experience to';
        }

        if (errorInfo.status && tableDatas.experience_from == '') {
            errorRef.current.experience_from = 'Experience From should be less then experience to';
        }

        if (errorInfo.status && tableDatas.experience_to == '') {
            errorRef.current.experience_to = "This field can't be empty";
        }


        if (parseInt(tableDatas.ctc_from) > parseInt(tableDatas.ctc_to)) {

            errorRef.current.ctc_from = 'CTC To should be greater than CTC From';
        }

        if (errorInfo.status && tableDatas.ctc_from === '') {
            errorRef.current.ctc_from = "This field can't be empty";

        }

        if (errorInfo.status && tableDatas.ctc_to === '') {
            errorRef.current.ctc_to = "This field can't be empty";
        }

        for (let key in errorRef.current) {
            if (errorRef.current[key] !== '') {
                status = 0
                break;
            }
        }

        for (let key in errorDataStore) {
            if (errorDataStore[key] !== '') {
                errorstatus = 0
                break;
            }
        }
        // setShowErrorMultiline(status ? false : true)
        setErrorInfo({ status: !status, error: errorRef.current });

        if (status == 1 && errorstatus == 1 && tableDatas.recruiterMobileNumber.length == 10 && !mailError.error) {
            updateJobList()
        }
        // else {
        //     setShowError({ display: true, text: 'Mobile number min and max length is 10' });
        // }
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={openEditMenu}
                onClose={handlePopUpClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Edit Job"}
                </DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                required
                                id="outlined-required"
                                label="Contact Number"
                                name="recruiterMobileNumber"
                                // value={tableData.recruiterMobileNumber}
                                value={tableDatas.recruiterMobileNumber}
                                // onChange={(e) => {
                                //     setTableData({
                                //         ...tableData,
                                //         recruiterMobileNumber: e.target.value,
                                //     });
                                // }
                                // }
                                onChange={handleInputChange}
                                // // onInput={(e) => {
                                // //     e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                // // }}
                                // onInput={(e) => {
                                //     e.target.value = e.target.value.slice(0, 10)
                                //     if (isNaN(parseInt(e.target.value))) {
                                //         e.target.value = '';
                                //     }
                                //     else {
                                //         e.target.value = e.target.value.slice(0, 10)
                                //     }
                                //     e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                // }}
                                error={errorDataStore.recruiterMobileNumber}
                                helperText={errorDataStore.recruiterMobileNumber}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                // required={isAdmin}
                                name="email"
                                disabled={!isAdmin}
                                id="outlined-required"
                                label="Email ID"
                                value={tableDatas.email}
                                onChange={handleInputChange}
                                error={mailError.error}
                                helperText={mailError.error && mailError.message}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                type="number"
                                required
                                id="outlined-required"
                                label="CTC From(LPA)"
                                name='ctc_from'
                                value={tableDatas.ctc_from}
                                onChange={handleInputChange}
                                onInput={(e) => {
                                    if (e.target.value.length == 1 && e.target.value === '.') {
                                        e.target.value = ''
                                    }
                                    else if (e.target.value.includes('.')) {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                    else {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                }}
                                error={parseInt(tableDatas.ctc_from) > parseInt(tableDatas.ctc_to) || (errorInfo.status && tableDatas.ctc_from === '')}
                                helperText={fieldError.ctc_from}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                type="number"
                                required
                                id="outlined-required"
                                label="CTC To(LPA)"
                                value={tableDatas.ctc_to}
                                name='ctc_to'
                                onChange={handleInputChange}
                                onInput={(e) => {
                                    if (e.target.value.length == 1 && e.target.value === '.') {
                                        e.target.value = ''
                                    }
                                    else if (e.target.value.includes('.')) {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                    else {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                }}
                                error={parseInt(tableDatas.ctc_from) > parseInt(tableDatas.ctc_to) || (errorInfo.status && tableDatas.ctc_to === '')}
                                helperText={fieldError.ctc_to}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            {/* <TextField
                                        required
                                        id="outlined-required"
                                        label="Experience From "
                                        value={tableDatas.designation}
                                        onChange={(e) => {
                                            settableDatas({
                                                ...tableDatas,
                                                designation: e.target.value,
                                            });
                                        }
                                        }
                                    /> */}
                            <TextField
                                type="number"
                                required
                                id="outlined-required"
                                label="Experience from"
                                name="experience_from"
                                value={tableDatas.experience_from}
                                onChange={handleInputChange}
                                onInput={(e) => {
                                    if (e.target.value.length == 1 && e.target.value === '.') {
                                        e.target.value = ''
                                    }
                                    else if (e.target.value.includes('.')) {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                    else {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                                error={parseInt(tableDatas.experience_from) > parseInt(tableDatas.experience_to) || (errorInfo.status && tableDatas.experience_from === '')}
                                helperText={fieldError.experience_from}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                type="number"
                                required
                                id="outlined-required"
                                label="Experience to "
                                name="experience_to"
                                value={tableDatas.experience_to}
                                onChange={handleInputChange}
                                onInput={(e) => {
                                    if (e.target.value.length == 1 && e.target.value === '.') {
                                        e.target.value = ''
                                    }
                                    else if (e.target.value.includes('.')) {
                                        e.target.value = e.target.value.slice(0, 4);
                                    }
                                    else {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                                error={parseInt(tableDatas.experience_from) > parseInt(tableDatas.experience_to) || (errorInfo.status && tableDatas.experience_to === '')}
                                helperText={fieldError.experience_to}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Designation"
                                name="designation"
                                value={tableDatas.designation}
                                onChange={handleInputChange}
                                error={errorDataStore.designation}
                                helperText={errorDataStore.designation}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Skills"
                                name="skills"
                                value={tableDatas.skills}
                                onChange={handleInputChange}
                                error={errorDataStore.skills}
                                helperText={errorDataStore.skills}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Job Title"
                                name="jobName"
                                value={tableDatas.jobName}
                                onChange={handleInputChange}
                                error={errorDataStore.jobName}
                                helperText={errorDataStore.jobName}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Location"
                                name="location"
                                value={tableDatas.location}
                                onChange={handleInputChange}
                                error={errorDataStore.location}
                                helperText={errorDataStore.location}
                                InputProps={{ readOnly: isInterviewStatusAvailable }}
                            />
                            <TextField
                                // id="outlined-select-currency"
                                select
                                label="Status"
                                name="jobStatus"
                                value={tableDatas.jobStatus || ''}
                                onChange={handleInputChange}
                                error={errorInfo.status && tableDatas.jobStatus === ''}
                                helperText={errorInfo.status && tableDatas.jobStatus === '' ? "This field can't be empty " : ' '}
                            >
                                {statusValue.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <TextField
                                        disabled
                                        id="outlined-required"
                                        label="Created Date"
                                        value={rowData['Created Date']}
                                    /> */}
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus
                        onClick={handlePopUpClose}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        onClick={handleEmpty}
                        // onClick={updateJobList}
                        autoFocus>
                        Edit Job
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}



function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {

    const [filterAlert, setfilterAlert] = useState(false);

    return (
        <>
            <img
                title='Clear Filter'
                className="clean"
                src={ClearFiltericon}
                onClick={() => {
                    const isFilterApplied = dataGridRef.current.instance.getCombinedFilter();
                    if (isFilterApplied != undefined) {
                        clearFilters();
                        setfilterAlert(true);
                        setTimeout(() => {
                            setfilterAlert(false);
                        }, 3000);
                    }

                }}
            ></img>



            < SnackBar filterAlert={filterAlert} />
            {/* <SnackBar /> */}
        </>
    )
}

function SnackBar({ filterAlert }) {



    return (
        <>
            {
                filterAlert &&
                <Snackbar
                    open={filterAlert}
                    autoHideDuration={2000}
                    // message="Filter Removed Successfully"
                    // action={action}
                    // variant="filled"
                    variant="outlined"
                    severity="success"
                >
                    <Alert fontSize="small" variant="filled" severity="success">Filter Removed Successfully</Alert>

                </Snackbar>
            }
        </>
    )
}