
import AppConstants from "../Constants";
import { environment } from "../utils/Environment/Environment";
import { AxiosInstanceAws, AxiosInstanceRefresTokenCup } from "./http.handler";


export const GetToken = async (code) => {
    const headers = {
        "Content-Type": "application/x-www-form-urlencoded"

    };

    const body = `grant_type=authorization_code&client_id=${environment.client_id}&code=${code}&redirect_uri=${environment.redirect_uri}`;

    return await AxiosInstanceAws.post(`${AppConstants.serverConstants.AWS.GET_TOKEN}token/`,
        body, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};

export const GetNewAccessToken = async (token) => {
    const body = `grant_type=refresh_token&client_id=${environment.client_id}&refresh_token=${token}`

    const headers = {
        "Content-Type": "application/x-www-form-urlencoded"
    }

    return await AxiosInstanceAws.post(`${AppConstants.serverConstants.AWS.GET_TOKEN}token/`,
        body, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};

export const GetUserDetails = async (accessToken) => {
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        "Content-Type": "application/x-www-form-urlencoded",
    };
    return await AxiosInstanceAws.get(`${AppConstants.serverConstants.AWS.GET_TOKEN}userInfo`,
        { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};







export const GetNewRefreshATokenCup = async (unicode) => {
    const body = JSON.stringify({ "data": { "query": { "term": { "code.keyword": { "value": unicode } } } }, "type": "GET" })

    // const headers = {
    //     "Content-Type": "application/x-www-form-urlencoded"
    // }

    const headers = {
        'X-API-KEY': `${environment.refresh_token_key}`,
    };

    return await AxiosInstanceRefresTokenCup.post('',
        body, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};


export const GetNewRefreshATokenCupPost = async (unicode, refreshToken) => {
    const body = JSON.stringify({
        type: "POST",
        data: {
            IsAuthenticated: true,
            code: unicode,
            token: refreshToken,
            dateCreated: new Date().toISOString(), "dateUpdated": new Date().toISOString()
        }
    })

    // const headers = {
    //     "Content-Type": "application/x-www-form-urlencoded"
    // }

    const headers = {
        'X-API-KEY': `${environment.refresh_token_key}`,
    };

    return await AxiosInstanceRefresTokenCup.post('',
        body, { headers: headers }
    )
        .then((response) => response
        )
        .catch((error) => error);
};



