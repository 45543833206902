import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import ReplyIcon from '@mui/icons-material/Reply';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import WorkIcon from '@mui/icons-material/Work';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

import { getQuestionsOnQuestionIds } from '../../../handlers/question_handler';
import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import DownloadIcon from '@mui/icons-material/Download';

import Loader from '../../Preloaders/Loader';
import Feedback from '../../Interview/Create-Interview/Popup/Feedback';
import './index.css';

import { getVideoFromAzureMedia } from '../../../handlers/interviewVideo';

export default function InterviewVideoPreview() {
    const interviewVideoRef = React.useRef();
    //const interviewVideoPauseRef = React.useRef();
    const location = useLocation();
    const selectedVideoData = location.state;
    const navigate = useNavigate();
    const { accessToken } = useUserAuthContext();
    const [interviewQuestions, setInterviewQuestions] = React.useState({ questions: [], currentQuestion: '' });
    const [loader, setLoader] = React.useState({ isLoading: true, msg: '', videoChunkLoaded: 'loading' });
    const [isVideoDownloading, setIsVideoDownloading] = React.useState('idle');
    const [downloadProgess, setDownloadProgess] = React.useState({ isProgressStarted: 'idle', progress: 0 });
    const [previewProgess, setPreviewProgess] = React.useState({ isProgressStarted: 'idle', progress: 0 });


    // If location state on history object not available then redirect to dashboard
    if (!location.state) {
        return (
            <Box sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '0',
                left: '0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box sx={{ textAlign: 'center' }}>
                    <h2>Sorry</h2>
                    <h2>No Data Found</h2>
                </Box>
            </Box >
        )
    }

    let feedback = 'No feedback found';

    if (selectedVideoData.feedback) {

        feedback = selectedVideoData.feedback;

    }

    //console.log(location.state);


    // Get the video URL 
    let url = '';
    if (selectedVideoData.video) {
        url = selectedVideoData.video.videoInterviewUrl;
    }

    const handleNavigation = () => {
        navigate("/dashboard", { state: {page: selectedVideoData.page} })
    }
// console.log(location.state, 'location.state');

    const [ques, setQues] = React.useState([])

    React.useEffect(() => {
        // Set the questions to local state
        setQues(selectedVideoData.candidateQuestionAnswerTime);

        // video endpoint for axios instance
        const videoEndPoint = url.split('/')[4];

        // Get the video stream data from azure server
        getVideoFromAzureMedia(videoEndPoint, handleVideoDownloadProgress, 'preview')
            .then(res => {

                if (res.status) {
                    //console.log('preview', res);
                    // let a = document.createElement('a');
                    // const z = window.URL.createObjectURL(res.data);
                    // a.href = window.URL.createObjectURL(res.data);
                    // a.download = 'someName.mp4';
                    // a.click();
                    // console.log('getVideoFromAzureMedia', res, z);

                    if (res.data instanceof Blob) {
                        interviewVideoRef.current.src = window.URL.createObjectURL(res.data);
                        setLoader(prevState => ({ ...prevState, videoChunkLoaded: 'video-loaded' }))
                    }


                }
            })
            .catch(err => console.log(err))

    }, []);

    /* -------------------------------------------
    React.useEffect(() => {
        console.log('new ques', ques);
        if (accessToken) {
            const length = ques.length;
            for (let i = 0; i < length; i++) {
                getQuestionsOnQuestionIds(accessToken, ques[i].questionId)
                    .then(res => {
                        if (res.data.status === 'succeed') {
                            setInterviewQuestions(prevState => ({
                                ...prevState,
                                questions: [...prevState.questions,
                                {
                                    id: ques[i].questionId,
                                    question: res.data.data.question,
                                    startTime: ques[i].startTime,
                                    endTime: ques[i].endTime
                                }]
                            }))
                        }

                    })
                    .catch(err => console.log(err))
            }
        }
    }, [ques, accessToken]);


    ---------------------------------------------------------------*/


    //-------------------------- no need to fetch question separately

    React.useEffect(() => {
        if (ques.length) {
            const length = ques.length;
            for (let i = 0; i < length; i++) {
                setInterviewQuestions(prevState => ({
                    ...prevState,
                    questions: [...prevState.questions,
                    {
                        id: ques[i].questionId,
                        question: ques[i].question,
                        startTime: ques[i].startTime,
                        endTime: ques[i].endTime
                    }]
                }))
            }
        }

    }, [ques.length]);

    //---------------------------------------------------------------- 

    const plainTextArray = [];
    const tempArrayQuestions = [];
    [...interviewQuestions.questions].forEach(element => {
        if (!plainTextArray.includes(element.id)) {
            plainTextArray.push(element.id);
            tempArrayQuestions.push(element);
        }
    });

    const sortedQuestions = sortElements(tempArrayQuestions, 'startTime');


    React.useEffect(() => {
        const questionsLength = interviewQuestions.questions.length;
        const idsLength = ques.length;

        if ((questionsLength && idsLength) && questionsLength === idsLength) {
            setLoader(prevState => ({ ...prevState, isLoading: false }));
            handleVideoTimeStamp(sortedQuestions[0].question, sortedQuestions[0].startTime, sortedQuestions[0].endTime, 1);

            if (interviewVideoRef.current) {
                interviewVideoRef.current.addEventListener('timeupdate', (e) => {
                    for (let i = 0; i < sortedQuestions.length; i++) {
                        if ((e.target.currentTime >= sortedQuestions[i].startTime) && (e.target.currentTime <= sortedQuestions[i].endTime)) {
                            setInterviewQuestions(prevState => ({ ...prevState, currentQuestion: `${i + 1}. ${sortedQuestions[i].question}` }));
                            // pauseVideoWithEndTime(sortedQuestions[i].startTime, sortedQuestions[i].endTime);
                        }
                    }

                });
            }

        }
    }, [interviewQuestions.questions]);


    React.useEffect(() => {
        if (interviewVideoRef.current) {
            interviewVideoRef.current.pause();
        }

    }, [interviewQuestions.currentQuestion])



    // const pauseVideoWithEndTime = (startTime, endTime) => {
    //     clearInterval(interviewVideoPauseRef.current)
    //     let duration = endTime - startTime;
    //     console.log(startTime, endTime, duration, interviewVideoRef.current);
    //     interviewVideoPauseRef.current = setTimeout(() => {
    //         interviewVideoRef.current.pause();
    //     }, duration * 1000);
    // }

    const handleVideoTimeStamp = (question, startTime, endTime, index) => {

        if (interviewVideoRef.current) {
            const VIDEO_DELAY = 1;
            interviewVideoRef.current.currentTime = startTime + VIDEO_DELAY;
            // console.log(interviewVideoRef.current.readyState);
            // interviewVideoRef.current.addEventListener('loadeddata', function () {
            //     console.log('video loaded')
            // });
            //interviewVideoRef.current.play();

        }
        setInterviewQuestions(prevState => ({ ...prevState, currentQuestion: `${index}. ${question}` }))

    };



    function handleVideoDownloadProgress(progressEvent, type) {

        const total = progressEvent.total;
        const loaded = progressEvent.loaded;

        const percentage = (loaded / total) * 100;
        if (type === 'download') {
            setDownloadProgess(prevState => ({ isProgressStarted: 'started', progress: percentage }));
        }
        else {
            // console.log('progressEvent', progressEvent);
            setPreviewProgess(prevState => ({ isProgressStarted: 'started', progress: percentage }));
        }

    }


    function customVideoURLName(videoUrl, videoName) {
        setIsVideoDownloading('downloading');
        const videoEndPoint = videoUrl.split('/')[4];
        const index = videoEndPoint.indexOf('.');
        const videoType = videoEndPoint.slice(index)

        setDownloadProgess(prevState => ({ ...prevState, isProgressStarted: 'initiating' }));
        // Get the video stream data from azure server
        getVideoFromAzureMedia(videoEndPoint, handleVideoDownloadProgress, 'download')
            .then(res => {
                // console.log('video_download', res);
                if (res.status) {
                    // console.log(res, 'check exception');
                    const a = document.createElement('a');
                    a.href = window.URL.createObjectURL(res.data);
                    a.download = videoName + '' + videoType;
                    a.click();
                    setDownloadProgess(prevState => ({ ...prevState, isProgressStarted: 'completed' }));
                    setIsVideoDownloading('downloaded');
                    setTimeout(() => {
                        setIsVideoDownloading('idle');
                    }, 2000);
                }
            })
            .catch(err => {
                console.log(err);
                setIsVideoDownloading('idle');
            })


    }



    function sortElements(array, constraint) {

        return array.sort((a, b) => {
            if (a.startTime && b.startTime) {
                if (a[constraint] > b[constraint]) return 1;

                else if (a[constraint] < b[constraint]) return -1;

                return 0;
            }
        })

    }


    let dateDisplayStr = '';
    if (selectedVideoData.chunkStartTime) {
        let dateString = selectedVideoData.chunkStartTime['$date'];
        let timeString = new Date(dateString).toTimeString();

        dateString = new Date(dateString).toDateString();
        dateString = dateString.split(' ');
        timeString = timeString.split(' ');

        dateDisplayStr = ` ${dateString[2]} ${dateString[1]} ${dateString[3]} ${timeString[0]}`;
    }


    //`${selectedVideoData.candidateName.trim()}_${selectedVideoData.jobName.trim()}`
    let video_candidate_job_name = '';



    if (selectedVideoData.candidateName && selectedVideoData.jobName) {
        let cname = selectedVideoData.candidateName.trim().split(' ');
        video_candidate_job_name = cname.join('_');

        let cjobname = selectedVideoData.jobName.trim().split(' ');
        video_candidate_job_name += '_' + cjobname.join('_');

    }


    // console.log(values.chunkStartTime['$date']);




    return (
        <Box>
            <Grid container spacing={2} sx={{ height: '83vh', background: 'white', marginBottom: '150px' }}>
                <Grid item xs={12} >
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '30px', height: '30px', borderRadius: '50%', background: '#00bd9c' }}>
                                    <OndemandVideoIcon fontSize='small' sx={{ color: 'white' }} />
                                </Box>
                                <Box sx={{ padding: '0 10px' }}>
                                    <b>Watch Interview</b>
                                </Box>
                                <Box>

                                    <Feedback feedback={feedback} />

                                </Box>
                            </Box>
                            <Stack spacing={2} direction="row">
                                <Button className='interview-preview-back-btn' sx={{ background: 'white', color: 'black' }} variant="contained" startIcon={<ReplyIcon />} onClick={handleNavigation}>Back to Dashboard</Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '32px', height: '32px' }}>
                                    <WorkIcon fontSize='small' sx={{ color: 'gray' }} />
                                </Box>
                                <Box sx={{ padding: '0 10px' }}><b>
                                    Job Name:</b> {selectedVideoData.jobName}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
                                    <CalendarMonthIcon fontSize='small' sx={{ color: 'gray' }} />
                                </Box>
                                <Box sx={{ padding: '0 10px' }}>
                                    <b> Interview Start Date:</b>{dateDisplayStr}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <Grid item md={12} >
                        <Box className='card_info' sx={{ boxShadow: 2, padding: '5px 5px 5px 10px', borderRadius: '6px', marginBottom: '10px' }}>
                            <b className='font'>Candidate Name :</b>{selectedVideoData.candidateName}
                            <br></br>
                            <b className='font'>Candidate Email :</b>{selectedVideoData.candidateEmail}


                        </Box>
                    </Grid>
                    <Grid item md={12} sx={{ boxShadow: 2, padding: '10px 5px 5px 10px', borderRadius: '6px', height: '63vh', marginBottom: '30px', overflowX: 'hidden', overflowY: 'scroll' }}>
                        <Box>
                            <b>Interview Questions </b>
                        </Box>
                        <Box sx={{ position: 'relative', height: '100%' }}>
                            {
                                loader.isLoading ?
                                    <Box sx={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Loader />
                                    </Box>
                                    :

                                    <List className='Ques-list'>
                                        {
                                            sortedQuestions.map((question, index) => {
                                                const selected = interviewQuestions.currentQuestion.includes(`${index + 1}. ${question.question}`)
                                                return <ListItem sx={{ background: selected ? '#84bde0' : 'white' }} disablePadding key={index}>
                                                    <ListItemButton onClick={() => handleVideoTimeStamp(question.question, question.startTime, question.endTime, index + 1)}>
                                                        <ListItemText primary={`${index + 1}. ${question.question}`} />
                                                    </ListItemButton>
                                                </ListItem>
                                            })
                                        }
                                    </List>
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid>
                        <Box sx={{ width: '100%', padding: '0 25px 25px 25px', boxShadow: 3, borderRadius: '6px' }}>
                            <Box sx={{ padding: '15px 0 15px 10px', display: 'flex', justifyContent: 'space-between' }}>
                                <span>{interviewQuestions.currentQuestion}</span>

                                {(downloadProgess.isProgressStarted !== 'started') &&
                                    <Box sx={{ display: 'flex', position: 'relative' }}>
                                        <Box sx={{ display: 'flex', visibility: isVideoDownloading === 'downloading' ? 'visible' : 'hidden' }}>
                                            <CircularProgress />
                                        </Box>



                                        <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                                            <Tooltip title="Download video" placement="top">
                                                <IconButton
                                                    aria-label="search"
                                                    id='video-downld-btn'
                                                    disabled={isVideoDownloading === 'downloading' ? true : false}
                                                    onClick={() => customVideoURLName(url, video_candidate_job_name)
                                                    }>

                                                    {isVideoDownloading === 'downloaded' && < CheckIcon />}
                                                    {(isVideoDownloading === 'downloading' || isVideoDownloading === 'idle') && <DownloadIcon sx={{ color: '#607d8b' }} id='video-downld-icon' />}
                                                </IconButton>
                                            </Tooltip>
                                        </Box>

                                    </Box>
                                }

                                {
                                    downloadProgess.isProgressStarted === 'started' && <Box sx={{ position: 'relative', display: 'inline-flex' }}>

                                        <CircularProgress variant="determinate" value={downloadProgess.progress} />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography variant="caption" component="div" color="text.secondary" className='progress-label'>
                                                {`${Math.round(downloadProgess.progress)}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            {
                                url ? <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
                                    {
                                        loader.videoChunkLoaded === 'loading' && <Box sx={{
                                            width: '100%', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center',
                                            //  background: '#000000ab'
                                            background: 'black'
                                            , zIndex: 9
                                        }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

                                                <Box
                                                    sx={{
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Typography variant="h4" component="div" className='progress-label' sx={{ color: '#2e7d32' }}>
                                                        {`${Math.round(previewProgess.progress)}%`}
                                                    </Typography>

                                                    <Box sx={{ width: '100%', color: 'green', textAlign: 'center', fontWeight: 'bold', marginTop: '12px' }}>Loading video...</Box>
                                                </Box>
                                                {/* <CircularProgress color="success" sx={{ margin: '25px 0' }} variant="determinate" value={previewProgess.progress} /> */}

                                            </Box>
                                        </Box>
                                    }

                                    <video
                                        className='interview-video-player'
                                        ref={interviewVideoRef}
                                        controls={loader.videoChunkLoaded === 'video-loaded' ? true : false}
                                        controlsList="nodownload"
                                    ><source type="video/mp4" />
                                    </video>

                                    {/* <video className='interview-video-player' ref={interviewVideoRef} controls={true}><source src={url} type="video/mp4" /></video> */}
                                </Box>
                                    :
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', color: 'white', borderRadius: '7px', height: '65vh' }}>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <h2>Sorry</h2>
                                            <h2>This Video Does Not Exist</h2>
                                        </Box>
                                    </Box>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box >
    )
}
