let interviewTimeLineInfoState = {
    interviewDate: '',
    interviewTime: '',
    allDay: false,
    timeZone: '',
    candidatesEmail: [],
    template: 'default',
};



const createInterviewInitialState = {
    selectedJob: {},
    interviewType: 'one_way_interview',
    selectedCandidates: [],
    selectedCandidatesDGRowGrid: [],
    dxGridBoxValue: [],
    questionSet: [],
    interviewTimeLineInfoState: {
        interviewDateTo: '',
        interviewDateFrom: '',
        interviewTimeTo: '',
        interviewTimeFrom: '',
        allDay: false,
        timeZone: {},
        candidatesEmail: [],
        invitationTemplate: 'default',
    },
    selectedQuestionSetId: '',
    selectedQuestions: [],
    questionsAttached: false,
    time_duration: '',
    attachedCandidatesJobId: '',
    selectedCesCandidateLocalId: [],
}

export const handleCreateInterviewInitialState = (state = createInterviewInitialState, action) => {

    let questionSet = state.questionSet;
    let tempQuestionSet = {};
    let oldQuestionSets = [];
    let tempCandidates = [];

    switch (action.type) {

        case 'timeline_form_data':

            const key = Object.keys(action.payload)[0];
            const value = action.payload[key];

            return {
                ...state,
                interviewTimeLineInfoState: {
                    ...state.interviewTimeLineInfoState,
                    [key]: value
                }
            }

        case 'time_duration':

            return { ...state, time_duration: action.payload }

        case 'attached_candidates':
            // console.log(action.payload);
            return {
                ...state,

            }

        case 'selected_job_id':

            const originalState = {
                selectedJob: {},
                interviewType: 'one_way_interview',
                selectedCandidates: [],
                selectedCandidatesDGRowGrid: [],
                dxGridBoxValue: [],
                questionSet: [],
                interviewTimeLineInfoState: {
                    interviewDateTo: '',
                    interviewDateFrom: '',
                    interviewTimeTo: '',
                    interviewTimeFrom: '',
                    allDay: false,
                    timeZone: {},
                    candidatesEmail: [],
                    invitationTemplate: 'default',
                },
                selectedQuestionSetId: '',
                selectedQuestions: [],
                questionsAttached: false,
                time_duration: '',
                attachedCandidatesJobId: '',
                selectedCesCandidateLocalId: [],
            };
            return { ...originalState, selectedJob: action.payload }


        case 'interviewType':
            return { ...state, interviewType: action.payload }


        case 'selected_candidates_ces_to_local':

            tempCandidates = [...action.payload.candidates];

            return { ...state, selectedCandidates: tempCandidates };

        case 'selected_candidates':

            tempCandidates = [...action.payload.candidates].map((item) => {
                let isMailSend = null;
                if (item.isMailSend != undefined) {
                    isMailSend = item.isMailSend;
                }
                return { ...item.item, isMailSend: isMailSend }
            })

            let attachedCandidatesJobId = action.payload.attachedCandidatesJobId;
            let selectedCandidatesDGRowGrid = action.payload.selectedCandidatesDGRowGrid;

            if (!tempCandidates.length) {
                attachedCandidatesJobId = '';
            }
            /*
                 tempCandidates = [...state.selectedCandidates];
                let temp = tempCandidates.map(item => item.attachedResumeId);
    
                if(!temp.includes(action.payload.attachedResumeId)){
                    tempCandidates.push(action.payload)
                }
                else{
                    tempCandidates = tempCandidates.filter(item => item.attachedResumeId !== action.payload.attachedResumeId)
                }
    
            */




            //console.log({ ...state, selectedCandidates: tempCandidates });

            return { ...state, selectedCandidates: tempCandidates, attachedCandidatesJobId: attachedCandidatesJobId, selectedCandidatesDGRowGrid: selectedCandidatesDGRowGrid };


        case 'remove_all_selected_redux_data_after_mail_send':

            const empty_store = {
                selectedJob: {},
                interviewType: 'one_way_interview',
                selectedCandidates: [],
                selectedCandidatesDGRowGrid: [],
                dxGridBoxValue: [],
                questionSet: [],
                interviewTimeLineInfoState: {
                    interviewDateTo: '',
                    interviewDateFrom: '',
                    interviewTimeTo: '',
                    interviewTimeFrom: '',
                    allDay: false,
                    timeZone: {},
                    candidatesEmail: [],
                    invitationTemplate: 'default',
                },
                selectedQuestionSetId: '',
                selectedQuestions: [],
                questionsAttached: false,
                time_duration: '',
                attachedCandidatesJobId: '',
                selectedCesCandidateLocalId: [],
            }

            return { ...empty_store };

        case 'add_candidate':

            tempCandidates = [...state.selectedCandidates];

            // console.log(action.payload);

            return { ...state };


        case 'timezone':

            return {
                ...state,
                interviewTimeLineInfoState: {
                    ...state.interviewTimeLineInfoState,
                    timeZone: action.payload
                }
            }

        case 'question_set_name':

            return {
                ...state,
                questionSet: [...state.questionSet, action.payload]

            }

        case 'save_questions_to_created_question_set':


            oldQuestionSets = [];
            questionSet = state.questionSet;
            tempQuestionSet = {};
            oldQuestionSets = questionSet.filter((item, index) => {
                if (item.questionsetId !== action.payload.id) {
                    return true;
                }
                else {
                    tempQuestionSet = item;
                }
            })

            // console.log(tempQuestionSet);
            tempQuestionSet.questions = [...tempQuestionSet.questions, ...action.payload.questions];

            oldQuestionSets.push(tempQuestionSet);


            return {
                ...state,
                questionSet: oldQuestionSets
            }

        //  console.log(state.questionSet, action.payload);
        //     questionSet = state.questionSet;
        //     tempQuestionSet = {};
        //     oldQuestionSets = questionSet.filter((item, index) =>  {
        //         if(item.questionsetId !== action.payload.id){
        //             return true;
        //         }
        //         else{
        //             tempQuestionSet = item;
        //         }
        //     })

        //   console.log( tempQuestionSet);
        //     tempQuestionSet.questions = [...tempQuestionSet.questions, action.payload.questions];

        //     oldQuestionSets.push(tempQuestionSet);

        //     console.log(oldQuestionSets);

        //     return {
        //         ...state,
        //        questionSet: oldQuestionSets
        //     }

        case 'clicked_question':

            //console.log('action.payload', action.payload);
            oldQuestionSets = questionSet.map((item, index) => {

                if (item.questionsetId === action.payload.questionSetId) {

                    return { ...item, questions: action.payload.showTime_updatedQuestionList }
                }

                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets
            }


        case 'question_editor_enable_disable':


            oldQuestionSets = questionSet.map((item, index) => {

                if (item.questionsetId === action.payload.questionSetId) {

                    return { ...item, questions: action.payload.questionsList }
                }

                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets
            }


        case 'question_editor_timeUpdate_enable_disable':

            oldQuestionSets = questionSet.map((item, index) => {

                if (item.questionsetId === action.payload.questionSetId) {

                    return { ...item, questions: action.payload.questionsList }
                }

                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets
            }



        case 'question_editor_save_update':


            oldQuestionSets = questionSet.map((item, index) => {

                if (item.questionsetId === action.payload.questionSetId) {

                    return { ...item, questions: action.payload.questionsList }
                }

                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets
            }


        case 'question_editor_save_updated_time':

            oldQuestionSets = questionSet.map((item, index) => {

                if (item.questionsetId === action.payload.questionSetId) {

                    return { ...item, questions: action.payload.questionsList }
                }

                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets
            }



        case 'retrieve_existing_question_sets':

            return {
                ...state,
                questionSet: action.payload
            }


        case 'assign_que_IDs_to_retrieved_question_set':

            if (action.payload.array) {
                let questionsArray = [...action.payload.array].map((item, index) => {
                    return { id: item.questionReferenceId, thinkTime: item.thinkTime, timeLimit: item.timeLimit, queName: '', remark: '', queItemClicked: false, isEditing: false, isTimeEditing: false, order: item.order }

                })


                oldQuestionSets = [...questionSet].map((item, index) => {

                    if (item.questionsetId === action.payload.questionsetId) {

                        return { ...item, questions: questionsArray }
                    }

                    return item;
                })
            }
            else {
                oldQuestionSets = questionSet
            }



            return {
                ...state,
                questionSet: oldQuestionSets,


            }

        case 'modify_selected_questions':

            // console.log('modify_selected_questions', action);

            let setId = '';
            if (action.payload.setId !== state.selectedQuestionSetId) {
                setId = action.payload.setId;
            }
            return {
                ...state,
                selectedQuestionSetId: setId,
                selectedQuestions: []
            }


        case 'store_selected_questions':
            return {
                ...state,
                selectedQuestions: action.payload,
                questionsAttached: true
            }


        case 'remove_selected_questions':
            return {
                ...state,
                selectedQuestions: action.payload,
                questionsAttached: false
            }



        case 'assign_questionNames_to_retrieved_question_set':


            oldQuestionSets = [...questionSet].map((item, index) => {

                if (item.questionsetId === action.payload.questionsetId) {

                    return { ...item, questions: action.payload.array }
                }

                return item;
            })





            return {
                ...state,
                questionSet: oldQuestionSets
            }

        case 'set_selected_questions':

            // console.log(action.payload);

            let tempArr = [...state.selectedQuestions];
            if (tempArr.includes(action.payload.id)) {
                let index = tempArr.indexOf(action.payload.id);
                tempArr.splice(index, 1)
            }
            else {
                tempArr.push(action.payload.id);
            }

            return {
                ...state,
                selectedQuestions: tempArr,
                selectedQuestionSetId: action.payload.setId
            }


        case 'remove_question_from_questionset':

            const modifiedQuestionArr = action.payload.questionsList.filter((question, index) => question.id !== action.payload.questionId);

            oldQuestionSets = [...questionSet].map((item, index) => {
                if (item.questionsetId === action.payload.questionSetId) {
                    item.questions = modifiedQuestionArr;
                }
                return item;
            })

            return {
                ...state,
                questionSet: oldQuestionSets,
            }


        case 'remove_questionnaire_from_questionset_list':

            oldQuestionSets = [...questionSet].filter(item => item.questionsetId !== action.payload.questionSetId);
            return {
                ...state,
                questionSet: oldQuestionSets,
            }



        case 'store_ordered_questions_to_question_set':

            return {
                ...state,
                questionSet: action.payload,
            }



        case 'set_dx_grid_box_value':

            return {
                ...state,
                dxGridBoxValue: action.payload.dx_grid_box_value,
            }


        default:
            return state;
    }

}

//selected_candidates
