import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import RadioGroup from '@mui/material/RadioGroup';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TimezoneSelect from 'react-timezone-select'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Autocomplete from '@mui/material/Autocomplete';
import './index.css';
import { getEmailTemplate } from '../../../../handlers/mail_handler';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { handleEmailTemplate } from '../../../../handlers/job_handlers';
import DirectionSnackbar from '../../../Alerts/Snackbar';
import ReplayIcon from '@mui/icons-material/Replay';

/*----redux---*/

import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';
import { useEffect } from 'react';
import { IconButton, Tooltip } from '@mui/material';
/*----redux---*/



// import Email template keywords
import { EMAIL_TEMPLATE_KEYWORDS } from '../../../../Constants/email-template-keywords';
import { Today } from '@mui/icons-material';
//


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const inputStyles = { width: '100%', height: '100%' };
const paperStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent', boxShadow: 'none', padding: '0 20px' };
const paperStyles2 = { ...paperStyles, padding: '0px' }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];


const editorClassName = {

}
const limit = 24 / 0.5;
const inputItems = [];
let elementInstance = 0;
let currentTime = 0;

for (let i = 0; i < limit; i++) {
    if (elementInstance <= 2) {
        elementInstance++;
        inputItems[i] = `${currentTime < 10 ? '0' + currentTime : currentTime}:${elementInstance < 2 ? '00' : '30'}`

        if (elementInstance == 2) {
            elementInstance = 0;
            currentTime++;
        }
    }
}

let today = new Date().toISOString();

function generateDates(value) {
    if (value) {
        const today = new Date(value);
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        return `${yyyy}-${mm}-${dd}`;
    }
    else {
        return undefined;
    }
}
today = generateDates(today);



let timeHours = [];
for (let i = 1; i <= 24; i++) {
    timeHours.push(i)
}

export default function InterviewTimeLine() {

    const { interviewTimeLineInfoState, selectedCandidates, selectedQuestionSetId, selectedQuestions } = useSelector(state => state.handleCreateInterviewInitialState);
    const interviewState = useSelector(state => state.handleCreateInterviewInitialState);
    const dispatch = useDispatch();
    const [hours, setHours] = React.useState('');
    const [interviewDayTimeDiff, setInterviewDayTimeDiff] = React.useState('');
    const [editorStateValue, setEditorStateValue] = React.useState('');
    const [templateUpdated, setTemplateUpdated] = React.useState('idle');
    const { interviewDateTo, interviewDateFrom, interviewTimeTo, interviewTimeFrom, allDay, timeZone, candidatesEmail, invitationTemplate } = interviewTimeLineInfoState;
    const htmlTemplate = React.useRef({ status: true, state: '', initialState: '', missingKeywords: [] });
    const editorRef = React.useRef();


    const isLocalJob = interviewState.selectedJob.isLocal;
    //console.log(selectedCandidates);
    function getSimpleEmailStrings() {
        let arr = [];
        if (isLocalJob) {
            arr.push(selectedCandidates.map(item => item.attachCandidate[0].candidateEmail))
        }
        else if (!isLocalJob && isLocalJob != undefined) {
            arr.push(selectedCandidates.map(item => item.candidate.email))
        }
        return arr[0];
    }

    const [personName, setPersonName] = React.useState(getSimpleEmailStrings());
    const allEmailSelected = React.useRef(true);
    const allEmailSelectionCheckedClicked = React.useRef(false);
    const { accessToken, userInfo } = useUserAuthContext();

    const editorStateRef = React.useRef();

    const handleChangeEmails = (event) => {
        let status = 1;

        const {
            target: { value },
        } = event;

        for (let i = 0; i < value.length; i++) {
            if (value[i] !== 'select_all_email') {
                status = 0;
                break;
            }
        }

        if (!allEmailSelected.current && allEmailSelectionCheckedClicked.current) {
            setPersonName([])
            return;
        }
        else if (allEmailSelected.current && allEmailSelectionCheckedClicked.current) {
            setPersonName(getSimpleEmailStrings())
            return
        }


        // if (status.checked) {

        //     return;
        // }

        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    React.useEffect(() => {
        getEmailTemplate(accessToken)
            .then(res => {



                if (res.status) {
                    if (res.data.status === "succeed") {
                        const contentBlock = htmlToDraft(window.atob(res.data.data.message));
                        if (contentBlock) {
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);
                            htmlTemplate.current.state = editorState;
                            htmlTemplate.current.initialState = editorState;
                            setEditorStateValue(editorState)
                        }
                    }
                }


            })
            .catch(res => {})
    }, []);

    useEffect(() => {
        dispatch(CI_InitialStateAction('timezone', {
            abbrev: "IST",
            altName: "India Standard Time",
            label: "(GMT+5:30) Chennai, Kolkata, Mumbai, New Delhi",
            offset: 5.5,
            value: "Asia/Kolkata"
        }))
    }, [])


    React.useEffect(() => {

        handleTimeLineForm('candidatesEmail', personName);

    }, [personName]);


    React.useEffect(() => {
        let navBtnWrapper = document.getElementById('nav-btn-wrapper');
        let navBtnInner = document.getElementById('nav-btn-inner');
        let templateHolder = document.getElementById('template-holder');
        if (invitationTemplate === 'updated') {

            templateHolder.style.height = '880px';
            navBtnWrapper.classList.add('nav-btn-wrapper-override');
            // navBtnInner.classList.add('nav-btn-inner-override');
        }
        else {
            templateHolder.style.height = 'auto';
            navBtnWrapper.classList.remove('nav-btn-wrapper-override');
            // navBtnInner.classList.remove('nav-btn-inner-override');
            if (templateUpdated === 'saved') {
                setTemplateUpdated('idle');
            }
        }

        // navBtnWrapper.classList.toggle('nav-btn-wrapper-override');
        // navBtnInner.classList.toggle('nav-btn-inner-override');


        return () => {
            navBtnWrapper.classList.remove('nav-btn-wrapper-override');
            // navBtnInner.classList.remove('nav-btn-inner-override');
        }
    }, [invitationTemplate])

    const handleChange = (event) => {
        setHours(event.target.value);
    };


    const handleTimeLineForm = (field, value) => {

        dispatch(CI_InitialStateAction('timeline_form_data', { [field]: value }));
    }


    React.useEffect(() => {

        calculateDaysHoursMinutes()

    }, [interviewTimeFrom, interviewTimeTo, interviewDateTo, interviewDateFrom])


    useEffect(() => {

        if (interviewDayTimeDiff && !allDay) {
            let all_day_checkbox = document.querySelector('.all-day-checkbox.default');
            all_day_checkbox.classList.add('m-interviewDayTimeDiff-chkbox')
        }
    }, [interviewDayTimeDiff, allDay])



    function calculateDaysHoursMinutes() {
        if (interviewDateFrom == '' || interviewDateTo == '' || interviewTimeFrom == '' || interviewTimeTo == '') return;

        let timeDiff = {
            h1: interviewTimeFrom.substring(0, 2),
            m1: interviewTimeFrom.substring(3, 5),
            h2: interviewTimeTo.substring(0, 2),
            m2: interviewTimeTo.substring(3, 5),
        };
        // console.log(timeDiff.h1);

        let from = new Date(interviewDateFrom).setHours(timeDiff.h1, timeDiff.m1);
        let to = new Date(interviewDateTo).setHours(timeDiff.h2, timeDiff.m2);

        let diff = new Date(to) - new Date(from);
        let days = diff / (24 * 60 * 60 * 1000);
        let hours = (days % 1) * 24;
        let minutes = (hours % 1) * 60;
        let seconds = (minutes % 1) * 60;

        [days, hours, minutes, seconds] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(seconds)]


        let dateTimeInfo = '';
        if (days > 0) {
            dateTimeInfo = days > 1 ? `${days} Days ` : `${days} Day `;
        }
        if (hours > 0) {
            dateTimeInfo += hours > 1 ? `${hours} Hours ` : `${hours} Hour `;
        }
        if (minutes > 0) {
            dateTimeInfo += minutes > 1 ? `${minutes} Minutes ` : `${minutes} Minute `;
        }

        if (dateTimeInfo == '') {
            dateTimeInfo = 'Select valid duration';
            // dateTimeInfo = <span style={{ color: 'red' }}>Select valid duration</span>;
            dispatch(CI_InitialStateAction('time_duration', dateTimeInfo));
        }

        dispatch(CI_InitialStateAction('time_duration', dateTimeInfo));

        setInterviewDayTimeDiff(dateTimeInfo);

    }




    //console.log(interviewDateFrom, selectedQuestionSetId, selectedQuestions, interviewState);


    const onEditorStateChange = (editorState) => {
        setEditorStateValue(editorState)
    }



    const editorOnChange = () => {
        let keywordStatus = true;
        const dataElementsTexts = editorRef.current.editor.editor.querySelector("[data-contents='true']");
        const textStreams = dataElementsTexts.innerText.toLowerCase();
        for (let key in EMAIL_TEMPLATE_KEYWORDS) {
            if (!textStreams.includes(EMAIL_TEMPLATE_KEYWORDS[key].toLowerCase())) {
                if (!htmlTemplate.current.missingKeywords.includes(EMAIL_TEMPLATE_KEYWORDS[key].toLowerCase())) {
                    htmlTemplate.current.missingKeywords.push(EMAIL_TEMPLATE_KEYWORDS[key].toLowerCase());
                }
                keywordStatus = false;
                break;
            }
        }

        htmlTemplate.current.status = keywordStatus;
        if (!keywordStatus) {
            setEditorStateValue(htmlTemplate.current.state);
            setTemplateUpdated('template_wraning');
        }
        else {
            htmlTemplate.current.state = editorStateValue;
        }

    }

    let templateErrorMessage = htmlTemplate.current.missingKeywords.join(', ');
    templateErrorMessage += htmlTemplate.current.missingKeywords.length > 1 ? ' are required template keywords!' : ' is required template keyword!'


    useEffect(() => {

        if (editorStateValue) {
            // console.log(window.btoa(draftToHtml(convertToRaw(editorStateValue.getCurrentContent()))));
        }

    }, [editorStateValue])


    const modifyTemplate = () => {
        setTemplateUpdated('loading');

        if (!htmlTemplate.current.status) {
            setTimeout(() => {
                setTemplateUpdated('template_wraning');
            }, 500);
            return;
        }

        handleEmailTemplate(accessToken, window.btoa(draftToHtml(convertToRaw(editorStateValue.getCurrentContent()))))
            .then(res => {
                if (res.data.status === "succeed") {
                    setTemplateUpdated('saved');
                }
            })
            .catch(err => console.log(err))
    }

    const revertTemplateChanges = () => {
        setEditorStateValue(htmlTemplate.current.initialState);
        htmlTemplate.current.missingKeywords = [];
    }

    const handleEditorScroll = (e) => {
        if (e.target.scrollTop > 0) {
            e.target.style.borderTop = '1px solid #e9e9e9';
        }
        else {
            e.target.style.borderTop = 'none';
        }
    }
    const handleEditorScrollOnFocus = (e) => {
        e.target.style.display = 'none';
    }

    let newDate = new Date();

    /*console.log(newDate.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    })
    );
 
    console.log(Intl.supportedValuesOf('timeZone'));*/
    // console.log(templateUpdated);
   
    return (
        <>
            <Box sx={{
                flexGrow: 1,
            }}>
                <Box sx={{ width: '100%', padding: '0 30px 50px 20px' }}>
                    <Typography variant="h5" component="div" gutterBottom sx={{ borderBottom: '1px solid #c4c4c4', padding: '0 0 11px 0' }}>
                        Set a Timeline
                    </Typography>
                </Box>
                {
                    !allDay ?
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item md={2.5} sm={6} xs={12} className='timeline-input-grid'>
                                <Paper sx={paperStyles}>
                                    <TextField
                                        id="date1"
                                        label="Start Date"
                                        type="date"
                                        sx={inputStyles}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleTimeLineForm('interviewDateFrom', e.target.value)}
                                        value={interviewDateFrom}
                                        InputProps={{ inputProps: { min: today } }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item md={2.5} sm={6} xs={12} className='timeline-input-grid'>
                                <Paper sx={paperStyles} className='xs-to-down'>
                                    <TextField
                                        id="time"
                                        label="Start Time"
                                        type="time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                        }}
                                        sx={inputStyles}
                                        onChange={(e) => handleTimeLineForm('interviewTimeFrom', e.target.value)}
                                        value={interviewTimeFrom}
                                    />
                                    {/* <FormControl sx={{ width: '95%', height: '30%' }} >
                                         <Autocomplete
                                             id="free-solo-demo"
                                             freeSolo
                                             options={inputItems.map((option) => option)}
                                             renderInput={(params) => <TextField {...params} label="Start Time" />}
                                             onInputChange={(event, newInputValue) => {
                                                 handleTimeLineForm('interviewTimeFrom', newInputValue)
                                             }}
                                         />
                                     </FormControl> */}

                                </Paper>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='arrow-sign'>
                                <div className='arrow-right-icon'>
                                    <ArrowForwardIcon />
                                </div>
                                <div className='arrow-down-icon'>
                                    <ArrowDownwardIcon />
                                </div>
                            </Box>
                            <Grid item md={2.5} sm={6} xs={12} className='timeline-input-grid'>
                                <Paper sx={paperStyles} className='sm-to-down xs-to-down middle'>
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        type="date"
                                        sx={inputStyles}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleTimeLineForm('interviewDateTo', e.target.value)}
                                        value={interviewDateTo}
                                        InputProps={{ inputProps: { min: interviewDateFrom } }}
                                    />
                                </Paper>

                            </Grid>
                            <Grid item md={2.5} sm={6} xs={12} className='timeline-input-grid '>
                                <Paper sx={paperStyles} className='sm-to-down xs-to-down'>
                                    <TextField
                                        id="time"
                                        label="End Time"
                                        type="time"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        sx={inputStyles}
                                        onChange={(e) => handleTimeLineForm('interviewTimeTo', e.target.value)}
                                        value={interviewTimeTo}
                                        InputProps={{ inputProps: { min: interviewTimeFrom } }}
                                    />
                                    {/* <FormControl sx={{ width: '95%', height: '30%' }} >
                                     <Autocomplete
                                             id="free-solo-demo"
                                             freeSolo
                                             options={inputItems.map((option) => option)}
                                             renderInput={(params) => <TextField {...params} label="End Time" />}
                                             onInputChange={(event, newInputValue) => {
                                                 handleTimeLineForm('interviewTimeTo', newInputValue)
                                             }}
                                         />
                                     </FormControl> */}
                                </Paper>
                            </Grid>
                            {
                                interviewDayTimeDiff && <Grid item md={1.8} sm={2.5} xs={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <Paper sx={{ ...paperStyles }} className='all-day-checkbox'>
                                        {
                                            interviewDayTimeDiff === 'Select valid duration' ? <span style={{ color: 'red' }}>{interviewDayTimeDiff}</span>
                                                : interviewDayTimeDiff
                                        }
                                    </Paper>
                                </Grid>
                            }
                            <Grid item md={1.8} className='timeline-input-grid' sx={{ display: 'none', justifyContent: interviewDayTimeDiff ? 'flex-start' : 'center', alignItems: 'center' }}>
                                <Paper sx={paperStyles2} className='all-day-checkbox default'>
                                    <FormControlLabel control={<Checkbox checked={allDay} />} label="All Day" onChange={(e) => handleTimeLineForm('allDay', e.target.checked)} />
                                </Paper>
                            </Grid>
                        </Grid>
                        :
                        <>
                            <Grid container>
                                <Grid item md={1.5} sm={3} xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <Paper sx={paperStyles} className='all-day-checkbox chkbox2'>
                                            <FormControlLabel control={<Checkbox checked={allDay} />} label="All Day" onChange={(e) => handleTimeLineForm('allDay', e.target.checked)} />
                                        </Paper>
                                    </Box>
                                </Grid>
                                <Grid item md={5} sm={6} xs={8}>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <FormControl sx={{ width: '95%', height: '30%' }} >
                                            <InputLabel id="demo-select-small">Select Duration</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={hours}
                                                label="Select Hours"
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {timeHours.map((time, index) => {
                                                    return < MenuItem value={time} key={index}>{time} Hours</MenuItem>
                                                })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                            </Grid>
                        </>
                }
                <Grid container sx={{ margin: '10px 0' }}>
                    <Grid item md={4} sm={6} xs={12} sx={{ margin: '50px 0' }} className='timezone-selector-div'>
                        <Paper sx={paperStyles}>
                            <FormControl sx={{ width: '100%', height: '100%', zIndex: 91 }}>
                                <InputLabel id="demo-simple-select-label" className='timezone-selector'>Time Zone</InputLabel>
                                <TimezoneSelect
                                    label='Time Zone'
                                    // defaultValue={}
                                    // defaultValue= "Asia/Kolkata"
                                    value={timeZone.value == undefined ? 'Asia/Kolkata' : timeZone.value}
                                    onChange={(e) => {
                                       
                                        dispatch(CI_InitialStateAction('timezone', e))
                                    }}
                                />
                            </FormControl>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12} sx={{ padding: '30px 30px' }} id='template-holder'>
                            <Grid item xs={12}>
                                <Paper sx={{ background: 'transparent', boxShadow: 'none' }}>
                                    <Typography variant="h5" component="div" gutterBottom sx={{ padding: '0 0 11px 0' }} className='interview-invitation-head'>
                                        Do you want to modify the email invitation that will be sent ?
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={(e) => handleTimeLineForm('invitationTemplate', e.target.value)}
                                        >
                                            <Tooltip title='Open Email Template/Edit Email' placement='bottom'>
                                                <FormControlLabel value="updated" control={<Radio checked={invitationTemplate === 'updated'} />} label="Yes" />
                                            </Tooltip>
                                            <FormControlLabel value="default" control={<Radio checked={invitationTemplate === 'default'} />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                    {
                                        templateUpdated === 'template_wraning' && <Box sx={{ width: '500px', display: 'flex', justifyContent: 'center' }}>
                                            <DirectionSnackbar snackbarDisplay={templateUpdated === 'template_wraning'} message={templateErrorMessage} className='template-warning-snackbar' templateUpdater={setTemplateUpdated} />
                                        </Box>
                                    }
                                    {
                                        invitationTemplate === 'updated' && <Box sx={{ width: '400px', display: 'flex', justifyContent: 'flex-end' }}>
                                            {
                                                templateUpdated === 'saved' && <DirectionSnackbar snackbarDisplay={templateUpdated === 'saved'} message="Template saved successfully!" className='template-update-snackbar' templateUpdater={null} />
                                            }
                                            {
                                                templateUpdated !== 'loading' ?
                                                    <Tooltip title='Save Changes' placement='top'>
                                                        <IconButton onClick={modifyTemplate} >
                                                            <CheckIcon color={templateUpdated === 'saved' ? 'success' : '#727070'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <CircularProgress size={20} />
                                            }
                                            <Tooltip title='Revert Changes' placement='top'>
                                                <IconButton onClick={revertTemplateChanges}>
                                                    <ReplayIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            {
                                invitationTemplate === 'updated' && <Grid item xs={12}>
                                    <div id='text-editor-main' className='text-editor' onScroll={handleEditorScroll}>
                                        {
                                            !editorStateValue && <Box sx={{
                                                position: 'absolute',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%'
                                            }}>
                                                <CircularProgress />
                                            </Box>
                                        }
                                        <Editor
                                            // editorState={editorState}
                                            editorState={editorStateValue}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName={editorClassName}
                                            onBlur={(event, editor) => {
                                                // console.log('Blur.', editor);
                                            }}
                                            onEditorStateChange={onEditorStateChange}
                                            ref={editorRef}
                                            onChange={editorOnChange}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link',
                                                    // 'embedded', 'emoji',
                                                    'image', 'remove', 'history'],
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                            }}
                                        // onFocus={handleEditorScrollOnFocus}
                                        />
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}