import { useState, useEffect, useRef, createContext, useContext } from "react";
import { GetNewAccessToken, GetToken, GetUserDetails, GetNewRefreshATokenCup, GetNewRefreshATokenCupPost } from "../../../handlers/aws_handler";
import { useCookies } from "react-cookie";
import { environment } from "../../Environment/Environment";
import { getUserRole } from "../../../handlers/User_details_handler";
import { accessTokenStore } from "../../../handlers/http.handler";
import { APP_DOMAIN } from '../.././../Constants/serverConstant';

export const UserAuthContext = createContext('');


export function UserAuthProvider({ children }) {
    const [accessToken, setAccessToken] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(['_at', 'unicode']);
    const [refreshToken, setRefreshToken] = useState('');
    const [unicode, setUnicode] = useState('');
    const [userRole, setUserRole] = useState('');
    const intervalIdRef = useRef('');
    const expiryTimeRef = useRef({
        token: 0,
        refreshToken: 0
    });

    const [acessTokenStatus, setAcessTokenStatus] = useState(null);

    const [proceedLogoutOut, setProceedLogoutOut] = useState(false);

    const [isRefreshTokenExpired, setIsRefreshTokenExpired] = useState('idle');

    const CupApiEmptyArrayResponse = useRef(0);

    function checkTokenStatus() {
        setAcessTokenStatus(accessTokenStore.getState().token_status)
    }

    accessTokenStore.subscribe(checkTokenStatus);


    const getJobIdFromUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        //console.log(code);
        return code;
    };



    const delete_cookie = (cookies) => {
        for (let i = 0; i < cookies.length; i++) {
            document.cookie = `${cookies[i]}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; domain=${APP_DOMAIN}`;
        }

    };



    useEffect(() => {



        /*
        * check cookie status ----------------------------------------------------------------Start
        */
        let checkCookie = function () {

            var lastCookie = document.cookie; // 'static' memory between function calls
            //console.log('checkCookie', document.cookie);

            return function () {

                var currentCookie = document.cookie;
                const cookieTokenArr = document.cookie.split(";");

                //console.log(cookieTokenArr, 'cookieTokenArr');
                const channelCookiePromise = new Promise((resolve, reject) => {
                    let check_at = 'null';
                    let counter = 0
                    for (let i = 0; i < cookieTokenArr.length; i++) {
                        if (cookieTokenArr[i].includes('_at')) {
                            check_at = cookieTokenArr[i];
                            // console.log(cookieTokenArr[i], 'cookieTokenArr');
                        }
                        counter++;
                    }

                    // console.log('fldkhldhfl', counter, cookieTokenArr.length);
                    if (counter === cookieTokenArr.length) {
                        if (check_at !== 'null') {
                            resolve({ '_at': 'token_exists' });
                        }
                        else {
                            reject({ '_at': 'token_removed' });
                        }
                    }


                });




                channelCookiePromise
                    .then(res => {
                        // console.log(res, 'channelCookieArray_res')
                    })
                    .catch(err => {
                        // console.log(err, 'channelCookieArray_err');
                        if (err['_at'] === "token_removed") {
                            window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
                        }
                    })
                // console.log(document.cookie, 'document.cookie');

                // if (currentCookie != lastCookie) {

                //     // something useful like parse cookie, run a callback fn, etc.

                //     lastCookie = currentCookie; // store latest cookie

                // }
            };
        }();
        window.setInterval(checkCookie, 2000); // run every 100 ms

        /*
        * check cookie status ----------------------------------------------------------------End
        */

        window.addEventListener('storage', storageChange, false)
        /*
        Get the code from the url to get the token 
        AWS Cognito needs code to get the access token & refresh token
        */
        const code = getJobIdFromUrl();
        /*
        This section runs when access & refresh tokens are not aviailable in cookies.
        Get the code from aws cognito & hit the token-endpoint to get the access token 
        */
        if (code) {
            // If code
            GetToken(code)
                .then(res => {
                    setUnicode(code);
                    // setRefreshToken(res.data.refresh_token);   // set the access token to state
                    setAccessToken(res.data.access_token);     // set the refresh token to state
                    // trackToken(res.data.refresh_token);
                    GetNewRefreshATokenCupPost(code, res.data.refresh_token)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data === "success") {
                                    getRefreshTokenFromCUP(code);
                                }
                                // console.log(res.data[0]['_source'].token, 'GetNewRefreshATokenCup');
                                // setRefreshToken(res.data[0]['_source'].token);
                            }
                        })
                        .catch(err => {
                        })
                    window.history.pushState(window.history.state, document.title, window.location.origin);  // after getting the token remove the code part from the url
                })
                .catch(err => console.log(err));
            return;
        }
        /*
        If tokens are available inside cookies then this condition runs
        */
        if (cookies['_at']) {
            setAccessToken(cookies['_at']);
            setUnicode(cookies.unicode);
            getRefreshTokenFromCUP(cookies.unicode)
            // GetNewRefreshATokenCup(cookies.unicode)
            //     .then(res => {
            //         if (res.status === 200) {
            //             // console.log(res.data[0]['_source'].token, 'GetNewRefreshATokenCup');
            //             setRefreshToken(res.data[0]['_source'].token);
            //         }
            //     })
            //     .catch(err => {

            //     })



            // set the access token to state
            // set the refresh token to state
            return
        }
        else {
            // If tokens are not available inside cookies then redirect to aws cognito
            window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
        }
        return () => {
            clearTimeout(intervalIdRef.current);
        }
    }, []);





    useEffect(() => {
        if (proceedLogoutOut) {
            removeCookie('_at');
            // removeCookie('refreshToken');
            removeCookie('unicode');
            handleUserAuthValues_remove()

            // window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
        }
    }, [proceedLogoutOut]);


    // useEffect(() => {
    //     if (JSON.stringify(cookies) === "{}" && proceedLogoutOut) {
    //         window.location.reload();
    //     }
    // }, [cookies, proceedLogoutOut]);


    function getRefreshTokenFromCUP(code) {
        GetNewRefreshATokenCup(code)
            .then(res => {

                if (res.status === 200) {
                    if (res.data.length === 0 && CupApiEmptyArrayResponse.current < 3) {
                        CupApiEmptyArrayResponse.current++;
                        getRefreshTokenFromCUP(code)
                    }
                    // console.log(res.data[0]['_source'].token, 'GetNewRefreshATokenCup');
                    setRefreshToken(res.data[0]['_source'].token);
                }
            })
            .catch(err => {

            })
    }

    function setUserDetails(token) {
        GetUserDetails(token)
            .then(res => {
                if (res.message === 'invalid_token') {
                    removeCookie('_at');
                    //removeCookie('refreshToken');
                    removeCookie('unicode');
                }
                let userName = res.data.email.substring(0, res.data.email.indexOf('@'));
                setUserInfo({
                    ...res.data, userName,
                    //userName: 'anil.choudhary', email: 'anil.choudhary@hitechpeopleinc.com'
                })
            })
            .catch(err => {
                console.log(err);
            })
    }
    /*
    Track the token according to the timeout, if it is expired then a new access token
    */
    function trackToken(refreshToken) {
        //  console.log('expiryTimeRef.current.token', expiryTimeRef.current.token, refreshToken, intervalIdRef.current);
        if (expiryTimeRef.current.token < 0) {
            expiryTimeRef.current.token = 0;
        }
        clearTimeout(intervalIdRef.current);
        intervalIdRef.current = setTimeout(() => {
            GetNewAccessToken(refreshToken)
                .then(res => {
                    if (res.message === 'invalid_grant' || res.message.status !== 200) {
                        // If refresh token also expires then redirect to aws cognito
                        setAcessTokenStatus('expired');
                        removeCookie('_at');
                        // removeCookie('refreshToken');
                        removeCookie('unicode');
                        window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
                    }
                    else {
                        setAccessToken(res.data.access_token);
                    }
                })
                .catch(err => console.log(err))
        }, expiryTimeRef.current.token);
    };

    async function onSubmit() {
        setCookie('_at', accessToken, { path: '/', domain: APP_DOMAIN });
        // setCookie('refreshToken', refreshToken, { path: '/', domain: APP_DOMAIN });
        setCookie('unicode', unicode, { path: '/', domain: APP_DOMAIN });
    }



    function handleUserAuthValues_set() {
        window.localStorage.setItem('is_logged_in', true);
        // window.dispatchEvent(new Event("storage"));
    }

    function handleUserAuthValues_remove() {
        window.localStorage.removeItem('is_logged_in');
        //window.localStorage.setItem('is_logged_in', false)
    }


    function handleUserAuthValues_get() {
        window.localStorage.getItem('is_logged_in');
    }


    function storageChange(event) {
        if (event.key === 'is_logged_in') {
            //  window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
        }
    }

    /*
    When new access token is set then track the expiry of the token
    */
    useEffect(() => {
        if (accessToken && refreshToken) {

            // clearTimeout(intervalIdRef.current)
            let cookieTokenDecode = '';
            const currentTimeStamp = new Date().getTime();
            if (accessToken) {
                cookieTokenDecode = JSON.parse(window.atob(accessToken.split('.')[1]));
                expiryTimeRef.current.token = (cookieTokenDecode.exp * 1000) - currentTimeStamp;
            }
            trackToken(refreshToken)
            if (!userInfo.userName) {
                setUserDetails(accessToken)
            }


            handleUserAuthValues_set();
        }
    }, [accessToken, refreshToken]);


    useEffect(() => {
        if (isRefreshTokenExpired === 'expired') {
            GetNewRefreshATokenCup(code)
                .then(res => {
                    if (res.status === 200) {
                        // console.log(res.data[0]['_source'].token, 'GetNewRefreshATokenCup');
                        setRefreshToken(res.data[0]['_source'].token);
                        setIsRefreshTokenExpired('active');
                    }
                })
                .catch(err => {

                })

        }
    }, [isRefreshTokenExpired]);





    useEffect(() => {

        const serverError = [500, 502, 503, 504, 400, undefined, 0, 404, 405]
        if (acessTokenStatus !== null) {
            if (!serverError.includes(acessTokenStatus.statusCode)) {
                if (acessTokenStatus.statusCode === 401
                    || acessTokenStatus.statusCode === 0
                    || acessTokenStatus.statusText === 'invalid_token'
                    || acessTokenStatus.statusText === 'invalid_grant'
                    || acessTokenStatus.statusText === undefined) {

                    GetNewAccessToken(refreshToken)
                        .then(res => {
                            // console.log(acessTokenStatus, 'check token');
                            if (res.message === 'invalid_grant' || res.message.status !== 200) {
                                // If refresh token also expires then redirect to aws cognito
                                setAcessTokenStatus('expired');
                                removeCookie('_at');
                                // removeCookie('refreshToken');
                                removeCookie('unicode');
                                window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
                            }
                            else {
                                setAccessToken(res.data.access_token);
                            }
                        })
                        .catch(err => console.log(err))
                }
            }


        }


    }, [acessTokenStatus])

    useEffect(() => {
        if (accessToken && unicode) { onSubmit() }
    }, [accessToken, unicode]);

    useEffect(() => {
        if (accessToken && userInfo.userName) {
            getUserRole(accessToken, userInfo.userName)
                .then(res => {
                    // console.log(res);
                    if (res) {
                        setUserRole(res.data.data.Response);
                    }

                })
        }
    }, [accessToken, userInfo]);


    let responseCode;
    const url = window.location.href.split('/?');
    if (url.length > 1) {
        const codeUrl = url[1].split('=');
        responseCode = codeUrl[1];
    }

    return (<> <UserAuthContext.Provider value={{ accessToken, userInfo, userRole, setProceedLogoutOut, removeCookie, delete_cookie }}>
        {children}
    </UserAuthContext.Provider>
    </>
    )
}

export function useUserAuthContext() {
    return useContext(UserAuthContext);
}