import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import plus from '../../../../Assets/icons/plus.png';
import Addplus from '../../../../Assets/icons/Addplus.png';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { AlertTitle, Box, Divider, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './Addcandidatepopup.css';
import { useState } from 'react';
import { AddCandidate } from '../../../../handlers/job_handlers';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { SuccessAlert } from '../../../Alerts/SuccessAlert';
import { socialDomains } from './socialDomains';
/*----redux---*/

import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';
import { ErrorAlert } from '../../../Alerts/ErrorAlert';

/*----redux---*/

const formFields = {
    CandidateName: '',
    Email: '',
    Number: ''
};

export default function AlertDialog({ selectedJobId, jobName, setNewCandidateAdded }) {

    const [open, setOpen] = React.useState(false);
    const [formRows, setFormRows] = useState([formFields]);
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' })
    const { accessToken, userInfo } = useUserAuthContext();
    const interviewState = useSelector(state => state.handleCreateInterviewInitialState);
    const [valid, setValid] = useState([{
        CandidateName: true,
        Email: true,
        Number: true
    }]);

    const [showError, setShowError] = useState({ display: false, text: '' });
    const validateErrorText = React.useRef([{
        CandidateName: '',
        Email: '',
        Number: ''
    }]);
    const [validateErrorTextState, setValidateErrorTextState] = useState([{
        CandidateName: '',
        Email: '',
        Number: ''
    }]);

    const addList = () => {
        closeFN()
        setFormRows(state => [...state, formFields]);
        setValid(state => [...state, {
            CandidateName: true,
            Email: true,
            Number: true
        }]);
        validateErrorText.current = [...validateErrorText.current, {
            CandidateName: '',
            Email: '',
            Number: ''
        }];
        setValidateErrorTextState(validateErrorText.current);
        // console.log(formRows, 'rowsss');
    };

    const removeListItem = num => {
        if (formRows.length > 1) {
            const tempArr = [...formRows];
            tempArr.splice(num, 1)
            setFormRows(tempArr)
            const tempArrValid = [...valid];
            tempArrValid.splice(num, 1)
            setValid(tempArrValid);
            const validateErrors = [...validateErrorText.current];
            validateErrors.splice(num, 1)
            validateErrorText.current = validateErrors;
            setValidateErrorTextState(validateErrorText.current);

        }
    };
    function closeFN() {
        setTimeout(() => {
            // setAlertMessage({ display: false, text: '' })
        }, 3000);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function closeAlert() {
        setTimeout(() => {
            setShowError({ display: false, text: '' })
            setAlertMessage({ display: false, text: '' })
        }, 3000);
    };


    const handleChange = (value, index, key) => {

        handleValidation(value, index, key);

        let arr = [...formRows].map((item, i) => {
            if (index === i) {
                // console.log(key, value);
                return { ...item, [key]: value }
            }
            return item;
        })
        setFormRows(arr)
    }

    function setRows() {
        setFormRows([{
            CandidateName: '',
            Email: '',
            Number: ''
        }]);
        setValid([{
            CandidateName: true,
            Email: true,
            Number: true
        }])
    }

    function handleSave() {
        let status = 1;
        for (let i = 0; i < valid.length; i++) {
            if (formRows[i].CandidateName.trim() == '' || formRows[i].Email.trim() == '' || formRows[i].Number.trim() == '') {
                status = 0;
                setShowError({ display: true, text: 'All fields Are required' });
                closeAlert();
                break;
            }
            if (!valid[i].CandidateName || !valid[i].Email || !valid[i].Number) {
                status = 0;
                setShowError({ display: true, text: 'Please Enter Valid Fields' })
                closeAlert();
                break;
            }
        }


        for (let i = 0; i < valid.length; i++) {
            if (validateErrorText.current[i].CandidateName
                //|| !validateErrorText.current[i].Email || !validateErrorText.current[i].Number
            ) {
                status = 0;
                setShowError({ display: true, text: validateErrorText.current[i].CandidateName })
                closeAlert();
                break;
            }
            // else if(){

            // }
        }



        if (status) {
            AddCandidate(accessToken, formRows, selectedJobId, jobName, userInfo.userName, true)
                .then(res => {
                    // console.log(res);
                    if (res.status) {
                        if (res.data.status == 'succeed') {
                            setRows();
                            setNewCandidateAdded(true);
                            if (res.data.data.message === 'candidates emailId Allready Exist!!') {
                                setShowError({ display: true, text: 'Candidates either email or mobile number already exist!!' })
                                closeAlert()
                            }
                            else if (res.data.data[0].message === 'Candidates Posted Successfully!!') {
                                setAlertMessage({ display: true, text: 'Candidate Added successfully' })
                                closeAlert()
                            }
                            handleClose()
                        }
                    }
                })
                .catch(err => console.log(err))
        }
    };

    // const socialDomains = [
    //     "gmail.com",
    //     "yahoo.com",
    //     "hotmail.com",
    //     "outlook.com",
    //     "aol.com",
    //     "zoho.com",
    //     "protonmail.com",
    //     "gmx.com",
    //     "mail.com",
    //     "icloud.com",
    //     "yandex.com",
    //     "facebook.com",
    //     "msn.com"
    // ]; 



    // Constructing the regex pattern dynamically
    const domainPattern = socialDomains.join("|");
    const handleValidation = (value, i, keyName) => {

        let validErr = [...valid].map((item, index) => {
            if (index === i) {
                if (keyName === 'Email') {
                    // const reg = new RegExp(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+(.))+(com)+$/);
                    // const reg = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
                    const reg = new RegExp(`^[a-zA-Z0-9._%+-]+@(${domainPattern})$`);

                    let regexResult = reg.test(value);

                    const regexWhiteSpace = new RegExp(/^\s|\s$/);
                    const regexWhiteSpaceResult = regexWhiteSpace.test(value);


                    if (regexWhiteSpaceResult || !regexResult) {
                        validateErrorText.current[index].Email = 'Invalid email';

                    }
                    else {
                        validateErrorText.current[index].Email = '';
                    }



                    return { ...item, [keyName]: regexResult }
                }
                else if (keyName === 'CandidateName') {
                    const reg = new RegExp(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g);
                    let regexResult = reg.test(value);


                    const regexWhiteSpace = new RegExp(/^(?:\S* ){5}/);
                    const regexWhiteSpaceResult = regexWhiteSpace.test(value);


                    if (regexWhiteSpaceResult) {
                        validateErrorText.current[index].CandidateName = 'Special characters, whitespaces, numbers are not allowed';
                        regexResult = false;
                    }
                    else {
                        validateErrorText.current[index].CandidateName = '';
                    }

                    if (!regexResult) {
                        validateErrorText.current[index].CandidateName = 'Special characters, whitespaces, numbers are not allowed';
                    }



                    return { ...item, [keyName]: regexResult }
                }
                else if (keyName === 'Number') {
                    // const reg = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
                    const reg = new RegExp(/^[1-9]\d{9}$/);
                    let regexResult = reg.test(value);
                    if (value.length < 10) {
                        validateErrorText.current[index].Number = 'Invalid phone number';
                    }
                    else {
                        validateErrorText.current[index].Number = '';
                    }
                    if (value.charAt(0) == 0) {
                        validateErrorText.current[index].Number = 'Invalid phone number';
                        setShowError({ display: true, text: "Mobile Number can't start with 0" })
                        closeAlert()

                        if (value === "") {
                            setShowError({ display: false, text: "" })
                            closeAlert()
                        }
                    }


                    if (regexResult) {

                        if (value.length === 10 || value == 0) {
                            //  validateErrorText.current[index].Number = '';
                            return { ...item, [keyName]: true }
                        }
                        // validateErrorText.current[index].Number = 'Invalid phone number';
                        return { ...item, [keyName]: false }
                    }


                    // const regexWhiteSpace = new RegExp(/^\s|\s$/);
                    // const regexWhiteSpaceResult = regexWhiteSpace.test(value);



                    // if (regexWhiteSpaceResult) {
                    //     validateErrorText.current[index].Number = 'Whitespaces are not allowed';
                    // }
                    // else {
                    //     validateErrorText.current[index].Number = '';
                    // }

                    return { ...item, [keyName]: false }
                }
            }
            return item;
        })
        setValid(validErr);
        setValidateErrorTextState(validateErrorText.current);
    };

    const closeDialog = () => {
        setOpen(true);
    };


    function capitalizeFirstLetterOfName(name) {
        let nameCapitalizeFirstLetter = name;
        nameCapitalizeFirstLetter = nameCapitalizeFirstLetter.split(' ');

        nameCapitalizeFirstLetter = nameCapitalizeFirstLetter.map((letter) => {
            return letter.charAt(0).toUpperCase() + letter.slice(1)
        })

        return nameCapitalizeFirstLetter.join(' ');
    }


    // console.log(valid, 'hdhdh');

    return (
        <div>
            <ErrorAlert showError={showError} setShowError={setShowError} />
            <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <Tooltip title='Add Candidate' placement='top'>
                <IconButton aria-label="search" onClick={handleClickOpen}>
                    <div style={{ margin: '0 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={plus} />
                    </div>
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={closeDialog} sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "1000px",  // Set your width here
                        padding: "0 20px"
                    },
                },
            }}>
                <Grid className='add-more-candidate-form-header'>
                    <div className='amc-form-header-inner'>
                        <DialogTitle sx={{ fontWeight: 600 }}>Add Candidate </DialogTitle>
                        <div className='add-more-fields'>
                            <IconButton aria-label="search" onClick={addList} className="input-center" style={{ width: '45px', height: '45px' }}>
                                <AddBoxOutlinedIcon style={{ color: '#52acff' }} className='add-more-icon' />
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <CloseOutlinedIcon style={{ color: '#9a9a9a', cursor: 'pointer', marginTop: '12px' }} onClick={handleClose} />
                    </div>
                </Grid>
                <Divider />
                <div className='add-more-candidate-form'>
                    {formRows.map((item, index) => {
                        return (
                            <Grid key={index} container sx={{ marginBottom: '25px' }}>
                                <Box
                                    component="form"
                                    sx={{
                                        "& .MuiTextField-root": { m: 1, width: "33ch" }
                                    }}
                                    noValidate
                                    autoComplete="off"
                                ><div>
                                        <TextField
                                            required
                                            type="text"
                                            id="outlined-required"
                                            label="Candidate Name"
                                            sx={{ width: '95%', paddingBlock: '10px' }}
                                            onChange={(e) => handleChange(e.target.value, index, 'CandidateName')}
                                            value={item.CandidateName}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/\s{2,}/g, ' ')
                                                e.target.value = capitalizeFirstLetterOfName(e.target.value);
                                                const regex = new RegExp(/[a-zA-Z]+[(@!#\$%\^\&*\)\(+=._-]{1,}/);
                                                const regexResult = regex.test(e.target.value);
                                                if (regexResult) {
                                                    e.target.value = formRows[index].CandidateName;

                                                }
                                                // else {
                                                //     e.target.value = e.target.value.slice(0, 10)
                                                // }
                                                //e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                            }}
                                            error={!valid[index].CandidateName}
                                            helperText={validateErrorTextState[index].CandidateName}
                                        />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            type="email"
                                            label="Email"
                                            sx={{ width: '95%', paddingBlock: '10px' }}
                                            onChange={(e) => { handleChange((e.target.value).toLowerCase(), index, 'Email'); }}
                                            value={item.Email}
                                            onInput={(e) => {

                                                e.target.value = e.target.value.trim()

                                            }}
                                            error={!valid[index].Email && item.Email.length > 0}
                                            helperText={validateErrorTextState[index].Email}
                                        />
                                        <TextField
                                            required
                                            id="outlined-required"
                                            // type="number"
                                            onInput={(e) => {
                                                if (isNaN(parseInt(e.target.value))) {
                                                    e.target.value = '';
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 10)
                                                }
                                                //e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                            }}
                                            error={!valid[index].Number}
                                            // helperText={!valid[index].Number ? "Mobile Number can't start with 0 or 1 ":''}
                                            label="Contact Number"
                                            sx={{ width: '95%', paddingBlock: '10px' }}
                                            onChange={(e) => handleChange(e.target.value, index, 'Number')}
                                            value={item.Number}
                                            helperText={validateErrorTextState[index].Number}
                                        />
                                    </div>
                                </Box>
                                {
                                    formRows.length > 1 && <Grid item md={12} className="input-right">
                                        <div>
                                            <RemoveCircleOutlineIcon onClick={() => removeListItem(index)} sx={{ color: '#ff2020', cursor: 'pointer' }} />
                                        </div>
                                    </Grid>
                                    // <button type="button" onClick={() => removeListItem(index)}>
                                    //     Remove
                                    // </button>
                                }
                            </Grid>
                        );
                    })}
                </div>
                < Divider />
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleSave} type='button'>Save</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}