import Box from '@mui/material/Box';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Stack from '@mui/material/Stack';
import Select from "@mui/material/Select";
import { useState, useEffect, useRef } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
// import { DataGrid } from '@mui/x-data-grid';
import './Createinterview.css';
import { Grid, Icon, IconButton } from '@mui/material';
import { AddCandidate, DeleteCandidate, GetCandidateCes, handleJobs, ViewJobList } from '../../../handlers/job_handlers';
import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import Questionnaires from './QuestionSet/Questionnaires';
import SetInterviewTimeLine from './SetTimeline/SetInterviewTimeLine';
import { getQuestionnairesIds } from '../../../handlers/question_handler';
import EmailTemplate from './EmailTemplate/EmailTemplate';
import Addcandidatepopup from './Popup/Addcandidatepopup';
import { useNavigate } from 'react-router-dom';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import Loader from '../../Preloaders/Loader';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { darken, lighten } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import clean from "../../../Assets/icons/clean.png";
import ClearFiltericon from "../../../Assets/icons/ClearFiltericon.png";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DropDownBox from "devextreme-react/drop-down-box";
import Snackbar from '@mui/material/Snackbar';
import DataGrid, {
    Column,
    FilterRow,
    Paging,
    Scrolling,
    Selection,
    Pager,
    ColumnChooser,
    Sorting,
    Lookup,
    Item,
    Toolbar,
    Export,
    ColumnFixing,
    Editing,
    StateStoring,
} from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button as DevExButton } from "devextreme-react/button";
const gridColumns = ["jobName"];
const initialColumnStructure = {
    searchText: "",
    allowedPageSizes: [20, 50, 100],
    filterPanel: { filterEnabled: true },
    pageIndex: 0,
    columns: [
        { visible: true, visibleIndex: 4, name: "buttons", width: "110px" },
        {
            dataField: "Name",
            visible: true,
            visibleIndex: 1,
            dataType: "string",
            name: "CandidateName",
            width: "500px",
        },
        {
            dataField: "Email",
            visible: true,
            visibleIndex: 2,
            dataType: "string",
            name: "Email",
            width: "500px",
        },
        {
            dataField: "Contact Number",
            visible: true,
            visibleIndex: 3,
            dataType: "string",
            name: "Contact Number",
            width: "440px",
        },
    ],
    pageSize: 20,
};
/*----redux---*/
import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../utils/Redux/Actions';
import { SuccessAlert } from '../../Alerts/SuccessAlert';
import { color } from '@mui/system';
import { merge } from 'immutable';
import { GridCustomization, GridCustomizeGet } from '../../../handlers/customize';
import { useCallback } from 'react';
import { ScrollView } from 'devextreme-react';
/*----redux---*/
const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 650
        }
    }
};
function getStyles(jobId, selectedJobId, theme) {
    return {
        fontWeight:
            selectedJobId === jobId
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    };
}
const headingTxtColor = { color: "#00000099" }
const pages = ['select_job', 'candidates_list', 'build_questions', 'set_timeline', 'Send_Email'];
export default function Createinterview(props) {
    const theme = useTheme();
    const [jobsList, setJobsList] = useState([]);
    // const [selectedJobId, setSelectedJobId] = useState([]);
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' });
    const [showError, setShowError] = useState({ display: false, text: '' });
    const [selectedJobId, setSelectedJobId] = useState('');
    const [selectedLocalId, setSelectedLocalId] = useState([]);
    const [candidatesList, setCandidatesList] = useState([]);
    const [cesCandidateList, setCesCandidateList] = useState([]);
    const [selectedCandidatesChecked, setSelectedCandidatesChecked] = useState([]);
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    //const [interviewType, setInterviewType] = useState('');
    const [pageNavigation, setPageNavigation] = useState(1);
    const [jobTypeRedirect, setJobTypeRedirect] = useState('');
    const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState('');
    // testing loader
    const [tableLoaderErrInfoTEST, setTableLoaderErrInfoTEST] = useState({ select_job: '', candidates_list: '', build_questions: '', set_timeline: '', Send_Email: '' });
    const [proceedToSendMail, setProceedToSendMail] = useState(false);
    const [pageContextError, setPageContextError] = useState('');
    const [newCandidateAdded, setNewCandidateAdded] = useState(false);
    const [newCandidateDeleted, setNewCandidateDeleted] = useState(false);
    const [interviewScheduleInfo, setInterviewScheduleInfo] = useState({ state: 'idle', sentStatus: 'idle', text: '' });
    const [open, setOpen] = useState(false);
    const [cesToLocal, setCesToLocal] = useState(false);
    const [cesCandidateDataList, setCesCandidateDataList] = useState('');
    const [jobValue, setJobValue] = React.useState('local_job');
    const [newAddedCandidate, setNewAddedCandidate] = useState([]);
    const [localJobGet, setLocalJobGet] = useState(false);
    const [pageSize, setPageSize] = React.useState(20);
    const [ApplicationLoaderErrInfo, setApplicationLoaderErrInfo] = React.useState('');
    // let sortedCandidate = sortElements([...candidatesList], 'candidateId');
    // const [deleteCheck, setDeleteCheck] = useState('false');
    const [gridBoxValue, setGridBoxValue] = React.useState([])
    const [isGridBoxOpened, setIsGridBoxOpened] = React.useState(false)
    const [DGPageNumber, setDGPageNumber] = useState(0);
    const allowedPageSizes = [20, 50, 100];
    const selectedRowKeyMailSend = useRef([]);
    const [resetCall, setResetCall] = React.useState("idle");
    const [clearCustomizationPopUp, setClearCustomizationPopUp] = React.useState(false)
    const [customizationData, setCustomizationData] = React.useState(null);
    const reset_custom = useRef(initialColumnStructure);
    const [deleteDilogOpen, setDeleteDilogOpen] = React.useState(false);
    const deleteId = useRef('');
    const [Yesloading, setYesLoading] = React.useState(false);
    const deleteCandidateIdRow = useRef('');
    let merged = [];
    const handleClickOpen = (ids) => {
        setOpen(true);
        deleteId.current = ids
    };
    //console.log(deleteId);
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (jobTypeRedirect !== '') {
            createJobRedirect()
        }
    }, [jobTypeRedirect]);
    const interviewState = useSelector(state => state.handleCreateInterviewInitialState);
    const { jobName } = useSelector(state => state.handleCreateInterviewInitialState.selectedJob);
    const isLocalJob = interviewState.selectedJob.isLocal;
    const testData = useSelector(state => state.handleCreateInterviewInitialState.selectedLocalId);
    //console.log('interviewState', interviewState, selectedLocalId, testData, jobName);
    const dispatch = useDispatch();
    const pageContext = pages[pageNavigation - 1];
    const selectedRowsKeysDataRef = useRef([]);
    const datagridCandidateListRef = useRef({ array: [], keysUnique: [] });
    const datagridCandidateListSelectedRef = useRef([]);
    const [selectedRowKeysCurrentState, setSelectedRowKeysCurrentState] = React.useState([]);
    const dataGridRef = useRef(null);
    const dataGridHeaderCheckboxSelectionRef = useRef(false);


    function handleDeleteCandidate(deleteIds) {
        DeleteCandidate(accessToken, userInfo.userName, deleteIds)
            .then(res => {
                if (!res) {
                    setShowError({ display: true, text: 'Something went wrong' })
                    return
                }
                if (res.data.status == 'succeed') {
                    setAlertMessage({ display: res.data.status == 'succeed' ? true : false, text: 'Candidate Deleted successfully' });
                    closeAlert()
                    setYesLoading(false)
                    setNewCandidateDeleted(true);
                    setOpen(false);
                    deleteId.current = ('');
                }
                else {
                    setShowError({ display: true, text: 'Something went wrong' })
                }
            })
            .catch(err => {
                console.log(err);
            })
    };
    React.useEffect(() => {
        if (accessToken && userInfo.userName && pageContext === "candidates_list") {
            const CandidateList = "create-interview";
            GridCustomizeGet(accessToken, userInfo.userName, CandidateList).then(
                (res) => {
                    // console.log(res.status, res, "res");
                    if (res.status) {
                        //  console.log(res);
                        setCustomizationData(res.data.data)
                    }
                }
            );
        }
    }, [accessToken, userInfo.userName, pageContext])
    const loadState = useCallback(() => {
        if (pageContext === "candidates_list") {
            let data = {};
            if (resetCall === "active") {
                data = reset_custom.current;
                setResetCall("isnactive");
                setClearCustomizationPopUp(false)
            }
            else if (resetCall === "idle") {
                data = { ...customizationData }
            }
            return data;
        }
        return customizationData;
    }, [customizationData, resetCall, pageContext]);
    const saveState = useCallback(
        (state) => {
            const CandidateList = "create-interview";
            if (userInfo.userName && pageContext === "candidates_list") {
                GridCustomization(
                    accessToken,
                    userInfo.userName,
                    state,
                    CandidateList
                ).then(res => {
                    
                });

            }
        }, [userInfo, pageContext, accessToken]);
    function closeAlert() {
        setTimeout(() => {
            setAlertMessage({ display: false, text: '' })
        }, 2000);
    };
    const columns = [
        { field: 'Name', headerName: 'Name', width: 400, },
        { field: 'Email', headerName: 'Email ID', width: 400, },
        {
            field: 'Contact Number',
            headerName: 'Cotact Number',
            width: 350,
        },
        {
            field: 'Action', headerName: 'Action', width: 500, margin: 60, size: 'large', renderCell: (candidate) => {
                // console.log('candidate', candidate.row.isMailSend);
                const isMailSend = candidate.row.isMailSend;
                const isChecked = candidate.row.isChecked;
                const deleteBtnDisabled = (isLocalJob === true && (isMailSend === false)) ? false : true;
                return (
                    <Tooltip title='Delete'>
                        <span>
                            <IconButton disabled={deleteBtnDisabled} onClick={() => {
                                handleClickOpen(candidate.row.item.attachCandidate[0].candidateId)
                                //handleDeleteCandidate(candidate.row.item.attachCandidate[0].candidateId)
                            }}>
                                <DeleteIcon sx={isLocalJob ? { color: deleteBtnDisabled ? '#a0a0a0' : '#ed6c02' } : { color: 'grey' }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                );
            }
        },
    ];
    // console.log('candidatelist', candidatesList);
    useEffect(() => {
        setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, select_job: 'Loading' }));
        //    console.log(userRole);
        // if (accessToken && userRole == 'Global Administrator') {
        //     handleJobs('getJobs', accessToken, 'tester')
        //         .then(res => {
        //             //  console.log(res);
        //             setJobsList(res.data.data.response)
        //             setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, select_job: '' }));
        //             //setTableLoaderErrInfo('')
        //         })
        //         .catch(err => {
        //             //setTableLoaderErrInfo('Contact to Administrator')
        //             setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, select_job: 'Contact to Administrator' }));
        //         })
        // }
        // else {
        if (accessToken && userInfo.email) {
            ViewJobList(accessToken, userInfo, userRole, 1, 21000)
                .then((res) => {
                    let array = [...res.data.data.response].map((item) => {
                        let _jobname = item.jobName;
                        // if (item.isLocal == false) {
                        //     _jobname = _jobname;
                        // }
                        return { ...item, jobName: _jobname };
                    });
                    array = [...array].filter(jobName => {
                        if (jobName.jobStatus !== undefined) {
                            return true
                        }
                        else {
                            return false;
                        }
                    })
                    setJobsList(array);
                    setTableLoaderErrInfoTEST((prevstate) => ({
                        ...prevstate,
                        select_job: "",
                    }));
                })
                .catch((err) => {
                    //setTableLoaderErrInfo('Contact to Administrator')
                    setTableLoaderErrInfoTEST((prevstate) => ({
                        ...prevstate,
                        select_job: "Contact to Administrator",
                    }));
                });
            // }
        }
    }, [accessToken, userInfo, userRole]);
    // console.log(jobsList);
    // useEffect(() => {
    //     if (jobName) {
    //         getQuestionnairesIds(accessToken, 'BackendDeveloper')
    //             .then(res => {
    //                 //console.log(res)
    //             })
    //             .catch(err => console.log(err))
    //     }
    // }, [jobName]);

    // useEffect(() => {
    //     if (accessToken) {
    //         //SendMail(accessToken)
    //     }

    // }, [accessToken]);

    // console.log(jobsList);
    useEffect(() => {
        if (pageContext === 'select_job') {
            setTableLoaderErrInfo('')
            if (Object.keys(interviewState.selectedJob).length) {
                setSelectedJobId(interviewState.selectedJob.jobId);
                setNewCandidateAdded(true); // This state updater function is used to call  handleJobs('getAttachedCandidates', accessToken, '', selectedJobId);
            }
            //jobsList.filter
        }
        else if (pageContext === 'Send_Email') {
            // const nextButton = document.getElementById('next-btn');
        }
    }, [pageContext]);
    useEffect(() => {
        if (accessToken && selectedJobId && pageContext === 'candidates_list' && (newCandidateAdded || newCandidateDeleted)) {
            setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, candidates_list: 'Loading' }));
            handleJobs('getAttachedCandidates', accessToken, '', selectedJobId)
                .then(res => {
                    if (res.status) {
                        if (res.data) {
                            if (res.data.status === "succeed") {
                                if (res.data.data.message === 'record not found') {
                                    // setTableLoaderErrInfo('record not found');
                                    setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, candidates_list: 'Record not found' }));
                                    setCandidatesList([]);
                                }
                                else {
                                    setCandidatesList(res.data.data.response);
                                    setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, candidates_list: '' }));
                                }
                                setNewCandidateAdded(false);
                                setNewCandidateDeleted(false);
                            }
                        }
                        else {
                            throw { code: res.message.code, message: res.message.message };
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    //setTableLoaderErrInfo('Contact to Administrator');
                    setTableLoaderErrInfoTEST(prevstate => ({ ...prevstate, candidates_list: 'Request failed with status code 405' }));
                })
        }
    }, [pageContext, jobsList, newCandidateAdded, newCandidateDeleted]);
    const candidatesListRow = getCandidateInfo();
    useEffect(() => {
        if (accessToken && selectedJobId && !isLocalJob) {
            GetCandidateCes(accessToken, selectedJobId)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data != null) {
                                setCesCandidateList(res.data.data.response)
                            }
                        }
                    }
                })
                .catch(err => console.log(err))
        }
    }, [accessToken, selectedJobId, isLocalJob]);
    // console.log('test', selectedJobId, jobName, 'interview State', interviewState, cesToLocal, pageContext, isLocalJob);
    function cesJobToLocalJOb() {
        // console.log('test 1', selectedJobId, jobName, interviewState, cesToLocal, pageContext, isLocalJob);
        AddCandidate(accessToken, interviewState.selectedCandidates, selectedJobId, jobName, userInfo.userName, isLocalJob)
            .then(res => {
                // console.log(res);
                if (res.status) {
                    if (res.data.status == 'succeed') {
                        if (res.data.data[0].message === 'candidates emailId Allready Exist!!') {
                            // setShowError({ display: true, text: 'Candidates email already exist!!' })
                            // closeAlert()
                            //console.log(res);
                        }
                        else if (res.data.data[0].message === 'Candidates Posted Successfully!!') {
                            // setAlertMessage({ display: true, text: 'Candidate Added successfully' })
                            // closeAlert()
                            // const value = res.data.data[0].candidateId;
                            // console.log('value', value);
                            handleJobs('getAttachedCandidates', accessToken, '', selectedJobId)
                                .then(res => {
                                    // console.log(res); setNewAddedCandidate(res.data.data.response)
                                })
                                .catch(err => console.log(err))
                            // interviewState.selectedCesCandidateLocalId.selectedLocalId;
                        }
                    }
                }
            })
            .catch(err => console.log(err))
    };
    if (candidatesList.length) {
        candidatesList.sort((a, b) => {
            if (a.attachCandidate[0].candidateId < b.attachCandidate[0].candidateId) return 1;
            else if (a.attachCandidate[0].candidateId > b.attachCandidate[0].candidateId) return -1;
            return 0;
        }
        )
        // console.log(candidatesList);
    }
    useEffect(() => {
        const oldCandidates = [...interviewState.selectedCandidates]
        // let resarray = [{ oldId: '1234', newId: '4321' }, {}];
        const tempArry = [];
        if (newAddedCandidate.length) {
            // console.log('old', oldCandidates, 'new', newAddedCandidate);
            newAddedCandidate.forEach((resItem, i) => {
                //  console.log('firstarray', resItem);
                oldCandidates.forEach((cnd, index) => {
                    // console.log('secondarray', cnd);
                    if (resItem.attachCandidate[0].candidateEmail == cnd.candidate.email) {
                        // console.log('report', resItem.attachCandidate[0].candidateEmail == cnd.candidate.email, resItem.attachCandidate[0].candidateId, cnd.objectId);
                        cnd.objectId = resItem.attachCandidate[0].candidateId;
                        // tempArry.push({
                        //     ...cnd, objectId: resItem.attachCandidate[0].candidateId
                        // })
                    }
                    // return cnd
                })
            })
        }
        // console.log('old update', tempArry);
        dispatch(CI_InitialStateAction('selected_candidates_ces_to_local', { candidates: oldCandidates }));
        // performRowKeyIndexing()
    }, [newAddedCandidate]);
    // if (localJobGet) {
    //     handleJobs('getAttachedCandidates', accessToken, '', selectedJobId)
    //         .then(res => {
    //             console.log(res);
    //             setNewAddedCandidate(res.data.data.response)
    //         });
    // }
    useEffect(() => {
        /*
        Display selected candidates inside table with check boxes chekced or selected. 
        */
        // console.log(interviewState.attachedCandidatesJobId, selectedJobId);
        /*
        if (interviewState.attachedCandidatesJobId !== selectedJobId) {
            //  console.log('1st');
            setSelectedCandidatesChecked([]);
            return;
         }
         //isLocalJob
         if (pageContext === 'candidates_list' && (candidatesList.length || cesCandidateList.length)) {
             const selectedCandidates = interviewState.selectedCandidates;
             const checkedIndexesDataGrid = [];
             // const Data = ['ravil', 'sigh']
             // const testing = ([...checkedIndexesDataGrid, ...Data]);
             // console.log('checkedIndexesDataGrid', testing);
             for (let i = 0; i < selectedCandidates.length; i++) {
                 let id = '';  // Candidate id
                 if (!isLocalJob) {
                     id = selectedCandidates[i].objectId;
                 }
                 else {
                     id = selectedCandidates[i].attachCandidate[0].candidateId;
                 }
                 //  const id = selectedCandidates[i].attachCandidate[0].candidateId;
                 for (let j = 0; j < candidatesListRow.length; j++) {
                     // console.log('checkedIndexesDataGrid', candidatesListRow[j]);
                     if (isLocalJob) {
                         if (!candidatesListRow[j].isMailSend) {
                             if (candidatesListRow[j].item.attachCandidate[0].candidateId === id) {
                                 checkedIndexesDataGrid.push(j);
                             }
                         }
                     }
                     else {
                         if (candidatesListRow[j].item.objectId === id) {
                             checkedIndexesDataGrid.push(j);
                         }
                     }
                 }
             }
             setSelectedCandidatesChecked(checkedIndexesDataGrid)
            //  console.log(checkedIndexesDataGrid, 'checkedIndexesDataGrid');
         }
         */
    }, [pageContext, candidatesList, selectedJobId]);
    // console.log('selectedCandidatesChecked', selectedCandidatesChecked);
    // const handleChange = (event) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     console.log(value);
    //     const selectedJobDetail = jobsList.filter((job, i) => job.jobId === value)[0];
    //     // console.log(selectedJobDetail);
    //     dispatch(CI_InitialStateAction('selected_job_id', selectedJobDetail))
    //     // dispatch(CI_InitialStateAction('selected_job_id', { id: value, jobName: selectedJobDetail.jobName, isLocalJob: selectedJobDetail.isLocal }))
    //     setSelectedJobId(value);
    //     setNewCandidateAdded(true);
    //     // const jobname = jobsList.filter((job, i) => job.jobId === value[0])[0].jobName;
    //     // dispatch(CI_InitialStateAction('selected_job_id', { id: value[0], jobName: jobname }))
    //     // setSelectedJobId(value);
    // };
    const handleChange = (value) => {
        //console.log(value);
        const selectedJobDetail = jobsList.filter((job, i) => job.jobId === value.jobId)[0];
        // console.log(selectedJobDetail);
        dispatch(CI_InitialStateAction('selected_job_id', selectedJobDetail))
        // dispatch(CI_InitialStateAction('selected_job_id', { id: value, jobName: selectedJobDetail.jobName, isLocalJob: selectedJobDetail.isLocal }))
        setSelectedJobId(selectedJobDetail.jobId);
        setNewCandidateAdded(true);
        // const jobname = jobsList.filter((job, i) => job.jobId === value[0])[0].jobName;
        // dispatch(CI_InitialStateAction('selected_job_id', { id: value[0], jobName: jobname }))
        // setSelectedJobId(value);
        setIsGridBoxOpened(false)
    };
    function getCheckedListCes(array1, array2) {
        return array1.filter(object1 => {
            return array2.some(object2 => {
                return object1.candidate.email === object2.attachCandidate[0].candidateEmail;
            });
        });
    };
    //console.log('merged', merged);
    function getCheckedList(array1, array2) {
        return array1.filter(object1 => {
            return array2.some(object2 => {
                return object1.attachCandidate[0].candidateEmail === object2.candidate.email;
            });
        });
    }
    // console.log(getData(candidatesList, cesCandidateList));
    function getNonCheckedList(array1, array2) {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.candidate.email === object2.attachCandidate[0].candidateEmail;
            });
        });
    };
    function getCandidateInfo() {
        let tempArr = [];
        let data = [];
        let data1 = [];
        if (isLocalJob) {
            candidatesList.forEach((item, index) => {
                // console.log('local candidate', item);
                const candidate = item.attachCandidate[0];
                let isMailSend = candidate.mailSend ? true : false;
                let isChecked = candidate.isChecked ? true : false;
                tempArr.push({ id: index, Name: candidate.candidateName, Email: candidate.candidateEmail, 'Contact Number': candidate.candidateNumber, isMailSend: isMailSend, isChecked: isChecked, item })
            })
        }
        else if (!isLocalJob && isLocalJob != undefined && cesCandidateList.length > 0) {
            {
                // getCheckedListCes(cesCandidateList, candidatesList).map((item, index) => {
                //     // console.log("item of list", item);
                //     // data.push({ id: index, Name: item.attachCandidate[0].candidateName ? item.attachCandidate[0].candidateName : '', Email: item.attachCandidate[0].candidateEmail, 'Contact Number': item.attachCandidate[0].candidateNumber ? item.attachCandidate[0].candidateNumber : 'N/A', isChecked: item.attachCandidate[0].isChecked, isMailSend: item.attachCandidate[0].mailSend, item })
                //     data.push({ id: index, Name: item.candidate.name ? item.candidate.name : '', Email: item.candidate.email, 'Contact Number': item.candidate.mobileNumber ? item.candidate.mobileNumber : '', isChecked: item.attachCandidate[0].isChecked, isMailSend: item.attachCandidate[0].mailSend, item })
                // })
                const checkedData = getCheckedListCes(cesCandidateList, candidatesList);
                for (let i = 0; i < checkedData.length; i++) {
                    merged.push({
                        ...checkedData[i],
                        ...(candidatesList.find((itmInner) => itmInner.attachCandidate[0].candidateEmail === checkedData[i].candidate.email
                        ))
                    }
                    );
                }
                // console.log(merged.length);
                if (merged.length > 0) {
                    merged.forEach((item, index) => {
                        //  console.log(item, "item of list");
                        data.push({ id: index, Name: item.candidate.name ? item.candidate.name : '', Email: item.candidate.email, 'Contact Number': item.candidate.mobileNumber ? item.candidate.mobileNumber : '', isChecked: item.attachCandidate[0].isChecked, isMailSend: item.attachCandidate[0].mailSend, item })
                    })
                }
            }
            {
                {
                    getNonCheckedList(cesCandidateList, candidatesList).map((item, index) => {
                        data1.push({ id: data.length + index, Name: item.candidate.name ? item.candidate.name : '', Email: item.candidate.email, 'Contact Number': item.candidate.mobileNumber ? item.candidate.mobileNumber : '', item })
                    })
                }
            }
            // if (!isLocalJob) {
            //     data.forEach((item, index) => {
            //         console.log('first list',item);
            //         data1 = [...data1].map((item1, index1) => {
            //             console.log('real-list', item1);
            //             if (item.Email === item1.Email) {
            //                 return { id: index1, ...item1, isChecked: true, mailSend: false }
            //             }
            //             return { ...item1, id: index1 }
            //         })
            //     })
            tempArr = [...data, ...data1];
        }
        // cesCandidateData().length>1)
        // console.log(getCheckedList(cesCandidateList, candidatesList));
        // getCheckedList(cesCandidateList, candidatesList), getNonCheckedList(cesCandidateList, candidatesList)
        // console.log('check_the-list', isLocalJob, data1);
        // console.log(tempArr);
        // }
        return tempArr;
    };
    // console.log('list', cesCandidateList, candidatesList);
    const handlePageNavigation = (go) => {
        if (go === +1 && pageContext === 'select_job' && (interviewState.interviewType === '' || selectedJobId === '')) {
            setPageContextError('Please select Job and Interview type');
            setTimeout(() => {
                setPageContextError('');
            }, 2000);
            return;
        }
        else if (go === +1 && pageContext === 'candidates_list' && !interviewState.selectedCandidates.length) {
            setPageContextError('Please select Candidates');
            setTimeout(() => {
                setPageContextError('');
            }, 2000);
            return;
        }
        else if (go === +1 && pageContext === 'build_questions') {
            let questionsExist = 1;
            const questionSet = interviewState.questionSet
            const questionSetLen = questionSet.length;
            for (let i = 0; i < questionSetLen; i++) {
                if (questionSet[i].questionsetId === interviewState.selectedQuestionSetId && !questionSet[i].questions.length) {
                    questionsExist = 0;
                    break;
                }
            }
            if (!interviewState.selectedQuestionSetId) {
                setPageContextError('Please select a Question set');
                setTimeout(() => {
                    setPageContextError('');
                }, 2000);
                return;
            }
            else if (interviewState.selectedQuestionSetId && !questionsExist) {
                setPageContextError('Please add questions into this question set!');
                setTimeout(() => {
                    setPageContextError('');
                }, 2000);
                return;
            }
            else if (!interviewState.questionsAttached) {
                setPageContextError('Please wait for questions to load');
                setTimeout(() => {
                    setPageContextError('');
                }, 2000);
                return;
            }
        }
        else if (go === +1 && pageContext === 'set_timeline') {
            if (!isLocalJob) {
                // setCesToLocal(true);
                cesJobToLocalJOb();
                //console.log('next button called', go);
            }
            setPageContextError('Please select valid Timeline');
            setTimeout(() => {
                setPageContextError('');
            }, 2000);
            const { interviewDateFrom, interviewDateTo, interviewTimeFrom, interviewTimeTo, timeZone } = interviewState.interviewTimeLineInfoState
            if (interviewDateFrom == '' || interviewDateTo == '' || interviewTimeFrom == '' || interviewTimeTo == '' || !Object.keys(timeZone).length || interviewState.time_duration === 'Select valid duration') {
                return;
            }
        }
        else if (go === -1 && pageContext === 'Send_Email') {
            setInterviewScheduleInfo({ state: 'idle', sentStatus: 'idle', text: '' })
        }
        setPageContextError('');
        let cal = go + pageNavigation;
        //  console.log(cal);
        if (cal === 6) {
            setProceedToSendMail(true);
        }
        if (cal >= 1 && cal <= 5) {
            setPageNavigation(cal)
            setProceedToSendMail(false);
        }
    };
    const { interviewType } = interviewState;
    const history = useNavigate();
    function createJobRedirect() {
        history('/job/create-job', { state: jobTypeRedirect });
    }
    useEffect(() => {
        if (jobTypeRedirect !== '') {
            createJobRedirect()
        }
    }, [jobTypeRedirect]);
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = (candidatesListRow) => {
        const ws = XLSX.utils.json_to_sheet(candidatesListRow);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'myfile' + fileExtension);
    };
    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#e0e0e0"
        },
        border: "1px solid #f0f0f0",
        "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: `2px solid #f0f0f0`,
            borderLeft: `1.5px solid #f0f0f0`,
        },
        // "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        //     borderLeft: `1.5px solid #f0f0f0`
        // },
        "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1.5px solid #f0f0f0`,
            borderTop: `1px solid #f0f0f0`
        },
        // "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
        //     borderTop: `1px solid #f0f0f0`
        // },
    }));
    function handleJobView(e) {
        setJobValue(e.target.value);
    }
    // console.log('tableLoaderErrInfoTEST', tableLoaderErrInfoTEST);
    // console.log('interviewState', interviewState,
    //     //candidatesListRow.length, tableLoaderErrInfo, interviewScheduleInfo
    // );
    function selectedRowKeys(e) {

        // setSelectedRowKey(e.selectedRowKeys);
        // selectedRowsKeysDataRef.current = e.selectedRowsData;
        const selectedRowsDataCandidateIds = e.selectedRowsData.map(candidate => {

            if (candidate.item.attachCandidate) {
                return candidate.item.attachCandidate[0].candidateId;
            }
            else if (candidate.item.objectId) {
                return candidate.item.objectId;
            }
        });
        datagridCandidateListSelectedRef.current = [];

        dispatch(CI_InitialStateAction('selected_candidates', { candidates: e.selectedRowsData, attachedCandidatesJobId: selectedJobId, selectedCandidatesDGRowGrid: selectedRowsDataCandidateIds }));
    }
    const customChanges = {
        icon: 'undo',
        text: 'Clear',
        type: 'default',
        onClick: () => setResetCall('active')
    }
    const cancelCustomchanges = {
        text: 'Close',
        icon: 'remove',
        type: 'danger',
        onClick: () => { setClearCustomizationPopUp(false) },
    }
    function gridBoxDisplayExpr(item) {
        return item && `${item.jobName}`
    }
    function onGridBoxOpened(e) {

        if (e.name === "opened") {
            setIsGridBoxOpened(
                e.value
            )
        }
    }
    function syncDataGridSelection(e) {

        setGridBoxValue(e.value)
    }
    function dataGridOnSelectionChanged(e) {

        if (e.selectedRowKeys.length) {
            if (e.selectedRowKeys[0].jobStatus === "Active" || e.selectedRowKeys[0].jobStatus === "Reactivated" || e.selectedRowKeys[0].jobStatus == undefined) {
                handleChange(e.currentSelectedRowKeys[0])

                // setGridBoxValue(e.currentSelectedRowKeys[0])
                setGridBoxValue(e.currentSelectedRowKeys);
            }
        }
    }

    function onEditorPreparing(e) {

        if (newCandidateAdded) {
            // dataGridRef.current.instance.refresh()
            //alert();
        }
        if (e.row != (undefined || null)) {
            let candidateCollectionObj = '';
            let candidateIdKeyName = '';
            if (e.row.data.item.attachCandidate != undefined) {
                if (e.row.data.item.attachCandidate[0].mailSend || e.row.data.item.attachCandidate[0].isChecked) {
                    if (!selectedRowKeyMailSend.current.includes(e.row.key)) {
                        selectedRowKeyMailSend.current.push(e.row.key);
                    }
                    e.editorOptions.disabled = true;
                    e.editorOptions.showValidationMark = true
                    // e.editorOptions.focusStateEnabled = true
                }
                const selectedRowIndexes = [];
                //candidateIdValue = e.row.data.item.attachCandidate[0].candidateId;
                candidateIdKeyName = 'candidateId';
                candidateCollectionObj = 'attachCandidate';
            }
            else if (e.row.data.item.objectId != undefined) {
                // console.log(e.row, e.row.data.item.objectId, 'urtwjhqeg');
                // candidateIdValue = e.row.data.item.objectId;
                candidateIdKeyName = 'objectId';
                candidateCollectionObj = 'attachCandidatenull';
            }
            if (candidateIdKeyName != '') {
                let candidateIDUnique = '';
                let candidateIDSortUnique_a = '';
                let candidateIDSortUnique_b = '';
                let IDOfCandidate = '';
                if (candidateCollectionObj === 'attachCandidate') {
                    IDOfCandidate = e.row.data.item.attachCandidate[0][candidateIdKeyName];
                }
                else {
                    IDOfCandidate = e.row.data.item.objectId;
                }
                if (!datagridCandidateListRef.current.keysUnique.includes(IDOfCandidate)) {
                    datagridCandidateListRef.current.keysUnique.push(IDOfCandidate);
                    let x = [...datagridCandidateListRef.current.array, e.row];
                    x.sort((a, b) => {
                        if (candidateCollectionObj === 'attachCandidate') {
                            candidateIDSortUnique_a = a.data.item.attachCandidate[0][candidateIdKeyName];
                            candidateIDSortUnique_b = b.data.item.attachCandidate[0][candidateIdKeyName];
                        }
                        else {
                            candidateIDSortUnique_a = a.data.item.objectId;
                            candidateIDSortUnique_b = b.data.item.objectId;
                        }
                        if (candidateIDSortUnique_a < candidateIDSortUnique_b) return 1;
                        else if (candidateIDSortUnique_a > candidateIDSortUnique_b) return -1;
                        return 0;
                    })
                    datagridCandidateListRef.current.array = x;
                }
                let datagridItemFilteredCandidateObj = '';
                interviewState.selectedCandidatesDGRowGrid.forEach(item => {
                    datagridCandidateListRef.current.array.forEach(datagridItem => {
                        if (candidateCollectionObj === 'attachCandidate') {
                            if (datagridItem.data.item.attachCandidate != undefined) {
                                datagridItemFilteredCandidateObj = datagridItem.data.item.attachCandidate[0][candidateIdKeyName];
                            }
                            else {
                                datagridItemFilteredCandidateObj = datagridItem.data.item.objectId;
                            }
                            //IDOfCandidate = e.row.data.item.attachCandidate[0][candidateIdKeyName];
                        }
                        else {
                            datagridItemFilteredCandidateObj = datagridItem.data.item.objectId;
                            //IDOfCandidate = e.row.data.item.objectId;
                        }
                        if (datagridItemFilteredCandidateObj === item) {
                            if (!datagridCandidateListSelectedRef.current.includes(datagridItem.key)) {
                                datagridCandidateListSelectedRef.current.push(datagridItem.key);
                                // console.log(datagridItem, 'datagridItem');
                            }
                        }
                    });
                });
            }
        }
    }
    function onEditorPrepared(e) {
    }

    function handleSelectUnSelect(e) {
        const checkboxRole = e.cellElement.attributes.getNamedItem('role').value;
        if (checkboxRole === 'columnheader' && dataGridHeaderCheckboxSelectionRef.current === true) {
            dataGridHeaderCheckboxSelectionRef.current = false;
            dataGridRef.current.instance.deselectAll();
        }
        else {
            dataGridHeaderCheckboxSelectionRef.current = true;
        }
    }



    React.useEffect(() => {
        //performRowKeyIndexing();
    }, [interviewState.selectedCandidatesDGRowGrid]);
    React.useEffect(() => {
        datagridCandidateListRef.current = { keysUnique: [], array: [] };
        datagridCandidateListSelectedRef.current = [];
        // if (dataGridRef.current != null) {
        //     // dataGridRef.current.instance.selectRows([1, 3])
        // }
        //  performRowKeyIndexing();
    }, [newCandidateAdded]);
    function performRowKeyIndexing() {
        // setSelectedRowKeysCurrentState()
        datagridCandidateListRef.current.array.sort((a, b) => {
            if (a.data.item.attachCandidate[0].candidateId < b.data.item.attachCandidate[0].candidateId) return 1;
            else if (a.data.item.attachCandidate[0].candidateId > b.data.item.attachCandidate[0].candidateId) return -1;
            return 0;
        }
        )
        const selectedRowKeysCurrentStateLocal = [];
        interviewState.selectedCandidatesDGRowGrid.forEach(item => {
            datagridCandidateListRef.current.array.forEach(datagridItem => {
                if (datagridItem.data.item.attachCandidate[0].candidateId === item) {
                    if (!selectedRowKeysCurrentStateLocal.includes(datagridItem.rowIndex)) {
                        selectedRowKeysCurrentStateLocal.push(datagridItem.rowIndex);
                    }
                }
            });
            // if (e.row.data.item.attachCandidate[0].candidateId === item) {
            //     console.log(e, e.row, 'e.row');
            //     // setSelectedRowKeysCurrentState(prevState => [...prevState, e.row.rowIndex])
            //     // selectedRowIndexes.push();
            //     //selectedRowsKeysDataRef.current.push(e.row.rowIndex);
            //     // e.component.selectRowsByIndexes(selectedRowsKeysDataRef.current);
            //     e.component.selectRowsByIndexes(e.row.rowIndex)
            // }
        });
        setSelectedRowKeysCurrentState(selectedRowKeysCurrentStateLocal)
    }
    function getStatusColor(data) {
        // console.log(data, 'idfuu');
        let color = '';
        if (data.isMailSend || data.isChecked) {
            return color = '#adadad'
        }
        else {
            color = '#0074b8'
        }
        return color
    };
    function setStatus(e) {
        return <div style={{ color: getStatusColor(e.data) }}>{e.value}</div>
    };
    function deleteCellHandler(e) {
        handleDeleteCandidate(deleteCandidateIdRow.current)
        setDeleteDilogOpen(false);
    }
    function CellColorHandler(params) {

        let color = '';
        if (params.data.jobStatus === "Close") {
            return color = "#a0a0a0"
        }
        // if (params.data.jobStatus === "Close") {
        //     return <>
        //      <IconButton> <CloseIcon></CloseIcon>  </IconButton>
        //     </>
        // }
    }
    function dataGridRender() {
        return (
            <div className="dropdown-job-selector-DG">
                <DataGrid
                    dataSource={jobsList}
                    // columns={gridColumns}
                    hoverStateEnabled={true}
                    selectedRowKeys={gridBoxValue}
                    onSelectionChanged={dataGridOnSelectionChanged}
                    height="104%"
                    // noDataText={tableLoaderErrInfoTEST.select_job === 'No record found' ? 'No Data' : 'Loading'}
                    noDataText={tableLoaderErrInfoTEST.select_job}
                    onContentReady={(e) => {
                        let dx_datagrid_nodata = document.querySelector('.dropdown-job-selector-DG .dx-datagrid-nodata')
                        if (e.component.totalCount() === 0 || e.component.totalCount()) {
                            dx_datagrid_nodata.innerText = 'No Record Found'
                        }
                        else {
                            dx_datagrid_nodata.innerText = ''
                        }
                    }}
                >
                                        <StateStoring
                        enabled={true}
                    />
                    <FilterRow visible={true} />
                    <Column dataField="jobName" allowSorting={false} width={'550px'}
                        cellRender={(data) => {
                            return <>
                                <div style={{ color: CellColorHandler(data), fontWeight: '450' }} title={'Created by ' + data.data.createdBy}>
                                    {
                                        data.data.isLocal ?
                                            data.data.jobName + (data.data.jobCode ? ' (' + data.data.jobCode + ')' : '') : data.data.jobName + ' ' + '(' + data.data.jobCode + ')' + ' (ATS Job)'}
                                    {
                                        data.data.jobStatus == "Close" ? "  (Job Closed)" : ''
                                    }
                                </div >
                            </>
                        }}
                    />

                    {/* <Column dataField="jobsList"></Column> */}
                    <Selection mode="single" />
                    <Scrolling mode="virtual" />
                    <Paging enabled={true} pageSize={10} />

                    <LoadPanel visible={(!jobsList.length ? true : false)} />
                </DataGrid>
            </div >
        );
    }
    function cellOpen(e) {
        deleteCandidateIdRow.current = e.row.data.item.attachCandidate[0].candidateId;
        setDeleteDilogOpen(true)
    }
    function closeHandler() {
        setDeleteDilogOpen(false)
    }
    const refresh = () => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.refresh();
        }
    }

    const clearFilters = (e) => {
        // console.log(e, 'eysk')
        if (dataGridRef.current) {
            dataGridRef.current.instance.clearFilter();
        }
    };

    // change
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Alert"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={() => {
                        handleDeleteCandidate(deleteId.current)
                    }} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div>
                <Dialog
                    open={deleteDilogOpen}
                    // TransitionComponent={Transition}
                    keepMounted
                    onClose={closeHandler}
                    aria-describedby="alert-dialog-slide-description"
                    style={{
                        backdropFilter: 'blur(4px)', backgroundColor: 'rgba(111, 126, 140, 0.2)', transition: 'opacity 100ms ease 0s', zIndex: 1600
                    }}
                >
                    <DialogTitle style={{ background: '#3aa1f0', marginBottom: '3rem', color: "white" }}>{"Alert"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to delete this record?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            deleteCellHandler();
                            setYesLoading(true)
                        }} color='success' variant="contained">
                            Yes {
                                Yesloading && <Box sx={{ display: 'flex', m: 1.5, position: 'relative' }}>
                                    <CircularProgress size={24}
                                        sx={{
                                            color: 'wheat',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }} />
                                </Box>
                            }
                        </Button>
                        <Button color='error' variant="contained" onClick={closeHandler} >No</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Box>
                {
                    pageContextError && <>
                        <Box sx={{ width: '94%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', zIndex: 2, top: '72px', }}>
                            <Alert className='page-context-error' severity="error" sx={{ width: '35%', boxShadow: '2px 2px 2px #c5c0c0' }} action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setPageContextError('')
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>
                                <AlertTitle>{pageContextError}</AlertTitle>
                            </Alert>
                        </Box>
                    </>
                }
                {
                    pageContext === 'select_job' && <Box
                        sx={{
                            width: 'auto',
                            height: '75vh',
                            boxShadow: 4,
                        }}
                        className='create-interview-main-div'
                    >
                        <div className='job-selection-header'>
                            <Box>
                                <h1 style={headingTxtColor} className='job-interview-type-heading'>Job And Interview Type</h1><hr className='jsh-divider' />
                            </Box>
                            <div style={{ display: 'inline-flex' }}>
                                <div>
                                    {/* <IconButton> <img src={plus} /> </IconButton> */}
                                    <FormControl sx={{ width: '150px' }} >
                                        <InputLabel id="demo-simple-select-label">Create Job</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={jobTypeRedirect}
                                            label="Please Select Job From"
                                            onChange={(e) => setJobTypeRedirect(e.target.value)}
                                        >
                                            <MenuItem value='local_job'>Create Local Job</MenuItem>
                                            <MenuItem value='crm_job'>Create Job From CRM</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className='main-div-select-job'>
                            <Box sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center'
                            }}>
                                {/* 
<Box
      sx={{
        width: 500,
        maxWidth: '100%',
      }}
    >
      <TextField fullWidth label="fullWidth" id="fullWidth" />
    </Box> */}
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: '50px' }}>
                                            <Box sx={{ display: 'flex', margin: '0 0 25px 0' }}>
                                                <span className='select-job' style={headingTxtColor}>Select Job</span>
                                            </Box>
                                            <div className="main-dropDownBox">
                                                <div className="dropdown-job-selector">
                                                    <DropDownBox
                                                        // height="50%"
                                                        value={gridBoxValue}
                                                        opened={isGridBoxOpened}
                                                        valueExpr="clientName"
                                                        deferRendering={false}
                                                        displayExpr={() => {
                                                            return jobName
                                                        }}
                                                        placeholder="Please Select a Job..."
                                                        showClearButton={false}
                                                        dataSource={jobsList}
                                                        onValueChanged={syncDataGridSelection}
                                                        onOptionChanged={onGridBoxOpened}
                                                        contentRender={dataGridRender}
                                                    />
                                                </div>
                                            </div>
                                            {/* <Box sx={{ width: '80%' }} > */}
                                            {/* <Autocomplete
                                                // disablePortal
                                                // id="combo-box-demo"
                                                // options={jobsList}
                                                //value={jobName}
                                                // sx={{ width: 300}}
                                                // getOptionLabel={(option) => option['jobName'] || option}
                                                // onChange={(event, newValue) => {
                                                //     // console.log(event, newValue.jobId);
                                                //     handleChange(newValue)
                                                // }}
                                                loading={!jobsList.length ? true : false}
                                                loadingText={ApplicationLoaderErrInfo === "no-record-found" ? "Record not found" : "Loading... "}
                                                defaultValue={interviewState.selectedJob}
                                                clearIcon={false}
                                                renderInput={(params) => <TextField {...params} label="Please select Job" />}
                                                disablePortal
                                                id="combo-box-demo"
                                                // getOptionLabel={(option) => option.name}
                                                getOptionLabel={(option) => (option.jobName ? option.jobName : '')}
                                                options={jobsList}
                                                sx={{ width: 300 }}
                                                renderOption={(props, option) => {
                                                    return <Box component="li" {...props} key={option.jobId != undefined ? option.jobId + props['data-option-index'] : props['data-option-index']}>
                                                        {option.jobName}{option.isLocal ? '' : ' (ATS Job)'}
                                                    </Box>
                                                }}
                                                onChange={(event, newValue) => {
                                                    // console.log(event, newValue.jobId);
                                                    handleChange(newValue)
                                                }}
                                            // renderInput={(params) => <TextField {...params} label="Movie" />}
                                            /> */}
                                            {/* <TextField fullWidth id="fullWidth" label ="Please Select Job">   */}
                                            {/* <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple={false}
                                                value={selectedJobId}
                                                onChange={handleChange}
                                                input={<OutlinedInput label="Test" />}
                                                MenuProps={MenuProps}
                                                className='job-selection-box-sm'
                                            >
                                                {
                                                    jobsList.map((item, i) => {
                                                        return <MenuItem
                                                            key={i}
                                                            value={item.jobId}
                                                            style={getStyles(item.jobId, selectedJobId, theme)}
                                                            selected={item.jobName === 'AWS'}
                                                        >
                                                            {item.jobName}
                                                            {!item.isLocal && ' (ATS Job)'}
                                                        </MenuItem>
                                                    })
                                                }
                                                {tableLoaderErrInfoTEST.select_job === 'loading' && <Loader className='preloader-icon' />}
                                                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '50px 0' }}>{tableLoaderErrInfoTEST.select_job}</Box>
                                            </Select> */}
                                            {/* </TextField>  */}
                                            {/* </Box> */}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} className='interview-type-selector-sm'>
                                            <Box sx={{ display: 'flex', margin: '0 0 25px 0' }}>
                                                <InputLabel className='labelModify' sx={{ marginLeft: '34px', fontWeight: 'bold' }}>Interview Type</InputLabel>
                                                {/* <span className='interview-type-label'>Interview type</span> */}
                                            </Box>
                                            <Box>
                                                <FormControl>
                                                    {/* <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: 'rgb(74, 74, 74)', fontWeight: '600' }}>Interview type</FormLabel> */}
                                                    <RadioGroup className='drop'
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        onChange={(e) => dispatch(CI_InitialStateAction('interviewType', e.target.defaultValue))}
                                                    >
                                                        <FormControlLabel sx={{ mr: 5 }} value="one_way_interview" control={<Radio checked={interviewType === 'one_way_interview'} />} label="One way interview" />
                                                        {/* <FormControlLabel value="live_interview" control={<Radio checked={interviewType === 'live_interview'} />} label="Live interview" /> */}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Divider sx={{ width: '95%', mt: 8 }} variant="middle" />
                            </Box>
                        </div>
                    </Box >
                }
                {
                    pageContext === 'candidates_list' && <Box sx={{
                        width: 'auto',
                        height: '75vh',
                        boxShadow: 4,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}
                        className='candidate-list-main-div'
                    >
                        <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 0 0 40px' }}>
                            <h1 className='candidate-list-m-d-header' style={{ color: "rgb(74, 74, 74)" }}>&nbsp;Job :&nbsp; <span style={{ color: '#1890ff' }}>{jobName}</span></h1>
                            {isLocalJob && <Addcandidatepopup selectedJobId={selectedJobId} jobName={jobName} setNewCandidateAdded={setNewCandidateAdded} />}
                            {/* <div className='download-btn'>
                                <Box style={{ right: '10px', float: 'right' }}>
                                    <Tooltip title='Export Data' placement="top">
                                        <IconButton onClick={(e) => exportToCSV(candidatesListRow)}  ><FileDownloadIcon /></IconButton>
                                    </Tooltip>
                                </Box>
                            </div> */}
                            {
                                // !isLocalJob &&
                                //     <FormControl>
                                //         {/* <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: 'rgb(74, 74, 74)', fontWeight: '600' }}>Interview type</FormLabel> */}
                                //         <RadioGroup className='drop'
                                //             row
                                //             aria-labelledby="demo-controlled-radio-buttons-group"
                                //             name="controlled-radio-buttons-group"
                                //             value={jobValue}
                                //             onChange={handleJobView}
                                //         >
                                //             <FormControlLabel sx={{ mr: 5 }} value="local_job" control={<Radio />} label="Local Candidate" />
                                //             <FormControlLabel value="ces_job" control={<Radio />} label="CES candidate" />
                                //         </RadioGroup>
                                //     </FormControl>
                            }
                        </div>
                        <hr />
                        {/* <Box sx={{
                            width: '95%', height: '62vh', boxShadow: 4, ml: 5,
                        }} className='candidate-list-main-div-inner'>
                            <div style={{ height: '100%', width: '100%' }} className='candidate-list-main-d-i-child'>
                                <Box sx={{
                                    width: '100%', height: '100%',
                                    '& .super-app-theme--row-disabled': {
                                        bgcolor: (theme) =>
                                            'white',
                                        color: (theme) =>
                                            '#bfbfbf',
                                        '&:hover': {
                                            bgcolor: (theme) =>
                                                'white',
                                        },
                                    },
                                    '& .super-app-theme--is-checked': {
                                        bgcolor: (theme) =>
                                            '#e1fcee',
                                        color: (theme) =>
                                            '#9a7171',
                                     
                                        },
                                }}>
                                    <StyledDataGrid
                                        page={DGPageNumber}
                                        onPageChange={(num) => {

                                            setDGPageNumber(num)
                                      
                                        }}
                                        className='candidate-list-data-grid'
                                        loading={tableLoaderErrInfoTEST.candidates_list === 'loading'}
                                        components={{
                                            NoRowsOverlay: () => (
                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                    {tableLoaderErrInfoTEST.candidates_list}
                                                </Stack>
                                            )
                                        }}
                                        rows={candidatesListRow}
                                        columns={columns}
                                       
                                        pageSize={pageSize}
                                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        checkboxSelection
                                      
                                        disableSelectionOnClick
                                   
                                        onSelectionModelChange={(newSelection) => {
                                            const rows = [];
                                            candidatesListRow.forEach((candidate, index) => {
                                                if (newSelection.includes(index)) {
                                                    if (candidate.isMailSend != undefined) {
                                                        rows.push(index);
                                                    }
                                                    else if (!candidate.isMailSend) {
                                                        rows.push(index);
                                                    }
                                                }
                                            })
                                            setSelectedCandidatesChecked(rows);
                                            const selectedIDs = new Set(rows);
                                            const selectedRows = candidatesListRow.filter((row) =>
                                                selectedIDs.has(row.id),
                                            );
                                            dispatch(CI_InitialStateAction('selected_candidates', { candidates: selectedRows, attachedCandidatesJobId: selectedJobId }));
                                        }}
                                        selectionModel={selectedCandidatesChecked}
                                        getRowClassName={(params) => {
                                            if (params.row.isMailSend) {
                                                return `super-app-theme--row-disabled`
                                            }
                                         
                                        }}
                                        isRowSelectable={(params) => {
                                            if (params.row.isMailSend) {
                                                return false;
                                            } else {
                                                return true
                                            }
                                        }}
                                    />              
                                                      </Box>
                            </div>
                        </Box> */}
                        <Popup
                            visible={clearCustomizationPopUp}
                            // onHiding={hideInfo}
                            dragEnabled={false}
                            hideOnOutsideClick={true}
                            showCloseButton={false}
                            showTitle={true}
                            container="body"
                            width={500}
                            height={200}
                            title="Alert"
                        >
                            <Position
                                my="center"
                                of={'body'}
                                collision="fit"
                            />
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                options={customChanges}
                            />
                            <ToolbarItem
                                widget="dxButton"
                                toolbar="bottom"
                                location="after"
                                options={cancelCustomchanges}
                            />
                            <p>Your custom changes made by you on grid will be removed..</p>
                        </Popup>


                        <DataGrid
                            className='candidate-list-area'
                            ref={dataGridRef}
                            dataSource={candidatesListRow}
                            keyExpr="id"
                            key="Candidate name"
                            height="600px"
                            hoverStateEnabled
                            noDataText={tableLoaderErrInfoTEST.candidates_list}
                            showBorders={true}
                            showRowLines
                            allowColumnResizing={true}
                            onEditorPreparing={onEditorPreparing}
                            onEditorPrepared={onEditorPrepared}
                            onCellClick={handleSelectUnSelect}
                            onSelectionChanged={(e) => {

                                selectedRowKeys(e)
                                // console.log(selectedRowKeyMailSend.current, 'ttttttttttttttttttt');
                                //setSelectedCandidatesChecked
                                //e.component.deselectRows(selectedRowKeyMailSend.current);
                                let deselectRowKeys = [];
                                e.selectedRowsData.forEach((item) => {

                                    if (item.isMailSend != undefined) {
                                        if (item.isMailSend === true || item.isChecked === true) {
                                            deselectRowKeys.push(e.component.keyOf(item));
                                        }
                                    }
                                    if (deselectRowKeys.length) {
                                        e.component.deselectRows(deselectRowKeys);
                                    }
                                    // if (!this.isSelectable(item))
                                    //   deselectRowKeys.push(e.component.keyOf(item));
                                });
                            }}
                            // disabled={() => {
                            //     if (candidatesListRow.isMailSend) {
                            //     }
                            // }
                            // }
                            allowColumnReordering={true}
                            //  onRowValidating={true}
                            // activeStateEnabled={true}
                            rowAlternationEnabled={true}
                            // selectedRowKeys={selectedRowKeysCurrentState}
                            onContentReady={(e) => {
                                let dx_datagrid_nodata = document.querySelector('.candidate-list-area .dx-datagrid-nodata')
                                if (e.component.totalCount() === 0 || e.component.totalCount()) {
                                    dx_datagrid_nodata.innerText = 'No Record Found'
                                }
                                else {
                                    dx_datagrid_nodata.innerText = ''
                                }
                                dataGridRef.current.instance.selectRows(datagridCandidateListSelectedRef.current);
                            }}

                            // forceUpdate={true}
                            columnMinWidth={50}
                            width="100%"
                        >
                            <StateStoring
                                enabled={true}
                                type="custom"
                                customLoad={loadState}
                                customSave={saveState}
                            />
                            {/* <Sorting mode="none"/> */}
                            <Selection mode="multiple" showCheckBoxesMode={'always'} />
                            <Toolbar>
                                <Item>
                                    <div className="pull-right">
                                        <div className="pull-right-child">
                                            <span className="legendLable">
                                                <span className="spanClassCreateInterView"></span>
                                                Available
                                            </span>
                                            <span className="legendLable">
                                                <span className="spanClassCreateInterView1"></span>
                                                Already Sent Mail
                                            </span>

                                        </div>
                                    </div>
                                </Item>
                                <Item>
                                    <DevExtremToolbarClearfilter clearFilters={clearFilters} dataGridRef={dataGridRef} />

                                </Item>
                                <Item location="after">
                                    <DevExButton hint="Refresh" icon="refresh" onClick={() => refresh()} />
                                </Item>
                                <Item name="columnChooserButton" />
                                <Item name="exportButton" />
                                <Item>
                                    {/* <Button2 hint="Claer Filter" icon='filter'  /> */}
                                    <img
                                        title='Clear Customization'
                                        className="clean"
                                        src={clean}
                                        onClick={() => {
                                            setClearCustomizationPopUp(true)
                                        }}
                                    ></img>
                                </Item>
                            </Toolbar>
                            <ColumnChooser enabled={true} />
                            <Export enabled={true} allowExportSelectedData={false} />
                            <Editing
                                // mode="row"
                                useIcons={true}
                                allowDeleting={true}
                            // allowAdding={true}
                            />
                            {/* <Row
                                render={(candidatesListRow) => {
                                    // console.log(candidatesListRow, 'dddaatt');
                                    // const isDisabled = row.isMailSend == 'disable';
                                    // return (<div {...rowProps} disabled={isDisabled}>
                                    //     {rowProps.children}
                                    // </div>
                                    // )
                                }} /> */}
                            <FilterRow visible={true} />
                            <Selection mode="multiple" showCheckBoxesMode={'always'} />
                            <Column width='500px' allowReordering={false} caption='Name' dataField='Name' cellRender={setStatus} editorOptions={{
                                showClearButton: true
                            }} />
                            <Column width='500px' caption='Email ID' dataField='Email' cellRender={setStatus} editorOptions={{
                                showClearButton: true
                            }} />
                            <Column width='440px' caption='Contact Number' dataField='Contact Number' cellRender={setStatus} editorOptions={{
                                showClearButton: true
                            }} />
                            <Column alignment="center" type="buttons" width='110px' caption='Action'
                                cellRender={(e) => {
                                    return <Tooltip title="Delete">
                                        <span><IconButton disabled={e.row.data.isMailSend || e.row.data.isChecked} onClick={() => cellOpen(e)}><DeleteIcon icon='delete' name='delete' title='delete'
                                            sx={isLocalJob ? { color: (e.row.data.isMailSend || e.row.data.isChecked) ? '#a0a0a0' : '#ed6c02' } : { color: 'grey' }}
                                        />
                                        </IconButton></span>
                                    </Tooltip>
                                }}>
                                <Button name='delete' />
                            </Column>
                            <Paging defaultPageSize={20} enabled={true} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={allowedPageSizes} showInfo={true} visible={true} />
                        </DataGrid>
                    </Box>
                }
                {pageContext === 'build_questions' && <Questionnaires />}
                {(pageContext === 'set_timeline' || pageContext === 'Send_Email') && <>
                    <Box sx={{ display: interviewScheduleInfo.state === 'idle' ? 'block' : 'none' }}><SetInterviewTimeLine /></Box>
                    {
                        pageContext === 'Send_Email' && <Box sx={{ display: interviewScheduleInfo.state === 'interview_scheduled_email_send' ? 'block' : 'none' }}><EmailTemplate
                            proceedToSendMail={proceedToSendMail}
                            drawerOpen={props.open}
                            setInterviewScheduleInfo={setInterviewScheduleInfo} /></Box>
                    }
                </>
                }
                {/* {pageContext === 'Send_Email' && <EmailTemplate proceedToSendMail={proceedToSendMail} drawerOpen={props.open} setInterviewScheduleInfo={setInterviewScheduleInfo} />} */}
            </Box>
            <div sx={{ marginTop: '15px', right: '10px', float: 'right', }} id='nav-btn-wrapper' spacing={2}>
                <div className={pageContext === 'build_questions' ? 'global-navigation-button' : ''} style={{ marginTop: '15px', right: '10px', float: 'right', marginBottom: '100px' }} id='nav-btn-inner'>
                    <Button disabled={pageContext === 'select_job' ? true : false} variant="contained" sx={{ background: '#707070', marginRight: '10px' }} onClick={() => handlePageNavigation(-1)} >Back</Button>
                    <Button id="next-btn" variant="contained" disabled={pageContext === 'Send_Email' ? true : false} sx={{ background: '#1890ff' }} onClick={() => handlePageNavigation(+1)}>
                        {
                            pageContext !== 'Send_Email' &&
                            <>
                                {
                                    pageContext === 'set_timeline' ? 'Send Email' : 'Next'
                                }
                            </>
                        }
                        {
                            pageContext === 'Send_Email' && <>
                                {
                                    interviewScheduleInfo.sentStatus === 'email_not_sent_to' && 'Send email'
                                }
                                {
                                    interviewScheduleInfo.sentStatus === 'idle' && 'Send email'
                                }
                                {
                                    interviewScheduleInfo.sentStatus === 'loading' && <Box>Sending email...<CircularProgress size={18} sx={{ color: '#01579b' }} /></Box>
                                }
                                {
                                    interviewScheduleInfo.sentStatus === 'email_sent_to_candidates' && interviewScheduleInfo.text
                                }
                            </>
                        }
                    </Button>
                </div>
            </div>
            <Button className='hidden-icon' sx={{ float: 'right', marginBottom: '40px' }}>
                {pageContext === 'build_questions' &&
                    <>
                        <Button disabled={pageContext === 'select_job' ? true : false} variant="contained" sx={{ background: '#707070', marginRight: '10px' }} onClick={() => handlePageNavigation(-1)} >Back</Button>
                        <Button id="next-btn" variant="contained" disabled={pageContext === 'Send_Email' ? true : false} sx={{ background: '#1890ff' }} onClick={() => {
                            handlePageNavigation(+1)
                        }}>
                            Next
                        </Button>
                    </>
                }
            </Button>
        </>
    );
}
function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {

    const [filterAlert, setfilterAlert] = useState(false);

    return (
        <>
            <img
                title='Clear Filter'
                className="clean"
                src={ClearFiltericon}
                onClick={() => {
                    const isFilterApplied = dataGridRef.current.instance.getCombinedFilter();
                    if (isFilterApplied != undefined) {
                        clearFilters();
                        setfilterAlert(true);
                        setTimeout(() => {
                            setfilterAlert(false);
                        }, 3000);
                    }

                }}
            ></img>



            < SnackBar filterAlert={filterAlert} />
            {/* <SnackBar /> */}
        </>
    )
}

function SnackBar({ filterAlert }) {
    return (
        <>
            {
                filterAlert &&
                <Snackbar
                    open={filterAlert}
                    autoHideDuration={2000}
                    // message="Filter Removed Successfully"
                    // action={action}
                    // variant="filled"
                    variant="outlined"
                    severity="success"
                >
                    <Alert fontSize="small" variant="filled" severity="success">Filter Removed Successfully</Alert>

                </Snackbar>
            }
        </>
    )
}
