// import { AxiosInstanceRun } from "./http.handler";
// import AppConstants from "../Constants"; 

// export async function GetVideo() {
//     console.log(AppConstants.serverConstants);
//     const headers = {
//         'Content-type': 'application/json',
//         'X-API-KEY': `${process.env.REACT_APP_XAPI_KEY}`
//     };
//     return await AxiosInstanceRun.get(`${AppConstants.serverConstants.RUN.GET_VIDEO}?source=videoInterview`, {
//         headers: headers
//     })
//         .then((response) => response
//         )
//         .catch((error) => error);
// };






import { AxiosInstanceRun, AxiosInstanceAzureMedia, AxiosInstance } from "./http.handler";
import AppConstants from "../Constants";

export async function GetVideo(accessToken, isAdmin, recruiterEmailId, pageSize, pageIndex, searchCriteria = '', searchValue = '', filterInterviewsAPILoad = null) {
    const headers = {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    let recruiterInstance = [];
    let searchCriteriaArray = [];
    if (searchCriteria != '' && searchValue != '') {
        searchCriteriaArray.push({
            "key": "contains",
            [searchCriteria]: searchValue
        });
    }
    let order = [
        "startDate",
        "desc"
    ];

    if (filterInterviewsAPILoad != null) {
        const tempSearchCriteriaArray = [];
        for (let key in filterInterviewsAPILoad) {
            if (filterInterviewsAPILoad[key] != '') {
                order = [
                    "startDate",
                    "asc"
                ]
                // console.log(key, filterInterviewsAPILoad[key], 'sghafytWR');
                let keyname = "contains";
                let searchValueText = filterInterviewsAPILoad[key];
                if (key === 'startDate' || key === 'endDate') {
                    keyname = "daterange";
                    searchValueText = filterInterviewsAPILoad[key]
                }
                if (key === "jobName") {
                    tempSearchCriteriaArray.push({
                        "key": "exact",
                        [key]: searchValueText
                    });
                }
                else {
                    tempSearchCriteriaArray.push({
                        "key": keyname,
                        [key]: searchValueText
                    });
                }

            }
        }
        if (tempSearchCriteriaArray.length != 0) {
            if (searchCriteria != '' && searchValue != '') {
                searchCriteriaArray.push(...tempSearchCriteriaArray);
            }
            else {
                searchCriteriaArray = tempSearchCriteriaArray;
            }
        }
    }

    else {
        order = [
            "startDate",
            "desc"
        ]
    }
    let modifiedData = [];
    let dateRangeObj = {};

    for (let i = 0; i < searchCriteriaArray.length; i++) {
        if (searchCriteriaArray[i].key === "daterange") {
            // console.log(dateRangeObj.start, 'dateRangeObj');
            if (searchCriteriaArray[i].startDate && !dateRangeObj.start) {
                dateRangeObj.start = searchCriteriaArray[i].startDate;
            }
            if (searchCriteriaArray[i].endDate && !dateRangeObj.end) {
                dateRangeObj.end = searchCriteriaArray[i].endDate;
            }
        } else {
            modifiedData.push(searchCriteriaArray[i]);
        }
    }

    if (dateRangeObj.start && dateRangeObj.end) {
        modifiedData.push({
            key: "daterange",
            startDate: `${dateRangeObj.start}TO${dateRangeObj.end}`
        });
    }
    if (isAdmin === "Global Administrator" || isAdmin === "VideoInterview Administrator") {
        recruiterInstance = [
            {
                "key": "contains",
                "status": "interviewcompleted",
                "stage": "videoInterviewCompleted"
            }
        ]
    }
    else {
        recruiterInstance = [
            {
                "key": "contains",
                "recruiterEmailId": recruiterEmailId
            },
            {
                "key": "exact",
                "status": "interviewcompleted",
                "stage": "videoInterviewCompleted"
            }
        ]
    }

    const data = JSON.stringify({
        "request": [
            ...recruiterInstance,
            ...modifiedData,
            {
                "key": "equal",
                "deleted": false
            },
            {
                "include": [
                    "candidateName",
                    "candidateEmail",
                    "stage",
                    "status",
                    "video",
                    "endDate",
                    "startDate",
                    "jobId",
                    "candidateId",
                    "jobName",
                    "questionnairesId",
                    "candidateQuestionAnswerTime",
                    "feedback",
                    "recruiterEmailId",
                    "chunkStartTime",
                    "updatedAt",
                    "candidateQuestions"
                ]
            },
            {
                "sort": [
                    ...order
                ]
            }
        ]

    });



    return await AxiosInstance.post(`${AppConstants.serverConstants.API_ENDPOINTS.VIDEO_INTERVIEW_LIST_VIAPI}?perpage=${pageSize}&next=${pageIndex}&source=videoInterview`, data, {
        headers: headers
    })
        .then((response) => response
        )
        .catch((error) => error);
};


export async function getVideoFromAzureMedia(endPoint, progressEventHandler = null, type) {


    return await AxiosInstanceAzureMedia.get(`/${endPoint}`, {
        responseType: 'blob',  /* or responseType: 'arraybuffer'  */
        // headers: {
        //     "Range": "bytes=29004214-72510536"
        // }
        onDownloadProgress(progressEvent) {
            progressEventHandler(progressEvent, type)
        }
    })
        .then((response) => response
        )
        .catch((error) => error);

}

