import React from 'react';
import './Footer.css'

function Footer(props) {
    return (
        <footer className="footer">
            <div className='footer-child'>
            <div className="date"> 
                <p>{(new Date().getFullYear())} &copy; HitechPeople Inc. All rights reserved.</p>
            </div>
            </div>
        </footer>
    );
}

export default Footer;