import Sidebar from '../Navigation/Sidebar/Sidebar';
import AppDrawer from '../Navigation/AppDrawer';


export default function Main() {

    return (
        <>
            {/* <Sidebar/> */}
          
            <AppDrawer />
          
        </>
    ); 
}