import * as React from 'react';
import { EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER, EMAIL_TEMPLATE_KEYWORDS } from '../../../../Constants/email-template-keywords';
import parse from 'html-react-parser';
import { environment } from '../../../../utils/Environment/Environment';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { ScheduleInterviewInfotoRecruiter } from '../../../../handlers/mail_handler';
import { JobInterviewID } from '../../../../handlers/job_handlers';
import { SnakbarAlertCommon } from '../../../Alerts/SnakbarAlertCommon';

export default function EmailTemplatePreview({ template, candidateInformation, setLoaderStatus, setEmailSentALert, setEmailPreviewAvailable, emailPreviewAvailable, InterviewLinkDateTime }) {

    const { Email, CandidateName, JobName, mid, item, inputDateTime, questionnairesId } = candidateInformation;
    // const {  Email } = row;
    const { startDateTime, endDateTime } = inputDateTime;
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const [rescheduleErrorAlert, setRescheduleErrorAlert] = React.useState({ apiErrorStatus: false, message: '' });


    const htmlTemplate = React.useRef();
    let str = window.atob(template);

    function bindTextToElements(element, displayText, innerText = '', href = '', optionalText) {

        // console.log('candidateInformation', candidateInformation);

        if (element != '') {
            if (href === 'href') {
                if (element.nodeName === 'A') {
                    element.href = displayText + optionalText;
                }
                else if (element.parentNode.nodeName === 'A') {
                    element.parentNode.href = displayText + optionalText;
                }

            }
            if (innerText === 'innerText') {
                if (element.nodeName === 'A') {
                    element.href = displayText + optionalText;
                    element.innerText = displayText + optionalText;
                }
                else if (element.parentNode.nodeName === 'A') {
                    element.parentNode.href = displayText + optionalText;
                    element.parentNode.innerText = displayText + optionalText;
                }
                else {
                    for (let i = 0; i < element.parentNode.children.length; i++) {
                        // element.parentNode.children[i].innerText = ''
                    }
                    element.innerText = displayText + optionalText;
                }
            }
        }
    }


    function capitalize_first_letter(string) {
        const strArr = string.split(' ');
        let start_date_str = '';
        for (let i = 0; i < strArr.length; i++) {
            start_date_str += strArr[i].substring(0, 1).toUpperCase() + strArr[i].substring(1) + ' ';
        }

        return start_date_str;
    }

    function sendEmail() {
        // setLoaderStatus({ status: true, loaderType: 'email_loader', msg: 'Sending Email..' });
        //setEmailSendLoader(true);
        let mailTemplatePreviewMain = document.querySelector('.mail-template-preview-main');
        // for (let i = 0; i < mids.current.length; i++) {
        let html = `<!DOCTYPE html>
            <html lang="en" xmlns="http://www.w3.org/1999/xhtml">
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Interview mail</title>
            </head>
            <body style="width:100%;margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%">
                <div>
                    ${mailTemplatePreviewMain.innerHTML}
                </div>
            </body>
            </html>`

        // const startDateInfo = new Date(startDateTime).toISOString();
        // const endDateInfo = new Date(endDateTime;

        //    console.log('template-dtae-time', startDateInfo, endDateInfo);


        // SendMail(accessToken, selectedCandidates[i].attachCandidate[0].candidateEmail, html)
        //     .then(res => {
        //        // console.log(res)
        //         setEmailSentALert({ display: true, msg: 'Email sent successfully.' });
        //         setEmailSendLoader(false);
        //     })
        //     .catch(res => {
        //         console.log(res)
        //         setEmailSentALert({ display: true, msg: 'Something went wrong.' });
        //         setEmailSendLoader(false);
        //     })
        // let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
        // let endDateInfo = new Date(interviewDateTo).setHours(interviewTimeTo.substring(0, 2), interviewTimeTo.substring(3, 5));



        const timezone = { value: "Asia/Kolkata" };
        ScheduleInterviewInfotoRecruiter(accessToken, Email, html, startDateTime, endDateTime, "One Way Interview (Rescheduled)", timezone)
            .then(res => {
                if (res.data.success === "succeed!") {
                    setEmailSentALert({ status: 'email_sent' });
                    setEmailPreviewAvailable(prevState => ({ ...prevState, isPreviewAvailable: 'preview_available', showPreview: 'hidden' }));
                    // setEmailSentALert({ status: 'idle', isLoading: '', mailSent: false });
                    // setLoaderStatus({ status: true, loaderType: 'email_loader', msg: 'Email sent successfully.', isLoading: '' });
                    //setEmailSentALert({ display: true, msg: 'Email sent successfully.' });
                    // setEmailSendLoader(false);
                    // setMailSend(true);
                    // setInterviewScheduleInfo({ state: 'interview_scheduled_email_send', sentStatus: 'email_sent_to_candidates', text: 'Email sent successfully' });


                    /*
                    setTimeout(() => {
                        setEmailSentALert({ status: 'idle' });
                    }, 3000);
                    */
                }
            })
            .catch(err => {
                setEmailSentALert({ status: 'email_sent_error' });
                //setLoaderStatus({ status: true, loaderType: 'email_loader', msg: 'Something went wrong.', isLoading: 'loading' });
                //setEmailSentALert({ display: true, msg: 'Something went wrong.' });
                // setInterviewScheduleInfo({ state: 'interview_scheduled_email_send', sentStatus: 'email_not_sent_to', text: 'Something went wrong.' });
                // setEmailSendLoader(false);
                /*
              setTimeout(() => {
                  setEmailSentALert({ status: 'idle' });
              }, 3000);
              */
            })
    }


    React.useEffect(() => {
        if (template) {
            let url = environment.videoInterviewUrl
            // let startDateInfo = new Date(interviewDateFrom).setHours(interviewTimeFrom.substring(0, 2), interviewTimeFrom.substring(3, 5));
            // mids.current.forEach((item, index) => {
            //     if (index < mids.current.length - 1) {
            //         let row_border = '';
            //         if (index < mids.current.length - 2) {
            //             row_border = '<hr/>'
            //         }
            //         htmlTemplate.current.innerHTML += ` <div class='mail-template-preview-main'>
            //                                                 ${window.atob(template)}
            //                                                 ${row_border}
            //                                              </div>`
            //     }
            // })

            htmlTemplate.current.innerHTML = ` <div class='mail-template-preview-main'>
            ${window.atob(template)}
         </div>`


            let mailTemplatePreviewMain = document.querySelector('.mail-template-preview-main');
            // console.log('mailTemplatePreviewMain', mailTemplatePreviewMain, htmlTemplate.current.querySelectorAll('*'));

            const optionalTexts = {
                GREETING_KEYWORD: '',
                POSITION: '',
                START_DATE: '',
                INTERVIEW_INVITATION: '',
                INTERVIEW_APP_URL: ''
            };


            let text = '';
            let textLen = '';
            let textIndex = '';


            //return;

            // for (let i = 0; i < mailTemplatePreviewMain.length; i++) {

            let elements = mailTemplatePreviewMain.querySelectorAll('*');

            let CongratulationsHolder = '';
            let PositionHolder = '';
            let StartDateHolder = '';
            let InvitationHolder = '';
            let LinkHolder = '';




            for (let j = 0; j < elements.length; j++) {
                if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.GREETING_KEYWORD)) {
                    CongratulationsHolder = elements[j];

                    text = EMAIL_TEMPLATE_KEYWORDS.GREETING_KEYWORD;
                    textLen = text.length;
                    textIndex = CongratulationsHolder.innerText.toLowerCase().indexOf(text);
                    optionalTexts.GREETING_KEYWORD = CongratulationsHolder.innerText.slice(textLen);
                }
                if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.POSITION)) {
                    PositionHolder = elements[j];

                    text = EMAIL_TEMPLATE_KEYWORDS.POSITION;
                    textLen = text.length;
                    textIndex = PositionHolder.innerText.toLowerCase().indexOf(text);
                    optionalTexts.POSITION = PositionHolder.innerText.slice(textLen);
                    // console.log('optionalTexts', optionalTexts);
                }
                if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.START_DATE)) {
                    StartDateHolder = elements[j];

                    text = EMAIL_TEMPLATE_KEYWORDS.START_DATE;
                    textLen = text.length;
                    textIndex = StartDateHolder.innerText.toLowerCase().indexOf(text);
                    optionalTexts.INTERVIEW_INVITATION = StartDateHolder.innerText.slice(textLen);
                }

                if (elements[j].innerText.toLowerCase().includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_INVITATION)) {
                    InvitationHolder = elements[j];

                    text = EMAIL_TEMPLATE_KEYWORDS.INTERVIEW_INVITATION;
                    textLen = text.length;
                    textIndex = InvitationHolder.innerText.toLowerCase().indexOf(text);
                    optionalTexts.INTERVIEW_INVITATION = InvitationHolder.innerText.slice(textLen);
                }
                // if (elements[j].innerText.includes(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_APP_URL.slice(0, EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.INTERVIEW_APP_URL.length - 1))) {
                //     LinkHolder = elements[j];

                //     text = EMAIL_TEMPLATE_KEYWORDS.INTERVIEW_APP_URL;
                //     textLen = text.length;
                //     textIndex = LinkHolder.innerText.toLowerCase().indexOf(text);
                //     optionalTexts.INTERVIEW_APP_URL = LinkHolder.innerText.slice(textLen);
                // }
            }

            // console.log(optionalTexts.GREETING_KEYWORD);
            bindTextToElements(
                CongratulationsHolder,
                `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.GREETING_KEYWORD)} ${CandidateName}`,
                'innerText',
                '',
                optionalTexts.GREETING_KEYWORD
            );

            bindTextToElements(
                PositionHolder,
                `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.POSITION)}: ${JobName}`,
                'innerText',
                '',
                optionalTexts.POSITION
            );




            const _timezone = "Asia/Kolkata"
            const _startDateTime = new Date(InterviewLinkDateTime.startDateTime).toLocaleString('en-US', {
                timeZone: _timezone,
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            }) + ' (' + _timezone + ')';

            const _endDateTime = new Date(InterviewLinkDateTime.endDateTime).toLocaleString('en-US', {
                timeZone: _timezone,
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            }) + ' (' + _timezone + ')';

            let timeDiffMiliSeconds = new Date(InterviewLinkDateTime.endDateTime).getTime() - new Date(InterviewLinkDateTime.startDateTime).getTime()


            let days = timeDiffMiliSeconds / (24 * 60 * 60 * 1000);
            let hours = (days % 1) * 24;
            let minutes = (hours % 1) * 60;
            let seconds = (minutes % 1) * 60;

            [days, hours, minutes, seconds] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(seconds)]


            let dateTimeInfo = '';
            if (days > 0) {
                dateTimeInfo = days > 1 ? `${days} Days ` : `${days} Day `;
            }
            if (hours > 0) {
                dateTimeInfo += hours > 1 ? `${hours} Hours ` : `${hours} Hour `;
            }
            if (minutes > 0) {
                dateTimeInfo += minutes > 1 ? `${minutes} Minutes ` : `${minutes} Minute `;
            }


            bindTextToElements(
                StartDateHolder,
                `${capitalize_first_letter(EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER.START_DATE)}: ${_startDateTime} (Duration: ${dateTimeInfo} ) (End Date: ${_endDateTime})`,
                'innerText',
                '',
                optionalTexts.START_DATE
            );

            // bindTextToElements(
            //     LinkHolder,
            //     `${environment.videoInterviewUrl}?mid=${mid}`,
            //     'innerText',
            //     'href',
            //     optionalTexts.INTERVIEW_APP_URL
            // );

            bindTextToElements(
                InvitationHolder,
                `${environment.videoInterviewUrl}?mid=${mid}`,
                '',
                'href',
                optionalTexts.INTERVIEW_INVITATION
            );
            updateInterviewMid()
        }
    }, [template]);




    function updateInterviewMid() {
        let questions = ''
        if (candidateInformation.item.candidateQuestions?.length !== undefined && candidateInformation.item.candidateQuestions.length) {
            questions = candidateInformation.item.candidateQuestions
        }
        else {
            questions = []
        }

        JobInterviewID(accessToken, mid, questionnairesId, userInfo.userName, questions)
            .then(res => {
                if (res.status) {
                    if (res.data == undefined) {
                        if (res.message.code == 400) {
                            setEmailSentALert({ status: 'reschedule_error' });
                            setRescheduleErrorAlert({ apiErrorStatus: true, message: 'Bad Request!' });
                        }
                    }
                    else {
                        if (res.data.data.message === "record successfully updated") {
                            sendEmail();
                        }
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    let previewContainerDisplay = 'none';
    if (emailPreviewAvailable.showPreview === 'hidden') {
        previewContainerDisplay = 'none';
    }
    else if (emailPreviewAvailable.showPreview === 'show') {
        previewContainerDisplay = 'block';
    }


    // console.log(rescheduleErrorAlert, 'rescheduleErrorAlert');

    return (
        <>
            <SnakbarAlertCommon CommonAlert={rescheduleErrorAlert} setCommonAlert={setRescheduleErrorAlert} />
            <div ref={htmlTemplate} className='mail-template-preview' style={{ display: previewContainerDisplay, marginTop: '50px' }}>
                <div className='mail-template-preview-main'>
                    {
                        parse(str)
                    }
                    {/* {str && <div className='row-border'><hr /></div>} */}
                </div>
            </div>
        </>
    )

}

