import Box from '@mui/material/Box';
import error_404 from '../../Assets/icons/404.png';

const erorrStatuses = {
    '404': {
        text: 'Page Not Found',
        icon: error_404
    }
};


const Error_Page = ({ errorType }) => {
    return <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={erorrStatuses[errorType].icon} sx={{ width: '100%', height: '100%' }} alt='404 Not Found Icon' />
                <Box sx={{ padding: '15px 0', fontWeight: 'bold' }}>
                    {erorrStatuses[errorType].text}
                </Box>
            </Box>
        </Box>
    </Box>
}

export default Error_Page;