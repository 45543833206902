import React, { useContext, useEffect, useState, useCallback } from 'react';
import './CreateJob.css';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
//import { DataGrid } from '@mui/x-data-grid';
import rightarrow from '../../../Assets/icons/rightarrow.png';
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateLocalJob, GetCDSJobs, GetJobs } from '../../../handlers/job_handlers';
import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import Loader from '../../Preloaders/Loader';
import { width } from '@mui/system';
import { SuccessAlert } from '../../Alerts/SuccessAlert';
import { ErrorAlert } from '../../Alerts/ErrorAlert';
import Tooltip from '@mui/material/Tooltip';
import { ViewJobList } from '../../../handlers/job_handlers';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearFiltericon from "../../../Assets/icons/ClearFiltericon.png";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// dx
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import DataGrid, {
    Column,
    FilterRow,
    Paging,
    Scrolling,
    Selection,
    Pager,
    ColumnChooser,
    Sorting,
    Lookup,
    Item,
    Toolbar,
    Export,
    ColumnFixing,
    Editing,
    Box as DevExpressBox,
    Button as DevExpressButton,
    StateStoring,
} from "devextreme-react/data-grid";
import { Button as Button2 } from "devextreme-react/button";
import clean from "../../../Assets/icons/clean.png";
import {
    GridCustomization,
    GridCustomizeGet,
} from "../../../handlers/customize";




const allowedPageSizes = [20, 50, 100];

const initialColumnStructure = {
    searchText: "",
    allowedPageSizes: [20, 50, 100],
    filterPanel: { filterEnabled: true },
    pageIndex: 0,
    columns: [
        {
            dataField: "Job Title",
            visible: true,
            visibleIndex: 0,
            dataType: "string",
            name: "Job Title",
            width: "400px",
        },
        {
            dataField: "Job Code",
            visible: true,
            visibleIndex: 0,
            dataType: "string",
            name: "Job Code",
            width: "200px",
        },
        {
            dataField: "Account Name",
            visible: true,
            visibleIndex: 0,
            dataType: "string",
            name: "Account Name",
            width: "300px",
        },
    ],
    pageSize: 20,
};


const initialColumnStructureLocalFromATS = {
    searchText: "",
    allowedPageSizes: [20, 50, 100],
    filterPanel: { filterEnabled: true },
    pageIndex: 0,
    columns: [
        {
            dataField: "Job Title",
            visible: true,
            visibleIndex: 0,
            dataType: "string",
            name: "Job Title",
            width: "350px",
        }
    ],
    pageSize: 20,
};
// dx





const columns = [
    {
        field: 'Job Title',
        headerName: 'Job Title',
        width: 250,
        editable: true,
        renderCell: (params) => {
            let jobAvailableText = params.row.isJobConverted ? '(Local Job Exists)' : '';
            return <Tooltip title={params.row['Job Title'] + ' ' + jobAvailableText} >
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row['Job Title']}</span>
            </Tooltip>
        },
    },
    {
        field: 'Job Code',
        headerName: 'Job Code',
        width: 250,
        editable: true,
        renderCell: (params) => {
            let jobAvailableText = params.row.isJobConverted ? '(Local Job Exists)' : '';
            return <Tooltip title={params.row['Job Code'] + ' ' + jobAvailableText} >
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row['Job Code']}</span>
            </Tooltip>
        },
    },

    {
        field: 'Account Name',
        headerName: 'Account Name',
        width: 250,
        editable: true,
        backgroundColor: 'grey'
    },

];

// const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

const localcolumns = [
    {
        field: "Job Title",
        headerName: "Job Title",
        width: 250,
        editable: true
    }
];

const localrows = [
    { id: 1, lastName: "Snow" },
    { id: 2, lastName: "Lannister" },
    { id: 3, lastName: "Lannister" },
    { id: 4, lastName: "Stark" },
    { id: 5, lastName: "Targaryen" },
    { id: 6, lastName: "Melisandre" },
    { id: 7, lastName: "Clifford" },
    { id: 8, lastName: "Frances" },
    { id: 9, lastName: "Roxie" }
];

const CreateJob = ({ setCrossPageOpen }) => {
    const { accessToken, userInfo, userRole } = useUserAuthContext();
    const [atsJob, setAtsJob] = useState([]);
    const [job, setJob] = React.useState('');
    const [localJob, setLocalJob] = useState(false);
    const [crmJob, setCrmJob] = useState(false);
    const [create_Local_Job, setCreate_Local_Job] = useState({
        'jobtitle': '',
        'designation': '',
        'skills': '',
        'location': '',
        'experience_from': '',
        'experience_to': '',
        'ctc_from': '',
        'ctc_to': '',
        // 'client_id': '',
        'client_name': 'GlobalHunt India Pvt. Ltd',
        'description': '',
        'number': '',
    });

    const [selectedRows, setSelectedRows] = React.useState([]);
    const [crmLocalJob, setCrmLocalJOb] = useState(false);
    const [convertedLocalJobs, setConvertedLocalJobs] = useState([]);
    const [localJobSelectedRows, setLocalJobSelectedRows] = useState([]);
    const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState('Loading');
    const [message, setMessage] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' });
    const [showError, setShowError] = useState({ display: false, text: '' });
    const [jobAdded, setJobAdded] = useState(false);
    const navigate = useNavigate();
    const [pageSize, setPageSize] = React.useState(10);
    const [pageSizeLocal, setPageSizeLocal] = React.useState(10);
    const [selectedATSJobsRowChecked, setSelectedATSJobsRowChecked] = React.useState({ atsJobTable: [], localJobTable: [] });
    const [jobsMasterDataCommon, setJobsMasterDataCommon] = React.useState([]);
    const [convertedLocalJobsCopy, setConvertedLocalJobsCopy] = useState([]);
    const [atsJobsCopy, setATSJobsCopy] = useState([]);

    // dx
    const [clearCustomizationPopUp, setClearCustomizationPopUp] = React.useState(false);
    const [customizationData, setCustomizationData] = React.useState(null);
    const [resetCall, setResetCall] = React.useState({ ats: "idle", atsToLocal: 'idle' });
    const selectedRowIsJobConverted = React.useRef([]);
    const [loaderCircular, setLoaderCircular] = useState(true);
    const dataGridRef = React.useRef();
    const dataGridHeaderCheckboxSelectionRef = React.useRef(false);

    // dx

    const copyUserInfo = {
        email: '',
        ...userInfo
    }


    useEffect(() => {
        if (crmLocalJob) {
            setConvertedLocalJobs(selectedRows);
            setCrmLocalJOb(false);
        }
    }, [crmLocalJob])

    /*

    useEffect(() => {
        // To prevent already converted ats jobs from getting converted again
        if (convertedLocalJobs.length) {
            const jobs = [...convertedLocalJobs].map((job, index) => {
                let obj = { ...job, isJobConverted: false };
                [...jobsMasterData.current].forEach((masterJob) => {
                    if (job.jobId === masterJob.jobId) {
                        obj = { ...job, isJobConverted: true };
                    }
                })

                return obj;
            })

            setConvertedLocalJobsCopy(jobs)
        }
    }, [convertedLocalJobs.length]);


    */


    function closeAlert(setState, param) {

        setTimeout(() => {
            setState(param)
        }, 2000);
    }
    //   ^(?!0+(?:\.0+)?$)[0-9]+(?:\.[0-9]+)?$
    //   ^[0-9]*(.){0,1}[0-9]*$
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'experience_from' || name === 'experience_to' || name === 'ctc_from' || name === 'ctc_to') {
            if ((/^[0-9]*(.){0,1}[0-9]*$/.test(value))) {
                if (value > -1) {
                    setCreate_Local_Job({
                        ...create_Local_Job,
                        [name]: value,
                    });
                }
            }
        }
        else if (name === 'number') {
            if ((/^[0-9]{0,10}$/.test(value))) {
                if (value > -1) {
                    setCreate_Local_Job({
                        ...create_Local_Job,
                        [name]: value,
                    });
                }
            }
        }
        else if (name === 'designation') {
            if ((/^[a-zA-Z\s]*$/.test(value))) {
                setCreate_Local_Job({
                    ...create_Local_Job,
                    [name]: value,
                });
            }
        }
        else {
            setCreate_Local_Job({
                ...create_Local_Job,
                [name]: value,
            });
        }
    };
    const location = useLocation();
    const handleChange = (event) => {
        setJob(event.target.value);
    };
    useEffect(() => {
        if (jobAdded) {
            setTimeout(() => {
                navigate('/job/view-job', { state: 'job_created_and_redirected' })
            }, 2000);
        };
    }, [jobAdded]);
    useEffect(() => {
        if (location.state) {
            setCrossPageOpen('Create Job');
            if (location.state == 'crm_job') {
                setCrmJob(true);
                setLocalJob(false);
                setJob(location.state);
            }
            else {
                setCrmJob(false);
                setLocalJob(true);
                setJob(location.state);
            }
        }
    }, []);
    useEffect(() => {
        getCDSjobs();
    }, [accessToken, crmJob, copyUserInfo.email, userRole]);
    useEffect(() => {
        getLocalJobs()
    }, [accessToken, copyUserInfo.email, userRole])
    useEffect(() => {
        const selectedIds = selectedRows.map(row => {
            return row.id;
        })
        setSelectedATSJobsRowChecked(state => ({ ...state, atsJobTable: selectedIds }));
    }, [selectedRows.length])
    useEffect(() => {
        const selectedIds = localJobSelectedRows.map(row => {
            return row.id;
        })
        setSelectedATSJobsRowChecked(state => ({ ...state, localJobTable: selectedIds }));
    }, [localJobSelectedRows.length])




    function getCDSjobs() {
        if (crmJob && copyUserInfo.email && userRole && accessToken) {
            GetCDSJobs(accessToken, copyUserInfo, userRole)
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data.response.length < 1) {
                                setTableLoaderErrInfo('No record found')
                            }
                            else {
                                setAtsJob(res.data.data.response);
                            }
                        }
                    }
                })
                .catch(err => setTableLoaderErrInfo('Contact to Administrator'))
        }
    }

    function getLocalJobs() {
        if (accessToken && copyUserInfo.email && userRole) {
            const _page_size = 2100;
            const _next = 1;
            ViewJobList(accessToken, copyUserInfo, "Global Administrator", _next, _page_size, '', '', 'create_job_from_crm_module')
                .then(res => {
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data === null) {
                                setTableLoaderErrInfo('No record found')
                            }
                            if (res.data.data.response.length) {
                                setJobsMasterDataCommon(res.data.data.response);

                            }
                        }
                    }
                })
                .catch((err) => console.log(err))
        }
    }


    function getAllJobs() {
        let tempArr = [];
        atsJob.forEach((item, index) => {
            tempArr.push({
                id: index,
                'Job Title': item.name,
                'Job Code': item.ghjobcode,
                'Account Name': item.accountname,
                'client Id': item.accountid,
                'hrcontactid': item.hrcontactid,
                'hrcontactname': item.hrcontactname,
                'jobId': item.jobid,
                'jobs': item.name,
                'status': item.status,
                'skills': item.skillkeyword,
                'mobile number': item.mobilenumber,
                'Designation': item.designationfunctionalkeyword,
                'CTC from': item.ctcfrom,
                'CTC to': item.ctcto,
                'Experience from': item.experiencefrom,
                'Experience to': item.experienceto,
                'Location': item.locationcities ? item.locationcities : 'N/A',
                'Description': item.descriptionnote ? item.descriptionnote : '',
                'securitygroupuser': item.securitygroupuser,
                'jobstartdate': item.jobstartdate,
                'jobclosedate': item.jobclosedate
            })
        })
        return tempArr;
    }

    const rows = getAllJobs();


    useEffect(() => {

        if (rows.length && jobsMasterDataCommon.length) {
            const jobs = [...rows].map((job, index) => {
                let obj = { ...job, isJobConverted: false };
                [...jobsMasterDataCommon].forEach((masterJob) => {
                    if (job.jobId === masterJob.jobId) {
                        obj = { ...job, isJobConverted: true };
                    }
                });

                return obj;
            });
            setATSJobsCopy(jobs);
            setTableLoaderErrInfo('')
        }
    }, [rows.length, jobsMasterDataCommon.length]);









    // useEffect(() => {
    //     let status = 1;
    //     for (const key in create_Local_Job) {
    //         if (create_Local_Job[key] === '') {
    //             status = 0
    //             break
    //         }
    //     }
    //     if (status) {
    //         CreateLocalJob(accessToken, create_Local_Job);
    //     }
    // }, [create_Local_Job]);

    // function JobAlert(){
    //     emailSentAlert
    // }

    function saveData(isCRM, jobs) {

        if (!isCRM) {   // If local job                                
            let status = { flag: 1, msg: '' };
            for (let key in create_Local_Job) {
                if (create_Local_Job[key] == '') {
                    status.flag = 0;
                    status.msg = 'All fields required!';
                }
                else if (key === 'number' && create_Local_Job[key].charAt(0) == 0) {
                    status.flag = 0;
                    status.msg = "Number can't start with Zero";
                }
                else if (key === 'number' && create_Local_Job[key].length < 10) {
                    status.flag = 0;
                    status.msg = 'Invalid input provided!';
                    //status.msg = 'Number cannot be less than 10 digits';                       
                }
            }
            // if (jobs.experience_to < jobs.experience_from) {
            //     status.flag = 0;
            //     status.msg = 'Invalid input provided!';
            //     //status.msg += 'Experience To cannot be less than Experience From, ';
            // }                    
            if (Number(jobs.experience_to) < Number(jobs.experience_from)) {
                status.flag = 0;
                status.msg = 'Invalid input provided!';
                //status.msg += 'Experience To cannot be less than Experience From, ';
            }

            if (Number(jobs.ctc_to) < Number(jobs.ctc_from)) {
                status.flag = 0;
                status.msg = 'Invalid input provided!';
                // status.msg += 'CTC To cannot be less than CTC From';
            }

            if (!status.flag) {
                setShowError({ display: true, text: status.msg });
                closeAlert(setShowError, { display: false, text: '' });
                return;
            }
        }
        else {
            if (!jobs.length) {
                setShowError({ display: true, text: 'Please select the job(s)' });
                closePopUp()
                // closeAlert(setShowError, { display: false, text: '' });
                return;
            }
        }
        let copyJobs = { ...jobs };
        if (isCRM) {
            //------ Convert the lakh digits into single digit number
            copyJobs = [...jobs].map(job => {
                const LAKH_UNIT = 100000;
                const ctc_from = Math.floor(job['CTC from'] / LAKH_UNIT); // in lakh
                const ctc_to = Math.floor(job['CTC to'] / LAKH_UNIT);   // in lakh
                return { ...job, 'CTC from': ctc_from.toString(), 'CTC to': ctc_to.toString() };

            });

        }

        CreateLocalJob(accessToken, isCRM, copyJobs, userInfo, userRole)
            .then(res => {
                if (res.data.status === 'succeed') {
                    if (res.data.data.message === ("JobCode is already present" || 'Job already Exist!!')) {
                        setShowError({ display: true, text: res.data.data.message });
                        closeAlert(setShowError, { display: false, text: '' });
                        closePopUp()
                    }
                    else if (res.data.data.message === ('Job already Exist!!' || 'JobName is already present' || 'There multiple document present')) {
                        setShowError({ display: true, text: 'Job Name is already present' });
                        closePopUp()

                    }
                    else {
                        setAlertMessage({ display: true, text: isCRM ? 'CRM job successfully converted to Local Job.' : 'New Local Job created successfully.' });
                        closeAlert(setAlertMessage, { display: false, text: '' });
                        setJobAdded(true);
                    }
                    setCreate_Local_Job({
                        'jobtitle': '',
                        'designation': '',
                        'skills': '',
                        'location': '',
                        'experience_from': '',
                        'experience_to': '',
                        'ctc_from': '',
                        'ctc_to': '',
                        // 'client_id': '',
                        'client_name': 'GlobalHunt India Pvt. Ltd',
                        'description': '',
                        'number': '',
                    })
                }
            })
            .catch(err => {
                console.log(err)
                setShowError({ display: true, text: 'Something went wrong!' });
                closeAlert(setShowError, { display: false, text: '' });
            })

        function closePopUp() {
            setTimeout(() => {
                setShowError({ display: false, text: '' })
            }, 4000);
        };

    };



    // dx

    React.useEffect(() => {
        if (accessToken && userInfo.userName) {
            const interviewList = "create-job";
            GridCustomizeGet(accessToken, userInfo.userName, interviewList)
                .then(
                    (res) => {
                        if (res.status) {
                            setCustomizationData(res.data.data);
                        }
                        else {
                            throw { code: res.code, message: res.message };
                        }
                    })
                .catch(err => {
                    if (serverError.includes(err.code)) {
                        setCommonAlert({ apiErrorStatus: true, message: err.message });
                    }

                });
        }
    }, [accessToken, userInfo.userName]);

    const saveState = useCallback(
        (state) => {
            const interviewList = "create-job";
            if (userInfo.userName) {
                GridCustomization(
                    accessToken,
                    userInfo.userName,
                    state,
                    interviewList
                ).then((res) => {
                    if (res.status === false) {
                        throw { code: res.code, message: res.message };
                    }
                })
                    .catch(err => {
                        if (serverError.includes(err.code)) {
                            setCommonAlert({ apiErrorStatus: true, message: err.message });
                        }
                    });
            }
        },
        [userInfo, accessToken]
    );

    const loadState = useCallback(() => {
        // let data = {};
        // if (resetCall === "active") {
        //     data = reset_custom.current;
        //     setResetCall("inactive");
        // }
        // else if (resetCall === "idle") {
        //     data = { ...customizationData }

        // }
        // return data;
        return devExtremeStateCallbackHelper(initialColumnStructure, customizationData, resetCall.ats, 'ats')

    }, [customizationData, resetCall.ats]);

    function onEditorPreparing(e) {
        if (e.row != (undefined || null)) {
            if (e.row.data.isJobConverted) {
                if (!selectedRowIsJobConverted.current.includes(e.row.key)) {
                    selectedRowIsJobConverted.current.push(e.row.key);
                }

                e.editorOptions.disabled = true;
                e.editorOptions.showValidationMark = true;
                // e.editorOptions.focusStateEnabled = true

            }



            // atsJobsCopy.forEach((job, index) => {
            //     console.log(job.jobId, e.row, 'sDJDGADFF');
            // });


            //     if (e.row.data.item.attachCandidate[0].mailSend) {2
            //         if (!selectedRowIsJobConverted.current.includes(e.row.rowIndex)) {
            //             selectedRowIsJobConverted.current.push(e.row.rowIndex);
            //         }


            //         e.editorOptions.disabled = true;
            //         e.editorOptions.showValidationMark = true
            //         // e.editorOptions.focusStateEnabled = true

            //     }



            //     //e.component.selectRowsByIndexes(interviewState.selectedCandidatesDGRowGrid);
            //     // interviewState.selectedCandidates
            //     // selectedRowsKeysDataRef.current.forEach((item, index) => {
            //     //     // console.log(item.item.attachCandidate, 'Dhgcdds');
            //     //     if (item.item.attachCandidate[0].candidateId === e.row.data.item.attachCandidate[0].candidateId) {
            //     //         //console.log('hsaigdyugdauyuy', item.attachCandidate[0].candidateId, e.row.data.item.attachCandidate[0].candidateId);
            //     //         //e.component.selectRowsByIndexes(e.row.rowIndex)
            //     //     }
            //     // });
        }
    }

    const refresh = () => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.refresh();
            getCDSjobs();
            getLocalJobs();
        }
    }
    const customChanges = {
        icon: 'undo',
        text: 'Clear',
        type: 'default',
        onClick: () => setResetCall({ ats: "active", atsToLocal: 'idle' })
    }

    const cancelCustomchanges = {
        text: 'Close',
        icon: 'remove',
        type: 'danger',
        onClick: () => { setClearCustomizationPopUp(false) },
    }

    function devExtremeStateCallbackHelper(customizedDataRef, customizedData, resetHolder, listType) {
        let data = {};
        if (resetHolder === "active") {
            data = customizedDataRef;
            setResetCall(prevState => ({ ...prevState, [listType]: "inactive" }));
            setClearCustomizationPopUp(false)

        }
        else if (resetHolder === "idle") {
            data = { ...customizedData }

        }
        return data;
    }

    // dx

    function getStatusColor(data) {

        let color = '';
        if (data.isJobConverted) {
            return color = '#adadad'
        }
        else {
            color = '#0074b8'
        }
        return color
    };
    const clearFilters = (e) => {
        if (dataGridRef.current) {
            dataGridRef.current.instance.clearFilter();
        }
    };
    function handleSelectUnSelect(e) {
        const checkboxRole = e.cellElement.attributes.getNamedItem('role').value;
        if (checkboxRole === 'columnheader' && dataGridHeaderCheckboxSelectionRef.current === true) {
            dataGridHeaderCheckboxSelectionRef.current = false;
            dataGridRef.current.instance.deselectAll();
        }
        else {
            dataGridHeaderCheckboxSelectionRef.current = true;
        }
    }

    function setStatus(e) {
        return <div style={{ color: getStatusColor(e.data), fontWeight: '500' }}>{e.value}</div>
    };

    
    return (
        <>
            <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <ErrorAlert showError={showError} setShowError={setShowError} />
            <Box
                sx={{
                    width: 'auto',
                    height: '85vh',
                    boxShadow: 4,
                    marginTop: '-40px',
                    overflowX: 'scroll'
                }}
            >
                <div style={{ margin: '30px' }}>
                    <h1>Create Job</h1><hr />
                    <h3 className='required-field'>Create Job from</h3>
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '0 34px' }}>
                    <FormControl fullWidth  >
                        <InputLabel id="demo-simple-select-label" sx={{
                            background: "white",

                        }}>Please Select Job From</InputLabel>
                        <Select
                            sx={{ margin: '5px' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={job}
                            label="Please Select Job From"
                            onChange={handleChange}
                        >
                            <MenuItem value={'local_job'} onClick={() => { setLocalJob(true); setCrmJob(false) }}>Create Local Job</MenuItem>
                            <MenuItem value={'crm_job'} onClick={() => { setCrmJob(true); setLocalJob(false) }}>Create Job from CRM</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <hr style={{ marginTop: '20px', width: '95%' }} />
                {
                    localJob ?
                        <>  <form onSubmit={(e) => e.preventDefault()}>
                            <Box sx={{ width: '98%', marginLeft: '20px', display: 'flex' }}>
                                <Grid container>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField type='text'
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Job Title"
                                            value={create_Local_Job.jobtitle}
                                            onChange={handleInputChange}
                                            name="jobtitle"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.slice(0, 50)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField type='text'
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Designation"
                                            value={create_Local_Job.designation}
                                            onChange={handleInputChange}
                                            name="designation"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.slice(0, 100)
                                            }}
                                        />
                                    </Grid>


                                    {/* <Grid item md={6} sx={{m:1}}>
                                            <TextField
                                                required
                                                id="outlined-required"
                                                label="designation"
                                                value={create_Local_Job.designation}
                                                onChange={handleInputChange}
                                                
                                            />
                                        </Grid> */}

                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField type='text'
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Skills"
                                            value={create_Local_Job.skills}
                                            onChange={handleInputChange}
                                            name="skills"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.slice(0, 100)
                                            }}

                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Location"
                                            value={create_Local_Job.location}
                                            onChange={handleInputChange}
                                            name="location"
                                            onInput={(e) => {
                                                e.target.value = e.target.value.slice(0, 200)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            // type="number"
                                            id="outlined-required"
                                            label="Experience From (Yr)"
                                            value={create_Local_Job.experience_from}
                                            onChange={handleInputChange}
                                            name="experience_from"
                                            onInput={(e) => {
                                                if (e.target.value.length == 1 && e.target.value === '.') {
                                                    e.target.value = ''
                                                }
                                                else if (e.target.value.includes('.')) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 2);
                                                }

                                            }}

                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <Grid item md={6} sx={{m:1}}>
                                            <FormControl className='formfieldandtextfield'>
                                                <InputLabel id="demo-simple-select-disabled-label">Experience From</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-disabled-label"
                                                    id="demo-simple-select-disabled"
                                                    label="Experience From"
                                                    value={create_Local_Job.experience_from}
                                                    onChange={handleInputChange}
                                                    name="experience_from"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Ten</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid> */}
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            // type="number"
                                            id="outlined-required"
                                            label="Experience To (Yr)"
                                            value={create_Local_Job.experience_to}
                                            onChange={handleInputChange}
                                            name="experience_to"
                                            onInput={(e) => {
                                                if (e.target.value.length == 1 && e.target.value === '.') {
                                                    e.target.value = ''
                                                }
                                                else if (e.target.value.includes('.')) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 2);
                                                }

                                            }}
                                            error={Number(create_Local_Job.experience_to) < Number(create_Local_Job.experience_from)}
                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            // type="number"
                                            id="outlined-required"
                                            label="CTC From (LPA)"
                                            value={create_Local_Job.ctc_from}
                                            onChange={handleInputChange}
                                            name="ctc_from"
                                            // onInput={(e) => {
                                            //     e.target.value = e.target.value.slice(0, 10);
                                            // }}
                                            onInput={(e) => {
                                                if (e.target.value.length == 1 && e.target.value === '.') {
                                                    e.target.value = ''
                                                }
                                                else if (e.target.value.includes('.')) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 2);
                                                }

                                            }}


                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            // type="number"
                                            id="outlined-required"
                                            label="CTC To (LPA)"
                                            value={create_Local_Job.ctc_to}
                                            onChange={handleInputChange}
                                            name="ctc_to"
                                            // onInput={(e) => {
                                            //     e.target.value = e.target.value.slice(0, 10);
                                            // }}
                                            onInput={(e) => {
                                                if (e.target.value.length == 1 && e.target.value === '.') {
                                                    e.target.value = ''
                                                }
                                                else if (e.target.value.includes('.')) {
                                                    e.target.value = e.target.value.slice(0, 4);
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 2);
                                                }
                                            }}
                                            error={Number(create_Local_Job.ctc_to) < Number(create_Local_Job.ctc_from)}

                                        />
                                    </Grid>
                                    {/* <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Client Id"
                                            value={create_Local_Job.client_id}
                                            onChange={handleInputChange}
                                            name="client_id"

                                        />
                                    </Grid> */}
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Client Name"
                                            value={create_Local_Job.client_name}
                                            onChange={handleInputChange}
                                            name="client_name"
                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            // type='number'
                                            id="outlined-required"
                                            label="Recruiter Number"
                                            value={create_Local_Job.number}
                                            onChange={handleInputChange}
                                            name="number"
                                            onInput={(e) => {
                                                if (e.target.value.length == 1 && e.target.value === '.') {
                                                    e.target.value = ''
                                                }
                                                else if (e.target.value.includes('.')) {
                                                    e.target.value = e.target.value.slice(0, 10);
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 10);
                                                }
                                            }}
                                            error={create_Local_Job.number.length > 0 && create_Local_Job.number.length < 10}
                                        />
                                    </Grid>
                                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <TextField
                                            multiline={true}
                                            sx={{ margin: '5px', width: '95%' }}
                                            required
                                            id="outlined-required"
                                            label="Description"
                                            value={create_Local_Job.description}
                                            onChange={handleInputChange}
                                            name="description"
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={10.2} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                        <Button variant="contained" onClick={() => saveData(false, create_Local_Job)} type='submit' >Save</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                        </> : ''
                }
                {
                    crmJob ?
                        <><Grid container>
                            <Grid item md={8} xs={12} sx={{ height: 400, width: '55%', marginLeft: '32px' }}>
                                <Box sx={{
                                    display: ' inlineflex', justifyContent: 'space-between', paddingTop: '10px', paddingRight: '5px', paddingBottom: '7px'
                                }} ><h2>ATS Jobs</h2>
                                    {/* <Box
                                        component="form"
                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50%', height: 50, borderRadius: 4, boxShadow: 5 }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1, width: '100px' }}
                                            placeholder="Search"
                                            inputProps={{ 'aria-label': 'search' }}
                                        />
                                        <IconButton sx={{ p: '10px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    </Box> */}
                                </Box> <hr />
                                <Popup
                                    visible={clearCustomizationPopUp}
                                    dragEnabled={false}
                                    hideOnOutsideClick={true}
                                    showCloseButton={false}
                                    showTitle={true}
                                    container="body"
                                    width={500}
                                    height={200}
                                    title="Alert"
                                >
                                    <Position
                                        my="center"
                                        of={'body'}
                                        collision="fit"
                                    />
                                    <ToolbarItem
                                        widget="dxButton"
                                        toolbar="bottom"
                                        location="after"
                                        options={customChanges}
                                    />
                                    <ToolbarItem
                                        widget="dxButton"
                                        toolbar="bottom"
                                        location="after"
                                        options={cancelCustomchanges}
                                    />
                                    <p>Your custom changes made by you on grid will be removed..</p>
                                </Popup>
                                {

                                    <DataGrid
                                        ref={dataGridRef}
                                        id='atsJobList'
                                        dataSource={atsJobsCopy}
                                        keyExpr="id"
                                        key="Candidate name"
                                        height="620px"
                                        width="100%"
                                        hoverStateEnabled
                                        noDataText={tableLoaderErrInfo}
                                        showBorders={true}
                                        showRowLines
                                        allowColumnResizing={true}
                                        allowColumnReordering={true}
                                        onSelectionChanged={(ids) => {
                                            const selectedIDs = new Set(ids.selectedRowKeys);
                                            const selectedRows = rows.filter((row) =>
                                                selectedIDs.has(row.id)
                                            );
                                            setSelectedRows(selectedRows);

                                            ids.component.deselectRows(selectedRowIsJobConverted.current);
                                        }}
                                        onEditorPreparing={onEditorPreparing}
                                        onCellClick={handleSelectUnSelect}
                                        rowAlternationEnabled={true}
                                        onToolbarPreparing={(e) => {
                                            if (e.component.totalCount() === 0) {
                                                ///  setTableLoaderErrInfo('No record found')
                                            }
                                        }}
                                        columnMinWidth={50}
                                        // loadPanel={tableLoaderErrInfo === 'Loading'}
                                        filterSyncEnabled={true}

                                    >
                                        <Selection
                                            mode="multiple"
                                            allowSelectAll={true}
                                            showCheckBoxesMode={'always'}
                                        />
                                        <StateStoring
                                            enabled={true}
                                            type="custom"
                                            customLoad={loadState}
                                            customSave={saveState}
                                        />
                                        <Scrolling mode="standard" />
                                        <Lookup
                                            dataSource={rows}
                                            valueExpr="ID"
                                            displayExpr="Name"
                                        />
                                        <Toolbar>
                                            <Item>
                                                <div className="pull-right">
                                                    <div className="pull-right-child">
                                                        <span className="legendLable">
                                                            <span className="spanClass1"></span>
                                                            Available Jobs
                                                        </span>
                                                        <span className="legendLable">
                                                            <span className="spanClass2"></span>
                                                            Converted Local Jobs
                                                        </span>
                                                    </div>
                                                </div>
                                            </Item>
                                            <Item>
                                                <DevExtremToolbarClearfilter clearFilters={clearFilters} dataGridRef={dataGridRef} />

                                            </Item>
                                            <Item location="after">
                                                <Button2 hint="Refresh" icon="refresh" onClick={() => refresh()} />
                                            </Item>
                                            <Item name="columnChooserButton" />
                                            <Item name="exportButton" />
                                            <Item>
                                                <img
                                                    title='Clear Customization'
                                                    className="clean"
                                                    src={clean}
                                                    onClick={() => {
                                                        setClearCustomizationPopUp(true)
                                                    }}
                                                ></img>
                                            </Item>
                                        </Toolbar>
                                        <ColumnChooser enabled={true} />
                                        <Export enabled={true} allowExportSelectedData={false} />
                                        <ColumnFixing enabled={true} />
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={allowedPageSizes}
                                            showInfo={true}
                                        />
                                        <Paging defaultPageSize={20} enabled={true} />
                                        <FilterRow visible={true} />
                                        <Column cellRender={setStatus}
                                            allowReordering={false}
                                            width="400px"
                                            caption="Job Title"
                                            dataField="Job Title"
                                            editorOptions={{
                                                showClearButton: true
                                            }}
                                        />
                                        <Column cellRender={setStatus}
                                            width="200px"
                                            caption="Job Code"
                                            dataField="Job Code"
                                            editorOptions={{
                                                showClearButton: true
                                            }}
                                        />
                                        <Column cellRender={setStatus}
                                            width="300px"
                                            caption="Account Name"
                                            dataField="Account Name"
                                            editorOptions={{
                                                showClearButton: true
                                            }}
                                        />
                                    </DataGrid>
                                }
                            </Grid>
                            <Grid item md={1} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: ' 106px 0px 0px 8px', flexDirection: 'column' }}>
                                {/* <IconButton onClick={() => setCrmLocalJOb(true)}>
                                        <img src={rightarrow} style={{ width: '55px', height: '55px' }} className='ats-to-local-down-icon' />
                                    </IconButton> */}
                                <>
                                    <Tooltip placement='top' title="Convert ATS job to local job">
                                        <span onClick={() => setCrmLocalJOb(true)} className='ats-to-local-down-icon'>
                                            {/* <img title='Convert ATS job to local job' src={rightarrow} style={{ width: '55px', height: '55px' }} /> */}
                                            <IconButton sx={{ border: '1px solid #616161' }}>

                                                <ArrowForwardIcon fontSize="large" color='success'></ArrowForwardIcon>

                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </>
                            </Grid>
                            <Grid className='converted-job' item md={2} xs={12} sx={{ height: 400 }}>
                                <h2 style={{ margin: '25px' }}>Local Job</h2>
                                {
                                    <DataGrid
                                        dataSource={convertedLocalJobs}
                                        keyExpr="id"
                                        key="Candidate name"
                                        // onSelectionChanged={onSelectionChanged}
                                        // selectedRowKeys={viewArchiveList()}
                                        height="600px"
                                        width="100%"
                                        // showBorders='2'
                                        hoverStateEnabled
                                        showBorders={true}
                                        showRowLines
                                        allowColumnResizing={true}
                                        allowColumnReordering={true}
                                        onSelectionChanged={(ids) => {
                                            // const selectedLocalIDs = new Set(ids.selectedRowKeys);
                                            // const selectedLocalRows = convertedLocalJobsCopy.filter((row) =>
                                            //     selectedLocalIDs.has(row.id),*
                                            // );
                                            setLocalJobSelectedRows(ids.selectedRowsData);
                                        }}
                                    >
                                        {/* <HeaderFilter visible={true} /> */}
                                        <Selection
                                            mode="multiple"
                                        />
                                        <StateStoring
                                            enabled={(convertedLocalJobs.length > 0) ? true : false}
                                            type="custom"
                                        // customLoad={_loadState}
                                        // customSave={_saveState}
                                        />

                                        <Scrolling mode="standard" />
                                        <Lookup
                                            dataSource={convertedLocalJobs}
                                            valueExpr="ID"
                                            displayExpr="Name"
                                        />
                                        <ColumnFixing enabled={true} />
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={allowedPageSizes}
                                            showInfo={true}
                                        />
                                        <Paging defaultPageSize={20} enabled={true} />
                                        <FilterRow visible={true} />
                                        <Column
                                            width="350px"
                                            caption="Job Title"
                                            dataField="Job Title"
                                            editorOptions={{
                                                showClearButton: true
                                            }}
                                        />
                                    </DataGrid>
                                }
                                <Button variant="contained" style={{ top: '10px', right: '0px', float: 'right' }} onClick={() => saveData(true, localJobSelectedRows)}>Save</Button>
                            </Grid>
                        </Grid>
                        </>

                        : ''
                }
            </Box>
        </>
    );
};

export default CreateJob;

function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {

    const [filterAlert, setfilterAlert] = useState(false);

    return (
        <>
            <img
                title='Clear Filter'
                className="clean"
                src={ClearFiltericon}
                onClick={() => {
                    const isFilterApplied = dataGridRef.current.instance.getCombinedFilter();
                    if (isFilterApplied != undefined) {
                        clearFilters();
                        setfilterAlert(true);
                        setTimeout(() => {
                            setfilterAlert(false);
                        }, 3000);
                    }

                }}
            ></img>



            < SnackBar filterAlert={filterAlert} />
            {/* <SnackBar /> */}
        </>
    )
}

function SnackBar({ filterAlert }) {

    return (
        <>
            {
                filterAlert &&
                <Snackbar
                    open={filterAlert}
                    autoHideDuration={2000}
                    // message="Filter Removed Successfully"
                    // action={action}
                    // variant="filled"
                    variant="outlined"
                    severity="success"
                >
                    <Alert fontSize="small" variant="filled" severity="success">Filter Removed Successfully</Alert>

                </Snackbar>
            }
        </>
    )
}