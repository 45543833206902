import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { archiveInterviewListRow } from '../../handlers/job_handlers';
import DirectionSnackbarAlert from '../Alerts/SnakbarAlert';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';


const DialogHolder = ({ open, handleClose, dialogHandler, id, accessToken, userInfo, dialogTitle, setIsArchiveupdate }) => {
    let snackbarClassName = "";
    const [loading, setLoading] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState({
        status: false,
        loaderType: "",
        msg: "",
    });
    // function archiveInterviewListRow(accessToken, userInfo.userName, id) {

    // }
    async function archiveInterviewListRows() {
        await archiveInterviewListRow(accessToken, userInfo.userName, id)
            .then(res => {
                if (res.status) {
                    if (res.data.data.message === "Record Updated Successfully!!") {
                        setIsArchiveupdate('updated');
                        setLoading(false)
                        handleClose();
                        setLoaderStatus({
                            status: true,
                            loaderType: "archive_loader",
                            msg: "Record Moved To Archive List Successfully",
                        });
                        setTimeout(() => {
                            setIsArchiveupdate('idel');
                        }, 5000);
                    }
                }
                else if (res.data.data.message === "Record Not Updated!!") {
                    setLoaderStatus({
                        status: true,
                        loaderType: "archive_loader",
                        msg: "Record can't Moved To Archive List ",
                    });
                    // loadInterviewListData();  needs to change
                }
            })
        // if (res.status) {
        //     if (res.data.data.message === "Record Updated Successfully!!") {
        //         setIsArchiveupdate('updated')
        //         handleClose();
        //     }
        //     // setLoaderStatus({
        //     //     status: true,
        //     //     loaderType: "archive_loader",
        //     //     msg: "Record Moved To Archive List Successfully",
        //     // });
        //     //     //  loadInterviewListData(); needs to change
        //     //     setPopupVisible({ visible: false, mid: '' });
        //     //     setTimeout(() => {
        //     //         setLoaderStatus({
        //     //             status: false,
        //     //             loaderType: "",
        //     //             msg: "",
        //     //         });
        //     //     }, 1000)

        //     // } else if (res.data.data.message === "Record Not Updated!!") {
        //     //     setLoaderStatus({
        //     //         status: true,
        //     //         loaderType: "archive_loader",
        //     //         msg: "Record Moved To Archive List Successfully",
        //     //     });
        //     //     // loadInterviewListData();  needs to change
        //     // }
        // }


    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{
                    backdropFilter: 'blur(4px)', backgroundColor: 'rgba(111, 126, 140, 0.2)', transition: 'opacity 100ms ease 0s', zIndex: 1600
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ background: '#3aa1f0', marginBottom: '3rem', color: "white" }}>
                    {"Alert"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {dialogTitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        archiveInterviewListRows();
                        setLoading(true)
                    }} color='success' variant="contained">
                        Yes {
                            loading && <Box sx={{ display: 'flex', m: 1.5, position: 'relative' }}>
                                <CircularProgress size={24}
                                    sx={{
                                        color: 'wheat',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }} />
                            </Box>
                        }
                    </Button>
                    <Button onClick={handleClose} color='error' variant="contained">No</Button>
                </DialogActions>
            </Dialog>
            {/* {
            true && (
                <DirectionSnackbarAlert
                    snackbarDisplay={true}
                    message={loaderStatus.msg}
                    className={snackbarClassName}
                    templateUpdater={null}
                />
            )} */}
        </>
    );
}
export default DialogHolder;