import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Divider, IconButton, Tooltip } from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ feedback }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title='Feedback' placement="top">
                <IconButton onClick={handleClickOpen} sx={{ background: 'white', padding: '0 10px' }} color='info' variant="contained">
                    <FeedbackIcon fontSize="large" sx={{ paddingLeft: '5px' }} />
                </IconButton>
            </Tooltip>
            <Dialog 
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Feedback"} </DialogTitle>
                <Divider sx={{ margin: '10px' }} />
                <DialogContent sx={{ width: '550px' }}>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ color: feedback !== 'No feedback found' ? 'black' : 'lightgray' }}>
                        {
                            feedback
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
