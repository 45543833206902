import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function DirectionSnackbar({ snackbarDisplay, message, className, templateUpdater }) {
    const [open, setOpen] = React.useState(snackbarDisplay);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (templateUpdater !== null) {
            templateUpdater('idle');
        }
        setOpen(false);
    };

    let classNameStatus = 'warning';
    if (className === 'template-update-snackbar' || className === 'question-update-snackbar') {
        classNameStatus = 'success';
    }
    else if (className === 'question-update-error-snackbar') {
        classNameStatus = 'warning';
    }

    return (
        <Stack spacing={2} sx={{ width: '80%' }}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} className={className}>
                <Alert onClose={handleClose} severity={classNameStatus} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}
