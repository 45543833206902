import * as React from "react";
import "./InterviewList.css";
import { useUserAuthContext } from "../../../utils/Contexts/Auth/UserAuthProvider";
import { useEffect, useState, useRef } from "react";
import { InterviewListData } from "../../../handlers/job_handlers";
import FadeMenu from "./FadeMenu";
import DirectionSnackbarAlert from "../../Alerts/SnakbarAlert";
import { getEmailTemplate } from "../../../handlers/mail_handler";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

import {
  deleteInterviewListRow,
  archiveInterviewListRow,
  EditInterview,
} from "../../../handlers/job_handlers";
import { filterDataList } from "../../../utils/Helper/filterDataList";
import { LoadPanel } from "devextreme-react/load-panel";

import { Button as Button2 } from "devextreme-react/button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import clean from "../../../Assets/icons/clean.png";
import ClearFiltericon from "../../../Assets/icons/ClearFiltericon.png";
// import Scheduler from 'devextreme-react/scheduler';

const allowedPageSizes = [20, 50, 100];
const renderCell = (cellInfo) => {
  {
    /* Declare custom cell content here */
  }
};

const renderHeaderCell = (headerInfo) => {
  {
    /* Declare custom header content here */
  }
};
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

import DataGrid, {
  Column,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
  Pager,
  ColumnChooser,
  Sorting,
  Lookup,
  Item,
  Toolbar,
  Export,
  ColumnFixing,
  Editing,
  // Box,
  Button,
  StateStoring,
  FilterPanel
} from "devextreme-react/data-grid";
// import { Button } from 'devextreme-react/button';
import {
  GridCustomization,
  GridCustomizeGet,
} from "../../../handlers/customize";
import { useCallback } from "react";
import { SnakbarAlertCommon } from '../../Alerts/SnakbarAlertCommon';
import CustomStore from "devextreme/data/custom_store";
import DeferRendering from "devextreme/ui/defer_rendering";
const initialColumnStructure = {
  searchText: "",
  allowedPageSizes: [20, 50, 100],
  filterPanel: { filterEnabled: true },
  pageIndex: 0,
  columns: [
    { visible: true, visibleIndex: 0, name: "buttons", width: "150px" },
    {
      dataField: "id",
      visible: true,
      visibleIndex: 1,
      dataType: "number",
      name: "id",
      allowSorting: false,
      width: "50px"
    },
    {
      dataField: "CandidateName",
      visible: true,
      visibleIndex: 2,
      dataType: "string",
      name: "CandidateName",
      width: "255px",
    },
    {
      dataField: "Email",
      visible: true,
      visibleIndex: 3,
      dataType: "string",
      name: "Email",
      width: "240px",

    },
    {
      dataField: "Contact No",
      visible: true,
      visibleIndex: 4,
      dataType: "string",
      name: "Contact No",
      width: "100px",
    },
    {
      dataField: "JobName",
      visible: true,
      visibleIndex: 5,
      dataType: "string",
      name: "JobName",
      width: "325px"
    },
    {
      dataField: "Status",
      visible: true,
      visibleIndex: 6,
      dataType: "string",
      name: "Status",
      width: "150px"
    },
    {
      dataField: "Interview_Start_Date_Time",
      visible: true,
      visibleIndex: 7,
      dataType: "date",
      name: "Interview_Start_Date_Time",
      width: "170px"
    },
    {
      dataField: "Interview_End_Date_Time",
      visible: true,
      visibleIndex: 8,
      dataType: "date",
      name: "Interview_End_Date_Time",
      width: "180px"
    }
  ],
  pageSize: 20,
};
const mapDataGridFeildsForServerSideSorting = {
  "id": 'id',
  "CandidateName": 'candidateName',
  "Email": 'candidateEmail',
  "Contact No": 'candidateNumber',
  "JobName": 'jobName',
  // "Email": 'recruiterEmailId',
  "Status": 'status',
  "Interview_Start_Date_Time": 'startDate',
  "Interview_End_Date_Time": 'endDate',
  "updatedAt": "updatedAt"
};
const mapDataGridConstraintsForServerSideFiltering =
{
  endswith: 'endwith',
  startswith: 'startwith',
  notcontains: 'notcontains',
  contains: 'contains',
  '=': 'equal',
  '<>': 'notequal',
  '<': 'dateless',
  '>': 'dategreater',
  '>=': 'dategreaterequal',
  '<=': 'datelessequal'
}
const mapDateDataGridConstraintsForServerSideFiltering =
{
  endswith: 'endwith',
  startswith: 'startwith',
  notcontains: 'notcontains',
  contains: 'contains',
  '=': 'daterange',
  '<>': 'notequaldate',
  '<': 'dateless',
  '>': 'dategreater',
  '>=': 'dategreaterequal',
  '<=': 'datelessequal',
  'between': 'daterange',
}
const gridStatusValue = {
  "Interview InProcess": "interviewInProcess",
  "interview in process": "interviewInProcess",
  "Interview In Process": "interviewInProcess",
  "Interview completed": "interviewcompleted",
  "interview completed": "interviewcompleted",
  "Interview Completed": "interviewcompleted",
  "Interview Link Expired": "interviewLinkExpired",
  "Interview LinkExpired": "interviewLinkExpired",
  "interview linkexpired": "interviewLinkExpired",
  "interview link expired": "interviewLinkExpired",
  "InterviewLinkExpired": "interviewLinkExpired",
  "interviewLinkExpired": "interviewLinkExpired",
  "interviewlinkexpired": "interviewLinkExpired",
  // "expired": "interviewLinkExpired",
  // "Expired": "interviewLinkExpired",
  "Interview Pending": "interviewPending",
  "interview pending": "interviewPending",
  "Interview Disqualified": "interviewDisqualified",
  "interview disqualified": "interviewDisqualified",
  "Unsent": "Unsent",
  "unsent": "Unsent",
  "UNSENT": "Unsent",

}
const serverError = [500, 502, 503, 504, 400, 404];

function InterviewList() {
  const [loaderStatus, setLoaderStatus] = useState({
    status: false,
    loaderType: "",
    msg: "",
  });
  const [open, setOpen] = useState({
    openType: "",
    id: "",
    alertTitle: "",
    handler: null,
  });
  const [snakbarOpen, setSnakbarOpen] = useState({ show: false, msg: "" });
  const pageNumber = useRef({});
  const [filteredData, setFilteredData] = useState([]);
  const { accessToken, userInfo, userRole } = useUserAuthContext();
  const [jobList, setJobList] = useState([]);
  const [tableLoaderErrInfo, setTableLoaderErrInfo] = useState("Loading");
  // const [pageSize, setPageSize] = React.useState(20);
  const [DGPageNumber, setDGPageNumber] = useState(0);
  // const [selectionModel, setSelectionModel] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(10);
  // const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [loaderCircular, setLoaderCircular] = useState(true);
  const [listData, setListData] = React.useState([]);
  const [customizationData, setCustomizationData] = React.useState(null);
  // const [eventHandler, setEventHandler] = React.useState({ type: "", mid: "" })
  const [reschedulehandler, SetReschedulehandler] = React.useState({
    type: "",
    rowData: "",
  });
  const reset_custom = useRef(initialColumnStructure);
  const [resetCall, setResetCall] = React.useState("idle");
  const [_popupVisible, setPopupVisible] = React.useState({ visible: false, mid: '' });
  const [CommonAlert, setCommonAlert] = React.useState({ apiErrorStatus: false, message: '' })
  const [clearCustomizationPopUp, setClearCustomizationPopUp] = React.useState(false);
  const [archiveList, setArchiveList] = React.useState('idle');
  const DateFilterExpressionRef = React.useRef('');
  const DateFilterStatusRef = React.useRef({ startDate: 'idle', endDate: 'idle' });
  const CustomizationApiDebounceInterval = React.useRef({ post: '', get: '' });
  const loadOptionsQuery = React.useRef({});
  const dataGridRef = useRef(null);

  // const onSelectionChanged = e => {
  //   console.log("Processing", e.selectedRowKeys);
  //   setSelectedMembers(e.selectedRowKeys);
  // };

  const deleteInterviewRow = async (mid) => {
    //console.log(mid);
    try {
      // setLoaderStatus({ status: true, loaderType: 'delete_loader', msg: 'Deleting Record..' });
      const res = await deleteInterviewListRow(
        accessToken,
        userInfo.userName,
        mid
      );
      // console.log(res);
      if (res.status) {
        if (res.data.data.message === "Record Updated Successfully!!") {
          setLoaderStatus({
            status: true,
            loaderType: "delete_loader",
            msg: "Record Deleted Successfully",
          });
          // setSnakbarOpen({ show: true, msg: "Record Deleted Successfully" });
          setTimeout(() => {
            setLoaderStatus({ status: false, loaderType: "", msg: "", });
          }, 2000);
          //  loadInterviewListData(); // needs to modify
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const moveInterviewRowToArchive = async () => {
    const mid = _popupVisible.mid;
    try {
      setLoaderStatus({
        status: true,
        loaderType: "archive_loader",
        msg: "Moving Record To Archive..",
      });
      const res = await archiveInterviewListRow(
        accessToken,
        userInfo.userName,
        mid
      );
      //  console.log(res);
      if (res.status) {
        if (res.data.data.message === "Record Updated Successfully!!") {
          setLoaderStatus({
            status: true,
            loaderType: "archive_loader",
            msg: "Record Moved To Archive List Successfully",
          });
          //  loadInterviewListData(); needs to change
          setPopupVisible({ visible: false, mid: '' });
          setTimeout(() => {
            setLoaderStatus({
              status: false,
              loaderType: "",
              msg: "",
            });
          }, 1000)
        } else if (res.data.data.message === "Record Not Updated!!") {
          setLoaderStatus({
            status: true,
            loaderType: "archive_loader",
            msg: "Record Moved To Archive List Successfully",
          });
          // loadInterviewListData();  needs to change
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const loadState = useCallback(() => {
    // clearTimeout(CustomizationApiDebounceInterval.current.get)
    // CustomizationApiDebounceInterval.current.get = setTimeout(() => {
    let data = {};
    if (resetCall === "active") {
      data = reset_custom.current;
      setResetCall("inactive");
      setClearCustomizationPopUp(false)
    }
    else if (resetCall === "idle") {
      data = { ...customizationData }
    }
    return data;
    // }, 2000);
  }, [customizationData, resetCall]);
  // console.log("fixxxxxx", loadState());
  React.useEffect(() => {
    if (accessToken && userInfo.userName) {
      const interviewList = "interview-list";
      GridCustomizeGet(accessToken, userInfo.userName, interviewList)
        .then(
          (res) => {
            if (res.status) {
              setCustomizationData(res.data.data);
            }
            else {
              throw { code: res.code, message: res.message };
            }
          })
        .catch(err => {
          if (serverError.includes(err.code)) {
            setCommonAlert({ apiErrorStatus: true, message: err.message });
          }
        });
    }
  }, [accessToken, userInfo.userName]);
  React.useEffect(() => {
    if (accessToken && userInfo.userName) {
      const interviewList = "interview-list";
      if (loadState.columns != undefined) {
        GridCustomization(accessToken, userInfo.userName, loadState, interviewList)
          .then(res => {
            // console.log(res.status, 'res', res);
            if (res.status) {
              // console.log(res);
            }
          })
      }
    }
  }, [accessToken, userInfo.userName])

  // const process = () => {
  //   selectedMembers.forEach((m) =>
  //     console.log(m)
  //   );
  // };
  // React.useEffect(() => {
  //   if (accessToken && userInfo.email && userRole) {
  //     InterviewListData(accessToken, userInfo.email, userRole).then((res) => {
  //       console.log(res);
  //       if (res.status) {
  //         if (res.data.data === null) {
  //           console.log("null value");
  //         } else {
  //           setListData(res.data.data.response);
  //         }
  //         setListData(res.data.data.response);
  //       }
  //       else {
  //         throw { code: res.code, message: res.message };
  //      }
  //     })
  //       .catch((err) => {
  //         if (serverError.includes(err.code)) {
  //           setTableLoaderErrInfo(err.message);
  //           setCommonAlert({ apiErrorStatus: true, message: err.message });
  //         } else {
  //           setTableLoaderErrInfo("Contact to Administrator")
  //         }
  //       });
  //   }
  // }, [accessToken, userRole, userInfo]);
  useEffect(() => {
    const hs = window.history.state;
    window.history.pushState(hs, "", "/interview/interview-list");
  }, []);

  // useEffect(() => {
  //   if (accessToken && userInfo.email && userRole) {
  //     //loadInterviewListData();
  //     // InterviewListData(accessToken, userInfo.email, userRole)
  //     //   .then(res => {
  //     //     if (res.data.data === null) {
  //     //       setTableLoaderErrInfo('No record found')
  //     //     }
  //     //     else {
  //     //       setJobList(res.data.data.response)
  //     //       setTableLoaderErrInfo('')
  //     //     }
  //     //   })
  //     //   .catch(err => setTableLoaderErrInfo('Contact to Administrator'))
  //   }
  // }, [accessToken, userInfo, userRole]);
  /*
  function loadInterviewListData() {
    setTableLoaderErrInfo("loading");
    InterviewListData(accessToken, userInfo.email, userRole)
      .then((res) => {
        if (res.status) {
          if (res.data.data === null) {
            setTableLoaderErrInfo("No record found");
            closeLoaders();
          } else {
            setJobList(res.data.data.response);
            setTableLoaderErrInfo("");
            closeLoaders();
          }
        }
        else {
          throw { code: res.code, message: res.message };
        }
      })
      .catch((err) => {
        if (serverError.includes(err.code)) {
          setTableLoaderErrInfo(err.message);
          setCommonAlert({ apiErrorStatus: true, message: err.message });
        } else {
          setTableLoaderErrInfo("Contact to Administrator")
        }
      });
  }
  */
  useEffect(() => {
    if (jobList.length) {
      setFilteredData(jobList);
    }
  }, [jobList]);
  useEffect(() => {
    if (!filteredData.length) {
      // setTableLoaderErrInfo("No record found");
    }
  }, [filteredData]);
  React.useEffect(() => {
    if (listData.length) {
      setFilteredData(listData);
      setLoaderCircular(false);
    }
  }, [listData]);
  function GetInterveiwsList() {
    const arr = sortElements([...filteredData], "startDate").map(
      (item, index) => {
        let startTime = "";
        // console.log(item);
        if (item.startDate) {
          const startDate = new Date(item.startDate * 1000).toDateString()
          const myArray = startDate.split(" ");
          const localTimeString = new Date(item.startDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
          // const localTime = localTimeString.slice(0, 11);
          const localTime = localTimeString.slice(0, 8);
          // const timeFormat = localTimeString.slice(9, 12);
          // console.log('local', localTime, timeFormat);
          const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
          startTime = sDate + " " + localTime;
          // console.log(startTime, "startTime", localTimeString);
        }
        let endTime = "";
        if (item.endDate) {
          //  console.log(item.endDate);
          const endDate = new Date(item.endDate * 1000).toDateString();
          const myArray = endDate.split(" ");
          const localTimeString = new Date(item.endDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })
          const localTime = localTimeString.slice(0, 8);
          // const timeFormat = localTimeString.slice(9, 12);
          // console.log('local', localTime, timeFormat);
          const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
          endTime = sDate + " " + localTime;
          // console.log(endTime);
        }
        return {
          id: index + 1,
          CandidateName: item.candidateName.trim(),
          Email: item.candidateEmail,
          "Contact No": item.candidateNumber,
          JobName: item.jobName,
          Status: item.status,
          Interview_Start_Date_Time: startTime,
          Interview_End_Date_Time: endTime,
          mid: item.id,
          questionnairesId: item.questionnairesId,
          item,
        };
      }
    );
    return arr;
  }
  function closeLoaders() {
    setTimeout(() => {
      setLoaderStatus({ status: false, loaderType: "", msg: "" });
    }, 2000);
  }
  function sortElements(array, constraint) {
    return array.sort((a, b) => {
      if (new Date(a[constraint]) < new Date(b[constraint])) return 1;
      else if (new Date(a[constraint]) > new Date(b[constraint])) return -1;
      return 0;
    });
  }
  let snackbarClassName = "";
  if (loaderStatus.loaderType === "record_deleted") {
    snackbarClassName = "question-update-snackbar";
  }
  // function onRowRemoving(e) {
  //   // console.log("delete", e);
  // }
  const saveState = useCallback(
    (state) => {
      // clearTimeout(CustomizationApiDebounceInterval.current.post)
      // CustomizationApiDebounceInterval.current.post = setTimeout(() => {
      // console.log(CustomizationApiDebounceInterval.current.post, 'CustomizationApiDebounceInterval.current.post');
      const interviewList = "interview-list";
      // setLoaderCircular(true); commented due to loading issue arised
      let tableData = "";
      if (resetCall === "inactive" && !checkstate) {
        tableData = state;
      } else {
        tableData = reset_custom.current;
      }
      // console.log('saveState', state);
      // const modifiedColumns = [...state.columns].map(item => {
      //   item.columns
      //   if (item.dataField === 'Interview_Start_Date_Time') {
      //     item.filterValue = null;
      //   }
      //   return item;
      // });
      // const modifiedState = { ...state, filterValue: null, columns: modifiedColumns };
      // console.log(modifiedState, 'modifiedState', DateFilterStatusRef.current);
      //return;


      if (userInfo.userName && DateFilterStatusRef.current.startDate != undefined && DateFilterStatusRef.current.endDate != undefined) {
        GridCustomization(
          accessToken,
          userInfo.userName,
          state,
          interviewList
        ).then((res) => {
          if (res.status === false) {
            throw { code: res.code, message: res.message };
          }
          //setLoaderCircular(false); commented due to loading issue arised
        })
          .catch(err => {
            if (serverError.includes(err.code)) {
              // setCommonAlert({ apiErrorStatus: true, message: err.message });
            }
          });
      }
      //}, 2000);
    },
    [userInfo, accessToken]
  );
  const filterDateFormat = "dd-MMM-yyyy HH:mm:ss";
  //const _InterviewListData = GetInterveiwsList();
  const emailButtonOptions = {
    icon: 'movetofolder',
    text: 'Move',
    type: 'success',
    onClick: moveInterviewRowToArchive
  };
  const closeButtonOptions = {
    icon: 'remove',
    text: 'Close',
    type: 'danger',
    onClick: () => { setPopupVisible({ visible: false, mid: '' }) },
  }
  const customChanges = {
    icon: 'undo',
    text: 'Clear',
    type: 'default',
    onClick: () => setResetCall('active'),
  }
  const cancelCustomchanges = {
    text: 'Close',
    icon: 'remove',
    type: 'danger',
    onClick: () => { setClearCustomizationPopUp(false) },
  }
  // console.log(_InterviewListData, '_InterviewListData');
  function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
  }
  const store = new CustomStore({
    key: "id",
    // loadMode: "raw",

    async load(loadOptions) {

      const array = loadOptions.filter;
      // const fvalue = array[2];


      const searchfilters = loadOptions.filter;

      if (loadOptions.filter) {
        if (loadOptions.filter.includes('and')) {
          searchfilters.map((filter, index) => {
            let array = filter;
            if (filter instanceof Array) {
              array[2] = encodeURIComponent(array[2]);
            }
            return array;
          });

        }
        else {
          searchfilters[2] = encodeURIComponent(searchfilters[2])
        }
      }
      loadOptions.filter = searchfilters;


      loadOptionsQuery.current = loadOptions;
      if (userInfo.email == undefined) {
        return {
          data: [],
          totalCount: 0
        }
      }
      let params = "?";
      [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary"
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) {

          params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        }
      });
      // console.log(loadOptions, 'loadOptions');
      const paramss = new URLSearchParams(params);
      const skip = paramss.get('skip');
      const take = paramss.get('take');
      let sortArray = {};
      const _filterData = [];

      // return {
      //   data: [],
      //   totalCount: 0                                                                        
      // }

      if (paramss.get('filter') != null) {

        const filterValue = JSON.parse(paramss.get('filter'));

        filterValue.forEach((item, index) => {
          if (item instanceof Array) {
            if (item[0] == "Status" && item[1] == 'contains') {
              let filterSearchArray = item[2].split(" ");
              let filterSearch = '';

              for (let filterSearchArrayItem of filterSearchArray) {
                filterSearch += filterSearchArrayItem;
              }

              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: filterSearch
              });
            }
            else if (item[0] == "Status" && item[1] == "=") {
              let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
              if (boxFilteredValue == undefined) {
                boxFilteredValue = item[2]
              }
              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
              });
            }
            else if (item[0] == "Status" && item[1] == "notcontains") {
              let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
              if (boxFilteredValue == undefined) {
                boxFilteredValue = item[2]
              }
              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue.replace(/\s{1,}/g, '')
                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
              });
            }
            else if (item[0] == "Status" && item[1] == "<>") {
              let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
              if (boxFilteredValue == undefined) {
                boxFilteredValue = item[2]
              }
              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
              });
            }
            else if (item[0] == "Status" && item[1] == "startswith") {
              let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
              if (boxFilteredValue == undefined) {
                boxFilteredValue = item[2]
              }
              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
              });
            }

            else if (item[0] == "Status" && item[1] == "endswith") {
              let boxFilteredValue = gridStatusValue[item[2].toLowerCase()];
              if (boxFilteredValue == undefined) {
                boxFilteredValue = item[2]
              }
              _filterData.push({
                key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                [mapDataGridFeildsForServerSideSorting[item[0]]]: boxFilteredValue
                // [mapDataGridFeildsForServerSideSorting[item[0]]]: gridStatusValue[item[2]]
              });
            }

            else if (item[0] == 'datefilter') {
              _filterData.push({
                key: item[2],
                [mapDataGridFeildsForServerSideSorting[item[1]]]: item[3]
              });
            }
            else {
              _filterData.push(
                {
                  key: mapDataGridConstraintsForServerSideFiltering[item[1]],
                  [mapDataGridFeildsForServerSideSorting[item[0]]]: item[2]
                });
            }
          }
        });
        if (_filterData.length === 0) {
          if (filterValue[0] == "Status" && filterValue[1] == 'contains') {
            let filterSearchArray = filterValue[2].split(" ");
            let filterSearch = '';

            for (let filterSearchArrayItem of filterSearchArray) {
              filterSearch += filterSearchArrayItem;
            }

            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterSearch
            });
          }
          else if (filterValue[0] == "Status" && filterValue[1] == "=") {
            let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
            if (boxFilteredValue == undefined) {
              boxFilteredValue = filterValue[2]
            }
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
            });
          }
          else if (filterValue[0] == "Status" && filterValue[1] == "notcontains") {

            let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
            if (boxFilteredValue == undefined) {
              boxFilteredValue = filterValue[2]
            }
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue.replace(/\s{1,}/g, '')
            });
          }
          else if (filterValue[0] == "Status" && filterValue[1] == "<>") {
            let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
            if (boxFilteredValue == undefined) {
              boxFilteredValue = filterValue[2]
            }
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
            });
          }
          else if (filterValue[0] == "Status" && filterValue[1] == "startswith") {

            let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
            if (boxFilteredValue == undefined) {
              boxFilteredValue = filterValue[2]
            }
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
            });
          }
          else if (filterValue[0] == "Status" && filterValue[1] == "endswith") {

            let boxFilteredValue = gridStatusValue[filterValue[2].toLowerCase()];
            if (boxFilteredValue == undefined) {
              boxFilteredValue = filterValue[2]
            }
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: boxFilteredValue
            });
          }
          else if (filterValue[0] === 'datefilter') {
            _filterData.push({
              key: filterValue[2],
              [mapDataGridFeildsForServerSideSorting[filterValue[1]]]: filterValue[3]
            });
          }
          else {
            _filterData.push({
              key: mapDataGridConstraintsForServerSideFiltering[filterValue[1]],
              [mapDataGridFeildsForServerSideSorting[filterValue[0]]]: filterValue[2]
            });
          }
        }
      }
      _filterData.forEach(item => {
        if (item.key === 'daterange') {
          if (item.startDate != undefined) {
            if (item.startDate.toLowerCase().includes('undefined') || item.startDate.toLowerCase().includes('Date-undefined')) {
              DateFilterStatusRef.current = { ...DateFilterStatusRef.current, startDate: undefined }
            }
            else {
              DateFilterStatusRef.current = { ...DateFilterStatusRef.current, startDate: 'idle' }
            }
          }
          if (item.endDate != undefined) {
            if (item.endDate.toLowerCase().includes('undefined') || item.endDate.toLowerCase().includes('Date-undefined')) {
              DateFilterStatusRef.current = { ...DateFilterStatusRef.current, endDate: undefined }
            }
            else {
              DateFilterStatusRef.current = { ...DateFilterStatusRef.current, endDate: 'idle' }
            }
          }
        }
      });
      const selectorObj = { selector: 'updatedAt', desc: 'true' };
      if (paramss.get('sort') != null) {
        sortArray = JSON.parse(paramss.get('sort'))
        selectorObj.selector = sortArray[0].selector;
        selectorObj.desc = sortArray[0].desc;
      }
      // const { selector, desc } = sortArray[0];
      // params.slice(0, -1); 
      params = params.slice(0, -1);

      // GetCDSJobs(accessToken, copyUserInfo, userRole)
      try {

        let totalCount = 0;
        let array = [];
        const res = await InterviewListData(accessToken, userInfo.email, userRole, (skip / take) + 1, take, mapDataGridFeildsForServerSideSorting[selectorObj.selector], selectorObj.desc, _filterData);
        // const array = data.data.data.response

        //  return
        if (res.status) {
          if (res.data.status === 'succeed') {
            if (res.data.data !== null) {
              array = [...res.data.data.response].map((item, index) => {
                let startTime = "";
                if (item.startDate) {
                  const startDate = new Date(item.startDate * 1000).toDateString();
                  const _startTime = new Date(item.startDate * 1000);
                  const myArray = startDate.split(" ");
                  // const localTimeString = new Date(item.startDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour24: true });
                  // const localTime = localTimeString.slice(0, 11);
                  // const localTime = localTimeString.slice(0, 8);
                  const localTime = formatDateTo24Hour(_startTime);
                  // const timeFormat = localTimeString.slice(9, 12);
                  const sDate = myArray[2] + "-" + myArray[1] + "-" + myArray[3];
                  startTime = sDate + " " + localTime;
                }
                let endTime = "";
                if (item.endDate) {
                  const endDate = new Date(item.endDate * 1000).toDateString();
                  const _endTime = new Date(item.endDate * 1000);
                  const myArray_1 = endDate.split(" ");
                  // const localTimeString_1 = new Date(item.endDate * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour24: true });
                  // const localTime_1 = localTimeString_1.slice(0, 8);
                  const localTime_1 = formatDateTo24Hour(_endTime);
                  // const timeFormat = localTimeString.slice(9, 12);
                  const sDate_1 = myArray_1[2] + "-" + myArray_1[1] + "-" + myArray_1[3];
                  endTime = sDate_1 + " " + localTime_1;
                };
                let interviewStatus = '';


                if (item.status.includes('interviewLinkExpired')) {
                  interviewStatus = item.status.slice(0, 9).charAt(0).toUpperCase() + item.status.slice(1, 9) + ' ' + item.status.slice(9, 13) + ' ' + item.status.slice(13, 20);
                }
                else {
                  interviewStatus = item.status.slice(0, 9).charAt(0).toUpperCase() + '' + item.status.slice(0, 9).slice(1) + ' ' + item.status.slice(9);
                }

                // console.log(interviewStatus);


                // if (checkTimeDifference(item.endDate * 1000) === 'link_expired') {
                //   interviewStatus = 'Link Expired';
                // }


                let waningCountText = [];
                if (item.candidateWarnings != undefined) {
                  waningCountText = item.candidateWarnings.map(warning => {
                    return warning.warningCount
                  });
                }
                waningCountText = waningCountText.join();



                return {
                  id: index + 1,
                  CandidateName: item.candidateName.trim(),
                  Email: item.candidateEmail,
                  "Contact No": item.candidateNumber ? item.candidateNumber : 'N/A',
                  JobName: item.jobName,
                  Status: interviewStatus + '-' + waningCountText,
                  Interview_Start_Date_Time: startTime,
                  Interview_End_Date_Time: endTime,
                  mid: item.id,
                  questionnairesId: item.questionnairesId,
                  item,
                };
              });
              totalCount = res.data.data.count
            }
            else {
              setTableLoaderErrInfo('Record Not Found')
            }
          }
        }
        return {
          data: array,
          totalCount: totalCount
        };
      } catch {
        setTableLoaderErrInfo("Please Contact To Administrator")
        throw new Error("Data Loading Error");
      }
    },
    async remove(loadOptions) {
      return;
    }
  });
  function getStatusColor(data) {

    let color = '';
    if (data.Status === 'Interview completed') {
      return color = 'green'
    }
    else if (data.Status === 'Interview InProcess') {
      return color = 'blue'
    }
    else if (data.Status === 'Interview Disqualified') {
      return color = 'red'
    }
    else if (data.Status.trim() === 'Unsent') {
      return color = '#aa6d6b'
    }
    else if (data.Status === 'Interview Link Expired') {
      return color = 'rgb(255 46 46 / 56%)'
    }

    else {
      color = '#ffa100'
    }
    return color
  };
  function setStatus(e) {
    //     if(e.column.name === 'Interview_Start_Date_Time'){
    // return <div style={{ color: getStatusColor(e.data) }}>{e.text}</div>
    //     }
    // let statusText = e.data.Status.split()
    // if (data.Status === 'Interview completed') {
    //   // return color = 'green'
    // }
    let statusData = {
      Status: ''
    };

    let statusTextArray = e.data.Status.split('-');
    statusData.Status = statusTextArray[0];
    let warning = '';
    if (statusTextArray[1] != '') {
      //warning = statusTextArray[1].split(',').length;
      //if (warning > 2) {
      let generatedWarningText = '';
      e.data.item.candidateWarnings.forEach(warning => {
        let timeInMin = warning.warningTime / 60;
        timeInMin = timeInMin.toString().substring(0, 4)
        generatedWarningText += `\nWarning: ${warning.warningCount}, Time: ${timeInMin} min`;
      })


      warning = warning + generatedWarningText;
      //  }
      // else {
      //   warning = warning + ' Warning(s)';
      // }
    }
    return <div style={{ color: getStatusColor(statusData), fontWeight: '500' }} title={warning}>{statusData.Status}</div>
  };
  function setStatusName(e) {
    let statusData = {
      Status: ''
    };
    //if(e.data.Status === 'Interview completed'){
    let statusTextArray = e.data.Status.split('-');
    statusData.Status = statusTextArray[0];
    let warning = '';
    if (statusTextArray[1] != '') {
      warning = statusTextArray[1];
    }
    return <div style={{ color: getStatusColor(statusData), fontWeight: '500' }}>{e.text}</div>
  };
  const renderButton = (event) => {

    return <FadeMenu
      reschedulehandler={reschedulehandler}
      data={event.row.data}
      store={store}
      refresh={refresh}
      setArchiveList={setArchiveList}
      loadOptionsQuery={loadOptionsQuery}
    />
  }
  function calculateFilterExpression(value, selectedFilterOperations, target) {
    DateFilterExpressionRef.current = selectedFilterOperations;
    const column = this;
    if (target === 'headerFilter' && value === 'weekends') {
      return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
    }
    if (selectedFilterOperations === "between") {
      const filterExpression = [this.dataField, 'darterange', value];
      // return filterExpression;
    }
    if ((this.dataField === 'Interview_Start_Date_Time') || (this.dataField === 'Interview_End_Date_Time')) {
      let dateText = '';
      let commonDateValue = value;
      if (selectedFilterOperations === "between") {

        /* code commented on 21-04-2023
        let dateFrom = new Date(value[0]).toLocaleDateString().split('/');
        let dateTo = new Date(value[1]).toLocaleDateString().split('/');
        let zeroIndex = dateFrom[0];
        let firstIndex = dateFrom[1];
        let datetozeroIndex = dateTo[0];
        let datetofirstIndex = dateTo[1];
        if (dateFrom[0] < 10) {
          zeroIndex = '0' + dateFrom[0];
        }
        if (dateFrom[1] < 10) {
          firstIndex = '0' + dateFrom[1];
        }
        if (dateTo[0] < 10) {
          datetozeroIndex = '0' + dateTo[0];
        }
        if (dateTo[1] < 10) {
          datetofirstIndex = '0' + dateTo[1];
        }

        dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;
        dateTo = dateTo[2] + '-' + datetozeroIndex + '-' + datetofirstIndex;
        */

        let dateFrom = generateDates(value[0]);
        let dateTo = generateDates(value[1]);
        commonDateValue = dateFrom + 'TO' + dateTo;



      }
      else {
        /* code commented on 21-04-2023
        let dateFrom = new Date(value).toLocaleDateString().split('/');
        let zeroIndex = dateFrom[0];
        let firstIndex = dateFrom[1];
        if (dateFrom[0] < 10) {
          zeroIndex = '0' + dateFrom[0];
        }
        if (dateFrom[1] < 10) {
          firstIndex = '0' + dateFrom[1];
        }
        dateFrom = dateFrom[2] + '-' + zeroIndex + '-' + firstIndex;
        */
        let dateFrom = generateDates(value);
        if (selectedFilterOperations === '=') {
          commonDateValue = dateFrom + 'TO' + dateFrom;
        }
        else {
          commonDateValue = dateFrom;
        }
      }
      const filterExpression = [
        'datefilter',
        this.dataField,
        // selectedFilterOperations,
        mapDateDataGridConstraintsForServerSideFiltering[selectedFilterOperations],
        commonDateValue
      ];
      return filterExpression
    }
    return column.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
  }




  function generateDates(value) {

    if (value) {
      const today = new Date(value);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm
      }

      return `${yyyy}-${mm}-${dd}`;

    }
    else {
      return undefined;
    }
  }



  function checkTimeDifference(endDate) {

    if (new Date().getTime() > new Date(endDate).getTime()) {
      return 'link_expired';
    }
    return 'link_active';
  }



  const clearFilters = (e) => {

    if (dataGridRef.current) {
      dataGridRef.current.instance.clearFilter();
    }

  };



  const refresh = () => {
    if (dataGridRef.current) {
      dataGridRef.current.instance.refresh()
    }
  }

  const formatDateTo24Hour = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };
  return (
    <>


      <SnakbarAlertCommon CommonAlert={CommonAlert} setCommonAlert={setCommonAlert} />
      {/* <div className='dev-extreme-datagrid-list' style={{ height: "90vh" }}> */}
      <div className="popUpdiv">
        <Popup
          visible={_popupVisible.visible}
          // onHiding={hideInfo}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={false}
          showTitle={true}
          container="body"
          width={500}
          height={200}
          title="Alert"
        >
          <Position
            my="center"
            of={'body'}
            collision="fit"
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={emailButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={closeButtonOptions}
          />
          <p>Are you sure you want to move this record into archive?</p>
        </Popup>
      </div>
      <Popup
        visible={clearCustomizationPopUp}
        // onHiding={hideInfo}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        container="body"
        width={500}
        height={200}
        title="Alert"
      >
        <Position
          my="center"
          of={'main_dataGridClass'}
          collision="fit"
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={customChanges}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelCustomchanges}
        />
        <p>Your custom changes made by you on grid will be removed..</p>
      </Popup>
      {loaderStatus.status && (
        <DirectionSnackbarAlert
          snackbarDisplay={true}
          message={loaderStatus.msg}
          className={snackbarClassName}
          templateUpdater={null}
        />
      )}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} 
        open={
          loaderStatus.status &&
          (loaderStatus.loaderType === "delete_loader" ||
            loaderStatus.loaderType === "archive_loader" ||
            loaderStatus.loaderType === "edit_loader")
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      {
        // <LoadPanel visible={(tableLoaderErrInfo === 'loading') ? true : false} />
      }
      {
        <div className="main_dataGridClass" style={{ height: '100vh', width: '100%' }}>
          <div className="pull-right">
            <div className="pull-right-child">
              <span className="legendLable">
                <span className="redspan1"></span>
                Interview Disqualified
              </span>
              <span className="legendLable">
                <span className="redspan2"></span>
                Interview Completed
              </span>
              <span className="legendLable">
                <span className="redspan3"></span>
                Interview In Process
              </span>
              <span className="legendLable">
                <span className="redspan4"></span>
                Interview Pending
              </span>
              <span className="legendLable">
                <span className="redspan5"></span>
                Unsent
              </span>
              <span className="legendLable">
                <span className="redspan6"></span>
                Interview Link Expired
              </span>
            </div>
          </div>
          <hr />
          <DataGrid
            //  cssClass="kaish"
            // className="interview-list-DG"
            dataSource={store}
            remoteOperations={true}
            keyExpr="id"
            key="Candidate name"
            // onSelectionChanged={onSelectionChanged}
            // selectedRowKeys={viewArchiveList()}
            height='80%'
            width='100%'
            // width={'100%'}
            // style={{ color: 'red' }}
            // showBorders='2'
            hoverStateEnabled
            rowAlternationEnabled={true}
            showBorders={true}
            showRowLines={true}
            // rowTemplate={rowHeight}
            showColumnLines={true}
            columnMinWidth={50}
            allowColumnResizing={true}
            // columnReordering={{ allowReordering: true }
            // }
            // onColumnReordering={handleColReorder}
            onRowRemoving={(e) => deleteInterviewRow(e.data.mid)}
            allowColumnReordering={true}
            loadPanel={{ enabled: true }}
            filterSyncEnabled={true}
            noDataText={tableLoaderErrInfo}
            ref={dataGridRef}
          // columnAutoWidth={true}
          >
            {/* <HeaderFilter visible={true} /> */}
            {/* <Selection
              mode="multiple"
              showCheckBoxesMode={'always'}
            /> */}
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={loadState}
              customSave={saveState}
            />
            <Scrolling mode="standard" />
            <Lookup
              dataSource={store}
              valueExpr="ID"
              displayExpr="Name"
            />
            <Toolbar>
              <Item>
                <DevExtremToolbarClearfilter clearFilters={clearFilters} dataGridRef={dataGridRef} />

              </Item>
              <Item location="after">
                <Button2 hint="Refresh" icon="refresh" onClick={refresh} />
              </Item>
              <Item name="columnChooserButton" />
              <Item name="exportButton" />
              <Item>
                <img
                  title="Clear Customization"
                  className="clean"
                  src={clean}
                  onClick={() => {
                    setClearCustomizationPopUp(true)
                  }}
                ></img>
              </Item>
            </Toolbar>
            <ColumnChooser enabled={true} />
            <Export enabled={true} allowExportSelectedData={false} />
            <ColumnFixing enabled={true} />
            {/* <LoadPanel visible={loaderCircular} /> */}
            <FilterRow visible={true} />
            {/* <FilterPanel visible={true} /> */}
            <Editing
              // mode="row"
              useIcons={true}
              allowUpdating={false}
              allowDeleting={true}
            />
            <Column allowSorting={false}
              width="50px"
              allowFiltering={false}
              alignment="left" caption="S.No" dataField="id" allowReordering={false} />
            <Column allowReordering={false}
              width="255px"
              caption="Candidate Name"
              dataField="CandidateName"
              cellRender={setStatusName}
              editorOptions={{
                showClearButton: true
              }}
            />
            <Column width="240px" dataField="Email" caption="Email Id"
              editorOptions={{
                showClearButton: true
              }}
              allowReordering={false}
            />
            <Column width="100px" caption="Contact No" dataField="Contact No"
              editorOptions={{
                showClearButton: true
              }} />
            <Column width="325px" caption="Job Title" dataField="JobName"
              editorOptions={{
                showClearButton: true
              }} />
            <Column width="150px" dataField="Status" cellRender={setStatus}
              editorOptions={{
                showClearButton: true
              }} />
            <Column
              width="170px"
              dataField="Interview_Start_Date_Time"
              dataType="date"
              editorOptions={true}
              format={filterDateFormat}
              // cellRender={(e) => {

              //   return setStatus(e)
              // }}
              calculateFilterExpression={calculateFilterExpression}
            />
            <Column
              width="180px"
              dataField="Interview_End_Date_Time"
              dataType="date"
              format={filterDateFormat}
              editorOptions={true}
              calculateFilterExpression={calculateFilterExpression}
            // cellRender={(e) => {
            //   return setStatus(e)
            // }}
            />
            <Column width="150px" type="buttons" caption="Action">
              <Button name="delete" />
              {/* <Button
                onClick={(e) => {
                  // console.log("resche", e.row.data.mid);
                  SetReschedulehandler({ type: "reschedule", rowData: e.row });
                }}
                hint="Reschedule"
                name="event"
                icon="event"
              ></Button> */}
              <Button className='reschedule-button' render={renderButton}
                name="event"
                icon="event" />
              {/* <Button
                onClick={(e) => {
                  setPopupVisible({ visible: true, mid: e.row.data.mid })
                }}
                hint="Move to Archive"
                icon="movetofolder"
              /> */}
            </Column>
            <Paging defaultPageSize={20} enabled={true} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={allowedPageSizes}
              showInfo={true}
              visible={true}
            />
          </DataGrid>
        </ div>
      }
      {/* </div> */}
    </>
  );
}
export default InterviewList;


function DevExtremToolbarClearfilter({ clearFilters, dataGridRef }) {

  const [filterAlert, setfilterAlert] = useState(false);

  return (
    <>
      <img
        title='Clear Filter'
        className="clean"
        src={ClearFiltericon}
        onClick={() => {
          const isFilterApplied = dataGridRef.current.instance.getCombinedFilter();
          if (isFilterApplied != undefined) {
            clearFilters();
            setfilterAlert(true);
            setTimeout(() => {
              setfilterAlert(false);
            }, 3000);
          }

        }}
      ></img>



      < SnackBar filterAlert={filterAlert} />
      {/* <SnackBar /> */}
    </>
  )
}

function SnackBar({ filterAlert }) {



  return (
    <>
      {
        filterAlert &&
        <Snackbar
          open={filterAlert}
          autoHideDuration={2000}
          // message="Filter Removed Successfully"
          // action={action}
          // variant="filled"
          variant="outlined"
          severity="success"
        >
          <Alert fontSize="small" variant="filled" severity="success">Filter Removed Successfully</Alert>

        </Snackbar>
      }
    </>
  )
}