// import { environment } from "../utils/Environment/Environment" 

// export const EMAIL_TEMPLATE_KEYWORDS = {
//     GREETING_KEYWORD : 'congratulations',
//     POSITION: 'position',
//     START_DATE: 'start date',
//     INTERVIEW_INVITATION: 'interview invitation',
//     INTERVIEW_APP_URL: environment.videoInterviewUrl
// }


import { environment } from "../utils/Environment/Environment"

export const EMAIL_TEMPLATE_KEYWORDS = {
    GREETING_KEYWORD: 'congratulations [candidate name]',
    POSITION: 'position: [job name]',
    START_DATE: 'start date:[DD:MM:YY HH:MM:SS]',
    INTERVIEW_INVITATION: 'interview invitation',
    // INTERVIEW_APP_URL: environment.videoInterviewUrl
}


export const EMAIL_TEMPLATE_KEYWORDS_PLACEHOLDER = {
    GREETING_KEYWORD: 'congratulations',
    POSITION: 'position:',
    START_DATE: 'start date:',
    INTERVIEW_INVITATION: 'interview invitation',
    // INTERVIEW_APP_URL: environment.videoInterviewUrl
}