import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import plus from '../../../../Assets/icons/plus.png';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Select } from '@mui/material';
import { postQuestions } from '../../../../handlers/question_handler';
import { useUserAuthContext } from '../../../../utils/Contexts/Auth/UserAuthProvider';
import { postQuestionsData } from '../../../../handlers/question_handler';
import { ErrorAlert } from '../../../Alerts/ErrorAlert';
import { SuccessAlert } from '../../../Alerts/SuccessAlert';

/*----redux---*/

import { useSelector, useDispatch } from 'react-redux';
import { CI_InitialStateAction } from '../../../../utils/Redux/Actions';
import { useEffect, useState } from 'react';
/*----redux---*/



export default function AddQuestion({ questionSetId, questionsList }) {
    // console.log(questionSetId);
    const { accessToken, userInfo } = useUserAuthContext()
    const [open, setOpen] = React.useState(false);
    const [questions, setQuestions] = React.useState([{ queName: '', timeLimit: '', thinkTime: '', remark: '' }]);
    const [getPostQuestionsIds, setGetPostQuestionsIds] = React.useState([]);
    const [showError, setShowError] = React.useState({ display: false, text: '' });
    const [alertMessage, setAlertMessage] = useState({ display: false, text: '' });
    const questionsListOrder = React.useRef(questionsList.length);




    const questionSet = useSelector(state => state.handleCreateInterviewInitialState.questionSet);
    const dispatch = useDispatch();



    let userName = '';
    if (Object.keys(userInfo).length) {
        userName = userInfo.email.substring(0, userInfo.email.indexOf('@'));
    }



    React.useEffect(() => {
        questionsListOrder.current = questionsList.length;
    }, [questionsList.length]);


    function closeAlert(setState) {

        setTimeout(() => {
            setState({ display: false, text: '' })
        }, 3000);

    }

    useEffect(() => {
        if (questionSetId) setShowError({ display: false, text: '' })
    }, [questionSetId])

    const handleClickOpen = () => {
        if (questionSetId == '') {
            setShowError({ display: true, text: 'Please select a Question Set to add questions' })
            closeAlert(setShowError)
            return;
        }
        // questionsListOrder.current += 1;
        setOpen(true);
    };

    const handleClose = () => {
        // questionsListOrder.current = questionsList.length;
        setOpen(false);
        setQuestions([{ queName: '', timeLimit: '', thinkTime: '', remark: '' }]);
    };


    const saveQuestionsToCreatedQuestionSet = () => {
        let status = 1;
        // for (let key in questions) {
        //     console.log(questions[key]);
        //     if (questions[key].trim() == '') {
        //         status = 0;
        //         break;
        //     }
        // } 
        for (let i = 0; i < questions.length; i++) {
            if (Number(questions[i].thinkTime.trim()) > Number(questions[i].timeLimit.trim())) {
                status = 0;
                setShowError({ display: true, text: 'Think time should be less then time limit' })
                closeAlert(setShowError);
                break;
            }
            if (questions[i].queName.trim() == '' || questions[i].thinkTime.trim() == '' || questions[i].timeLimit.trim() == '') {
                status = 0;
                setShowError({ display: true, text: 'All fields Are required!' })
                closeAlert(setShowError);
                break;
            }
        }
        if (status) {
            // Post question to the questionnaires (attach the question to the questionnaire (based on the id))
            const _questions = [];
            const questionsExistingIndex = [];
            const _questionsTemp = [];
            // check questions from existing questions from state
            [...questions].forEach((item, index) => {
                let currentQuestionExists = 'not_exist';
                [...questionsList].forEach((item2, index2) => {
                    if (item.queName === item2.queName) {
                        currentQuestionExists = 'exist'
                    }
                });
                if (currentQuestionExists === 'not_exist') {
                    _questionsTemp.push(item);
                }
            });
            //----------------------------------------------------------
            // check questions from existing questions from local state of add question modal 
            _questionsTemp.forEach((item, index) => {
                if (!questionsExistingIndex.includes(item.queName)) {
                    questionsExistingIndex.push(item.queName);
                    _questions.push(item);
                }
            });
            //----------------------------------------------------------
            let tempQuestionsArray = [..._questions].map((que, i) => {
                return {
                    "question": que.queName.trim(),
                    "createdBy": userName,
                    "createdAt": new Date().toISOString(),
                    "attachQuestionnairesId": questionSetId
                }
            });
            postQuestions(accessToken, tempQuestionsArray)
                .then(res => {
                    //console.log('que-set', res);
                    if (res.status) {
                        if (res.data.status === "succeed") {
                            if (res.data.data.message === "Questions name Same Allready Exist!!") {
                                setShowError({ display: true, text: 'Same question already exist!' });
                                closeAlert(setShowError);
                                handleClose();
                            }
                            else {
                                setGetPostQuestionsIds(state => [...state, res.data.data.id[0]])
                                let questionStructure = [..._questions].map((element, index) => {
                                    return { id: res.data.data.id[index], queItemClicked: false, queName: element.queName, timeLimit: element.timeLimit, thinkTime: element.thinkTime, remark: element.remark, order: questionsListOrder.current + index + 1 };
                                })
                                dispatch(CI_InitialStateAction('save_questions_to_created_question_set', { id: questionSetId, questions: questionStructure }));
                                questionStructure = [...questionStructure].map((element, index) => {
                                    return { questionReferenceId: element.id, thinkTime: element.thinkTime, timeLimit: element.timeLimit, order: questionsListOrder.current + index + 1 };
                                })

                                //return;
                                postQuestionsData(accessToken, questionSetId, {
                                    "updatedAt": new Date().toISOString(),
                                    "updatedBy": userName,
                                    "questionsId": questionStructure
                                })
                                    .then(result => {
                                       
                                        if (res.status) {
                                            if (res.data.status === 'succeed') {
                                                setAlertMessage({ display: true, text: 'Question Added successfully' });
                                                closeAlert(setAlertMessage);
                                                handleClose()
                                            }
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                        setShowError({ display: true, text: 'Something went wrong!' });
                                        closeAlert(setShowError);
                                    })
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                    setShowError({ display: true, text: 'Something went wrong!' });
                    closeAlert(setShowError);
                })
        }
    }
    // const handleChange = (value, field) => {
    //     setQuestions(state => ({ ...state, [field]: value }))
    // }
    function addMoreFields() {
        // questionsListOrder.current += 1;
       
        setQuestions(state => [...state, { queName: '', timeLimit: '', thinkTime: '', remark: '' }])
    }
    function removeField(i) {
        //questionsListOrder.current -= 1;
      
        let arr = [...questions]
        arr.splice(i, 1);
        setQuestions(arr);
    }
    const handleChange = (value, index, key) => {
        // let arr = [...formRows];
        // console.log( arr[index][key]);
        
        if (key === 'timeLimit' || key === 'thinkTime') {
            if (!(/^[0-9]*$/.test(value))) {
                return;
            }
        }
        let arr = [...questions].map((item, i) => {
            if (index === i) {
                return { ...item, [key]: value }
            }
            return item;
        })
        // arr[index][key] = value;
       
        setQuestions(arr)
    }
    return (
        <>
            <SuccessAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <ErrorAlert showError={showError} setShowError={setShowError} />
            <div>
                <Tooltip title='Add Question' placement="top">
                    <IconButton aria-label="search" onClick={handleClickOpen}>
                        <img src={plus} />
                    </IconButton>
                </Tooltip>
                <Dialog open={open} onClose={handleClose} sx={{ overflowY: 'scroll' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <DialogTitle>Add New Questions</DialogTitle>
                        <div style={{ padding: '0 22px' }}>
                            {/* <Button variant="outlined" onClick={addMoreFields} color="success" size="small">Add more</Button> */}
                            <Tooltip title="Add more" placement="left-start">
                                <IconButton aria-label="search" className="input-center" style={{ width: '50px', height: '50px' }} onClick={addMoreFields}>
                                    <AddBoxOutlinedIcon color="success" className='add-more-icon' />
                                </IconButton >
                            </Tooltip>
                        </div>
                    </div>
                    <Divider />
                    {
                        questions.map((item, index) => {
                            return < React.Fragment key={index}>
                                <DialogContent sx={{ overflowY: 'initial' }}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Question Name"
                                        sx={{ width: '100%' }}
                                        onChange={(e) => handleChange(e.target.value, index, 'queName')}
                                        value={item.queName}
                                    // onChange={(e) => handleChange(e.target.value, index, 'CandidateName')}
                                    />
                                </DialogContent>
                                <Box sx={{ display: 'inlineflex', justifyContent: 'space-around' }}>
                                    <DialogContent sx={{ overflowY: 'initial' }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Time limit (seconds)"
                                            type='text'
                                            sx={{ width: 'auto' }}
                                            onChange={(e) => handleChange(e.target.value, index, 'timeLimit')}
                                            value={item.timeLimit}
                                            onInput={(e) => {
                                                if (isNaN(parseInt(e.target.value))) {
                                                    e.target.value = '';
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 3)
                                                }
                                                //e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogContent sx={{ overflowY: 'initial' }}>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Think time (seconds)"
                                            type='text'
                                            sx={{ width: 'auto' }}
                                            onChange={(e) => handleChange(e.target.value, index, 'thinkTime')}
                                            value={item.thinkTime}
                                            onInput={(e) => {
                                                if (isNaN(parseInt(e.target.value))) {
                                                    e.target.value = '';
                                                }
                                                else {
                                                    e.target.value = e.target.value.slice(0, 3)
                                                }
                                                //e.target.value = Math.max(0, parseInt(e.target.value) || 0).toString().slice(0, 10)
                                            }}
                                        />
                                    </DialogContent>
                                </Box>
                                <DialogContent sx={{ overflowY: 'initial' }}>
                                    <TextField
                                        id="outlined-required"
                                        label="Remark"
                                        sx={{ width: '100%' }}
                                        onChange={(e) => handleChange(e.target.value, index, 'remark')}
                                        value={item.remark}
                                    />
                                </DialogContent>
                                {questions.length > 1 && <>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div style={{ padding: '5px 10px' }}>
                                            <Tooltip title="Remove" placement="left-start">
                                                <IconButton onClick={() => removeField(index)} sx={{ color: '#ff2020', cursor: 'pointer' }}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {(index < questions.length - 1) && <div style={{ padding: '0 15px' }}><Divider /></div>}
                                </>}
                            </React.Fragment>
                        })
                    }
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" onClick={saveQuestionsToCreatedQuestionSet}>Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}
