import React, { useEffect, useRef, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../../Assets/icons/logo.png';
import pic from '../../../Assets/icons/pic.png';
import ViAdmin from '../../../Assets/icons/ViAdmin.png';
import recruterPic from '../../../Assets/icons/recruterPic.png';

import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { environment } from '../../../utils/Environment/Environment';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import './Header.css';
import { GetUserDetails } from '../../../handlers/User_details_handler';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { Button, Tooltip } from '@mui/material';
import { useCookies } from "react-cookie";

const headerBG = {
  background: 'white',
  // background: 'linear-gradient(16deg, rgba(2,0,36,1) 0%, rgba(39,39,130,1) 0%, rgba(96,175,191,1) 64%)'
}

function Header({ handleDrawerOpen, open }) {

  const { accessToken, userInfo, userRole, setProceedLogoutOut, removeCookie, delete_cookie } = useUserAuthContext();
  // const [removeCookie] = useCookies(['_at']);
  const [info, setInfo] = useState(false);

  const [userDetails, setUserDetails] = useState({});

  let userName = '';
  let userProfileName = '';

  if (userInfo !== null || undefined) {
    if (Object.keys(userInfo).length) {
      userName = userInfo.email.substring(0, userInfo.email.indexOf('@'));
      const userData = userInfo.email.substring(0, userInfo.email.indexOf('@'));
      const data = userData.split(".")

      for (let i = 0; i < data.length; i++) {
        userProfileName += data[i].substring(0, 1).toUpperCase() + data[i].substring(1) + ' ';
      }
    };
  }



  useEffect(() => {
    if (accessToken && userName) {
      GetUserDetails(accessToken, userName)
        .then(res => {
          if (res.status) {
            if (res.data.status === "succeed") {
              if (res.data.data != null) {
                if (res.data.data.response[0] != null) {
                  setUserDetails(res.data.data.response[0])
                }
                else {
                  setUserDetails({
                    active_time: "--:--:--",
                    average_productiviy: 0,
                    cyicpercentage: null,
                    cyteampercentage: null,
                    icpercentage: null,
                    productivity_percentage: 0,
                    teampercentage: null
                  })
                }
              }
              else (
                setUserDetails({
                  active_time: "--:--:--",
                  average_productiviy: 0,
                  cyicpercentage: null,
                  cyteampercentage: null,
                  icpercentage: null,
                  productivity_percentage: 0,
                  teampercentage: null
                })
              )
            }
          }
          // else {
          //   alert('something went wrong')
          // }
        }
        )
        .catch(err => console.log(err))
    }
  }, [accessToken, userName]);



  function logout() {
    // removeCookie('_at');
    delete_cookie(['_at', 'unicode']);
    // removeCookie('refreshToken');
    // removeCookie('unicode');
    //setProceedLogoutOut(true);
    // setTimeout(() => {

    // }, timeout);


    //window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;

    setTimeout(() => {
      window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
    }, 400);

  }


  const ref = useRef();
  useOnClickOutside(ref, () => setInfo(false));

  let profilePic = null

  if (userRole === "Global Administrator") {
    profilePic = < img className='admin-logo' src={pic} />;
  }

  else if (userRole === "VideoInterview Administrator") {
    profilePic = < img className='admin-logo' src={ViAdmin} />
  }
  else if (userRole === "OWNER") {
    profilePic = < img className='admin-logo' src={recruterPic} />
  }

  else {
    profilePic = <AccountCircleRoundedIcon src="pic.png" fontSize='large' />
  }

  return (
    <>
      <Toolbar sx={headerBG}>
        {/* <Box sx={{
          ...(!open),
        }} className='hitech-logo'>
          <img src={logo} />
        </Box> */}
        <IconButton
          color="info"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div className='hitech-logo' style={{ ...(open && { display: 'none' }), }}>
            <img src={logo} />
          </div>
          <marquee scrollamount="3">
            <div style={{ display: 'flex' }}>
              <div className='user-details'>Active Time : &nbsp;{userDetails.active_time != null ? userDetails.active_time : ''}</div>
              <div className='user-details'>Productivity : &nbsp; &nbsp;  IC-&nbsp;<span style={{ color: '#5bb0f2' }}>{userDetails.average_productiviy != undefined ? Math.round(userDetails.average_productiviy).toFixed(2) : ''}%</span>&nbsp;&nbsp; Team-&nbsp;<span style={{ color: '#5bb0f2' }}>{userDetails.teampercentage != undefined ? Math.round(userDetails.teampercentage).toFixed(2) : ''}%</span></div>
              <div className='user-details'>Current Month Performance : &nbsp; &nbsp;  IC-&nbsp;<span style={{ color: '#5bb0f2' }}>{userDetails.icpercentage != undefined ? Math.round(userDetails.icpercentage).toFixed(2) : ''}%</span>&nbsp;&nbsp; Team-&nbsp;<span style={{ color: '#5bb0f2' }}>{userDetails.teampercentage != undefined ? Math.round(userDetails.teampercentage).toFixed(2) : ''}%</span></div>
              <div className='user-details'>YTD- &nbsp;&nbsp;IC- <span style={{ color: '#5bb0f2' }}>{userDetails.cyicpercentage != undefined ? Math.round(userDetails.cyicpercentage).toFixed(2) : ''}&nbsp;%</span>&nbsp;&nbsp;Team-<span style={{ color: '#5bb0f2' }}>{Math.round(userDetails.cyteampercentage).toFixed(2)}&nbsp;%</span></div>
            </div>
          </marquee>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <Tooltip title={(userRole === "VideoInterview Administrator" ? "Video Interview Administrator" : (userRole === "OWNER" ? 'Video Interview Recruiter' : userRole))} >

              <IconButton name='user-card' sx={{ fontSize: '500px', color: '#008ce3' }} onClick={() => { setInfo(!info) }}>
                {profilePic}
              </IconButton>
            </Tooltip>

            <IconButton className='user-info-active-arrow' onClick={() => { setInfo(!info) }}>
              {info ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownRoundedIcon />}
            </IconButton>
          </div>
        </div>
      </Toolbar>
      {
        info &&
        <div className='info-box'>
          <div className='inner-info-box' ref={ref}>
            <div className='info-box-body'>
              <div className='info-box-body-left'>
                <IconButton>
                  <PersonOutlineRoundedIcon />
                </IconButton>
              </div>
              <div className='info-box-body-right'>
                <p>{userProfileName ? userProfileName : <CircularProgress size={14} />}</p>
              </div>
            </div>
            <div onClick={logout} className='info' >
              <div className='info-box-body'>
                <div className='info-box-body-left'>
                  <IconButton onClick={logout}>
                    <PowerSettingsNewIcon />
                  </IconButton>
                </div>
                <div className='info-box-body-right' onClick={logout}>
                  <p>Logout</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Header;

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}