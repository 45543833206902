import { useContext, useEffect } from 'react';
import Main from './Components/Main/Main';
import { useUserAuthContext } from './utils/Contexts/Auth/UserAuthProvider';
import './Assets/css/index.css'

export default function App() {
  const { accessToken, userInfo } = useUserAuthContext();
  //console.log(process.env);

  return (
    <>
    {accessToken && userInfo && <Main />}      
    </>
  );
}
