import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ArchiveIcon from '@mui/icons-material/Archive';
import DialogHolder from '../../Dialog/DialogHolder';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './FadeMenu.css';
import { getEmailTemplate } from '../../../handlers/mail_handler';
import DirectionSnackbarAlert from '../../Alerts/SnakbarAlert';
import { useUserAuthContext } from '../../../utils/Contexts/Auth/UserAuthProvider';
import EmailTemplatePreview from './RescheduleInterview/EmailTemplatePreview';
import SendAndArchiveOutlinedIcon from '@mui/icons-material/SendAndArchiveOutlined';
import { EditInterview } from '../../../handlers/job_handlers';

//import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
export default function FadeMenu({ id, reschedulehandler, data, store, setArchiveList, loadOptionsQuery, refresh }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState({ openType: '', id: '', alertTitle: '', handler: null });
    const [popUpOpen, setPopUpOpen] = React.useState(false);
    const [inputDateTime, setInputDateTime] = useState({ startDateTime: '', endDateTime: '' });
    const [inputDateTimeStatic, setInputDateTimeStatic] = useState({ startDateTime: '', endDateTime: '' });
    const [loaderStatus, setLoaderStatus] = useState({ status: false, loaderType: '', msg: '', isLoading: '' });
    const { accessToken, userInfo } = useUserAuthContext();
    const [template, SetTemplate] = useState('');
    const [emailSentAlert, setEmailSentALert] = useState({ status: 'idle' });
    const [emailPreviewAvailable, setEmailPreviewAvailable] = useState({ isPreviewAvailable: 'preview_unavailable', showPreview: 'hidden', button: 'preview_type' });
    const [InterviewLinkDateTime, setInterviewLinkDateTime] = useState({ startDateTime: '', endDateTime: '' });
    const [inputDateTimeValidationError, setInputDateTimeValidationError] = useState({ status: false, msg: '' });
    const closehandlerDelayIdRef = React.useRef('');
    const [isArchiveupdate, setIsArchiveupdate] = useState('idle');
    const [_startDate, setstartDate] = useState(new Date().toISOString());
    const [displayDateError, setDisplayDateError] = useState('');
    // console.log(reschedulehandler, "rescheduler");
    // const [dialogOpen, setDialogOpen] = useState({ openType: '', id: '', alertTitle: '', handler: null });
    const open = anchorEl;
    const mid = React.useRef('');
    const candidateInfo = React.useRef('');



    // dx
    const [_reschedulehandler, _SetReschedulehandler] = React.useState({
        type: "",
        rowData: "",
    });
    const [_archiveHandler, setArchiveHandler] = React.useState({
        type: "",
        rowData: "",
    });

    useEffect(() => {
        if (isArchiveupdate == 'updated') {
            // alert('kjfb')
            setArchiveList('archiveList')
        }
    }, [isArchiveupdate])


    useEffect(() => {
        if (popUpOpen) {
            let dateTextFirst = '';
            let dateTextSecond = '';
            const dateFormatStrObj = { start: {}, end: {} };
            // const startDateTime = new Date(candidateInfo.current['Interview_Start_Date_Time']).toISOString();
            const startLocalTime = new Date((candidateInfo.current['Interview_Start_Date_Time']));

            const startTimeUtcTime = new Date(startLocalTime.getTime() - (startLocalTime.getTimezoneOffset() * 60000));
            const startDateTime = startTimeUtcTime.toISOString();


            // const endDateTime = new Date(candidateInfo.current['Interview_End_Date_Time']).toISOString('en-IN');
            dateTextFirst = startDateTime.split('T')[0] + 'T';
            dateTextSecond = candidateInfo.current['Interview_Start_Date_Time'].split(' ')[1] + '.000';
            const endLocalTime = new Date((candidateInfo.current['Interview_End_Date_Time']))
            const endTimeUtcTime = new Date(endLocalTime.getTime() - (endLocalTime.getTimezoneOffset() * 60000));
            const endDateTime = endTimeUtcTime.toISOString();

            dateFormatStrObj.start = dateTextFirst + dateTextSecond;
            dateTextFirst = endDateTime.split('T')[0] + 'T';
            dateTextSecond = candidateInfo.current['Interview_End_Date_Time'].split(' ')[1] + '.000';
            dateFormatStrObj.end = dateTextFirst + dateTextSecond;
            setInputDateTime({ startDateTime: dateFormatStrObj.start, endDateTime: dateFormatStrObj.end });
            setInputDateTimeStatic({ startDateTime: dateFormatStrObj.start, endDateTime: dateFormatStrObj.end })
            setInterviewLinkDateTime({
                startDateTime: convertDateFormat(candidateInfo.current['Interview_Start_Date_Time']),
                endDateTime: convertDateFormat(candidateInfo.current['Interview_End_Date_Time'])
            });
        }
    }, [popUpOpen])


    useEffect(() => {
        if (emailSentAlert.status === 'email_sent') {
            closehandlerDelayIdRef.current = setTimeout(() => {
                handlePopUpClose();
                refresh()
                // store.load(loadOptionsQuery.current)
                //store.load()
            }, 3000);

            // loadInterviewListData()
        }

    }, [emailSentAlert.status])


    const handleClick = (event, id) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // console.log('dateTimeArr', inputDateTime);

    function handleDialogClose() {
        setDialogOpen({ openType: '', id: '', alertTitle: '' });
    }


    const editInterviewList = (mid, startDate, endDate) => {
        return EditInterview(accessToken, mid, startDate, endDate, userInfo)
            .then(res => {
                //console.log('editInterviewList', res)
                return res;
            })
            .catch(err => console.log(err))
    };


    function convertDateFormat(date) {
        let startDateEmailText = '';
        const startDateEmailTextStr = new Date(date).toDateString();
        const startDateEmailTextArray = startDateEmailTextStr.split(" ");
        const localTimeString = new Date(date).toTimeString();
        const localTime = localTimeString.slice(0, 8);
        return startDateEmailText = (startDateEmailTextArray[2] + '-' + startDateEmailTextArray[1] + '-' + startDateEmailTextArray[3]) + ' ' + localTime;
    }


    const handleRowClick = (actionType, row) => {

        if (actionType === 'delete') {
            setDialogOpen({ openType: 'delete', id: row.mid, alertTitle: 'Are you sure you want to delete ?', handler: handlers.deleteInterviewRow });
            //setOpen({ delete: true, archive: false, id: row.mid});
            //deleteInterviewRow(row.mid);
        }
        else if (actionType === 'archive') {
            setDialogOpen({
                openType: 'archive', id: row.mid, alertTitle: 'Are you sure you want to move this record into archive?',
                handler: handlers.moveInterviewRowToArchive
            });
        }
    }


    const handleEditClick = (edit, obj) => {
        setPopUpOpen(true)
        mid.current = obj.mid;
        candidateInfo.current = obj.row;

    };

    useEffect(() => {
        // console.log('test', reschedulehandler);
        if (reschedulehandler.type === "reschedule") {
            setAnchorEl(true)
        }

    }, [reschedulehandler])
    // console.log(reschedulehandler);


    useEffect(() => {
        if (reschedulehandler.rowData.data) {
            handleEditClick('edit', { row: reschedulehandler.rowData.data, mid: reschedulehandler.rowData.data.mid })
        }

    }, [reschedulehandler]);


    useEffect(() => {
        if (_reschedulehandler.rowData) {
            handleEditClick('edit', { row: _reschedulehandler.rowData, mid: _reschedulehandler.rowData.mid })
        }

    }, [_reschedulehandler]);



    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    function handleRowEdit() {
        if (inputDateTime.startDateTime == '' || inputDateTime.endDateTime == '') {
            setInputDateTimeValidationError({ status: true, msg: 'Start Date and End Date fields are required!' });
            setTimeout(() => {
                setInputDateTimeValidationError({ status: false, msg: '' });
            }, 2000);
            return;
        }

        if (checkTimeDifference(rescheduleStartDate, inputDateTime.endDateTime) === 'interview_date_not_validated') {
            setInputDateTimeValidationError({ status: true, msg: 'End Date must be greater than Start Date!' });
            setTimeout(() => {
                setInputDateTimeValidationError({ status: false, msg: '' });
            }, 2000);
            return;
        }
             setInterviewLinkDateTime({
            startDateTime: convertDateFormat(inputDateTime.startDateTime),
            endDateTime: convertDateFormat(inputDateTime.endDateTime)
        });

        setEmailSentALert({ status: 'loading' });
        //setLoaderStatus({ status: true, loaderType: 'edit_loader', msg: 'Rescheduling Interview..' });
        let sDate = '';
        let eDate = "";
        if (inputDateTime.startDateTime !== '' && inputDateTime.endDateTime !== '') {
            sDate = new Date(inputDateTime.startDateTime).toString();
            eDate = new Date(inputDateTime.endDateTime).toString();
        }


        // console.log(_startDate.length, _startDate, inputDateTime.endDateTime, 'datee');

        // return;
        editInterviewList(mid.current, _startDate, new Date(inputDateTime.endDateTime).toISOString())
            .then(res => {
                if (res.status) {
                    if (res.data.data.message === "Record Successfully Updated!!") {
                        //setLoaderStatus({ status: true, loaderType: 'edit_loader', msg: 'Interview Rescheduled Successfully' });
                        // loadInterviewListData();

                        getEmailTemplate(accessToken)
                            .then(res => {
                                //console.log('editInterviewList', res)
                                if (res.status) {
                                    SetTemplate(res.data.data.message)

                                }
                            })
                            .catch(err => console.log(err))
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    function checkTimeDifference(startDate, endDate) {


        if ((new Date(endDate).getTime() > new Date(startDate).getTime()) && new Date(endDate).getTime() > new Date().getTime()) {
            return 'interview_date_validated';
        }
        return 'interview_date_not_validated';
    }


    function handlePopUpClose() {
        setEmailPreviewAvailable({ isPreviewAvailable: 'preview_unavailable', showPreview: 'hidden', button: 'preview_type' });
        SetTemplate('');
        setEmailSentALert({ status: 'idle' })
        setPopUpOpen(false);
        // loadInterviewListData(); 
    }

    function handleEmailPreview() {
        if (emailPreviewAvailable.isPreviewAvailable === 'preview_available') {
            clearTimeout(closehandlerDelayIdRef.current);
            setEmailPreviewAvailable(prevState => ({ ...prevState, showPreview: 'show', button: 'hidden_type' }));
        }

    }

    function hideEmailPreview() {
        if (emailPreviewAvailable.isPreviewAvailable === 'preview_available') {
            setEmailPreviewAvailable(prevState => ({ ...prevState, showPreview: 'hidden', button: 'preview_type' }));
        }
    }



    let snackbarClassName = '';
    if (loaderStatus.loaderType === 'record_deleted') {
        snackbarClassName = 'question-update-snackbar';
    }


    let isPreviewButtonDisabled = true;
    if (emailPreviewAvailable.isPreviewAvailable === 'preview_unavailable') {
        isPreviewButtonDisabled = true;
    }
    else if (emailPreviewAvailable.isPreviewAvailable === 'preview_available') {
        isPreviewButtonDisabled = false;
    }





    let newDate = new Date().toISOString()
    const date = new Date(newDate);
    const convertedTimestamp = new Date(date.getTime()).toISOString().replace(/(\d{2})(:\d{2})(:\d{2})/, '$1$2:00.000');
    const rescheduleStartDate = convertedTimestamp.slice(0, -5);
    const rescheduleStartDateValue = addHoursToISOdate();


    function addHoursToISOdate() {
        let arr = new Date().toString();
        arr = arr.split(' ');
        const timeValueInsideDate = arr[4];
        let arr2 = rescheduleStartDate.split('T');
        arr2[1] = timeValueInsideDate;
        return arr2.join('T');
    }


    return (
        <>
            <IconButton sx={{ padding: 0, margin: 0, cursor: (data.Status.includes("Interview completed") || data.Status.includes("Interview InProcess")) ? 'not-allowed' : 'pointer' }} onClick={() => {

                if (!data.Status.includes("Interview completed") && !data.Status.includes("Interview InProcess")) {
                    _SetReschedulehandler({ type: 'reschedule', rowData: data })
                }

            }}><span className='archive-btn1'><Tooltip title="Reschedule" ><CalendarMonthIcon color={(data.Status.includes("Interview completed") || data.Status.includes("Interview InProcess")) ? 'disabled' : 'primary'} fontSize='small' /></Tooltip></span></IconButton>

            <IconButton sx={{ padding: 0, marginLeft: 1 }} onClick={() => {
                // console.log(data, 'jbb');
                setArchiveHandler({ type: 'archive', rowData: data })
                setDialogOpen({
                    openType: 'archive', id: data.mid, alertTitle: 'Are you sure you want to move this record into archive?',
                    // handler: handlers.moveInterviewRowToArchive
                });
            }}> <span className='archive-btn2'> <Tooltip title="Move To Archive"><SendAndArchiveOutlinedIcon color='primary' fontSize='small' /></Tooltip></span></IconButton>

            {/* <ButtonDevExpress
                hint="Reschedule"
                name="event"
                icon="event"
            ></ButtonDevExpress> */}

            {
                loaderStatus.status && <DirectionSnackbarAlert snackbarDisplay={true} message={loaderStatus.msg} className={snackbarClassName} templateUpdater={null} />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderStatus.status && (loaderStatus.loaderType === 'edit_loader')}
            >
                <CircularProgress color="inherit" />
            </Backdrop>



            {
                popUpOpen &&
                <div>
                    <Dialog open={popUpOpen} fullScreen={fullScreen} aria-labelledby="responsive-dialog-title" >
                        <DialogTitle> <span className='abc'> Reschedule Interview </span> </DialogTitle>
                        <DialogContent>
                            <Box>
                                <Box sx={{ display: 'auto', margin: "5px" }}>

                                    <div className='reschedule' ><span className='abc'>Candidate Name:</span> {candidateInfo.current.CandidateName}</div>
                                    <div className='reschedule'><span className='abc'>Candidate Email:</span> {candidateInfo.current.Email}</div>
                                    <div className='reschedule'><span className='abc'>Job Name: </span> {candidateInfo.current.JobName}</div>
                                    <div className='reschedule'><span className='abc'>Interview Id: </span> {candidateInfo.current.mid}</div>

                                    {
                                        emailSentAlert.status === 'loading' ?
                                            <>
                                                <div className='reschedule reschedule-loader-align'><span className='abc'>Interview Start Date:</span>
                                                    <Box sx={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}><CircularProgress size={20} color={'success'} /></Box>
                                                </div>
                                                <div className='reschedule reschedule-loader-align'><span className='abc'>Interview End Date:</span>
                                                    <Box sx={{ marginLeft: '30px', display: 'flex', alignItems: 'center' }}><CircularProgress size={20} color={'success'} /></Box>
                                                </div>
                                            </>
                                            : <>
                                                <div className='reschedule'><span className='abc'>Interview Start Date:</span>
                                                    {
                                                        emailSentAlert.status === 'email_sent' ? ' ' + InterviewLinkDateTime.startDateTime : candidateInfo.current['Interview_Start_Date_Time']
                                                    }
                                                </div>
                                                <div className='reschedule'><span className='abc'>Interview End Date:</span>
                                                    {
                                                        emailSentAlert.status === 'email_sent' ? ' ' + InterviewLinkDateTime.endDateTime : candidateInfo.current['Interview_End_Date_Time']
                                                    }
                                                </div>
                                            </>
                                    }


                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


                                        <ArrowDownwardIcon />

                                    </Box>

                                </Box>

                                <Stack noValidate spacing={3} sx={{ margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <Box>
                                        <TextField
                                            id="datetime-local"
                                            label="Start Date and Time"
                                            type="datetime-local"
                                            defaultValue={rescheduleStartDateValue}
                                            //value={inputDateTime.startDateTime}
                                            onChange={(e) => {
                                                setInputDateTime(prevState => ({ ...prevState, startDateTime: e.target.value }));
                                                if ((e.target.value).length > 18) {
                                                    const inputDateTime = e.target.value;
                                                    const dateObj = new Date(inputDateTime);
                                                    const isoDateString = dateObj.toISOString();
                                                    setstartDate(isoDateString);
                                                }
                                                else {
                                                    const inputDateTime = e.target.value;
                                                    const dateObj = new Date(inputDateTime);
                                                    const isoDateString = dateObj.toISOString();
                                                    setstartDate(isoDateString);
                                                }
                                            }}
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={inputDateTime.startDateTime == ''}
                                            InputProps={{ inputProps: { min: rescheduleStartDate } }}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='arrow-sign'>
                                        <div className='arrow-right-icon'>
                                            {/* <ArrowForwardIcon /> */}
                                        </div>
                                        {/* <div className='arrow-down-icon'>
                                    <ArrowDownwardIcon />
                                </div> */}
                                    </Box>
                                    <Box>
                                        <TextField
                                            id="datetime-local"
                                            label="End Date and Time"
                                            type="datetime-local"
                                            defaultValue={inputDateTime.endDateTime}
                                            // defaultValue="2017-05-24T10:30"
                                            onChange={(e) => {
                                                setInputDateTime(prevState => ({ ...prevState, endDateTime: e.target.value }));
                                            }}
                                            sx={{ width: 250 }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={inputDateTime.endDateTime == '' || checkTimeDifference(rescheduleStartDate, inputDateTime.endDateTime) === 'interview_date_not_validated'}
                                            InputProps={{ inputProps: { min: inputDateTime.startDateTime } }}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                            <EmailTemplatePreview
                                template={template}
                                candidateInformation={{ ..._reschedulehandler.rowData, inputDateTime }}
                                setLoaderStatus={setLoaderStatus}
                                setEmailSentALert={setEmailSentALert}
                                setEmailPreviewAvailable={setEmailPreviewAvailable}
                                emailPreviewAvailable={emailPreviewAvailable}
                                InterviewLinkDateTime={InterviewLinkDateTime} />

                        </DialogContent>
                        <DialogActions></DialogActions>

                        <DialogActions>
                            <Button onClick={handlePopUpClose} disabled={emailSentAlert.status === 'loading'}>Cancel</Button>
                            {
                                emailPreviewAvailable.button === 'hidden_type' && <Button onClick={hideEmailPreview}>Hide Preview</Button>
                            }
                            {
                                emailPreviewAvailable.button === 'preview_type' && <Button onClick={handleEmailPreview} disabled={isPreviewButtonDisabled}>Preview Email</Button>
                            }
                            <Button id='reschedule-button' onClick={handleRowEdit} disabled={(emailSentAlert.status === 'email_sent' || emailSentAlert.status === 'reschedule_error')}>
                                {
                                    (emailSentAlert.status === 'idle' || emailSentAlert.status === 'reschedule_error') && <Box>Reschedule Interview</Box>
                                }
                                {
                                    emailSentAlert.status === 'loading' && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Box><CircularProgress size={20} color={'success'} /></Box>
                                        <Box sx={{ width: '100%', color: 'green', textAlign: 'center', fontWeight: 'bold', marginLeft: '5px' }}>Rescheduling Interview..</Box>
                                    </Box>
                                }
                                {
                                    emailSentAlert.status === 'email_sent' && <Box sx={{ color: 'green', fontWeight: 'bold' }}>Interview Rescheduled</Box>
                                }

                                {/* {
                                    (loaderStatus.status && loaderStatus.loaderType === 'email_loader' && 'email_loader' && loaderStatus.isLoading === 'loading') &&
                                    <CircularProgress size={20} />
                                }
                                {
                                    (!loaderStatus.status && loaderStatus.loaderType === '' && '' && loaderStatus.isLoading === '') && 'Reschedule Interview'
                                } */}
                            </Button>
                        </DialogActions>
                        {
                            emailSentAlert.status === 'email_sent' && <DirectionSnackbarAlert snackbarDisplay={true} message={'Email sent successfully!'} className={'reschedule-email-sent'} templateUpdater={null} />
                        }
                        {
                            inputDateTimeValidationError.status && <DirectionSnackbarAlert snackbarDisplay={true} message={inputDateTimeValidationError.msg} className={'interview-res-validation-error'} templateUpdater={null} />
                        }

                    </Dialog>
                </div>
            }
            <DialogHolder
                open={(dialogOpen.openType === 'delete' || dialogOpen.openType === 'archive')}
                handleClose={handleDialogClose}
                dialogHandler={dialogOpen.handler}
                id={dialogOpen.id}
                accessToken={accessToken}
                userInfo={userInfo}
                dialogTitle={dialogOpen.alertTitle}
                setIsArchiveupdate={setIsArchiveupdate} />
            <React.Fragment>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Edit Row">
                        <IconButton
                            onClick={(e) => handleClick(e, id)}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </Box> */}
                {/* <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },

                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => handleEditClick('edit', id.row)} >
                        <CalendarMonthIcon style={{ color: 'rgb(91, 176, 242)' }} />
                        <Box sx={{ padding: '0 10px' }}>Reschedule</Box>
                    </MenuItem>
                    <MenuItem onClick={() => handleRowClick('delete', id.row)} >
                        <DeleteOutlineRoundedIcon style={{ color: 'red' }} />
                        <Box sx={{ padding: '0 10px' }}>Delete</Box>
                    </MenuItem>
                    <MenuItem onClick={() => handleRowClick('archive', id.row)} >
                        <ArchiveIcon style={{ color: 'rgb(91, 176, 242)' }} />
                        <Box sx={{ padding: '0 10px' }}>Archive</Box>
                    </MenuItem>
                </Menu> */}
            </React.Fragment>
        </>
    );
}
