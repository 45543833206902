import  { useState, useEffect } from "react";

function usePagination(data, itemsPerPage) {
  
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(data.length / itemsPerPage);

    // useEffect(() => {   
    //     console.log('data.length', currentData());
    // }, [data.length])

    function currentData() {
        let begin = (currentPage - 1) * itemsPerPage;
        let end = begin + itemsPerPage;
        if(begin < 0){
            begin = 0;
            end = begin + itemsPerPage;
        }
        // console.log('data.length', begin, end, currentPage, itemsPerPage);
        return data.slice(begin, end);
    }

    function next() {
        setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
    }

    function prev() {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    function jump(page) {
        const pageNumber = Math.max(1, page);
        setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
    }

    return { next, prev, jump, currentData, currentPage, maxPage };
}

export default usePagination;
