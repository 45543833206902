import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './app/Assets/css/index.css';
import App from './app/App';
import { Provider } from 'react-redux';
import { UserAuthProvider } from './app/utils/Contexts/Auth/UserAuthProvider';
import { store } from './app/utils/Redux/Store';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <UserAuthProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </UserAuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


