export const filterDataList = (constraint, array, searchStr) => {
    if (searchStr == '') {
        return array;
    }
    //  let selectedDate = '';
    // console.log(constraint, array, searchStr);
    return array.filter((item, index) => {

        if (constraint === 'filter_through_search') {
         
            let foundStatus = false;
            for (let key in item) { 
                if (key !== 'startDate' && key !== 'endDate' && key !== 'video' && typeof item[key] === 'string') {
                    // console.log('searhch', item[key]);
                    if (item[key].toLowerCase().search(searchStr.toLowerCase()) !== -1) {
                        foundStatus = true;
                        break;
                    }

                }
            }

            return foundStatus;
        }



        if (!item[constraint]) {
            // console.log('sdhkjdkhk');
            return false;
        }

        if (constraint === 'startDate') {


            return interviewFinderWithDate(searchStr, item[constraint].$date, constraint);

            // selectedDate = new Date(searchStr).toLocaleDateString();
            // selectedDate = new Date(selectedDate).getTime();
            // let resdate = new Date(item[constraint].$date).toLocaleDateString();
            // resdate = new Date(resdate).getTime()


            // if (resdate >= selectedDate) {
            //     console.log('tempValueArray.current1 s', new Date(selectedDate), new Date(resdate));
            //     return true;
            // }
            // return false;
        }
        if (constraint === 'endDate') {
            return interviewFinderWithDate(searchStr, item[constraint].$date, constraint);
            // selectedDate = new Date(searchStr).toLocaleDateString();
            // selectedDate = new Date(selectedDate).getTime();
            // let resdate = new Date(item[constraint].$date).toLocaleDateString();
            // resdate = new Date(resdate).getTime()

            // if (resdate <= selectedDate) {
            //     console.log('tempValueArray.current2 e', new Date(selectedDate), new Date(resdate));
            //     return true;
            // }
            // return false;
        }

        if (item[constraint].toLowerCase().search(searchStr.toLowerCase()) !== -1) {
            return true
        }


        return false;
    })
}



function interviewFinderWithDate(selectedDate, responseDate, dateType) {
    let _selectedDate = new Date(selectedDate).toLocaleDateString();
    _selectedDate = new Date(_selectedDate).getTime();
    let _responseDate = new Date(responseDate).toLocaleDateString();
    _responseDate = new Date(_responseDate).getTime();

    if (dateType === 'startDate') {
        if (_responseDate >= _selectedDate) {
            return true;
        }
    }
    else {
        if (_responseDate <= _selectedDate) {
            return true;
        }
    }
    return false;

}