import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SpeedIcon from '@mui/icons-material/Speed';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsIcon from '@mui/icons-material/Settings';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ArchiveIcon from '@mui/icons-material/Archive';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';





export default function Sidebar({menuOpen,crossPageOpen, setCrossPageOpen }) {
  const [open, setOpen] = React.useState({
    'Interview': false,
    'Job': false,
    'Template': false
  });
  const navList = [
    {
      name: 'Dashboard',
      link: '/dashboard' || '/',
      icon: !menuOpen ?  <><Tooltip title='Dashboard' placement='top'><SpeedIcon /></Tooltip></>  : <><SpeedIcon/></>,
      isActive: false,
      item: [],
    },
    {
      name: 'Interview',
      icon:  !menuOpen ? <><Tooltip title='Interview' placement='top'><VideoFileIcon /></Tooltip></> : <VideoFileIcon />,
      isActive: false,
      item: [
        {
       name: 'Create Interview',
       link: '/dashboard/create-interview',
       icon:  !menuOpen ? <><Tooltip title='Create Interview' placement='top'><CreateNewFolderIcon /></Tooltip></> : <CreateNewFolderIcon />,
       isActive: false
        },
        // {
        //   name: 'View Interview List',
        //   link: '/interview/interview-list',
        //   icon: <SummarizeIcon />,
        //   isActive: false
        // },
        {
          name: 'Interview List',
          link: '/interview/interview-list',
          icon:  !menuOpen ? <><Tooltip title='Interview List' placement='top'><SummarizeIcon /></Tooltip></> : <SummarizeIcon />,
          isActive: false
        },
        // {
        //   name: 'One Way Interview',
        //   link: '/interview/one-way-interviewlist',
        //   icon: <VideoLibraryIcon />,
        //   isActive: false
        // },
        // {
        //   name: 'Live Interview',
        //   link: '/interview/live-interview',
        //   icon: <VideocamIcon />,
        //   isActive: false
        // },
        {
          name: 'Archive Interview',
          link: '/interview/archive-interview',
          icon:  !menuOpen ? <><Tooltip title='archive-interview' placement='top'><ArchiveIcon /></Tooltip></> : <ArchiveIcon />,
          isActive: false
        }
  
      ]
    },
    {
      name: 'Job',
      icon:  !menuOpen ? <><Tooltip title='Job' placement='top'><BusinessCenterIcon /></Tooltip></> : <BusinessCenterIcon />,

      isActive: false,
      item: [
        {
          name: 'Create Job',
          link: '/job/create-job',
          icon:  !menuOpen ? <><Tooltip title='Create Job' placement='top'><WorkIcon /></Tooltip></> : <WorkIcon />,
          isActive: false
        },
        {
          name: 'View Job',
          link: '/job/view-job',
          icon:  !menuOpen ? <><Tooltip title='View Job' placement='top'><WorkOutlineIcon /></Tooltip></> : <WorkOutlineIcon />,
          isActive: false
        }
      ]
    },
    // {
    //   name: 'Template',
    //   icon: <ImportContactsIcon />,
    //   isActive: false,
    //   item: [
    //     {
    //       name: 'Email template',
    //       link: '/template/email-template',
    //       icon: <ContactMailIcon />,
    //       isActive: false
    //     },
    //     {
    //       name: 'Question Template',
    //       link: '/template/question-template',
    //       icon: <TextSnippetIcon />,
    //       isActive: false
    //     }
    //   ]
    // },
    // {
    //   name: 'Analytics',
    //   link: '/analytics',
    //   icon: <InsightsIcon />,
    //   isActive: false,
    //   item: []
    // },
    // {
    //   name: 'Settings',
    //   link: '/settings',
    //   icon: <SettingsIcon />,
    //   isActive: false,
    //   item: []
    // },
  ];
  

  const previousActiveChildRef = React.useRef({ parent: 0, child: 0 });

  const [activeItem, setActiveItem] = React.useState(navList);

  const location = useLocation();


  React.useEffect(() => {
    //console.log(location.pathname);

    let listOpenState = '';
    const temp = [...activeItem].map((pItem, pIndex) => {
      // console.log(pItem);
      if (!pItem.item.length) {
        if (pItem.link === location.pathname) {
          pItem.isActive = true;
        }
        else if ((location.pathname === '/' || location.pathname === '/dashboard/review-interview') && (pItem.link === '/dashboard' || pItem.link === '/')) {
          pItem.isActive = true;
        }
        // else if (location.pathname === '/' && pItem.name === 'Dashboard' && (cItem.link === '/dashboard' && cItem.link === '/')) {
        //   pItem.isActive = true;
        // }
      }

      pItem.item.map((cItem, cIndex) => {
        // console.log('cItem', cItem.link);
        if (cItem.link === location.pathname) {
          cItem.isActive = true;
          pItem.isActive = true;
          listOpenState = pItem.name;

        }
        // else if (location.pathname === '/' && pItem.name === 'Interview' && cItem.link === '/interview/interview-list') {
        //   pItem.isActive = true;
        //   cItem.isActive = true;
        //   listOpenState = pItem.name;
        // }



        //Dashboard  '/dashboard' || '/'

        // console.log(location.state === 'job_created_and_redirected' && pItem.name === 'Job' && cItem.link === '/job/view-job');
        else if (location.state === 'job_created_and_redirected' && pItem.name === 'Job') {
          if (cItem.link === '/job/view-job') {
            pItem.isActive = true;
            cItem.isActive = true;
            listOpenState = pItem.name;
          }
          else if (cItem.link === '/job/create-job') {
            cItem.isActive = false;
          }

        }

        else if (location.state === 'interview_scheduled_and_redirected' && pItem.name === 'Interview') {
          if (cItem.link === '/interview/interview-list') {
            pItem.isActive = true;
            cItem.isActive = true;
            listOpenState = pItem.name;
          }
          else if (cItem.link === '/dashboard/create-interview') {
            cItem.isActive = false;
          }

        }


        return cItem;
      })

      return pItem;
    })

    if (listOpenState) {
      setOpen({
        'Interview': false,
        'Job': false,
        'Template': false,
        [listOpenState]: true
      })
    }


    setActiveItem(temp);

  }, [location.state]);

  React.useEffect(() => {
    if (crossPageOpen === 'Create Job') {

      setOpen(state => ({ ...state, 'Job': true }))
      setActiveItem(prevState => {
        const arr = [...prevState].map((element, index) => {
          element.isActive = false;
          element.item.forEach((cElement, i) => {
            cElement.isActive = false;
            if (element.name === 'Job' && cElement.name === 'Create Job') {
              element.isActive = true;
              cElement.isActive = true;
            }
          })
          return element;
        })


        return arr;
      })
    }

  }, [crossPageOpen])

  const handleClick = (item) => {
    setOpen(prevState => {
      return {
        ...prevState,
        [item]: !prevState[item]
      }
    });
  };
  function handleItemClick(item, isParent, childIndex, parentIndex) {

    //console.log('check_navigation', item, isParent, childIndex, parentIndex);

    setCrossPageOpen('');
    setActiveItem(prevState => {
      const arr = [...prevState].map((element, index) => {

        element.isActive = false;
        element.item.forEach((cElement, cIndex) => {
          cElement.isActive = false;
          if (item.name === cElement.name) {
            cElement.isActive = true;
            previousActiveChildRef.current.child = cIndex;
          }
        })

        if (isParent) {
          if (item === element.name) {
            element.isActive = true;
            previousActiveChildRef.current.parent = index;
          }
          else {
            element.isActive = false;
          }
        }
        else {

          //  console.log('check_navigation', element);

          if (parentIndex === index) {
            element.isActive = true;
            previousActiveChildRef.current.parent = index;
          }
          // else {
          //   element.isActive = false;
          // }
        }

        return element;
      })

      return arr;
    })
  }


  // console.log(location);
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {navList.map((item, index) => {
        return <React.Fragment key={index}>
          {
            item.name === 'Interview' || item.name === 'Job' || item.name === 'Template' ?

              <React.Fragment key={index}>
                <ListItemButton key={index} onClick={() => {
                  handleItemClick(item.name, true);

                }} sx={{ backgroundColor: activeItem[index].isActive ? '#c2c1c0' : '' }}>
                  <ListItemIcon key={index} onClick={() => handleClick(item.name)}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} onClick={() => handleClick(item.name)} />

                  <div onClick={() => handleClick(item.name)}  >
                    {open[item.name] ? <ExpandLess /> : <ExpandMore />}
                  </div>

                </ListItemButton>

                <Collapse in={open[item.name]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {
                      item.item.map((childItem, cIndex) => {
                        // console.log('abcd', childItem, cIndex)
                        return <React.Fragment key={cIndex}>
                          <Link to={childItem.link} style={{
                            outline: 'none',
                            textDecoration: 'none',
                            color: '#2e2924'
                          }}>
                            <ListItemButton sx={{ pl: 4, backgroundColor: activeItem[index].item[cIndex].isActive ? '#dbdbdb' : '' }} key={cIndex} onClick={() => { handleItemClick(childItem, false, cIndex, index); }}  >

                              <ListItemIcon key={cIndex}>
                                {childItem.icon}
                              </ListItemIcon>

                              <ListItemText primary={childItem.name} />
                            </ListItemButton>
                          </Link>
                        </React.Fragment>
                      })
                    }
                  </List>
                </Collapse>
              </React.Fragment>
              :
              <>
                <Link to={item.link} style={{ color: '#212121', textDecoration: 'none' }}>
                  <ListItemButton key={index} sx={{ backgroundColor: activeItem[index].isActive ? '#c2c1c0' : '' }} onClick={() => { handleItemClick(item.name, true); }}>
                    <ListItemIcon key={index}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </Link>
              </>
          }
        </React.Fragment>
      })}
    </List>
  );
}





