export const API_PATH = process.env.REACT_APP_VIAPI_PATH;
export const API_PATH_CDS = process.env.REACT_APP_CDSAPI_PATH;
export const AWS_COGNITO = process.env.REACT_APP_AWS_tokenEndPoint;
export const MAIL_PATH = process.env.REACT_APP_GRAPH_MAIL_PATH;
export const CES_API = process.env.REACT_APP_CES_API
export const RUN_API = process.env.REACT_APP_RUN_API;
export const AZURE_MEIDA = process.env.REACT_APP_AZURE_MEDIA_API;
export const APP_DOMAIN = process.env.REACT_APP_domain;
export const REFRESH_TOKEN_CUP = process.env.REACT_APP_REFRESH_TOKEN_CUP;

export const API_ENDPOINTS = {
    QUESTION: {
        GET_QUESTIONNAIRESID: +'nsearch/ViJobInterview',
        GET_QUESTIONID: 'getsearchdata/ViQuestionnaires',
        GET_QUESTION: 'question',
        UPDATE_INFO: 'JobInterview',
        POST_QUESTIONNAIRE: 'questionnaires',
        POST_QUESTION: 'question',
        GET_JOB: 'assignJob',
        CREATE_JOB: 'createJobByRecruiter',
        DELETE_QUESTION: 'removeQuestion',
        GET_ATS_JOB: 'atsJob',
        ADD_CANDIDATE: 'localCandidate',
        SCHEDULE_INTERVIEW: 'prepareInterview',
        JOB_INTERVIEW: 'jobInterview',
        JOB_INTERVIEWPUT: 'ViJobInterview',
        INTERVIEW_LIST: 'getsearchdata/ViJobInterview',
        INTERVIEW_LIST_ROW_DELETE: 'interviewDelete',
        VIEW_JOB_LIST: 'videoInterviewMasterData/ViAssignJob',
        DELETE_CANDIDATE: 'removeCandidate',
        ARCHIVE_CANDIDATE: 'interviewArchiveCandidate',
        EDIT_INTERVIEW_LIST: 'updateCandidateInterview',
        UPDATE_JOB: 'updateJob',
        DELETE_JOB: 'removeJob',
        UPDATE_QUESTION_ORDER: 'updateQuestionsOrder'
    },
    EMAIL_TEMPLATE: 'viGetTemplate',
    VIDEO_INTERVIEW_LIST_VIAPI: 'videoInterviewMasterData/ViJobInterview',
    IS_MAIL_SEND: 'sendMailForInterview',
    GRID_CUSTOMIZE: "videoInterviewCustomization",
    GRID_CUSTOMIZE_GET: "getViCustomization"
};

export const AWS = {
    GET_TOKEN: 'oauth2/',
};

export const CDS = {
    GET_ATS_JOB_CDS: '/jobsByDataVideo',
    GET_USER_DETAILS: '/userdailyheader',
    GET_USER_ROLE: '/videointerviewuserrole',
};

export const RUN = {
    // GET_VIDEO:'/getVideoUrlWithCandidate',
    GET_VIDEO: '/getsearchdata/ViJobInterview'
};

export const CES = {
    GET_CANDIDATE: '/getmultisearch',
}

